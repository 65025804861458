import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class AddStandarsService {
  // static serverURL = "http://143.110.188.253:1919/standard/";
  static service = "standard/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createAddStandards(contact) {
    var dataURL = this.serverURL + "create";

    return axios.post(dataURL, contact);
  }

  //   static deleteStandard(contact) {
  //     var dataURL = this.serverURL + "create";

  //     return axios.post(dataURL, contact);
  //   }

  static deleteStandard(contact, yearName) {
    var dataURL =
      this.serverURL + "delete?standardID=" + contact + "&yearName=" + yearName;

    return axios.delete(dataURL);
  }

  static standardListAll(yearName) {
    var dataURL = this.serverURL + "listall?yearName=" + yearName;

    return axios.get(dataURL);
  }

  static standardStrengthListAll(yearName) {
    var dataURL = this.serverURL + "listall?yearName=" + yearName;

    return axios.get(dataURL);
  }
}
