import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class HolidayService {
  static service = "holiday";
  static baseURL = AppRootUrl.Rooturl();
  static serverURL = this.baseURL + this.service;

  static createHoliday(contact) {
    var dataURL = this.serverURL + "/create";
    console.log(dataURL);
    return axios.post(dataURL, contact);
  }
  static deleteHoliday(id, year) {
    var dataURL =
      this.serverURL + "/delete?holidaymasterID=" + id + "&yearName=" + year;
    return axios.delete(dataURL);
  }
  static Holidaylist(yearName) {
    var dataURL = this.serverURL + "/getbyyear?yearName=" + yearName;
    return axios.get(dataURL);
  }
}
