


import React from 'react'
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";

function FreezeData() {
  return (
    <>
      {/* <LaunchpadTopBar id="afterfinalexam" /> */}
      
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 1,
          m: 1,
          bgcolor: "background.paper",
          borderRadius: 1
        }}
      >

        <Typography
          fontWeight="fontWeightBold"
          variant="body1"
          sx={{ color: "darkblue", fontSize: 20 }}
        >
          {" "}
          DO YOU WANT TO FREEZE ALL THE CURRENT INFORMATION AND<br></br>{" "}
          MIGRATE ALL THE CURRENT DATA TO THE NEXT ACADEMIC YEAR
        </Typography>

      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          p: 3,
          m: 2,
          border: "2px solid black"

          //   borderRadius: 1,
        }}
      >

        <Typography
          fontWeight="fontWeightBold"
          sx={{
            color: "darkblue",
            paddingBottom: 2,
            marginTop: -2,
            textAlign: "center",
            fontSize: 20
          }}
        >
          THIS PROCESS
        </Typography>
        <Typography
          variant="body2"
          fontWeight="fontWeightBold"
          sx={{ textAlign: "center", color: "darkblue", fontSize: 20 }}
        >
          MOVES ALL THE PROMOTED STUDENTS TO THE NEXT STANDARD <br></br>
          DEACTIVATED STUDENTS AND WHO ARE ISSUED TC/LC WILL NOT BE MOVED{" "}
          <br></br>
          PREVIOUS BALANCE FEES INFORMATION WILL NOT BE AVAILABLE <br></br>
          ALL THE PREVIOUS FEES STRUCTURE WILL BE REPLACED WITH NEW FEES
          STRUCTURE <br></br>
          ALL THE STAFF INFORMATION EXCEPT ATTTENDANCE, CLASS ROUTINE, RIGHTS AND
          SALARIES WILL BE MOVED <br></br>
          ALL DEACTIVATED STAFF WILL NOT BE MOVED TO NEXT ACADEMIC YEAR{" "}
          <br></br>
          CLASS ROUTINE WILL NEEDED TO REDESIGNED<br></br>
          PREVIOUS YEARS INFORMATION WILL BE AVAILABLE FOR ONLY
        </Typography>

      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          p: 4,
          m: 2,
          paddingTop: 2,
          marginTop: -2.5,
          bgcolor: "background.paper",
          //   borderRadius: 1,
          border: "2px solid black"
        }}
      >

        <Button
          variant="contained"
          sx={{
            color: "white",
            width: 265,
            fontWeight: "fontWeightBold",
            borderRadius: "16px"
          }}
        >
          confirm freeze
        </Button>


        <Button
          variant="contained"
          sx={{
            color: "white",
            width: 265,
            fontWeight: "fontWeightBold",
            borderRadius: "16px"
          }}
        >
          password
        </Button>


        <Button
          variant="contained"
          sx={{
            color: "white",
            width: 265,
            fontWeight: "fontWeightBold",
            borderRadius: "16px"
          }}
        >
          verification code
        </Button>


        <Button
          variant="contained"
          sx={{
            color: "white",
            width: 265,
            fontWeight: "fontWeightBold",
            borderRadius: "16px"
          }}
        >
          process
        </Button>

      </Box>
    </>
  )
}

export default FreezeData