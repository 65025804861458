import * as React from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { styled } from "@mui/material/styles";
// import styled from "@emotion/styled";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardDivision } from "../../../services/StandardDIvision";
import { BuildSchoolExam } from "../../../services/examStandards";
import { StudentInfoService } from "../../../services/StudentInfo/StudentList";
import { Report } from "../../../services/ReportService";
import { C } from "../../util/C";
import Divider from "@mui/material/Divider";

// const Item = styled(Paper)(({ theme }) => ({
//   // padding: theme.spacing(1),
//   textAlign: "center",
//   border: "1px solid black",
// }));
const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "2px"
  // color: theme.palette.text.secondary
}));
const Item1 = styled(Paper)(({ theme }) => ({
  // padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
}));

const ItemBg = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));

const ItemE = styled(Paper)(({ theme }) => ({
  // padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  height:30,
  verticalAlign:"middle",
  background: "#5485e594",
  "&:hover": {
    background: "#3661b494",
  },
}));

export default function ExamResults() {
  const [Standard, setStandard] = React.useState([]);
  const [studUSN, setStudUSN] = React.useState([]);
  const [Division, setDivision] = React.useState([]);
  const [studentInfo, updateStudentInfo] = React.useState([]);
  const [standardTest, updateStandardTest] = React.useState([]);
  const [testName, setTestName] = React.useState([]);
  React.useEffect(() => {
    studentDetailsLoad();
  }, []);

  const studentDetailsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let studentUSN = localStorage.getItem("studentUSN");
    try {
      let response = await StudentInfoService.studentInfoShowStudentDetails(
        studentUSN,
        data.yearName
      );
      console.log(response.data.data);
      updateStudentInfo(response.data.data);
      setStandard(response.data.data.standardName);
      setStudUSN(response.data.data.studentUSN);
      testLoad(response.data.data.standardID);
      setDivision(response.data.data.divisionName);
      
      
    } catch (error) {
      console.log(error);
    }
  };

  const testLoad = async (id)=> {
    let year = JSON.parse(localStorage.getItem("academicYear"))
try {
  let response = await BuildSchoolExam.showBuildSchoolStandardTest(
    id,
    year.yearName
    );
    console.log(response.data.data);
    response.data.data.sort(function (a, b) {
      return a.testOrder - b.testOrder;
    });
    updateStandardTest(response.data.data);
    // resultLoad(response.data.data.testTitleName);
} catch(error) {
  C.c(error.message);
}
  }

  const onTestSelect = async(e) => {
    console.log(e.currentTarget.textContent);
    setTestName(e.currentTarget.textContent);
    let studentUSN = localStorage.getItem("studentUSN");
    console.log(studentUSN);
      let data =     JSON.parse(localStorage.getItem("academicYear"));
      try {
            let response = await Report.examResult(
              Division,
              Standard,
              e.target.textContent,
              studUSN,
              data.yearName
            );
            console.log(response.data.data);
          } catch(error) {
            console.log(error);
          }
  }
  // const resultLoad = async(div,std,tes) => {
  //   let studentUSN = localStorage.getItem("studentUSN");
  //   let data = JSON.parse(localStorage.getItem("academicYear"))
  //   try {
  //     let response = await Report.examResult(
  //       div,
  //       std,
  //       tes,
  //       studentUSN,
  //       data.yearName
  //     );
  //     console.log(response.data.data);
  //   } catch(error) {
  //     console.log(error);
  //   }
  // }
  
  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="studentinfo" /> */}
      <Box sx={{ flexGrow: 1,mt:2 }}>
        
      <Box sx={{ width: "100%", paddingLeft: "30px", boxSizing: "border-box", mt: 2}}>
          <Grid container>
            <Grid item md={10} xs={8}>
              <Box
                display={"flex"}
                alignContent={"center"}
                justifyContent={"center"}


              >
                <Stack direction="column" sx={{ width: "100%",  boxSizing: "border-box", mt: 2 }}>
                  <Item >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      STUDENT NAME
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      {studentInfo ? studentInfo.studentName : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
              <Box
                sx={{ width: "100%", boxSizing: "border-box", my: "1%" }}
              >
                <Grid  container spacing={{ xs: 1, sm: 1, md: 2 }} sx={{ width: "100%", boxSizing: "border-box", mt: 2 }}>
                  <Grid item md={1} xs={3} >
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        USN
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentUSN : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        STANDARD | DIVISION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.standardName +
                          " | " +
                          studentInfo.divisionID
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={1} xs={3}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        CLASS TR
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {"--"}
                        {/* {studentInfo ? studentInfo.classteacher : "--"} */}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        MEDICAL ATTENTION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.studentMedicalAttentionDetail
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        BLOOD GROUP
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentBloodGroup : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        DOB
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentDOB : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  {/* <Typography
              variant="body1"
              width="250px"
              sx={{ textAlign: "center" }}
            >
              {studentInfo.studentAge + " Age"}
            </Typography> */}
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item>
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        AGE
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentAge : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={2} xs={4}>
              <Box display={"flex"} justifyContent={"center"}  sx={{mt:"2%"}}>
                <Avatar
                  alt="Remy Sharp"
                  src={studentInfo ? studentInfo.studentProfileUrl : ""}
                  //   id={element.staffID}
                  variant="square"
                  sx={{
                    width: 150,
                    height: 190,
                    mx: 2,
                    // mt: 1,
                    border: "3px solid skyblue",
                    borderRadius: "5%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
      </Box>
       
      <Grid
        container
        spacing={1}
        sx={{ width: "100%", padding: "0 10px", boxSizing: "border-box", display:"flex", justifyContent:"center" }}
      >
        <Grid item md={9} xs={8}>
          <ItemBg>
            <Grid container spacing={1} sx={{ minHeight: 40 }}>
              {standardTest.map((element, index) => (
                <Grid item md={2} xs={6} sx={{ cursor: "pointer" }}>
                  <ItemE
                    sx={{ fontWeight: "bold", paddingTop: "5px" }}
                    id={element.standardTestID}
                    
                    onClick={(e) => {
                      onTestSelect(e, index);
                      setTestName(e.currentTarget.textContent);
                    }}
                    className={element.isSelected ? "selectedTest" : ""}
                  >
                    {element.testTitleName.toUpperCase()}
                  </ItemE>
                </Grid>
              ))}
            </Grid>
          </ItemBg>
        </Grid>
      </Grid>


        {/* <Box sx={{ flexGrow: 1 }} ml="5%" mt="1%">
          <Grid container>
            <Grid item sm={2} xs={3} border="2px solid black">
              <Item sx={{ borderBottom: "2px solid black" }}>subject1</Item>
              <Stack direction="row" sx={{ borderBottom: "2px solid black" }}>
                <Item sx={{ width: "50%", borderRight: "2px solid black" }}>
                  50
                </Item>
                <Item sx={{ width: "50%" }}>17</Item>
              </Stack>
              <Item sx={{ borderBottom: "2px solid black" }}>33</Item>
              <Item sx={{ borderBottom: "2px solid black" }}>grade</Item>
              <Item>Result</Item>
            </Grid>
          </Grid>

          <Grid container mt="30px">
            <Grid item sm={2} xs={3} border="2px solid black">
              <Item sx={{ borderBottom: "2px solid black" }}>RESULT</Item>
              <Item>PASS/FAIL</Item>
            </Grid>

            <Grid item sm={2} xs={3} border="2px solid black" borderLeft="none">
              <Item sx={{ borderBottom: "2px solid black" }}>PERCENTAGE</Item>
              <Item>%</Item>
            </Grid>

            <Grid item sm={4} xs={5} border="2px solid black" borderLeft="none">
              <Item sx={{ borderBottom: "2px solid black" }}>
                Teacher's Remark
              </Item>
              <Item>He is obedient and hardworking – Keep it Up</Item>
            </Grid>
          </Grid>
        </Box> */}
       
      </Box>
    </React.Fragment>
  );
}
