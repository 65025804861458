import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Avatar, Button, Typography } from "@mui/material";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center"
  // color: theme.palette.text.secondary,
}));

  const columns = [
    { 
      
      field: 'id', 
    headerName: 'No', 
    width: 80 ,
    headerAlign: "center",
    align: "center",
    renderHeader: ()=> <strong style={{fontSize:15}}>{"No"}</strong>,
    headerClassName: "super-app-theme--header",
    editable:true
  },
    {
     field:' FeesTitle',
     headerName: ' Fees Title',
     width:220,
     headerAlign: "center",
     align: "center",
     renderHeader: ()=> <strong style={{fontSize:15}}>{"Fees Title"}</strong>,
     headerClassName: "super-app-theme--header",
     editable:true
    },
  
    {
      field: 'amount',
      headerName: 'Amount',
      width: 172,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"Amount"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
      
    },
    
    
  
    
  ];
  
  const rows = [
    { id: 1, FeesTitle:'Admission Fees',amount:'788787'},
   
  ];

  const column = [
    { 
      
      field: 'id', 
    headerName: 'No', 
    width: 80 ,
    headerAlign: "center",
    align: "center",
    renderHeader: ()=> <strong style={{fontSize:15}}>{"No"}</strong>,
    headerClassName: "super-app-theme--header",
    editable:true
  },
    {
     field:' selectfees',
     headerName: 'Select Fees',
     width:100,
     headerAlign: "center",
     align: "center",
     renderHeader: ()=> <strong style={{fontSize:15}}>{"Select Fees"}</strong>,
     headerClassName: "super-app-theme--header",
     editable:true
    },
  
    {
      field: 'feesTitle',
      headerName: 'FeesTitle',
      width: 115,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"FeesTitle"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
      
    },
    
    {
      field: 'amount',
      headerName: 'Amount',
      width: 110,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"Amount"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },

    {
      field: 'recieptNo',
      headerName: 'Reciept NO',
      width: 99,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"Reciept NO"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },

    {
      field: 'paid',
      headerName: 'PAID',
      width: 110,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"PAID"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: 'balance',
      headerName: 'Balance',
      width: 113,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"Balance"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },

    {
      field: 'concession',
      headerName: 'Concession',
      width: 110,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"Concession"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },

    {
      field: 'remarks',
      headerName: 'Remarks',
      width: 125,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"Remarks"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    }

    

  ];
  const row = [
    { id: 1, deselectfees:'',feesTitle:'',amount:'', recieptNo:'',paid:'',balance:'',concession:'',remarks:''},
   
  ];

  const column1 = [
    {
      field: 'usid',
      headerName: 'USID',
      // type: 'number',
      width: 110,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"USID"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"Name"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
      
    },
    
    {
      field: 'gender',
      headerName: 'Gender',
      width: 90,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"Gender"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    
    {
      field: 'std',
      headerName: 'STD/DIV',
      // description: 'This column has a value getter and is not sortable.',
      // sortable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"STD/DIV"}</strong>,
      headerClassName: "super-app-theme--header",
     },
     
     {
      field: 'fathersName',
      headerName:'Fathers Name',
    width: 250,
    headerAlign: "center",
    align: "center",
    renderHeader: ()=> <strong style={{fontSize:15}}>{"Fathers Name"}</strong>,
    headerClassName: "super-app-theme--header",
    },
  
    {
      field: 'totalFees',
      headerName: 'TotalFees',
      width: 110,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"TotalFees"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: 'paid',
      headerName: 'PAID',
      width: 90,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"PAID"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: 'concession',
      headerName: 'Concession',
      width: 110,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"Concession"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: 'balance',
      headerName: 'Balance',
      width: 112,
      headerAlign: "center",
      align: "center",
      renderHeader: ()=> <strong style={{fontSize:15}}>{"Balance"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    
    
  ];
  
  const row1 = [
    { id: 1, name: 'Sali Amir', gender: 'Boy', usid:'SA0013', std:'1/A', fathersName:'Suhail Mohammed', TotalFees :'' ,PAID:' ' ,Concession:'',Balance:''},
    
  
  ];


export default function BasicGrid() {

  let navigate = useNavigate();
  const onLogout = () => {
    console.log("onLogout");
    navigate("/launchpad", { replace: true });
  };
  return(
    <div>
      {/* <LaunchpadTopBar id="fees"/> */}
    <Box sx={{ flexGrow: 1 }}>
<Grid container spacing={3}>
<Grid item xs={10}>
        <Item sx={{ height: 165,width:'100%' }}>
        
            <Box sx={{ height: 165, width: '100%'  }}>
      <DataGrid
        showColumnRightBorder={true}
        showCellRightBorder={true}
        rows={row1}
        columns={column1}
        //  pageSize={7}
         rowsPerPageOptions={[10]}
        // checkboxSelection
        // disableSelectionOnClick
        // experimentalFeatures={{ newEditingApi: true }}
        sx={{
          "& .super-app-theme--header": {
                backgroundColor: "#c0c0c0",
                //  color:'white',
                fontWeight: "bold",
                fontSize: "15px",
                border: "1px solid white",
              },
            }}
        
        />
      
    </Box>
        </Item>
          
        </Grid>
        <Grid item xs={2}>
          <Item sx={{height:160,width:200}}>
          <Avatar sx={{ height:160 , width:200}} variant="square">
       </Avatar>
          </Item>
       
        </Grid>
        </Grid>

      <Grid container spacing={0}>
        <Grid item xs={4}>
        <Item sx={{ height: 480 }}>
        <Typography
        sx={{fontSize:"15px", textAlign: "center",  fontWeight: "Bold",backgroundColor:"lightblue" }}
        >APPLIED FEES</Typography>
            <Box sx={{ height: 400, width: '100%'  }}>
      <DataGrid
        showColumnRightBorder={true}
        showCellRightBorder={true}
        rows={rows}
        columns={columns}
        //  pageSize={7}
         rowsPerPageOptions={[10]}
        // checkboxSelection
        // disableSelectionOnClick
        // experimentalFeatures={{ newEditingApi: true }}
        sx={{
          "& .super-app-theme--header": {
                backgroundColor: "#c0c0c0",
                //  color:'white',
                fontWeight: "bold",
                fontSize: "15px",
                border: "1px solid white",
              },
            }}
        
        />
      
    </Box>
          </Item>
        </Grid>
        <Grid item xs={8}>
        <Item sx={{ height: 480 }}>
        <Typography
         sx={{fontSize:"15px", textAlign: "center",  fontWeight: "Bold",backgroundColor:"lightblue" }}
        >APPLIED FEES AND RECEIPTS</Typography>
            <Box sx={{ height: 400, width: '100%'  }}>
      <DataGrid
        showColumnRightBorder={true}
        showCellRightBorder={true}
        rows={row}
        columns={column}
        //  pageSize={7}
         rowsPerPageOptions={[10]}
        // checkboxSelection
        // disableSelectionOnClick
        // experimentalFeatures={{ newEditingApi: true }}
        sx={{
          "& .super-app-theme--header": {
                backgroundColor: "#c0c0c0",
                //  color:'white',
                fontWeight: "bold",
                fontSize: "15px",
                border: "1px solid white",
              },
            }}
        
        />
      
    </Box>
    <Grid container>
      <Grid xs={6}>
    <Button
    variant ="contained"
    color ="info"
    sx={{border:'1px solid black',mt:1,fontWeight:'bold',borderRadius:'10px',color:"white",backgroundColor:"blue"}}>
    Click on Receipt No. to View the Receipt Detail 
</Button>
</Grid>
<Grid xs={6}>
<Button 
 variant ="contained"
 color="success" 
sx={{border:'1px solid black',mt:1,borderRadius:'10px',fontWeight:'bold',color:"white",backgroundColor:"green"}}
>Enter Concession Amount n click to Save</Button>
</Grid>
</Grid>
          </Item>
          <Button 
          onClick={onLogout}
          variant ="contained"
          color = "error"
          sx={{borderRadius:'10px',fontWeight:'bold',ml:95,
        backgroundColor: "#ca424f",
        color: "white",
        }}>
            Do NOTHING-EXIT
          </Button>
        </Grid>
      </Grid>


         
    </Box>
    </div>
    
  );
}
