import {
  Avatar,
  Modal,
  Button,
  gridClasses,
  Paper,
  Typography,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import EditIcon from "@mui/icons-material/Edit";
import { C } from "../../util/C";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { UploadFileService } from "../../../services/FileUploadService";
import { StudentDcoumentService } from "../../../services/StudentDocument";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import { ImageConverter } from "../../../programs/imgaeToBase";

const FormItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: 180,
  // height: 80
  // color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid green",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Bankform() {
  const [request, setRequest] = React.useState();
  const [value, setValue] = React.useState(0);
  const [imageUrl, setImageUrl] = React.useState();
  const [imageSrc, setImageSrc] = React.useState();

  const [openSucess, setSucess] = React.useState(false);

  const handleOpen = () => setSucess(true);
  React.useEffect(() => {
    onDocumentLoad();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await ImageConverter.convertToBase64(file);
    // setPostImage({ ...postImage, myFile: base64 });
    console.log(base64);
    setImageUrl(base64);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    console.log({
      request: data.get("request"),
      // documentImgURL: fileUrl,
      documentType: "blankApplication",
      date: new Date().toLocaleDateString(),
    });

    // const fileData = new FormData();
    // fileData.append("file", imageSrc);
    // console.log(fileData.get("file"));
    // let fileUrl;
    // try {
    //   let response = await UploadFileService.upLoadFile(fileData);
    //   fileUrl = response.data;
    //   console.log(response.data);
    // } catch (error) {
    //   console.log(error.message);
    // }
    let docCbject = {
      description: data.get("request"),
      documentImgURL: imageUrl,
      documentType: "blankApplication",
      date: new Date().toLocaleDateString(),
    };
    try {
      let resp = await StudentDcoumentService.addStudentDocument(docCbject);
      setTimeout(() => {
        setSucess(false);
      }, 2000);
      console.log(resp.data.data);
      // alert("Document Saved Successfully");
      onDocumentLoad();
    } catch (error) {
      console.log(error);
    }
    setImageUrl("");
    setRequest("");
  };

  const onDocumentLoad = async () => {
    let suppliesDocs = [];
    let suppliesDocsData = [];
    try {
      let response = await StudentDcoumentService.getAllDocument();
      response.data.data.forEach((element) => {
        if (element.documentType == "blankApplication") {
          suppliesDocs.push(element);
        }
      });
      console.log(suppliesDocs);
    } catch (error) {
      console.log(error);
    }

    suppliesDocs.forEach(async (rec, i) => {
      // try {
      //   let respImg = await UploadFileService.downloadFile(rec.documentImgURL);
      //   console.log(respImg);
      suppliesDocsData.push({
        id: i+1,
        date: rec.date,
        application: rec.description,
        image: rec.documentImgURL,
        imageDownload: rec.documentImgURL,
      });
      // } catch (error) {
      //   console.log(error);
      // }
    });

    setTimeout(() => {
      setRows(suppliesDocsData);
    }, 1000);
  };

  const [columns, setColumns] = React.useState([
    {
      field: "id",
      headerName: "NO",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
      headerClassName: "super-app-theme--header",
      align: "center",
    },

    {
      field: "date",
      headerName: "DATE",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"DATE"}</strong>,
      headerClassName: "super-app-theme--header",
      align: "center",
    },
    {
      field: "application",
      headerName: "APPLICATION / REQUEST FORMS",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      valueFormatter: (params) => params.value.toUpperCase(),
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"APPLICATION/ REQUEST FORMS"}</strong>
      ),
      headerClassName: "super-app-theme--header",
      align: "center",
    },
    {
      field: "image",
      headerName: "IMAGE",
      flex: 1,
      height: "20%",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"IMAGE"}</strong>,
      headerClassName: "super-app-theme--header",
      renderCell: (rec) => {
        return (
          <Box>
            <Avatar
              component="a"
              variant="square"
              alt="book image"
              src={rec.value}
              sx={{ width: 56, height: 56 }}
              downloadFile
            />
            {/* <img
              style={
                height= "20px",
                width= "20px",
              }
              src={rec.value}
            /> */}
          </Box>
        );
      },
    },
    {
      field: "imageDownload",
      headerName: "DOWNLOAD",
      flex: 1,
      headerAlign: "center",
      align: "center",
      height: "20%",
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"DOWNLOAD"}</strong>
      ),
      renderCell: (rec) => {
        return (
          // <FormItem>
          <>
            <Button
              variant="contained"
              component="label"
              sx={{ fontWeight: "bold" }}
            >
              <a href={rec.value} download style={{ color: "white" }}>
                Download
              </a>
            </Button>
            {/* <Avatar alt="A" src={imageUrl} sx={{ width: 50, height: 50 }} /> */}
            {/* <input
              style={{ display: "none", ml: 5 }}
              id="contained-button-file"
              type="file"
              accept="image/*"
              name="bookImg"
              onChange={fileBrowseHandler}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                size="small"
              >
                Upload
              </Button>
            </label> */}
          </>
          // </FormItem>
        );
      },
    },
    // {
    //   field: "edit",
    //   headerName: "EDIT / UPDATE",
    //   sortable: false,
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   renderHeader: () => (
    //     <strong style={{ fontSize: 15 }}>{"EDIT/ UPDATE"}</strong>
    //   ),
    //   disableClickEventBubbling: true,
    //   renderCell: () => {
    //     return (
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         size="small"
    //         // startIcon={<EditIcon />}
    //       >
    //         Edit / Update
    //       </Button>
    //     );
    //   },
    // },
  ]);

  const [rows, setRows] = React.useState([]);

  const onAddRoW = (e, i) => {
    console.log(rows.length);

    let new_row = {
      // id: rows.length -1,
      id: i + 1,
      application: "",
      pdf: "0",
    };

    setRows((rows) => [...rows, new_row]);
    console.log(rows);
  };

  let navigate = useNavigate();
  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="dataentry" /> */}
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Blank Applications" />
          <Tab label="Blank Application Form" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              mt: 2,
              fontWeight: "bold",
              backgroundColor: "lightblue",
            }}
          >
            CLICK ON IMAGE TO ZOOM VIEW AND PRINT OR DOWNLOAD
          </Typography>
        </Box>
        <Box
          sx={{
            height: 450,
            mt: 2,
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "lightgray",
              //  color:'white',
              fontWeight: "bold",
              fontSize: "15px",
              border: "1px solid white",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            disableColumnMenu
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 3,
              },
              border: "2px solid black",
            }}
          />

          <Grid
            item
            xs={12}
            sx={{ mt: 2, display: "flex", justifyContent: "center", paddingBottom:"25px" }}
          >
            {/* <Button
              variant="contained"
              color="success"
              // onClick={onAddRoW}
              sx={{
                borderRadius: "30px",
                width: "120px",
                fontSize: 15,
                fontWeight: "bold",
                ml: 80,
              }}
            >
              Add Row
            </Button> */}
            <Button
              variant="contained"
              color="error"
              sx={{
                fontSize: 15,
                fontWeight: "bold",
                borderRadius: "30px",
                // ml: 5,
                width: "120px",
                backgroundColor: "#ca424f",
                "&:hover": { backgroundColor: "#ca424f" },
              }}
              onClick={onLaunchpad}
            >
              EXIT{" "}
            </Button>
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box component={"form"} onSubmit={handleSubmit} marginRight={4}>
          <Grid container spacing={{xs:5, md:""}}>
            <Grid item md={6} xs={6}>
              <Box p={1}>
                <TextField
                  fullWidth
                  // width={{ md:"364px", xs:"100px",}}
                  id="request"
                  name="request"
                  label="Request"
                  variant="filled"
                  value={request}
                  onChange={(e) => setRequest(e.target.value.toUpperCase())}
                  display={"none"}
                  sx={{
                    // width:"364px",
                    margin: "10px 10px 10px 0px",
                    textTransform: "uppercase",
                  }}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Box>
            </Grid>
            {/* <Grid item md={1} xs={1}></Grid> */}
            <Grid item md={6} xs={6} >
              <div>
                <Box display={"flex"} justifyContent={"center"}>
                  <Avatar
                    alt="A"
                    src={imageUrl}
                    sx={{ width: 300, height: 300, margin: 1, mt: 0.5, ml: 1 }}
                    variant="square"
                  />
                </Box>
                <Box display={"flex"} justifyContent="space-evenly">
                  <input
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    accept="image/*"
                    name="studentImg"
                    onChange={handleFileUpload}
                  />
                
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      align="center"
                      component="span"
                      sx={{
                        marginTop: 2,
                        borderRadius: 9,
                        fontWeight: 700,
                      }}
                    >
                      UPLOAD IMAGE
                    </Button>
                  </label>
                  <Button
                    type="submit"
                    align="center"
                    color="success"
                    variant="contained"
                    onClick={handleOpen}
                    sx={{
                      // borderRadius: "20px",
                      marginTop: 2,
                        borderRadius: 9,
                        fontWeight: 700,
                      // m: "10px 0 0 120px",
                    }}
                  >
                    Submit Document
                  </Button>
                </Box>
               
              </div>
              <Modal
                open={openSucess}
                // onClose={() => setSucess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div sx={style}>
                  <SucessModal
                    text="Document Uploaded Successfully"
                    name="Document Uploaded Successfully"
                  />
                </div>
              </Modal>
            </Grid>
          </Grid>
          <Box display={"flex"} justifyContent={"center"} mt={6}>
            <Button
              variant="contained"
              color="error"
              sx={{
                fontSize: 15,
                fontWeight: "bold",
                borderRadius: "30px",
                // ml: 5,
                width: "120px",
                backgroundColor: "#ca424f",
                "&:hover": { backgroundColor: "#ca424f" },
              }}
              onClick={onLaunchpad}
            >
              EXIT{" "}
            </Button>
          </Box>
        </Box>
      </TabPanel>
    </React.Fragment>
  );
}
