import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { Button, Grid, Stack, Typography, Modal } from "@mui/material";
import { StaffService } from "../../../services/StaffService";
import { useNavigate } from "react-router-dom";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { autoTable } from "jspdf-autotable";

const style = {
  position: "absolute",
  top: "80%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 150,
  bgcolor: "background.paper",
  border: "2px solid yellow",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  color: "darkblue",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

export default function DataGridDemo() {
  React.useEffect(() => {
    onStaffLoad();
  }, []);

  const [open, setOpen] = React.useState(false);

  const deactivate = () => {
    setOpen(true);
  };
  const [columns, setColumns] = React.useState([
    {
      field: "ssid",
      headerName: "SSID",
      flex: 0.5,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"SSID"} </strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "staffName",
      headerName: "STAFF NAME",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"STAFF NAME"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "designation",
      headerName: "DESIGNATION",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"DESIGNATION"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "type",
      headerName: "TEACHING / NON-TEACHING",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"TEACHING / NON-TEACHING"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "phnNo",
      headerName: "PHONE NO",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"PHONE NO"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "joining",
      headerName: "JOINING",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"JOINING"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "dob",
      headerName: "DOB",
      flex: 1,
      editable: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"D O B"} </strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: () => {
        return (
          // <Button variant="contained" color="success" onClick={deactivate}>
          <Button variant="contained" color="success">
            ACTIVE
          </Button>
        );
      },
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"STATUS"} </strong>,
      headerClassName: "super-app-theme--header",
    },
  ]);

  const [rows, setRows] = React.useState([]);
  const [staff, setStaff] = React.useState([]);
  const [ExcelData, setExcelData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const onStaffLoad = async () => {
    setLoading(true);
    try {
      let response = await StaffService.staffListAll();
      console.log(response.data.data);
      setStaff(response.data.data);
      console.log(staff);
      let yearData = JSON.parse(localStorage.getItem("academicYear"));
      console.log(yearData);
      let staffArray = [];
      response.data.data.forEach(function (data) {
        console.log(data);
        staffArray.push({
          ssid: data.ssid,
          staffName: data.staffName,
          designation: data.designation,
          type: data.staffType,
          phnNo: data.staffMobileNo,
          dob: data.staffDOB,
          joining: data.stafDOJ,
          id: data.staffID,
          yearID: yearData.yearID,
          yearName: yearData.yearName,
          status: data.deleteStatus == true ? "INACTIVE" : "ACTIVE",
        });
      });
      setRows(staffArray);
      setLoading(false);
      let staffExcel = [];
      response.data.data.forEach(function (data) {
        console.log(data);
        staffExcel.push({
          SSID: data.ssid,
          STAFFNAME: data.staffName,
          DESIGNATION: data.designation,
          TYPE: data.staffType,
          PHONENUM: data.staffMobileNo[0],
          DOB: data.staffDOB,
          JOININGDATE: data.stafDOJ,
          YERANAME: yearData.yearName,
          STATUS: data.deleteStatus == true ? "INACTIVE" : "ACTIVE",
        });
      });
      setExcelData(staffExcel);
      console.log(staffExcel);
      console.log(rows);
    } catch (error) {
      console.log(error);
    }
  };

  let navigate = useNavigate();
  const onLogout = () => {
    console.log("onLogout");
    navigate("/launchpad", { replace: true });
  };

  const [academicYear, setacademicYear] = React.useState(
    JSON.parse(localStorage.getItem("academicYear"))
  );
  const [schoolName, setschoolName] = React.useState(
    JSON.parse(localStorage.getItem("schoolDetails"))
  );

  const generatePDF = () => {
    const doc = new jsPDF("landscape");
    const sch = schoolName.schoolNameOnDocs.toUpperCase();
    const acad = academicYear.yearName;
    const img = schoolName.schoolLogoUrl;
    doc.setFont("helvetica", "bold");

    var fontSize = 18;
    var text = `${sch}`;
    var pageWidth = doc.internal.pageSize.getWidth();
    // var textWidth =
    //   (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    // var centerX = (pageWidth - textWidth) / 2;
    doc.text(text, 107, 15);

    doc.addImage(img ,"JPEG", 15,5,27,27);
    doc.setFontSize(10);
    doc.text(` ${acad}`, 265, 15);
    doc.setFontSize(15);
    doc.text("STAFF LIST", 135, 35);

    const tableData = rows.map((row) => [
      row.ssid,
      row.staffName,
      row.designation,
      row.type,
      row.phnNo,
      row.dob,
      row.joining,
      row.yearname,
      row.deleteStatus,
    ]);
    doc.autoTable({
      startY: 40,
      tableLineColor: [0,0,0],
      head: [
        [
          "SSID",
          "STAFF NAME",
          "DESIGNATION",
          "TEACHING/NON-TEACHING",
          "PHONE NO",
          "JOINING",
          "DOB",
        ],
      ],
      body: tableData,
      tableLineWidth: 0.95,
      theme: "grid",
      headStyles: {
        valign: "middle",
        backgroundColor: "lightgray",
        halign: "center",
       
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
        lineColor: [0,0,0],
      },
    });
    doc.setFontSize(10);
    // doc.text("Copyright: Technotharanga Solutions Pvt.ltd", 190, 800);
    doc.save("Staff List.pdf");
  };

  const fileType = "csv";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(ExcelData);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, "StaffList" + ".csv");
  };

  return (
    <div>
      <React.Fragment>
        {/* <LaunchpadTopBar id="staff" /> */}
        {loading ? (
          <div style={{padding:"180px 0px", display:"flex", justifyContent:"center",}}>
          <div className = "spinner-container">
          <div className = "loading-spinner">
          </div>
        </div>
        </div>
        ) : (
        <Box>
        <Box sx={{ m: 2 }}>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              backgroundColor: "lightblue",
              my: 1,
              fontWeight: "bold",
            }}
          >
            LIST OF ALL STAFF
          </Typography>
        </Box>
        <Box
          sx={{
            height: 423,
            padding: "0 16px",
            boxSizing: "border-box",
            mt: "1%",
            width: "100%",
              "& .super-app-theme--header": {
                backgroundColor: "#c0c0c0",
                // color:'white',
                fontWeight: "bold",
                fontSize: "15px",
                border: "1px solid white",
              },
              overflowX:"auto",
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            hideFooter
            disableColumnMenu
            rowsPerPageOptions={[10]}
            sx={{minWidth:1200}}
            // onCellClick={oncellselect}
            // getRowHeight={() => 'auto'}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            
            
            // experimentalFeatures={{ newEditingApi: true }}
            onCellEditCommit={(params) => console.log(params)}
          />
        </Box>
        
        <Box sx={{ display: "flex", justifyContent: "space-around", mt:2 }}>
       <Button
                variant="contained"
                size="large"
                color="error"
                onClick={generatePDF}
                sx={{
                  borderRadius: "30px",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                PDF
              </Button>
              <Button
                variant="contained"
                size="large"
                color="success"
                onClick={exportToCSV}
                sx={{
                  borderRadius: "30px",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                excel
              </Button>
     
        <Button
                variant="contained"
                size="large"
                onClick={()=>{window.print()}}
                sx={{
                  borderRadius: "30px",
                  backgroundColor:"black",
                  color:"white",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                  "&:hover":{backgroundColor:"black",color:"white"}
                }}
              >
                print
              </Button>
        <Button
            onClick={onLogout}
            variant="contained"
            sx={{
              backgroundColor: "#ca424f",
              width:"140px",
              color: "white",
              marginBottom:2,
              borderRadius: " 30px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#ca424f" },
              
            }}
          >
            EXIT
          </Button>
        </Box>

        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                justifyContent: "center",
              }}
            ></Box>
            <Typography variant="h6" textAlign="center">
              <b>DO YOU WANT TO DEACTIVATE THE STAFF </b>
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                color="success"
                size="small"
                variant="contained"
                onClick={() => {
                  setOpen(false);
                }}
              >
                YES
              </Button>
              <Button
                color="error"
                size="small"
                variant="contained"
                onClick={() => {
                  setOpen(false);
                }}
              >
                NO
              </Button>
            </Box>
          </Box>
        </Modal>
        </Box>
        )}
      </React.Fragment>
    </div>
  );
}
