import React from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./events-utils";
import { Grid, Paper } from "@mui/material";
import styled from "@emotion/styled";
import "./events.css";
import { useState } from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { HolidayService } from "../../../services/holidaysService";
import { C } from "../../util/C";
import { useEffect } from "react";
import { parse } from "date-fns";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
}));

export default function DemoApp(props) {
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const handleEvents = (events) => {
    setCurrentEvents(events);
  };
  // const [events, setEvents] = React.useState([]);
  React.useEffect(() => {
    onholidayLoad();
  }, []);
  const [events, setEvents] = useState([]);
  let yearData = JSON.parse(localStorage.getItem("academicYear"));
  const onholidayLoad = async () => {
    try {
      let response = await HolidayService.Holidaylist(yearData.yearName);
      C.c(response.data.data);
      setEvents(response.data.data);
      C.c(events);
    } catch (error) {
      C.c(error);
    }
  };

  const handleEventClick = (clickInfo) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${clickInfo.event.title}'`
      )
    ) {
      clickInfo.event.remove();
    }
  };
  const handleDateSelect = (selectInfo) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    let title = prompt("Please enter a new title for your event");
    let calendarApi = selectInfo.view.calendar;
    let date = calendarApi.currentDataManager.data.viewTitle;
    console.log(calendarApi);
    // console.log(calendarApi.currentDataManager.data.viewTitle)
    console.log(date);
    var arr = date.split(/ |,/);
    // var month = arr[0];
    // console.log(month)
    var year = arr[1];
    var startDate = selectInfo.startStr;
    var array = new Array();
    array = startDate.split("-");
    var newDate = array[2] + " " + array[1] + " " + array[0];
    var newDate1 = array[2];
    var month = array[1];
    var month1 = parseInt(month);
    console.log(month);
    console.log(month1);
    console.log(newDate);
    console.log(newDate1);
    // console.log(month);
    console.log(year);
    calendarApi.unselect(); // clear date selection

    // event description
    console.log(title);
    if (title) {
      calendarApi.addEvent({
        // OneventLoad
        // id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }

    // var event1 = calendarApi.getEventById('a');
    // console.log(event1);
    let holiday = [
      {
        holidayDesc: title,
        monthName: month,
        yearName: year,
        deleteStatus: false,
        // holidaymasterID: "string",
        date: newDate1,
        // "divisionID": "string",
        // "divisionName": "string",
        // "holidayType": "string",
        // "standardID": "string",
        // "standardName": "string",
        yearID: data.yearID,
        yearName: data.yearName,
      },
    ];
    console.log(holiday);
    // if (title) {
    calendarApi.addEvent = async () => {
      try {
        let response = await HolidayService.createHoliday(holiday);
        C.c(response.data.data);
      } catch (error) {
        C.c(error);
      }
      // };
    };
  };
  function renderSidebar() {
    return (
      <>
        <div className="demo-app-sidebar">
          <div className="demo-app-sidebar-section">
            <label mb="3">
              <input
                type="checkbox"
                checked={weekendsVisible}
                onChange={handleWeekendsToggle}
              ></input>
              toggle weekends
            </label>
          </div>
          <div className="demo-app-sidebar-section">
            {/* <h2>All Events ({currentEvents.length})</h2><hr /> */}
            {currentEvents.map(renderSidebarEvent)}
          </div>
        </div>
      </>
    );
  }
  function renderSidebarEvent(element) {
    return (
      <>
        {events.map((element) => (
          <li>
            <i style={{ display: "inline" }}>
              {formatDate(element.start, {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </i>
            <h5 style={{ color: "red", display: "inline" }}>{element.title}</h5>
          </li>
        ))}
        {/* <li key={event.id}>
                    <i style={{ display: 'inline' }}>
                        {formatDate(event.start, {
                            year: "numeric",
                            month: "short",
                            day: "numeric"
                        })}
                    </i><h5 style={{ color: 'red', display: 'inline' }}>{event.title}</h5>
                </li><hr /> */}
      </>
    );
  }
  function renderEventContent(eventInfo) {
    return (
      <>
        <h5 style={{ backgroundColor: "yellow" }}>{eventInfo.timeText}</h5>
        <i style={{ backgroundColor: "yellow", color: "red" }}>
          {eventInfo.event.title}
        </i>
      </>
    );
  }
  const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible);
  };
  return (
    <React.Fragment>
      <LaunchpadTopBar id="dataentry" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <div className="demo-app">
              {renderSidebar()}
              <div className="demo-app-main">
                <FullCalendar
                  className="fc fc-sun"
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    // left: "prev,title,next",
                    // left: "prev,next",
                    // center: "title",
                    // right: "dayGridMonth,"
                    start: "title",
                    // center: '',
                    end: "prev,next",
                  }}
                  initialView="dayGridMonth"
                  editable={true}
                  selectable={true}
                  // selectMirror={true}
                  showOtherMonthDays={false}
                  dayMaxEvents={true}
                  weekends={weekendsVisible}
                  // dateClick={alert('hasgadf')}
                  // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                  select={handleDateSelect}
                  eventContent={renderEventContent} // custom render function
                  eventClick={handleEventClick}
                  eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                  // events={handleDateSelect}
                  // eventChange
                  // eventRemove
                />
              </div>
            </div>
          </Item>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
