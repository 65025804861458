import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { Button, Stack, Tab, Tabs, Typography, Fade } from "@mui/material";
import { C } from "../../util/C";
import { StaffService } from "../../../services/StaffService";
import PropTypes from "prop-types";
import { StudentAttendenanceService } from "../../../services/studentAttendance";
import { useNavigate } from "react-router-dom";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import AttendenceTotal from "./attendenceTotal";
import Backdrop from "@mui/material/Backdrop";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid black",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

export default function BasicGrid() {
  const [AttendenceData, setAttendenceData] = React.useState([]);
  const [isloading, setIsloading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([
    {
      field: "id",
      headerName: "NO.",
      width: 90,
      sortable: false,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO."}</strong>,
    },
    {
      field: "standard",
      headerName: "Standard",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"STANDARD"}</strong>
      ),
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "divisionName",
      headerName: "Division",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"DIVISION"}</strong>
      ),
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "attendence",
      headerName: "Absent || Present",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontSize: 15
        }}><span style={{color:"red"}}>{"ABSENT"}</span> {"||PRESENT"}</strong>
      ),
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
  ]);

  // const [finalAttemdence, setFinalAttemdence] = React.useState(
  //   JSON.parse(localStorage.getItem("studentsAttendence"))
  // );

  React.useEffect(() => {
    // let attendence = JSON.parse(localStorage.getItem("studentsAttendence"));
    // onload(attendence);
    // =======================column setup==============================
    // attendence.forEach((e) => {
    //   if (e.divisionName == "A") {
    //     totalcolum = 1;
    //   } else if (e.divisionName == "B") {
    //     totalcolum = 2;
    //   } else if (e.divisionName == "C") {
    //     totalcolum = 3;
    //   } else if (e.divisionName == "D") {
    //     totalcolum = 4;
    //   } else if (e.divisionName == "E") {
    //     totalcolum = 5;
    //   } else if (e.divisionName == "F") {
    //     totalcolum = 6;
    //   } else if (e.divisionName == "G") {
    //     totalcolum = 7;
    //   } else if (e.divisionName == "H") {
    //     totalcolum = 8;
    //   } else if (e.divisionName == "I") {
    //     totalcolum = 9;
    //   } else if (e.divisionName == "J") {
    //     totalcolum = 10;
    //   }
    // });

    // let localColumn = [
    //   { field: "id", headerName: "ID", width: 90 },
    //   {
    //     field: "standard",
    //     headerName: "Standard",
    //     flex: 0.5,
    //   },
    // ];

    // if (totalcolum == 1) {
    //   localColumn.push({
    //     field: "a",
    //     headerName: "A",
    //     flex: 1,
    //   });
    // } else if (totalcolum == 2) {
    //   localColumn.push(
    //     {
    //       field: "a",
    //       headerName: "A",
    //       flex: 1,
    //     },
    //     {
    //       field: "b",
    //       headerName: "B",
    //       flex: 1,
    //     }
    //   );
    // } else if (totalcolum == 3) {
    //   localColumn.push(
    //     {
    //       field: "a",
    //       headerName: "A",
    //       flex: 1,
    //     },
    //     {
    //       field: "b",
    //       headerName: "B",
    //       flex: 1,
    //     },
    //     {
    //       field: "c",
    //       headerName: "C",
    //       flex: 1,
    //     }
    //   );
    // } else if (totalcolum == 4) {
    //   localColumn.push(
    //     {
    //       field: "a",
    //       headerName: "A",
    //       flex: 1,
    //     },
    //     {
    //       field: "b",
    //       headerName: "B",
    //       flex: 1,
    //     },
    //     {
    //       field: "c",
    //       headerName: "C",
    //       flex: 1,
    //     },
    //     {
    //       field: "d",
    //       headerName: "D",
    //       flex: 1,
    //     }
    //   );
    // } else if (totalcolum == 5) {
    //   localColumn.push(
    //     {
    //       field: "a",
    //       headerName: "A",
    //       flex: 1,
    //     },
    //     {
    //       field: "b",
    //       headerName: "B",
    //       flex: 1,
    //     },
    //     {
    //       field: "c",
    //       headerName: "C",
    //       flex: 1,
    //     },
    //     {
    //       field: "d",
    //       headerName: "D",
    //       flex: 1,
    //     },
    //     {
    //       field: "e",
    //       headerName: "E",
    //       flex: 1,
    //     }
    //   );
    // } else if (totalcolum == 6) {
    //   localColumn.push(
    //     {
    //       field: "a",
    //       headerName: "A",
    //       flex: 1,
    //     },
    //     {
    //       field: "b",
    //       headerName: "B",
    //       flex: 1,
    //     },
    //     {
    //       field: "c",
    //       headerName: "C",
    //       flex: 1,
    //     },
    //     {
    //       field: "d",
    //       headerName: "D",
    //       flex: 1,
    //     },
    //     {
    //       field: "e",
    //       headerName: "E",
    //       flex: 1,
    //     },
    //     {
    //       field: "f",
    //       headerName: "F",
    //       flex: 1,
    //     }
    //   );
    // } else if (totalcolum == 7) {
    //   localColumn.push(
    //     {
    //       field: "a",
    //       headerName: "A",
    //       flex: 1,
    //     },
    //     {
    //       field: "b",
    //       headerName: "B",
    //       flex: 1,
    //     },
    //     {
    //       field: "c",
    //       headerName: "C",
    //       flex: 1,
    //     },
    //     {
    //       field: "d",
    //       headerName: "D",
    //       flex: 1,
    //     },
    //     {
    //       field: "e",
    //       headerName: "E",
    //       flex: 1,
    //     },
    //     {
    //       field: "f",
    //       headerName: "F",
    //       flex: 1,
    //     },
    //     {
    //       field: "g",
    //       headerName: "G",
    //       flex: 1,
    //     }
    //   );
    // } else if (totalcolum == 8) {
    //   localColumn.push(
    //     {
    //       field: "a",
    //       headerName: "A",
    //       flex: 1,
    //     },
    //     {
    //       field: "b",
    //       headerName: "B",
    //       flex: 1,
    //     },
    //     {
    //       field: "c",
    //       headerName: "C",
    //       flex: 1,
    //     },
    //     {
    //       field: "d",
    //       headerName: "D",
    //       flex: 1,
    //     },
    //     {
    //       field: "e",
    //       headerName: "E",
    //       flex: 1,
    //     },
    //     {
    //       field: "f",
    //       headerName: "F",
    //       flex: 1,
    //     },
    //     {
    //       field: "g",
    //       headerName: "G",
    //       flex: 1,
    //     },
    //     {
    //       field: "h",
    //       headerName: "H",
    //       flex: 1,
    //     }
    //   );
    // } else if (totalcolum == 9) {
    //   localColumn.push(
    //     {
    //       field: "a",
    //       headerName: "A",
    //       flex: 1,
    //     },
    //     {
    //       field: "b",
    //       headerName: "B",
    //       flex: 1,
    //     },
    //     {
    //       field: "c",
    //       headerName: "C",
    //       flex: 1,
    //     },
    //     {
    //       field: "d",
    //       headerName: "D",
    //       flex: 1,
    //     },
    //     {
    //       field: "e",
    //       headerName: "E",
    //       flex: 1,
    //     },
    //     {
    //       field: "f",
    //       headerName: "F",
    //       flex: 1,
    //     },
    //     {
    //       field: "g",
    //       headerName: "G",
    //       flex: 1,
    //     },
    //     {
    //       field: "h",
    //       headerName: "H",
    //       flex: 1,
    //     },
    //     {
    //       field: "i",
    //       headerName: "I",
    //       flex: 1,
    //     }
    //   );
    // } else if (totalcolum == 10) {
    //   localColumn.push(
    //     {
    //       field: "a",
    //       headerName: "A",
    //       flex: 1,
    //     },
    //     {
    //       field: "b",
    //       headerName: "B",
    //       flex: 1,
    //     },
    //     {
    //       field: "c",
    //       headerName: "C",
    //       flex: 1,
    //     },
    //     {
    //       field: "d",
    //       headerName: "D",
    //       flex: 1,
    //     },
    //     {
    //       field: "e",
    //       headerName: "E",
    //       flex: 1,
    //     },
    //     {
    //       field: "f",
    //       headerName: "F",
    //       flex: 1,
    //     },
    //     {
    //       field: "g",
    //       headerName: "G",
    //       flex: 1,
    //     },
    //     {
    //       field: "h",
    //       headerName: "H",
    //       flex: 1,
    //     },
    //     {
    //       field: "i",
    //       headerName: "I",
    //       flex: 1,
    //     },
    //     {
    //       field: "j",
    //       headerName: "J",
    //       flex: 1,
    //     }
    //   );
    // }
    // setColumns(localColumn);
    // =======================column setup==============================

    // localStorage.setItem("studentsAttendence", JSON.stringify([]));
    onactivate();
  }, []);
  const onactivate = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    const currentDate = new Date();
    const iso8601Date = currentDate.toISOString().split("T")[0];
    const options = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);
    console.log(iso8601Date);
    //localhost:1919/studentattendance/getdateabsentreport?date=2023-11-07T06%3A51%3A43.567Z&yearName=2023-2024
    //localhost:1919/studentattendance/getdateabsentreport?date=2023-11-07T07:03:23.097Z&yearName=2023-2024

    // const formattedDate = currentDate.toLocaleDateString();
    // const formattedTime = currentDate.toLocaleTimeString();
    // console.log(formattedDate);
    // console.log(formattedTime);
    let attendenceList;
    try {
      let response = await StudentAttendenanceService.getAbsentreport(
        iso8601Date,
        data.yearName
      );
      attendenceList = response.data.data;
      setAttendenceData(response.data.data);
      C.c(response.data.data);
    } catch (error) {
      C.c(error.message);
    }

    let localRow = [];
    if (attendenceList) {
      for (var i = 0; i < attendenceList.length; i++) {
        console.log(attendenceList[i]);
        if (attendenceList[i].status === "false") {
          localRow.push({
            id: i + 1,
            studentdayattendanceID: attendenceList[i].studentdayattendanceID,
            standard: attendenceList[i].standardName,
            divisionName: attendenceList[i].divisionName,
            attendence:
              attendenceList[i].totalAbsent +
              " || " +
              attendenceList[i].totalPresent,
          });
        }
        console.log(localRow);
      }
    }

    setRows(localRow);

    setIsloading(true);
  };
  let totalcolum = 0;
  const onload = () => {
    // const uniqueArray = columns.filter(
    //   (value, index, self) => self.indexOf(value) === index
    // );
    // setColumns(uniqueArray);
    // console.log(uniqueArray);
  };

  const onDateSelect = (val) => {
    if (val === 0) {
      return "Sunday";
    } else if (val === 1) {
      return "Monday";
    } else if (val === 2) {
      return "Tueday";
    } else if (val === 3) {
      return "Wednesday";
    } else if (val === 4) {
      return "Thursday";
    } else if (val === 5) {
      return "Friday";
    } else if (val === 6) {
      return "Saturday";
    } else {
      return "Invalid day";
    }
  };

  const [attendenceApprove, SetattendenceApprove] = React.useState([]);

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    // console.log(selectedRowsData);
    SetattendenceApprove(selectedRowsData);
  };

  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);

  const onAttendenceReport = async () => {
    // console.log(attendenceApprove);
    // console.log(AttendenceData);
    let filterAttendence = [];
    attendenceApprove.forEach((r) => {
      AttendenceData.forEach((v) => {
        if (r.studentdayattendanceID == v.studentdayattendanceID) {
          filterAttendence.push(v);
        }
      });
    });

    filterAttendence.forEach((r) => {
      r.status = "true";
    });

    console.log(filterAttendence);

    setAlert(false);
    filterAttendence.forEach(async (rec) => {
      try {
        let response = await StudentAttendenanceService.createAttendance(rec);
      //  setAlert(false);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 2000);
      } catch (error) {
        C.c(error.message);
      }
    });

    onactivate();
    // finalAttemdence.forEach(async (rec) => {
    //   try {
    //     let response = await StudentAttendenanceService.createAttendance(rec);
    //     setOpen(true);
    //     setTimeout(() => {
    //       setOpen(false);
    //     }, 2000);
    //   } catch (error) {
    //     C.c(error.message);
    //   }
    //   // setRows("");
    // });
    // localStorage.setItem("studentsAttendence", JSON.stringify([]));
    // alert("Attendence successfully Aproved");
  };

  let navigate = useNavigate();
  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };

  return (
    <div
      style={{
        backgroundImage: `url("bgColor11.png")`,
        backgroundSize: "cover",
      }}
    >
      {/* <LaunchpadTopBar id="attendence" /> */}
      <Box sx={{ margin: "10px" }}>
        {isloading ? (
          <Box sx={{ height: 400, width: "100%" }}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                backgroundColor: "lightblue",
                my: 1,
                fontWeight: "bold",
              }}
            >
              APPROVE TODAY'S ATTENDANCE
            </Typography>
            <Box
              sx={{
                height: 400,
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#c0c0c0",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                rows={rows ? rows : []}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                hideFooter
                disableRowSelectionOnClick
                onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
              />
            </Box>
            <AttendenceTotal></AttendenceTotal>

            <Box sx={{ mt: 1, display:"flex",justifyContent:"space-evenly", paddingBottom:"25px"}}>
              <Button variant="contained"  onClick={() => setAlert(true)}>
                Approve and Close
              </Button>

              <Button
                  variant="contained"
                  onClick={onLaunchpad}
                  sx={{
                    backgroundColor: "#ca424f",
                    fontSize: 15,
                    fontWeight: "bold",
                    borderRadius: "30px",
                    width: "120px",
                    ml: 5,
                    "&:hover": { backgroundColor: "#ca424f" },
                  }}
                >
                  exit
                </Button>

                <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={alert}
                // onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={alert}>
                  <Box sx={style}>
                    {/* <Typography
                      id="transition-modal-title"
                      variant="body1"
                      component="h2"
                    >
                      You have clicked on
                    </Typography> */}
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      ARE YOU SURE?
                    </Typography>
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2 }}
                    >
                      Once You Click on the FREEZE Button all the attendance information will
                      get final approval and cannot be changed further
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                      {/* <div>
                      <audio controls autoPlay={true}>
  <source src="ruko.mp3" type="audio/mpeg" />
</audio>
                      </div> */}
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={onAttendenceReport}
                      >
                        FREEZE
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => setAlert(false)}
                      >
                        Review
                      </Button>
                    </Stack>
                  </Box>
                </Fade>
              </Modal>

              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div sx={style}>
                  <SucessModal
                    text="Attendance Approved Successfully"
                    name="Attendance Approved Successfully"
                  />
                </div>
              </Modal>
            </Box>
          </Box>
        ) : (
          <h2>loading</h2>
        )}
      </Box>
    </div>
  );
}
//localhost:1919/studentattendance/getDatestandaredabsentreport?date=10/14/2023&yearName=2023-2024
//localhost:1919/studentattendance/getDatestandaredabsentreport?date=10%2F14%2F2023&yearName=2023-2024
