import { Avatar, Button, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as React from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import Paper from "@mui/material/Paper";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { LibraryService } from "../../../services/libraryService";
import { UploadFileService } from "../../../services/FileUploadService";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  width: "100%",
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export default function Books() {
  React.useEffect(() => {
    onBookLoad();
  }, []);
  const [columns, setColumns] = React.useState([
    {
      field: "no",
      flex: 0.5,
      headerName: "NO",
      align: "center",
      headerAlign: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bookNumber",
      headerName: "BOOK NUMBER",
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"BOOK NUMBER"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bookTitle",
      headerName: "BOOK TITLE",
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"BOOK TITLE"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bookType",
      headerName: "TYPE",
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"TYPE"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "authorPublication",
      headerName: "AUTHORPUBLICATION",
      align: "center",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      border: "1px solid black",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"AUTHOR/PUBLICATION"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "condition",
      headerName: "CONDITION ",
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"CONDITION"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "image",
      headerName: "IMAGE",
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"IMAGE"}</strong>,
      headerClassName: "super-app-theme--header",
      renderCell: (rec) => {
        return (
          <Box>
            <Avatar
              variant="square"
              alt="book image"
              src={rec.value}
              sx={{ width: 56, height: 56 }}
            />
            {/* <img
              style={
                height= "20px",
                width= "20px",
              }
              src={rec.value}
            /> */}
          </Box>
        );
      },
    },
  ]);
  // const columns = React.useMemo(
  //   () => data.columns.filter((column) => VISIBLE_FIELDS.includes(column.field)),
  //   [data.columns],
  // );
  const [rows, setRows] = React.useState([]);
  const [dataRows, setDataRows] = React.useState([]);
  const [book, setBook] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const onBookLoad = async () => {
    setLoading(true);
    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await LibraryService.listAll(yearData.yearName);
      console.log(response.data.data);
      setBook(response.data.data);
      let book_array = [];
      // try {
      response.data.data.forEach(function (rec, i) {
        console.log(rec);

        book_array.push({
          id: rec.libraryID,
          no: i + 1,
          bookNumber: rec.bookNumber.toUpperCase(),
          bookTitle: rec.bookTitle,
          bookType: rec.bookType,
          authorPublication: rec.bookAuthorPublication,
          condition: rec.bookCondition,
          image: rec.bookImageCoverPath,
        });
      });

      // setTimeout(() => {
      setRows(book_array);
      // console.log(book_array);
      // }, 2000);

      console.log(rows);
      setLoading(false);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
    // rowData();
  };

  let navigate = useNavigate();
  const onLaunchPad = () => {
    console.log("launchpad");
    navigate("/launchpad", { replace: true });
  };

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="library" /> */}
      {loading ? (
         <div style={{padding:"180px 0px", display:"flex", justifyContent:"center",}}>
         <div className = "spinner-container">
         <div className = "loading-spinner">
         </div>
       </div>
       </div>
      ) : (
      <Box>
      <Box sx={{ width: "100%", padding: "0 10px", boxSizing: "border-box" }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            mt: 2,
            fontWeight: "bold",
            backgroundColor: "lightblue",
          }}
        >
          SEARCH A BOOK IN THE LIST BELOW
        </Typography>
      </Box>
      <Box
        sx={{
          height: 500,
          mt: 2,
          width: "100%",
          padding: "0 10px",
          boxSizing: "border-box",
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
            //  color:'white',
            fontWeight: "bold",
            fontSize: "15px",
            border: "1px solid white",
          },
          overflowX:"auto",
        }}
      >
        <DataGrid
          showCellRightBorder={true}
          showColumnRightBorder={true}
          rows={rows}
          columns={columns}
          pageSize={100}
          // sx={{ border: "2px solid black" }}
          rowsPerPageOptions={[10]}
          sx={{minWidth:1000}}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              hideFooter: "true",
              hideToolbar: "true",
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
        />
      </Box>
      <Box sx={{textAlign:"center",mt:1, paddingBottom:"25px"}}>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#ca424f",
          borderRadius: "30px",
          fontSize: 15,
          width: "120px",
          fontWeight: "bold",
          "&:hover": { backgroundColor: "#ca424f" },
        }}
        onClick={onLaunchPad}
      >
        EXIT
      </Button>
      </Box>

      </Box>
      )}
    </React.Fragment>
  );
}
