import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

export default function VariantButtonGroup() {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  var date = new Date().toLocaleDateString();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: "2px solid black",
          "& > *": {
            m: 1,
          },
        }}
      >
        <Stack direction="row" spacing={7}>
          <div>
            <Typography variant="h6">MAY</Typography>
            <Typography variant="h6">21</Typography>
            <Typography variant="h6">
              03 <span>04</span>
            </Typography>
          </div>
          <div>
            <Typography variant="h6">JUN</Typography>
            <Typography variant="h6">21</Typography>
            <Typography variant="h6">
              03 <span>04</span>
            </Typography>
          </div>
          <div>
            <Typography variant="h6">JUL</Typography>
            <Typography variant="h6">21</Typography>
            <span>
              <Typography variant="h6">03 </Typography>
            </span>
          </div>
          <div>
            <Typography variant="h6">AUG</Typography>
            <Typography variant="h6">21</Typography>
            <Typography variant="h6">
              03 <span>04</span>
            </Typography>
          </div>
          <div>
            <Typography variant="h6">SEP</Typography>
            <Typography variant="h6">21</Typography>
            <Typography variant="h6">
              03 <span>04</span>
            </Typography>
          </div>
          <div>
            <Typography variant="h6">OCT</Typography>
            <Typography variant="h6">21</Typography>
            <Typography variant="h6">
              03 <span>04</span>
            </Typography>
          </div>
          <div>
            <Typography variant="h6">NOV</Typography>
            <Typography variant="h6">21</Typography>
            <Typography variant="h6">
              03 <span>04</span>
            </Typography>
          </div>
          <div>
            <Typography variant="h6">DEC</Typography>
            <Typography variant="h6">21</Typography>
            <Typography variant="h6">
              03 <span>04</span>
            </Typography>
          </div>
          <div>
            <Typography variant="h6">JAN</Typography>
            <Typography variant="h6">21</Typography>
            <Typography variant="h6">
              03 <span>04</span>
            </Typography>
          </div>
          <div>
            <Typography variant="h6">FEB</Typography>
            <Typography variant="h6">21</Typography>
            <Typography variant="h6">
              03 <span>04</span>
            </Typography>
          </div>
          <div>
            <Typography variant="h6">MAR</Typography>
            <Typography variant="h6">21</Typography>
            <Typography variant="h6">
              03 <span>04</span>
            </Typography>
          </div>
          <div>
            <Typography variant="h6">APR</Typography>
            <Typography variant="h6">21</Typography>
            <Typography variant="h6">
              03 <span>04</span>
            </Typography>
          </div>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& > *": {
            m: 1,
          },
        }}
      >
        <Typography variant="h4">
          Attendence of the month <span>{date}</span>
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="demo-simple-select-label">1</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Attendence"
            onChange={handleChange}
          >
            <MenuItem value={10}> P </MenuItem>
            <MenuItem value={20}> A </MenuItem>
            <MenuItem value={30}> L </MenuItem>
          </Select>
        </FormControl>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button>ScreenShot PDF</Button>
          <Button>ScreenShot Print</Button>
          <Button>Edit info</Button>
          <Button>update info</Button>
          <Button>excel sheet</Button>
          <Button>Printsheet</Button>
          <Button sx={{ backgroundColor: "red", color: "yellow" }}>Exit</Button>
        </ButtonGroup>
      </Box>
    </>
  );
}
