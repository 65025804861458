import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class SchoolMaster {
  static service = "school/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createSchool(school) {
    var dataURL = this.serverURL + "master/create";
    return axios.post(dataURL, school);
  }
  static schoolList() {
    var dataURL = this.serverURL + "master/list";
    return axios.get(dataURL);
  }
}
