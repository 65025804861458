import { Box } from "@mui/material";
import React from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";


function RightsManagement() {
  return (
    <div>
      <LaunchpadTopBar id="settings" />

      <Box
        sx={{
          textAlign: "center",
          color: "steelblue",
          fontWeight: "fontWeightBold",
          
        }}
      >
        RIGHTS MANAGEMNT FOR USERS – MAXIMUM THREE ADMINISTRATORS WITH FULL
        RIGHTS ALLOWED
      </Box>
      <Box
        sx={{
          textAlign: "center",
          p:2,
          fontWeight: "fontWeightBold",
        }}
      >
        SELECT DESIGNATION AND SELECT RIGHTS AND SAVE
      </Box>
      <Grid  sx={{ m: 2, display: "flex" }}>
            <Stack direction="row">
              <Button
                variant="text"
                sx={{
                  border: "2px solid black",
                  borderRadius: "1px",
                }}
              >
                ADMINISTRATOR
              </Button>
            </Stack>
          </Grid>
      <Grid  sx={{ m: 2, display: "flex" }}>
            <Stack direction="row">
              <Button
                variant="text"
                sx={{
                  border: "2px solid black",
                  borderRadius: "1px",
                }}
              >
                ADMINISTRATORS HAVE ALL RIGHTS
              </Button>
            </Stack>
          </Grid>

          <Grid container sx={{ m:5,height:100}}>
            <Grid
              
              border="2px solid black"
              sx={{m:2 }}
            >
              <Stack>
                <Typography>ATTENDANCE</Typography>                 
                <Typography>ATTENDANCE ENTRY – Y/N </Typography>
                <Typography>APPROVE ATTENDANCE - Y/N</Typography> 
                </Stack>
            </Grid>
          </Grid>
          <Grid sx={{display:'flex',justifyContent: "flex-end",mt:25}}>
          <Button
              variant="contained"
              color="success"
              sx={{
                width: 300,
                border: "2px solid red",
                borderRadius: "20px",
                marginRight: 2,
              }}
            >
              save and continue
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ width: 120, borderRadius: "25px" }}
            >
              exit
            </Button>
          </Grid>
    </div>
  );
}

export default RightsManagement;
