import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class ImageService {
  // http://192.168.1.106:8084/admin/login
  // static serverURL = "http://localhost:1919/";
  // static serverURL = "http://143.110.188.253:1919/file/upload";

  static service = "file/upload/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static ImageUrl(contact) {
    //143.110.188.253:1919/file/upload/fileUpload
    var dataURL = this.serverURL + "fileUpload";
    return axios.post(dataURL, contact);
  }
}
