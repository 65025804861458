export class LaunchPadAuth {
  static authenticateLaunchPad() {
    const year = JSON.parse(localStorage.getItem("academicYear"));
    const userData = JSON.parse(localStorage.getItem("userData"));
    const schoolData = JSON.parse(localStorage.getItem("schoolDetails"));
    if (userData && year) {
      if (userData.token === 9272 && year.yearID === schoolData.yearID) {
        console.log("Valid login");
        let pin = Math.random();
        localStorage.setItem("pin", pin);
        return pin;
      } else {
        return "invalid";
        // navigate("/launchpadlogin", { replace: true });
      }
    } else {
      return "invalid";
      //   navigate("/launchpadlogin", { replace: true });
    }
  }
}
