import React from 'react'
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";

function IDCards() {
  return (
    <div>
    <LaunchpadTopBar id="settings" />
    </div>
  )
}

export default IDCards