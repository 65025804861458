import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class BuildSchoolControll {
  static service = "buildSchoolStatus/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createBuildSchoolStatus(contact) {
    var dataURL = this.serverURL + "create";

    return axios.post(dataURL, contact);
  }

  // static deleteDesignation(contact) {
  //   var dataURL = this.serverURL + "create";

  //   return axios.post(dataURL, contact);
  // }

  static updateBuildSchoolStatus(contact) {
    var dataURL = this.serverURL + "update";

    return axios.post(dataURL, contact);
  }

  static buildSchoolStatusListAll(yearName) {
    var dataURL =
      this.serverURL +
      "getBuildSchoolStatusList?buildSchoolYear=" +
      yearName +
      "&yearName=" +
      yearName;

    return axios.get(dataURL);
  }
}
