import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Avatar, Button, ButtonGroup, TextField, Modal } from "@mui/material";
import PropTypes from "prop-types";
import { Tab, Tabs,} from "@mui/material";
import { ImageConverter } from "../../../programs/imgaeToBase";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import { StaffService } from "../../../services/StaffService";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid green",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


export default function BasicGrid(props) {
  React.useEffect(() => {
    console.log(props.data);
  }, []);
  const [description, setDescription] = React.useState();
  const [name, setName] = React.useState();
  const [remarks, setRemarks] = React.useState();
  const [remark, setRemark] = React.useState();
  const [detail, setDetail] = React.useState();
  const [value, setValue] = React.useState(0);
  const [imageUrl, setImageUrl] = React.useState();
  const [openSucess, setSucess] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

const handleFileUpload = async(e) => {
  const file = e.target.files[0];
  const base64 = await ImageConverter.convertToBase64(file);
  console.log(base64);
  setImageUrl(base64);

}

const handleSubmit = async(e) => {
  e.preventDefault();
  const data = new FormData(e.currentTarget);
  console.log({
    description: data.get("description"),
    remark : data.get("remarks"),
    documentType : "staffDocument",
    date : new Date().toLocaleDateString(),
  })

  let docObject = {
    date : new Date().toLocaleDateString(),
    // deleteStatus : false,
    description : data.get("description"),
    documentImgURL: imageUrl,
    documentType: data.get("name"),
    remark: data.get("remarks"),
    ssid : props.data.ssid,
    staffId : props.data.staffID,
    staffName : props.data.staffName
  };
  console.log(docObject);
  try{
    let resp = await StaffService.createStaffDocumentsRemarks(docObject);
    console.log(resp.data.data);
    setSucess(true);
   setTimeout(() => {
    setSucess(false);
   }, 2000);
  } catch(error) {
    // alert(error);
    console.log(error);
  }
  setDescription("");
  setName("");
  setRemarks("");
  setImageUrl("");
}

const onSubmit = async(e) => {
  e.preventDefault();
  const remData = new FormData(e.currentTarget);
  console.log({
    remark : remData.get("remark"),
  detail : remData.get("detail"),
  date : new Date().toLocaleDateString(),
  })

  let staffRemarks = {
    remark : remData.get("remark"),
    remarkDate : new Date().toLocaleDateString(),
    remarkDetail : remData.get("detail"),
    ssid : props.data.ssid,
    staffName : props.data.staffName
  };
  try{
    let res = await StaffService.createStaffRemarks(staffRemarks);
    console.log(res.data.data);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  } catch(error) {
    alert(error);
    console.log(error);
  }
  setRemark("");
  setDetail("");
}

  return (
    <Box sx={{ flexGrow: 1 }}>

<Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Documents" />
          <Tab label="Remarks and Recognition" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
             
      <Box component={"form"} onSubmit={handleSubmit} >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box p={1}>
                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  variant="filled"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  display={"none"}
                  sx={{
                    width: "364px",
                    margin: "10px 10px 10px 0px",
                    textTransform: "uppercase",
                  }}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Box>

              <Box p={1}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  variant="filled"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  display={"none"}
                  sx={{
                    width: "364px",
                    margin: "10px 10px 10px 0px",
                    textTransform: "uppercase",
                  }}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Box>

              <Box p={1}>
                <TextField
                  id="remarks"
                  name="remarks"
                  label="Remarks"
                  variant="filled"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  display={"none"}
                  sx={{
                    width: "364px",
                    margin: "10px 10px 10px 0px",
                    textTransform: "uppercase",
                  }}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Avatar
                  alt="A"
                  src={imageUrl}
                  sx={{ width: 300, height: 300, margin: 1, mt: 0.5, ml: 1 }}
                  variant="square"
                />
                <input
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  accept="image/*"
                  name="studentImg"
                  onChange={handleFileUpload}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    sx={{
                      marginTop: 2,
                      borderRadius: 9,
                      fontWeight: 700,
                    }}
                  >
                    UPLOAD IMAGE
                  </Button>
                </label>
                <Button
                  type="submit"
                  color="success"
                  variant="contained"
                  // onClick={handleOpen}
                  sx={{
                    borderRadius: "20px",
                    m: "10px 0 0 120px",
                  }}
                >
                  Submit Document
                </Button>
              </div>
              <Modal
                open={openSucess}
                // onClose={() => setSucess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div sx={style}>
                  <SucessModal
                    text="Staff Documents Uploaded Successfully"
                    name="Staff Documents Uploaded Successfully"
                  />
                </div>
              </Modal>
            </Grid>
          </Grid>
         
        </Box>


              </TabPanel>

        <TabPanel value={value} index={1}>
             
      <Box component={"form"} onSubmit={onSubmit} >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box p={1} sx={{display:"flex"}}>
                <TextField
                  id="remark"
                  name="remark"
                  label="Remarks"
                  variant="filled"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value.toUpperCase())}
                  display={"none"}
                  sx={{
                    width: "364px",
                    margin: "10px 10px 10px 0px",
                    textTransform: "uppercase",
                  }}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              {/* </Box> */}

              {/* <Box p={1}> */}
                <TextField
                  id="detail"
                  name="detail"
                  label="Details"
                  variant="filled"
                  value={detail}
                  onChange={(e) => setDetail(e.target.value.toUpperCase())}
                  display={"none"}
                  sx={{
                    width: "364px",
                    margin: "10px 10px 10px 0px",
                    textTransform: "uppercase",
                  }}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>        
                <Button
                  type="submit"
                  color="success"
                  variant="contained"
                  // onClick={handleOpen}
                  sx={{
                    borderRadius: "20px",
                    m: "10px 0 0 120px",
                  }}
                >
                  Submit Document
                </Button>  
            </Grid>

            <Modal
                open={open}
                // onClose={() => setSucess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div sx={style}>
                  <SucessModal
                    text="Staff Remarks Uploaded Successfully"
                    name="Staff Remarks Uploaded Successfully"
                  />
                </div>
              </Modal>

          </Grid>
         
        </Box>


              </TabPanel>    

      {/* <Grid container spacing={2}>
        <Grid item xs={12}>          
          <TextField
            id="filled-basic"
            label="WEB LOGIN USER NAME"
            variant="filled"
          />
          <TextField id="filled-basic" label="PASSWORD" variant="filled" />
          <TextField
            id="filled-basic"
            label="ACCOUNT STATUS"
            variant="filled"
          />
          <TextField id="filled-basic" label="ACTION" variant="filled" />
          <TextField id="filled-basic" label="LAST LOGIN" variant="filled" />
        </Grid>
      </Grid> */}
      
    </Box>
  );
}
