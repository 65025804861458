import axios from "axios";
import { AppRootUrl } from "../serviceUtil/rootUrl";

export class StudentInfoService {
  // static serverURL = "http://143.110.188.253:1919/standard/";
  static service = "student/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static studentInfoListStudents(standardId, yearName) {
    // alert(standardId, yearName);
    var dataURL = this.serverURL + "studentlist";
    return axios.get(dataURL);
  }
  static studentInfoList(standardId, yearName) {
    // alert(standardId, yearName);
    var dataURL = this.serverURL + "studentlist?approveStatus=true";
    return axios.get(dataURL);
  }

  static studentInfoShowStudentDetails(studentID, yearName) {
    var dataURL =
      this.serverURL +
      "studentID/yearName?studentID=" +
      studentID +
      "&yearName=" +
      yearName;
    // http://localhost:1919/student/studentID/yearName?studentID=641e05206bb87b7a03d9692c&yearName=2022-2023

    return axios.get(dataURL);
  }
}
