import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class LibraryService {
  static service = "library/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createLibraryBook(contact) {
    var dataURL = this.serverURL + "create";

    return axios.post(dataURL, contact);
  }

  static createBookBorrow(contact) {
    var dataURL = this.serverURL + "createLibraryBookBorrow";
    return axios.post(dataURL, contact);
  }

  static listAll(yearName) {
    var dataURL = this.serverURL + "listAllBooks?yearName=" + yearName;

    return axios.get(dataURL);
  }
  static listAllTypeWise(yearName) {
    var dataURL = this.serverURL + "listAllBooksTypeWise?yearName=" + yearName;

    return axios.get(dataURL);
  }
  static borrowedBooklist(yearName) {
    var dataURL =
      this.serverURL + "listLibraryBookBorrowedList?yearName=" + yearName;

    return axios.get(dataURL);
  }
  static unBorrowedBooklist(yearName) {
    var dataURL =
      this.serverURL + "UnborrowedListAllBooks?yearName=" + yearName;

    return axios.get(dataURL);
  }
  static GetStudentBooks(usid, yearName) {
    var dataURL =
      this.serverURL +
      "getStudentLibraryBookDetails?SSID=" +
      usid +
      "&yearName=" +
      yearName;

    return axios.get(dataURL);
  }
}
