
import React from 'react'
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
function Congratulation() {
  return (
    <div>
        <LaunchpadTopBar id="afterfinalexam" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 1,
          backgroundColor: "white",
          borderRadius: 1,
        }}
      >
       
        <Button
          sx={{
            color: "black",
            fontWeight: "bold",
            variant: "outlined",
            border: "1px solid black",
            borderRadius: "1px",
            borderRight: "none",
          }}
        >
          FILL NEW ADMISSION FORM FOR THE NEXT ACADEMIC YEAR
        </Button>
        <Button
          sx={{
            color: "black",
            fontWeight: "bold",
            border: "1px solid black",
            borderRadius: "1px",
          }}
        >
          GRANT / REJECT ADMISSION FOR NEXT ACADEMIC YEAR
        </Button>
        <Button
          sx={{
            color: "black",
            fontWeight: "bold",
            border: "1px solid black",
            borderRadius: "1px",
            borderLeft: "none",
          }}
        >
          ACCEPT REQUEST FOR TC / LC
        </Button>
      </Box>
        <div style={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 2,
          mt: 10,
          ml: 30,
          mr: 30,
          //   bgcolor: 'background.paper',
          //   borderRadius: 1,
          border: "2px solid black",
          textAlign: "center"
        }}
      >
          <Typography fontWeight="fontWeightBold" sx={{ lineHeight: 5 }}>
           <p>CONGRATULATIONS</p> 
           <Typography sx={{ lineHeight: 3 }}>
            <p>ALL MIGRATION FOR THE NEW ACADEMIC YEAR IS COMPLETED</p>
            
            <p>INFORMATION FOR PREVIOUS YEAR IS ALSO AVAILABLE FOR VIEW ONLY</p>
            
            <p>KINDLY LOGOUT AND RE LOGIN FOR</p>
            
            <p>HAPPY ACADEMIC NEW YEAR</p>
            </Typography>
          </Typography>
        
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          sx={{
            color: "white",
            backgroundColor: "red",
            borderRadius: "25px",
            width: 150
          }}
        >
          logout
        </Button>
      </Box>
    </div>
    </div>
  )
}

export default Congratulation