import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class AfterFinalExam {
  // static serverURL = "http://143.110.188.253:1919/staff/";
  static service = "afterExam/";
  static baseURL = AppRootUrl.Rooturl();
  static serverURL = this.baseURL + this.service;


  static createNewStudentApplicationForm(contact) {
    var dataURL = this.serverURL + "create";
    return axios.post(dataURL, contact);
  }

  static getStandaredApplicationFormCount(yearName) {
    var dataURL = this.serverURL + "getStandaredApplicationFormCount?acadamic=" + yearName;
    return axios.get(dataURL);
  }

  static getApplicationFormNumber(yearName) {
    var dataURL = this.serverURL + 
  "getApplicationFormNumber?acadamic=" + yearName;
    return axios.get(dataURL);
  }


  // static getStudentInfornatation(FormOrPhonenumber) {
  //   var dataURL = this.serverURL + 
  // "getStudentInfornatation?FormOrPhonenumber=" + FormOrPhonenumber;
  //   return axios.get(dataURL);
  // }

  static getStandaredStudentInfornatation(StandaredName){
    var dataURL = this.serverURL +
    "getStandaredStudentInfornatation?StandaredName="+StandaredName;
    return axios.get(dataURL);

  }

}
