import { Box, Typography } from "@mui/material";
import React from "react";

const pageNotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src="404.png"></img>
      <Typography variant="h1">We lost you come back !</Typography>
    </Box>
  );
};

export default pageNotFound;
