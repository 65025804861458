import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SchoolMaster } from "../../services/SchoolMaster";
import { yearService } from "../../services/yearService";
import { BuildSchoolControll } from "../../services/buildSchoolControllService";
import { AddStandarsService } from "../../services/addStandardsService";

const AcadmicYear = () => {
  const [school, setSchool] = useState();
  const [yearList, setYear] = useState([]);
  const [progress, setProgress] = useState(30);
  const [openLoad, setOpenLoad] = useState(false);

  useEffect(() => {
    loadSchool();
    loadYear();
  }, []);

  async function loadYear() {
    let pageNo = 0;
    try {
      let response = await yearService.yearListAll(pageNo);
      setYear(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadSchool() {
    try {
      let response = await SchoolMaster.schoolList();
      setSchool(response.data.data[0]);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let year;
    setOpenLoad(true);
    const data = new FormData(event.currentTarget);
    year = data.get("year");

    if (!year) {
      alert("Please enters new year");
      return;
    }

    let ExsitingYear = yearList.find((rec) => rec.yearName === year);
    // console.log(ExsitingYear);
    if (ExsitingYear) {
      alert("Entered year already exists");
    } else {
      console.log(data.get("year"));
      try {
        let response = await yearService.createYear({ yearName: year });
        onCreateBuildschool(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  async function onCreateBuildschool(newYear) {
    let { yearName, yearID } = newYear;

    let standard = [
      "standard",
      "subject",
      "designation",
      "timetable",
      "feestructure",
      "exam",
      "buildschoolcomplete",
    ];

    let step = ["one", "two", "three", "four", "five", "six", "seven"];

    for (let i = 0; i <= 6; i++) {
      console.log("onBuildSchool" + i);
      if (i == 0) {
        console.log("if");

        try {
          let response = await BuildSchoolControll.createBuildSchoolStatus({
            activeStatus: "active",
            buildSchoolName: standard[i],
            buildSchoolStatus: "false",
            buildSchoolStep: step[i],
            buildSchoolYear: yearName,
            yearID: yearID,
            yearName: yearName,
          });
        } catch (error) {
          alert("Error creating buildSchool " + error);
        }
      } else {
        console.log("else");

        try {
          let response = await BuildSchoolControll.createBuildSchoolStatus({
            activeStatus: "inactive",
            buildSchoolName: standard[i],
            buildSchoolStatus: "false",
            buildSchoolStep: step[i],
            buildSchoolYear: yearName,
            yearID: yearID,
            yearName: yearName,
          });
        } catch (error) {
          alert("Error creating buildSchool " + error);
        }
      }
    }

    console.log("Standard Migration");

    onStandardsMigration(yearName, yearID);

    //   const timer = setInterval(() => {
    //     setProgress((prevProgress) =>
    //       prevProgress >= 100 ? 0 : prevProgress + 10
    //     );
    //   }, 800);
    //   return () => {
    //     clearInterval(timer);
    //   };
    //   navigate("/dashborad", { replace: true });
  }

  async function onStandardsMigration(yearName, yearID) {
    let prevStandardData = [];
    let nextStandardData = [];
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      // console.log(response.data.data);
      prevStandardData = response.data.data;
    } catch (error) {
      console.log(error);
    }
    if (prevStandardData) {
      prevStandardData.forEach((rec) => {
        nextStandardData.push({
          standardName: rec.standardName,
          yearID: yearID,
          yearName: yearName,
        });
      });
      console.log(nextStandardData);

      if (nextStandardData) {
        try {
          nextStandardData.forEach(async (rec) => {
            let response = await AddStandarsService.createAddStandards(rec);
          });
        } catch (error) {
          console.log(error);
          console.log("standard migration stoped");
        }
      }
    } else {
      console.log("standard migration stoped");
    }

    setOpenLoad(false);
  }
  return (
    <Container
      component="main"
      // maxWidth="xs"
      sx={{
        backgroundColor: "lightgrey",
        borderColor: "2px solid green",
        borderRadius: "5px",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openLoad}
        // onClick={handleClose}
      >
        <CircularProgress variant="determinate" value={progress} />
      </Backdrop>
      <Box
        sx={{
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1, minWidth: 100 }}
        >
          <h1>{school ? school.schoolName : "School Name"}</h1>
          <TextField
            margin="normal"
            required
            fullWidth
            id="year"
            label="Enter Year"
            name="year"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Create Acadmic Year
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
export default AcadmicYear;
