import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class StandardDivCapacityService {
  // http://192.168.1.106:8084/admin/login
  // static serverURL = "http://143.110.188.253:1919/standarddivisionmaster/";

  static service = "standarddivisionmaster/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createStandardDivision(contact) {
    console.log(contact);
    var dataURL = this.serverURL + "create";

    return axios.post(dataURL, contact);
  }
  static StandardDivisionList(yearID, yearName) {
    // console.log(yearID,yearName);
    var dataURL =
      this.serverURL +
      "year/division/list?yearID=" +
      yearID +
      "&yearName=" +
      yearName;

    return axios.get(dataURL);
  }
}
