import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import { HolidayService } from "../../../services/holidaysService";
import { hover } from "@testing-library/user-event/dist/hover";

const EventList = () => {
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    onListEvent();
  }, []);

  async function onListEvent() {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let month = new Date();
    try {
      let response = await HolidayService.Holidaylist(data.yearName);
      console.log(response.data.data);
      let monthDates = response.data.data.filter(
        (item) => item.monthName == month.getMonth()
      );
      setHolidays(monthDates);
    } catch (error) {
      alert(error.message);
    }
  }
  return (
    <Grid container spacing={0}>
      {holidays.length == 0 ? (
        <Typography
          variant="subtitle1"
          component="div"
          sx={{
            backgroundColor: "lightblue",
          }}
        >
          🗓️ No Events
        </Typography>
      ) : (
        ""
      )}
      {holidays.map((h) => {
        let date = new Date();
        console.log(date);
        let d = new Date(h.date);
        if (h.dateTime < date.getDate()) {
          return (
            <Grid item xs={12}>
              <Box sx={{ textAlign: "left" }}>
                <Typography sx={{ margin: "2px 0px 2px 25px" }} variant="p">
                  {"♦️"} {d.toLocaleDateString()} -
                  {h.holidayType == "Birthday" ? "🎂" : ""}{" "}
                  {h.holidayType == "Festival" ? "🎉" : ""}
                  {h.holidayType == "Event" ? "🗓️" : ""}
                  {h.holidayType == "Others" ? "📜" : ""}
                  {h.holidayType == "Exam" ? "📝" : ""}
                  {h.holidayType == "Holiday" ? "✨" : ""} {h.holidayDesc}
                  {h.data}
                </Typography>
              </Box>
            </Grid>
          );
        }
      })}
    </Grid>
  );
};
export default EventList;
