import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class FeeStructure {
  // static serverURL = "http://143.110.188.253:1919/feesstructure/";
  // static serverFeeTitleURL = "http://143.110.188.253:1919/feestitle/";

  static service1 = "feesstructure/";
  static service2 = "feestitle/";
  static service3 = "studentfees/";
  static service4 = "feesCollectionController/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service1;
  static serverFeeTitleURL = this.baseURL + this.service2;
  static serverStudentfees = this.baseURL + this.service3;
  static serverfeescollection = this.baseURL + this.service4;

  static createFee(contact) {
    var dataURL = this.serverFeeTitleURL + "create";

    return axios.post(dataURL, contact);
  }

  static feeListAll(yearName) {
    var dataURL = this.serverFeeTitleURL + "list?yearName=" + yearName;

    return axios.get(dataURL);
  }

  static feeDelete(contact, yearName) {
    // var dataURL = this.serverFeeTitleURL + "delete";
    var dataURL =
      this.serverFeeTitleURL +
      "delete?feestitleName=" +
      contact +
      "&yearName=" +
      yearName;
    console.log(dataURL, contact);
    return axios.delete(dataURL);
  }
  // =========================================================================================

  // =========================================================================================

  // *143.110.188.253:1919/feesstructure/standard/year/list?standardID=fsdf&yearID=75c2fd74-6b90-4784-bc28-646e4412a739
  static feeStructureListAll(standardID, yearName) {
    // console.log(standardID);
    //143.110.188.253:1919/feesstructure/standard/year/list?standardID=qwe&yearID=123&yearName=12
    var dataURL =
      this.serverURL +
      "standard/year/list?standardID=" +
      standardID +
      "&yearID=" +
      yearName +
      "&yearName=" +
      yearName;

    return axios.get(dataURL);
  }

  static feesStructureCreate(contact) {
    var dataURL = this.serverURL + "create";
    return axios.post(dataURL, contact);
  }
  static feesStructureListAll(contact) {
    var dataURL = this.serverURL + "standard/totalAmt/list?yearName=" + contact;
    return axios.get(dataURL);
  }
  static feesStructureDelete(feeName, standardID, yearName) {
    var dataURL =
      this.serverURL +
      "delete/standard?feestitleName=" +
      feeName +
      "&standardID=" +
      standardID +
      "&yearName=" +
      yearName;
    return axios.delete(dataURL);
  }
  static studentfeesStructureStandard(standardID, yearName) {
    var dataURL =
      this.serverURL +
      "standard/year/list?standardID=" +
      standardID +
      "&yearName=" +
      yearName;

    return axios.get(dataURL);
  }

  static StdStuentsfeesdetails(standardID, yearName) {
    var dataURL =
      this.serverStudentfees +
      "list/standard/student/applied?standardID=" +
      standardID +
      "&yearName=" +
      yearName;

    return axios.get(dataURL);
  }
  static feescollectiontodaytotal(date, yearName) {
    var dataURL =
      this.serverfeescollection +
      "feesCollectionToday?date=" +
      date +
      "&yearName=" +
      yearName;
    return axios.get(dataURL);
  }
  static Generatefeereceiptno(yearName) {
    var dataURL =
      this.serverfeescollection + "getFeesResiept?yearName=" + yearName;
    return axios.get(dataURL);
  }
  static getIndividualstudemtfees(studentName, studentUSN, yearName) {
    var dataURL =
      this.serverfeescollection +
      "getStudentAcceptFeeAmount?studentName=" +
      studentName +
      "&studentUSN=" +
      studentUSN +
      "&yearName=" +
      yearName;
    return axios.get(dataURL);
  }
  static createStudentAppliedFeesCollectionReciept(contact) {
    var dataURL =
      this.serverfeescollection + "createStudentAppliedFeesCollectionReciept";
    return axios.post(dataURL, contact);
  }
}
// http://localhost:1919/feesCollectionController/createStudentAppliedFeesCollectionReciept
// http://localhost:1919/feesCollectionController/getStudentAcceptFeeAmount?studentName=Sandep&studentUSN=Sa01&yearName=2023-2024
// http://localhost:1919/feesCollectionController/getFeesResiept?yearName=2023-2024
// http://localhost:1919/feesCollectionController/feesCollectionToday?date=25-05-2023&yearName=2023-2024
// http://localhost:1919/feesstructure/standard/year/list?standardID=9ec1f519-d4cc-4a79-9edc-08e4703a7115&yearName=2023-2024

// http://143.110.188.253:1919/feesstructure/standard/year/list?standardID=fsdf&yearID=75c2fd74-6b90-4784-bc28-646e4412a739
// http://localhost:1919/studentfees/list/standard/student/applied?standardID=7c33df75-3d9c-42e9-b680-db74a6e147a9&yearName=2023-2024
// http://localhost:1919/studentfees/list/standard/student/applied?standardID=9ec1f519-d4cc-4a79-9edc-08e4703a7115&yearName=2023-2024
