import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class UploadFileService {
  static service = "file/";
  // static baseURL = "http://localhost:1920/";
  static baseURL = "http://143.110.188.253:1920/";
  // static baseURL = "http://206.189.142.166:1920/";

  static serverURL = this.baseURL + this.service;

  static upLoadFile(contact) {
    var dataURL = this.serverURL + "upload";

    return axios.post(dataURL, contact);
  }
  static downloadFile(contact) {
    var dataURL = this.serverURL + "download/" + contact;

    return axios.get(dataURL);
  }
}
