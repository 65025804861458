import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Avatar,
  Backdrop,
  CircularProgress,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { C } from "../../util/C";
import jsPDF from "jspdf";
import { autoTable } from "jspdf-autotable";
import { FeeStructure } from "../../../services/FeeStructure";
import { FeeCollectionStructure } from "../../../services/Feescollectionservice";

const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //   ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  //   color: theme.palette.text.secondary
}));

const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#94cd95",
  },
}));

const Item2 = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  // background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));

const style = {
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const numberToText = (num) => {
  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const teens = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  if (num === 0) return "zero";

  const convertLessThanHundred = (num) => {
    let result = "";
    const digitTen = Math.floor(num / 10);
    const digitOne = num % 10;

    if (digitTen === 1) {
      result += teens[digitOne];
    } else {
      result += tens[digitTen] + " " + ones[digitOne];
    }

    return result.trim();
  };

  const convertLessThanThousand = (num) => {
    let result = "";
    const digitHundred = Math.floor(num / 100);
    const digitTen = Math.floor((num % 100) / 10);
    const digitOne = num % 10;

    if (digitHundred > 0) {
      result += ones[digitHundred] + " hundred ";
    }

    if (digitTen === 1) {
      result += teens[digitOne];
    } else {
      result += tens[digitTen] + " " + ones[digitOne];
    }

    return result.trim();
  };

  const convertNumber = (num, level) => {
    let result = "";
    const crore = Math.floor(num / 10000000);
    const lakh = Math.floor((num % 10000000) / 100000);
    const thousand = Math.floor((num % 100000) / 1000);
    const rest = num % 1000;

    if (crore > 0) {
      result += convertLessThanThousand(crore) + " crore ";
    }

    if (lakh > 0) {
      result += convertLessThanHundred(lakh) + " lakh ";
    }

    if (thousand > 0) {
      result += convertLessThanThousand(thousand) + " thousand ";
    }

    if (rest > 0) {
      result += convertLessThanThousand(rest);
    }

    return result.trim();
  };

  return convertNumber(num, 0);
};

export default function BasicGrid() {
  const [open, setOpen] = React.useState(false);
  const [openRecipt, setReciptOpen] = React.useState(false);
  const [schoolName, setschoolName] = React.useState(
    JSON.parse(localStorage.getItem("schoolDetails"))
  );
  const [student, setstudent] = React.useState(
    JSON.parse(localStorage.getItem("StudentFeedata"))
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleReciptOpen = () => setReciptOpen(true);
  const handleReciptClose = () => setReciptOpen(false);

  // student details column
  const columns = [
    {
      field: "id",
      headerName: "USID",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"USID"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NAME"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "gender",
      headerName: "GENDER",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"GENDER"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "std",
      headerName: "STD/DIV",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"STD / DIV"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },

    {
      field: "fathersName",
      headerName: "FATHER'S NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"FATHER'S NAME"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },

    {
      field: "totalFees",
      headerName: "TOTAL FEES",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"TOTAL FEES"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "paid",
      headerName: "PAID",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"PAID"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "concession",
      headerName: "CONCESSION",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"CONCESSION"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "balance",
      headerName: "BALANCE",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"BALANCE"}</strong>,
      headerClassName: "super-app-theme--header",
    },
  ];
  // APPLIED FEES AND RECEIPTS column
  const columnApplied = [
    {
      field: "id",
      headerName: "NO",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "feesTitle",
      headerName: "FEES TITLE",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"FEES TITLE"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },

    {
      field: "amount",
      headerName: "AMOUNT",
      flex: 1,
      editable: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"AMOUNT"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    // {
    //   field: "receiptNo",
    //   headerName: "Receipt No.",
    //   flex: 1,
    //   editable: true,
    //   headerAlign: "center",
    //   align: "center",
    //   sortable: false,
    //   renderHeader: () => (
    //     <strong style={{ fontSize: 15 }}>{"Receipt No."}</strong>
    //   ),
    //   headerClassName: "super-app-theme--header",
    // },

    {
      field: "paid",
      headerName: "PAID",
      flex: 1,
      editable: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"PAID"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "concessions",
      headerName: "CONCESSIONS",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"CONCESSIONS"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },

    {
      field: "balance",
      headerName: "BALANCE",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"BALANCE"}</strong>,
      headerClassName: "super-app-theme--header",
    },
  ];
  // APPLIED FEES AND RECEIPTS row
  const [rowApplied, setRowApplied] = React.useState([]);

  // ENTER BELOW THE AMOUNT ACCEPTED column
  const column2 = [
    {
      field: "feesTitle",
      headerName: "FEES TITLE",
      flex: 1,
      editable: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"FEES TITLE"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },

    {
      field: "balance",
      headerName: "BALANCE",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"BALANCE"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "receipts",
      headerName: "RECIPTS",
      flex: 1,
      type: "number",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"RECIPTS"}</strong>
      ),
      headerClassName: "super-app-theme--header",
      editable: true,
    },
  ];
  // ENTER BELOW THE AMOUNT ACCEPTED row
  const [row2, setRow2] = React.useState([]);

  // PREVIOUS RECEIPT DETAILS column
  const column3 = [
    {
      field: "date",
      headerName: "DATE",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"DATE"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "receiptNo",
      headerName: "RC NO",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"RC. NO"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "amount",
      headerName: "AMOUNT",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"AMOUNT"}</strong>,
      headerClassName: "super-app-theme--header",
    },
  ];
  // PREVIOUS RECEIPT DETAILS row
  const [row3, setRow3] = React.useState([]);

  //fee report column and row
  const [feerows, setFeeRows] = React.useState([]);
  const feecolumns = [
    {
      field: "id",
      headerName: "NO",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable:false,
    },

    {
      field: "feetitle",
      headerName: "FEES TITLE",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"FEES TITLE"}</strong>
      ),
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable:false,
    },
    {
      field: "amount",
      headerName: "AMOUNT",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"AMOUNT"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable:false,
    },
    {
      field: "paid",
      headerName: "PAID",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"PAID"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable:false,
    },
  ];
  const [feereciptData, setFeeReciptData] = React.useState([]);
  const [feereciptrows, setFeeReciptRows] = React.useState([]);
  const feeReciptcolumns = [
    {
      field: "id",
      headerName: "NO",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable:false,
    },

    {
      field: "feetitle",
      headerName: "FEES TITLE",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"FEES TITLE"}</strong>
      ),
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable:false,
    },
    {
      field: "amount",
      headerName: "AMOUNT",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"AMOUNT"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable:false,
    },
    {
      field: "paid",
      headerName: "PAID",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"PAID"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable:false,
    },
  ];

  const [stdFeesCollectionToday, setstdFeesCollectionToday] = React.useState();
  const [stdFeesCollection, setstdFeesCollection] = React.useState([]);
  const [totalCollection, settotalCollection] = React.useState([]);
  const [Generatefeesreceipt, setGeneratefeesreceipt] = React.useState([]);
  const [studentInfo, updateStudentInfo] = React.useState({});
  const [infoEditable, updateInfoEditable] = React.useState(false);
  const [rows, setrows] = React.useState([]);

  // console.log(alteredArray);
  React.useEffect(() => {
    onfeescollectiontoday();
    onStudentDataLoad();
  }, []);

  // by arun
  const [dataLoad, setDataload] = React.useState(false);
  const onStudentDataLoad = () => {
    let data = JSON.parse(localStorage.getItem("StudentFeedata"));
    console.log(data);
    if (data) {
      setDataload(true);
    } else {
      setDataload(false);
      return false;
    }
    let sdata = [];
    sdata.push({
      id: data.studentUSN,
      name: data.studentName,
      gender: data.studentGender,
      std: data.standardWithDivision,
      fathersName: data.fathersName,
      paid: data.paid,
      concession: data.concession,
      balance: data.balance,
      totalFees: data.totalFees,
    });
    setrows(sdata);

    onAppliedStudentDataLoad();
    onStudentAcceptFeeAmount();
  };

  // by arun
  const onAppliedStudentDataLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let studentData = JSON.parse(localStorage.getItem("StudentFeedata"));
    // C.c(studentData.studentName, studentData.studentUSN, data.yearName);
    let gridData = [];
    let feeArray = [];

    try {
      let response =
        await FeeCollectionStructure.getIndividualstudentappliedFeereceipts(
          studentData.studentName,
          studentData.studentUSN,
          data.yearName
        );
      console.log(response.data.data);
      response.data.data.forEach((r, i) => {
        console.log(r, i);
        gridData.push({
          id: i,
          feesTitle: r.feestitleName,
          amount: r.feesAmount,
          receiptNo: r.feesReceiptNumber,
          paid: r.feesPaid,
          concessions: r.concessions,
          balance: r.balance,
        });
      });
      setRowApplied(gridData);
    } catch (error) {
      console.log(error);
    }

    try {
      let response = await FeeCollectionStructure.getStudentAcceptFeeAmount(
        studentData.studentName,
        studentData.studentUSN,
        data.yearName
      );
      console.log(response.data.data);
      response.data.data.forEach((r, i) => {
        if (r.balance != 0.0) {
          feeArray.push({
            id: i,
            feeID: r.studentFeesTitleID,
            feesTitle: r.feestitleName,
            balance: r.balance,
          });
        }
      });
      setRow2(feeArray);
    } catch (error) {
      console.log(error);
    }

    try {
      let response = await FeeCollectionStructure.getIndividualstudentreceipts(
        studentData.studentName,
        studentData.studentUSN,
        data.yearName
      );
      console.log(response.data.data);
      response.data.data.forEach((r, i) => {
        console.log(r, i);
        gridData.push({
          id: i,
          date: r.date,
          amount: r.amount,
          receiptNo: r.feesReceiptNumber,
        });
      });
      setRow3(gridData);
    } catch (error) {
      console.log(error);
    }
    try {
      let response = await FeeCollectionStructure.StudentFeesCollectionList(
        data.yearName
      );
      console.log(response.data.data);
      setstdFeesCollection(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  //by arun
  const onStudentAcceptFeeAmount = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let studentData = JSON.parse(localStorage.getItem("StudentFeedata"));
    let feeArray = [];
    try {
      let response = await FeeCollectionStructure.getStudentAcceptFeeAmount(
        studentData.studentName,
        studentData.studentUSN,
        data.yearName
      );
      console.log(response.data.data);
      response.data.data.forEach((r, i) => {
        // if (r.balance != 0.0) {
        feeArray.push({
          id: i,
          feeID: r.studentFeesTitleID,
          feesTitle: r.feestitleName,
          balance: r.balance,
        });
        // }
      });
      setRow2(feeArray);
    } catch (error) {
      console.log(error);
    }
  };

  const date = new Date();
  const today = {
    day: "2-digit",
    month: "long",
    year: "numeric",
    weekday: "long",
  };
  const dateString = date.toLocaleDateString("eng-GB", today);

  const [Standard, setStandard] = React.useState([]);

  const onfeescollectiontoday = async () => {
    const dates = new Date();
    const today = {
      day: "2-digit",
      month: "long",
      year: "numeric",
      weekday: "long",
    };
    const date = dates.toLocaleDateString("eng-GB", today);
    let studentUSN = localStorage.getItem("studentUSN");
    let Studentdata = localStorage.getItem("Studentdata");
    const currentDate = new Date();
    const iso8601Date = currentDate.toISOString().split("T")[0];
    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    C.c(yearData);

    try {
      let response = await FeeStructure.feescollectiontodaytotal(
        iso8601Date,
        yearData.yearName
      );
      console.log(response.data.data.standardFeesCollection);
      console.log(response.data.data);
      setstdFeesCollectionToday(response.data.data.standardFeesCollection);
      settotalCollection(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };

  // by arun
  const [totalPaidAmount, setTotalPaidAmount] = React.useState(0);
  const onPayRowEdit = (r, v) => {
    const val = parseInt(r.value);
    C.c(val < 0);
    // var audio = new Audio("audio.mp3");

    if (val < 0) {
      // audio.play();
      alert("Moye Moye!");
      // const found = row2.find((element) => element.id == r.id);
      // found[r.field] = 0;
    } else {
      const found = row2.find((element) => element.id == r.id);
      found[r.field] = r.value;
      C.c(found);
    }

    let totalVal = 0;
    row2.forEach((v) => {
      if (v.receipts) {
        totalVal = totalVal + parseInt(v.receipts);
      }
    });
    setTotalPaidAmount(totalVal);
  };

  const [receiptNoData, setReceiptNo] = React.useState("");
  const [cash, setCash] = React.useState();
  const [bank, setBank] = React.useState();
  const [remark, setRemark] = React.useState();
  const [totalAmountInWords, SetTotalAmountInWords] = React.useState("");

  const [mask, setMask] = React.useState(false);
  const handleMaskClose = () => {
    setMask(false);
  };
  const handleMaskOpen = () => {
    setMask(true);
  };

  // by arun
  const onFeePay = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let sData = JSON.parse(localStorage.getItem("StudentFeedata"));
    let receiptNo = "";
    let feerowsarray = [];
    let confirmPay = window.confirm("Are you Ready to Pay ?");
    if (confirmPay) {
      handleMaskOpen();
      try {
        let response = await FeeCollectionStructure.getFeesResiept(
          data.yearName
        );
        console.log(response.data.data);
        receiptNo = response.data.data;
        setReceiptNo(response.data.data);
      } catch (error) {
        console.log(error);
      }

      // console.log(row2);
      let appliedFeeCollection = [];
      let updatedFeeCollection = [];

      let totalBalance = 0;
      let totalPaid = 0;
      let totalAmouunt = 0;

      row2.forEach((r, i) => {
        if (r.receipts) {
          totalAmouunt = totalAmouunt + parseInt(r.balance);
          totalPaid = totalPaid + parseInt(r.receipts);

          let balanceAmount = parseInt(r.balance) - parseInt(r.receipts);
          // done
          appliedFeeCollection.push({
            balance: balanceAmount.toString(),
            concessions: "0",
            feesAmount: r.balance,
            feesPaid: r.receipts,
            feesReceiptNumber: receiptNo.feesReceipt,
            feestitleName: r.feesTitle,
            studentGender: sData.studentGender,
            studentName: sData.studentName,
            studentUSN: sData.studentUSN,
            yearID: data.yearID,
            yearName: data.yearName,
          });
          feerowsarray.push({
            id: i+1,
            feetitle: r.feesTitle,
            amount: r.balance,
            paid: r.receipts,
          });
          C.c("appliedFeeCollection", appliedFeeCollection);
        }
      });
      totalBalance = totalAmouunt - totalPaid;
      setFeeRows(feerowsarray);
      // C.c(totalAmouunt.toString());
      // C.c(totalPaid.toString());
      // C.c(totalAmouunt - totalPaid);

      try {
        let response =
          await FeeCollectionStructure.createStudentAppliedFeesCollectionReciept(
            appliedFeeCollection
          );
      } catch (error) {
        console.log(error);
      }

      //done

      setTimeout(async () => {
        let createFeeCollection;
        stdFeesCollection.forEach((rec) => {
          if (rec.usid == sData.studentUSN && rec.idStudentFeesCollection) {
            let totalPaidAmount = parseInt(totalPaid) + parseInt(rec.paid);
            createFeeCollection = {
              idStudentFeesCollection: rec.idStudentFeesCollection,
              balance: totalBalance.toString(),
              concession: "0",
              division: sData.standardWithDivision.split(" | ")[1],
              fatherName: sData.fathersName,
              gender: sData.studentGender,
              paid: totalPaidAmount.toString(),
              standardName: sData.standardWithDivision.split(" | ")[0],
              studentName: sData.studentName,
              totalFees: rec.totalFees,
              usid: sData.studentUSN,
              yearID: data.yearID,
              yearName: data.yearName,
            };
          } else {
            createFeeCollection = {
              balance: totalBalance.toString(),
              concession: "0",
              division: sData.standardWithDivision.split(" | ")[1],
              fatherName: sData.fathersName,
              gender: sData.studentGender,
              paid: totalPaid.toString(),
              standardName: sData.standardWithDivision.split(" | ")[0],
              studentName: sData.studentName,
              totalFees: totalAmouunt.toString(),
              usid: sData.studentUSN,
              yearID: data.yearID,
              yearName: data.yearName,
            };
          }
        });

        C.c("createFeeCollection", createFeeCollection);
        try {
          let response =
            await FeeCollectionStructure.createStudentFeesCollection(
              createFeeCollection
            );
        } catch (error) {
          console.log(error);
        }
      }, 2000);

      const currentDate = new Date();
      const iso8601Date = currentDate.toISOString().split("T")[0];
      const options = { month: "long" };
      const month = new Intl.DateTimeFormat("en-US", options).format(
        currentDate
      );

      const totalPaidInWords = numberToText(totalPaid);
      SetTotalAmountInWords(numberToText(totalPaid));
      setTimeout(async () => {
        let createFeeRecipt = {
          amount: totalPaid,
          byBank: bank,
          byCash: cash,
          date: iso8601Date,
          // day: "saturtday",
          division: sData.standardWithDivision.split(" | ")[1],
          feesReceiptNumber: receiptNo.feesReceipt,
          month: month,
          rupeeInWords: totalPaidInWords,
          standardName: sData.standardWithDivision.split(" | ")[0],
          studenrName: sData.studentName,
          usid: sData.studentUSN,
          yearName: data.yearName,
        };
        console.log(createFeeRecipt);
        try {
          let response = await FeeCollectionStructure.createStudentFeesReciept(
            createFeeRecipt
          );
        } catch (error) {
          console.log(error);
        }
        handleMaskClose();
        setOpen(true);
      }, 3000);

      setTimeout(async () => {
        row2.forEach((r, i) => {
          // console.log(r);
          if (r.receipts) {
            let balanceAmount = parseInt(r.balance) - parseInt(r.receipts);
            let TotalAmount = balanceAmount + parseInt(r.receipts);
            // done
            updatedFeeCollection.push({
              balance: balanceAmount.toString(),
              concession: "0",
              division: sData.standardWithDivision.split(" | ")[0],
              fatherName: sData.fathersName,
              feestitleName: r.feesTitle,
              gender: sData.studentGender,
              paid: r.receipts,
              standardName: sData.standardWithDivision.split(" | ")[0],
              studentName: sData.studentName,
              totalFees: TotalAmount.toString(),
              studentFeesTitleID: r.feeID,
              usid: sData.studentUSN,
              yearID: data.yearID,
              yearName: data.yearName,
            });
            // feerowsarray.push({
            //   id: i,
            //   feetitle: r.feesTitle,
            //   amount: r.balance,
            //   paid: r.receipts,
            // });
            C.c("updateFeeCollection", updatedFeeCollection);
          }
        });

        try {
          let response =
            await FeeCollectionStructure.updateStudentFeesTitleCollection(
              updatedFeeCollection
            );
        } catch (error) {
          console.log(error);
        }
      }, 4000);
    }
  };

  const gotoRecipt = async (a, b, c) => {
    console.log(a, b, c);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await FeeCollectionStructure.getFeesReciptData(
        a.row.receiptNo,
        data.yearName
      );
      console.log(response);
      setFeeReciptData(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setReciptOpen(true);
  };

const generatePDF=()=>{
  console.clear();
  console.log("hello");
  const doc = new jsPDF("p","pt","a4");
  const school= JSON.parse(localStorage.getItem("schoolDetails"));
  const academic = JSON.parse(localStorage.getItem("academicYear"));

  const sch = school.schoolNameOnDocs.toUpperCase();
  const acad = academic.yearName;
  const img = schoolName.schoolLogoUrl;
  doc.setFont("helvetica", "bold");
  var fontSize = 18;
  var text = `${sch}`;
  var pageWidth = doc.internal.pageSize.getWidth();
  var textWidth =
    (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
  var centerX = (pageWidth - textWidth) / 2;
  doc.text(text, centerX, 35);

  doc.addImage(img, "JPEG", 20, 10, 80, 70);
  doc.setFontSize(10);
  doc.text(` ${acad}`, 500, 35);
  doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text("FEES RECIPT", 250, 85);

doc.setFont("helvetica");
doc.setFontSize(13);
  doc.text("RECIPT NO. : "+receiptNoData.feesReceipt,60,125);
  doc.text("NAME :- "+student.studentName,60,145);

  const today = new Date();
const dd = today.getDate();
const mm = today.getMonth() + 1; // Months start at 0!
const yyyy = today.getFullYear();
const formattedToday = dd + '/' + mm + '/' + yyyy;

doc.text("DATE :- "+formattedToday,420,125);
doc.text("USN :- "+student.studentUSN,420,145);

const tableData = feerows.map((r)=>[
  r.id,r.feetitle,r.amount,r.paid
]);

doc.autoTable({
  startY: 160,
  tableLineColor: [0,0,0],
  head: [["NO.", "FEES TITLE", "AMOUNT", "PAID"]],
  body: tableData,
  headStyles: {
    valign: "middle",
    halign: "center",
  },
  bodyStyles: {
    valign: "middle",
    halign: "center",
    lineColor: [0,0,0],
  },
  tableLineWidth: 0.45,
  theme: "grid",
});

doc.setLineWidth(0.9);
doc.setFillColor(192,192,192);
doc.roundedRect(38,420,135,30,5,5,'FD');
cash ? doc.text("CASH : "+cash+" RS",40,440) : doc.text("CASH : 0",40,440);

doc.setLineWidth(0.9);
doc.setFillColor(192,192,192);
doc.roundedRect(205,420,180,30,5,5,'FD');
bank ? doc.text("DIRECT BANK : "+bank+" RS",207,440) : doc.text("DIRECT BANK : 0",207,440);

let a= parseInt(cash);
let b=parseInt(bank);
let c = a+b;

doc.setLineWidth(0.9);
doc.setFillColor(192,192,192);
doc.roundedRect(415,420,170,30,5,5,'FD');
cash && bank ? doc.text("TOTAL PAID : "+c+" RS",417,440) : doc.text("TOTAL PAID : 0",417,440);

totalAmountInWords ? doc.text(totalAmountInWords+" Rupees Only",150,480) : doc.text("Something Went Wrong",170,480);
  doc.save("fee recipt.pdf");
}

  return (
    <div>
      <LaunchpadTopBar id="fees" />
      {dataLoad ? (
        <Box sx={{ width: "100%", padding: "0 10px", boxSizing: "border-box" }}>
          <Grid container>
            <Grid item xs={10}>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  mt: 2,
                  fontWeight: "Bold",
                  backgroundColor: "lightblue",
                }}
              >
                Fees Collected Today <b> {dateString}</b>
              </Typography>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid Item2 xs={9}>
                  <Item2>
                    <Grid container spacing={0} sx={{ minHeight: 40 }}>
                      {stdFeesCollectionToday
                        ? stdFeesCollectionToday.map((element) => (
                            <Grid item xs={2} sx={{ cursor: "pointer" }}>
                              <ItemS
                                id={element.standardName}
                                name={element.standardName}
                                sx={{ fontWeight: "bold" }}
                              >
                                {element.standardName}
                              </ItemS>

                              <Typography
                                size="large"
                                sx={{
                                  border: "1px solid black",
                                  height: 30,
                                  borderRadius: 1,
                                }}
                                inputProps={{
                                  style: {
                                    fontSize: 25,
                                    height: 30,
                                    fontWeight: "bold",
                                  },
                                }}
                              >
                                {element.total}
                              </Typography>
                            </Grid>
                          ))
                        : ""}
                    </Grid>
                  </Item2>
                </Grid>

                {/* {totalCollection.map((rec)=>( */}
                <Grid item xs={3}>
                  <Item>
                    <Typography
                      sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}
                    >
                      Total Collection
                    </Typography>
                    <Stack direction="row">
                      <Item sx={{ width: 85 }}>
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          sx={{ fontSize: 18 }}
                        >
                          CASH
                        </Typography>
                        <Typography variant="outlined" size="small">
                          {totalCollection.cash}
                        </Typography>
                      </Item>
                      <Item sx={{ width: 85 }}>
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          sx={{ fontSize: 18 }}
                        >
                          BANK
                        </Typography>
                        <Typography variant="outlined" size="small">
                          {totalCollection.bank}
                        </Typography>
                      </Item>

                      <Item sx={{ width: 85 }}>
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          sx={{ fontSize: 18 }}
                        >
                          TOTAL
                        </Typography>
                        <Typography variant="outlined" size="small">
                          {totalCollection.total}
                        </Typography>
                      </Item>
                    </Stack>
                  </Item>
                </Grid>
                {/* ))} */}
              </Grid>
            </Grid>
            <Grid xs={2}>
              <Box>
                <Avatar
                  sx={{ height: 160, width: 200, ml: 4, mt: 2 }}
                  variant="square"
                ></Avatar>
              </Box>
            </Grid>
          </Grid>
          {/* student display from localstorage */}

          <Grid container item>
            <Grid item xs={12}>
              <Box sx={{ width: "100%", display: "flex" }}>
                {/* student display from localstorage */}
                <Box sx={{ height: 120, width: "110%" }}>
                  <DataGrid
                    disableColumnMenu
                    showColumnRightBorder={true}
                    showCellRightBorder={true}
                    rows={rows ? rows : []}
                    columns={columns}
                    hideFooter
                    //  pageSize={7}
                    rowsPerPageOptions={[10]}
                    // checkboxSelection
                    // disableSelectionOnClick
                    // experimentalFeatures={{ newEditingApi: true }}
                    sx={{
                      "& .super-app-theme--header": {
                        backgroundColor: "#c0c0c0",
                        //  color:'white',
                        fontWeight: "bold",
                        fontSize: "15px",
                        border: "1px solid white",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xs={2}>
              <Box
                sx={{
                  border: "2px solid black",
                  backgroundColor: "lightgray",
                  height: 50,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    pt: 1.5,
                    textAlign: "center",
                    fontWeight: "Bold",
                  }}
                >
                  RECEIPT NO.
                </Typography>
              </Box>
              <Typography
                sx={{
                  border: "2px solid black",
                  height: 60,
                  fontWeight: "bold",
                  textAlign: "center",
                  pt: 2,
                }}
              >
                {Generatefeesreceipt.feesReceipt}
              </Typography>
            </Grid> */}
          </Grid>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Item sx={{ height: 500 }}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      textAlign: "center",
                      fontWeight: "Bold",
                      backgroundColor: "lightblue",
                    }}
                  >
                    APPLIED FEES AND RECEIPTS 1
                  </Typography>
                  <Box sx={{ height: 400, width: "100%" }}>
                    <DataGrid
                      disableColumnMenu
                      hideFooter
                      showColumnRightBorder={true}
                      showCellRightBorder={true}
                      rows={rowApplied}
                      columns={columnApplied}
                      //  pageSize={7}
                      rowsPerPageOptions={[10]}
                      // checkboxSelection
                      // disableSelectionOnClick
                      // experimentalFeatures={{ newEditingApi: true }}
                      sx={{
                        "& .super-app-theme--header": {
                          backgroundColor: "#c0c0c0",
                          //  color:'white',
                          fontWeight: "bold",
                          fontSize: "15px",
                          border: "1px solid white",
                        },
                      }}
                    />
                  </Box>
                  <Grid container sx={{ border: "1px solid black" }}>
                    <Grid xs={2} sx={{ borderLeft: "1px solid black" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          fontWeight: "Bold",
                          backgroundColor: "lightgray",
                        }}
                      >
                        FEES TITLE
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          fontWeight: "Bold",
                          backgroundColor: "lightgray",
                          borderTop: "1px solid black",
                        }}
                      >
                        TOTAL
                      </Typography>
                    </Grid>
                    <Grid xs={3} sx={{ borderLeft: "1px solid black" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          fontWeight: "Bold",
                          backgroundColor: "lightgray",
                        }}
                      >
                        AMOUNT
                      </Typography>
                      <Typography>{rows[0].totalFees}</Typography>
                    </Grid>
                    <Grid xs={2} sx={{ borderLeft: "1px solid black" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          fontWeight: "Bold",
                          backgroundColor: "lightgray",
                        }}
                      >
                        PAID
                      </Typography>
                      <Typography>{rows[0].paid}</Typography>
                    </Grid>
                    <Grid xs={2} sx={{ borderLeft: "1px solid black" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          fontWeight: "Bold",
                          backgroundColor: "lightgray",
                        }}
                      >
                        Concessions
                      </Typography>
                      <Typography>{rows[0].concession}</Typography>
                    </Grid>
                    <Grid xs={3} sx={{ borderLeft: "1px solid black" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          fontWeight: "Bold",
                          backgroundColor: "lightgray",
                        }}
                      >
                        Balance
                      </Typography>
                      <Typography>{rows[0].balance}</Typography>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item sx={{ height: 550 }}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      textAlign: "center",
                      fontWeight: "Bold",
                      backgroundColor: "lightblue",
                    }}
                  >
                    ENTER BELOW THE AMOUNT ACCEPTED
                  </Typography>
                  <Box sx={{ height: 370, width: "100%" }}>
                    <DataGrid
                      disableColumnMenu
                      showColumnRightBorder={true}
                      showCellRightBorder={true}
                      rows={row2}
                      columns={column2}
                      //  pageSize={7}
                      rowsPerPageOptions={[10]}
                      onCellEditCommit={onPayRowEdit}
                      hideFooter
                      // checkboxSelection
                      // disableSelectionOnClick
                      // experimentalFeatures={{ newEditingApi: true }}
                      sx={{
                        "& .super-app-theme--header": {
                          backgroundColor: "#c0c0c0",
                          //  color:'white',
                          fontWeight: "bold",
                          fontSize: "15px",
                          border: "1px solid white",
                        },
                      }}
                    />
                  </Box>
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    sx={{
                      backgroundColor: "#c0c0c0",
                    }}
                  >
                    Total Paid = {totalPaidAmount} Rs
                  </Typography>
                  <Grid container>
                    <Grid xs={6} sx={{ border: "1px solid black" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          fontWeight: "Bold",
                          backgroundColor: "lightgray",
                        }}
                      >
                        CASH
                      </Typography>
                      <TextField
                        type="number"
                        value={cash}
                        size="small"
                        onChange={(e) => {
                          setCash(e.target.value);
                        }}
                      ></TextField>
                    </Grid>
                    <Grid xs={6} sx={{ border: "1px solid black" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          fontWeight: "Bold",
                          backgroundColor: "lightgray",
                        }}
                      >
                        BANK
                      </Typography>
                      <TextField
                        type="number"
                        value={bank}
                        size="small"
                        onChange={(e) => {
                          setBank(e.target.value);
                        }}
                      ></TextField>
                    </Grid>
                    <Grid xs={12} sx={{ border: "1px solid black" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          fontWeight: "Bold",
                          backgroundColor: "lightgray",
                        }}
                      >
                        Remake
                      </Typography>
                      <TextField
                        fullWidth
                        type="text"
                        value={remark}
                        size="small"
                        onChange={(e) => {
                          setRemark(e.target.value);
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              <Grid item xs={3}>
                <Item sx={{ height: 550 }}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      textAlign: "center",
                      fontWeight: "Bold",
                      backgroundColor: "lightblue",
                    }}
                  >
                    PREVIOUS RECEIPT DETAILS
                  </Typography>
                  <Box sx={{ height: 350, width: "100%" }}>
                    <DataGrid
                      hideFooter
                      disableColumnMenu
                      showColumnRightBorder={true}
                      showCellRightBorder={true}
                      rows={row3}
                      columns={column3}
                      //  pageSize={7}
                      rowsPerPageOptions={[10]}
                      // onCellClick={gotoStudentInfo}
                      onRowClick={gotoRecipt}
                      // checkboxSelection
                      // disableSelectionOnClick
                      // experimentalFeatures={{ newEditingApi: true }}
                      sx={{
                        "& .super-app-theme--header": {
                          backgroundColor: "#c0c0c0",
                          //  color:'white',
                          fontWeight: "bold",
                          fontSize: "15px",
                          border: "1px solid white",
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginY: 2,
                    }}
                  >
                    <Button
                      color="success"
                      variant="contained"
                      sx={{
                        border: "1px solid black",
                        fontWeight: "bold",
                        backgroundColor: "green",
                        borderRadius: "10px",
                        color: "white",
                      }}
                      onClick={onFeePay}
                    >
                      Review the amount accepted. Print Receipt
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        width: "100%",
                        mt: 1,
                        borderRadius: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      JUST ENQUIRY - EXIT
                    </Button>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Box>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Typography id="modal-modal-title" variant="h4">
                  {schoolName.schoolName}
                </Typography>
                <Typography id="modal-modal-title" variant="h5">
                  Fees Receipt
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <Typography id="modal-modal-title" variant="h6">
                    {receiptNoData
                      ? "Recipt No: " + receiptNoData.feesReceipt
                      : "Recipt No:"}
                  </Typography>
                  <Typography id="modal-modal-title" variant="h6">
                    Name : {student.studentName}
                  </Typography>
                </Box>
                <Box>
                  <Typography id="modal-modal-title" variant="h6">
                    Date : {new Date().toLocaleDateString()}
                  </Typography>
                  <Typography id="modal-modal-title" variant="h6">
                    USN : {student.studentUSN}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ height: 350, width: "100%" }}>
                <DataGrid
                  rows={feerows}
                  columns={feecolumns}
                  hideFooter
                  disableColumnMenu
                  // initialState={{
                  //   pagination: {
                  //     paginationModel: {
                  //       pageSize: 5,
                  //     },
                  //   },
                  // }}
                  // pageSizeOptions={[5]}
                  // checkboxSelection
                  // disableRowSelectionOnClick
                />
              </Box>
              <Box display={"flex"} justifyContent={"space-evenly"}>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  sx={{
                    backgroundColor: "#c0c0c0",
                    padding: 1,
                    margin: "5px",
                    borderRadius: "10px",
                  }}
                >
                  By Cash : {cash ? cash : 0} Rs
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  sx={{
                    backgroundColor: "#c0c0c0",
                    padding: 1,
                    margin: "5px",
                    borderRadius: "10px",
                  }}
                >
                  Direct Bank : {bank ? bank : 0} Rs
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  sx={{
                    backgroundColor: "#c0c0c0",
                    padding: 1,
                    margin: "5px",
                    borderRadius: "10px",
                  }}
                >
                  Total Paid :{" "}
                  {cash && bank ? parseInt(cash) + parseInt(bank) : 0.0} Rs
                </Typography>
              </Box>
              <Typography
                id="modal-modal-description"
                variant="h6"
                sx={{ my: 2, fontWeight: "bold" }}
              >
                Total Amount in Words:{" "}
                {totalAmountInWords
                  ? totalAmountInWords + " Rupees Only"
                  : "Something Went wrong!"}
              </Typography>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <Button variant="contained" onClick= {generatePDF}>Save PDF</Button>
                </Box>
                <Box>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={handleClose}
                  >
                    Exit
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={openRecipt}
            onClose={handleReciptClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Typography id="modal-modal-title" variant="h4">
                  {schoolName.schoolName}
                </Typography>
                <Typography id="modal-modal-title" variant="h5">
                  Fees Receipt
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <Typography id="modal-modal-title" variant="h6">
                    {feereciptData
                      ? "Recipt No: " + feereciptData.receiptNumber
                      : "Recipt No:"}
                  </Typography>
                  <Typography id="modal-modal-title" variant="h6">
                    Name : {feereciptData.studentName}
                  </Typography>
                </Box>
                <Box>
                  <Typography id="modal-modal-title" variant="h6">
                    Date : {new Date().toLocaleDateString()}
                  </Typography>
                  <Typography id="modal-modal-title" variant="h6">
                    USN : {feereciptData.usid}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ height: 350, width: "100%" }}>
                <DataGrid
                  rows={feereciptrows}
                  columns={feeReciptcolumns}
                  hideFooter
                  disableColumnMenu
                  // initialState={{
                  //   pagination: {
                  //     paginationModel: {
                  //       pageSize: 5,
                  //     },
                  //   },
                  // }}
                  // pageSizeOptions={[5]}
                  // checkboxSelection
                  // disableRowSelectionOnClick
                />
              </Box>
              <Box display={"flex"} justifyContent={"space-evenly"}>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  sx={{
                    backgroundColor: "#c0c0c0",
                    padding: 1,
                    margin: "5px",
                    borderRadius: "10px",
                  }}
                >
                  By Cash : {feereciptData ? feereciptData.byCash : 0} Rs
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  sx={{
                    backgroundColor: "#c0c0c0",
                    padding: 1,
                    margin: "5px",
                    borderRadius: "10px",
                  }}
                >
                  Direct Bank : {feereciptData ? feereciptData.byBank : 0} Rs
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  sx={{
                    backgroundColor: "#c0c0c0",
                    padding: 1,
                    margin: "5px",
                    borderRadius: "10px",
                  }}
                >
                  Total Paid :{" "}
                  {feereciptData
                    ? parseInt(feereciptData.byCash) +
                      parseInt(feereciptData.byBank)
                    : 0.0}{" "}
                  Rs
                </Typography>
              </Box>
              <Typography
                id="modal-modal-description"
                variant="h6"
                sx={{ my: 2, fontWeight: "bold" }}
              >
                Total Amount in Words:{" "}
                {feereciptData
                  ? feereciptData.amountWords + " Rupees Only"
                  : "Something Went wrong!"}
              </Typography>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <Button variant="contained"  onClick={generatePDF} >Save PDF</Button>
                </Box>
                <Box>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={handleReciptClose}
                  >
                    Exit
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      ) : (
        <Box display={"flex"} justifyContent={"center"} alignContent={"center"}>
          <h1>
            Select the respected student first to view the Fee collection !
          </h1>
        </Box>
      )}
      <div>
        {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={mask}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
}
