import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class SubjectService {
  // static serverURL = "http://143.110.188.253:1919/subject/";
  static service = "subject/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createSubject(contact) {
    var dataURL = this.serverURL + "create";
    return axios.post(dataURL, contact);
  }

  static deleteSubject(contact, yearID) {
    var dataURL =
      this.serverURL + "delete?subjectID=" + contact + "&yearID=" + yearID;
    return axios.delete(dataURL);
  }

  static subjectListAll(yearName) {
    var dataURL = this.serverURL + "listall?yearName=" + yearName;

    return axios.get(dataURL);
  }

  // http://143.110.188.253:1919/standardsubject/delete/standard?standardID=abc&subjectID=abc&yearID=abc

  static deleteSubjectForStandard(standardID, subjectID, yearID) {
    // console.log(contact);
    var dataURL =
      this.baseURL +
      "/standardsubject/delete/standard?standardID=" +
      standardID +
      "&subjectID=" +
      subjectID +
      "&yearID=" +
      yearID;
    //  alert(dataURL);
    return axios.delete(dataURL);
  }
}
