import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Avatar,
  Button,
  Checkbox,
  Grid,
  Paper,
  Stack,
  ToggleButton,
  Typography,
} from "@mui/material";
import Topbar from "../../MainTopbar/Topbar";
import { Box } from "@mui/system";
import { StaffService } from "../../../services/StaffService";
import { StandardDivision } from "../../../services/StandardDIvision";
import { AddStandarsStrengthService } from "../../../services/standardStrength";
import { StandardSubjectService } from "../../../services/StandardSubject";
import { TimeTableService } from "../../../services/TimeTableService";
import { TimeTable } from "../../../services/TimeTable";
import { C } from "../../util/C";
import { styled } from "@mui/material/styles";
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";
import { Navigate, useNavigate } from "react-router-dom";
import { Class } from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function DataTable() {
  const [staff, staffList] = React.useState([]);
  const [staffSelected, setStaffsSelected] = React.useState(false);

  React.useEffect(() => {
    onStaffLoad();
    onStandardLoad();
  }, []);

  const [standardListAll, updateStandard] = React.useState([]);

  const onStandardLoad = async () => {
    try {
      let response = await AddStandarsStrengthService.standardStrengthListAll();
      //console.log(response.data.data);
      updateStandard(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onStaffLoad = async () => {
    try {
      let response = await StaffService.staffListAll();
      // console.log(response.data.data);
      staffList(response.data.data);
      // console.log(staff);
    } catch (error) {
      console.log(error);
    }
  };

  const [staffName, setstaff] = React.useState();
  const onStaffSelection = (e) => {
    console.log(e.currentTarget.value);
    setstaff(e.currentTarget.value);
    console.log(e.currentTarget.id);
  };

  const [standardID, setstandardID] = React.useState();
  const [standardName, setstandardName] = React.useState();

  const onStandardSelect = (e) => {
    // C.c(e.currentTarget.id);
    // C.c(e.currentTarget.name);
    setstandardID(e.currentTarget.id);
    setstandardName(e.currentTarget.name);
    onStandardSubjectLoad(e.currentTarget.id);
    onStandardDivisionLoad(e.currentTarget.id);
  };

  const [standardDivisionID, setstandardDivisionID] = React.useState();
  // const [standardDivisionName, setstandardDivisionName] = React.useState();

  const onDivisionSelect = (e) => {
    console.log(e.currentTarget.id);
    setstandardDivisionID(e.currentTarget.id);
    // setstandardDivisionName(e.currentTarget.name);
    timeTable();
  };

  const [standardSubjectID, setstandardsubjectID] = React.useState();
  const [standardsubjectName, setstandardsubjectName] = React.useState();

  const onSubjectSelect = (e) => {
    console.log(e.currentTarget.id);
    console.log(e.currentTarget.name);
    setstandardsubjectID(e.currentTarget.id);
    setstandardsubjectName(e.currentTarget.name);
  };

  const [standardSubjectListAll, updateStandardSubject] = React.useState([]);

  const onStandardSubjectLoad = async (standardID) => {
    // console.log("hello standardsubject");
    // console.log("=====================" + standardID);

    try {
      let response =
        await StandardSubjectService.findSubjectListByStandardIDYearID(
          "75c2fd74-6b90-4784-bc28-646e4412a739",
          standardID
        );
      console.log(response.data.data);

      updateStandardSubject(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [standardDivisionListAll, updateStandardDivision] = React.useState([]);

  const onStandardDivisionLoad = async (standardID) => {
    // console.log("hello onStandardDivisionLoad");
    // console.log("=====================" + standardID);
    try {
      let response = await StandardDivision.StandardDivisionWiseStandard(
        "75c2fd74-6b90-4784-bc28-646e4412a739",
        standardID
      );
      // console.log(response.data.data);
      updateStandardDivision(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [rows, setRows] = React.useState([]);

  const [columns, setColumns] = React.useState([]);

  const [timeTableMaster, setTimeTableMaster] = React.useState([]);

  const timeTable = async () => {
    console.log(standardDivisionID);
    console.log(standardName);

    let daysList = [];
    let typeList = [];
    try {
      // let response = await TimeTableService.StandardTimeTable(
      let response = await TimeTable.StandardTimeTable(
        standardID,
        standardName,
        standardDivisionID
      );
      daysList = response.data.data.daysList;
      if (response.data.data.titleList == null) {
        alert("Title List is null can't create table");
      } else {
        typeList = response.data.data.titleList;
        alert("Create tabled");
      }
      // typeList = ["p1", "p2", "p3", "p4", "p5", "p6", "p7", "p8"];
      setTimeTableMaster(response.data.data.timeTableMasterList);
    } catch (error) {
      console.log(error);
    }

    // daysList.unshift("No of day");
    daysList.unshift("No_of_day");
    C.c(daysList);

    let daysColumn = [];
    for (let i = 0; i < daysList.length; i++) {
      // console.log(daysList[i]);
      daysColumn.push({
        field: daysList[i],
        headerName: daysList[i],
        editable: "true",
        width: 200,
        ClassName: daysList[i],
      });
      console.log(daysColumn);
    }
    setColumns(daysColumn);

    // debugger;
    let rowType = [];
    for (let i = 0; i < typeList.length; i++) {
      rowType.push({
        id: i,
        No_of_day: "P" + i,
      });
      console.log(rowType);
    }
    C.c(rowType);
    setRows(rowType);
    C.c(rows);
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * adding the data to the datagrid * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  const oncellselect = (cell, e) => {
    let day = cell.field;
    C.c(cell.field);
    C.c(cell.row.id);
    C.c(timeTableMaster);

    let mondayMasterTimeTable = [];
    if (timeTableMaster.Monday) {
      mondayMasterTimeTable = timeTableMaster.Monday;
      // C.c(mondayMasterTimeTable);
    }
    let tuesdayMasterTimeTable = [];
    if (timeTableMaster.Tuseday) {
      tuesdayMasterTimeTable = timeTableMaster.Tuseday;
      C.c(tuesdayMasterTimeTable);
    }
    let wednesdayMasterTimeTable = [];
    if (timeTableMaster.Wednesday) {
      wednesdayMasterTimeTable = timeTableMaster.Wednesday;
      // C.c(wednesdayMasterTimeTable);
    }
    let thursdayMasterTimeTable = [];
    if (timeTableMaster.Thursday) {
      thursdayMasterTimeTable = timeTableMaster.Thursday;
      // C.c(thursdayMasterTimeTable);
    }
    let fridayMasterTimeTable = [];
    if (timeTableMaster.Friday) {
      fridayMasterTimeTable = timeTableMaster.Friday;
      // C.c(fridayMasterTimeTable);
    }
    let saturdayMasterTimeTable = [];
    if (timeTableMaster.Saturday) {
      saturdayMasterTimeTable = timeTableMaster.Saturday;
      // C.c(saturdayMasterTimeTable);
    }
    let sundayMasterTimeTable = [];
    if (timeTableMaster.Sunday) {
      sundayMasterTimeTable = timeTableMaster.Sunday;
      // C.c(sundayMasterTimeTable);
    }

    // let row_id = cell.row.id + 1;
    let row_id = cell.row.No_of_day;
    // C.c(cell.row);

    if (cell.field == "Monday") {
      mondayMasterTimeTable.find((rec) => {
        // C.c("====rec====" + rec);
        C.c(row_id);
        C.c(rec.title);
        // C.c("====standardName====" + standardName);
        // C.c("----rec.standardName----" + rec.standardName);
        // C.c("====standardDivisionID====" + standardDivisionID);
        // C.c("----rec.divisionName----" + rec.divisionName);
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          // C.c(rec);
          // C.c(rows);
          rows[cell.row.id].Monday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
          // alert("Please select the correct data");
        }
      });
    }
    if (cell.field == "Tuseday") {
      // C.c(tuesdayMasterTimeTable);
      tuesdayMasterTimeTable.find((rec) => {
        C.c("mmmmm");
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].Tuseday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
          // alert("Please select the correct data");
        }
      });
    }
    if (cell.field == "Wednesday") {
      wednesdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].Wednesday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
          // alert("Please select the correct data");
        }
      });
    }
    if (cell.field == "Thursday") {
      thursdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].Thursday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
          // alert("Please select the correct data");
        }
      });
    }
    if (cell.field == "Friday") {
      fridayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].Friday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
          // alert("Please select the correct data");
        }
      });
    }
    if (cell.field == "Saturday") {
      saturdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].Saturday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
          // alert("Please select the correct data");
        }
      });
    }
    if (cell.field == "Sunday") {
      sundayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].Sunday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
          // alert("Please select the correct data");
        }
      });
    }
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * deleting the data to the datagrid * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  const onCellDelete = (cell, e) => {
    C.c(cell.field);
    C.c(cell.row.id);
    C.c(timeTableMaster);

    let mondayMasterTimeTable = [];
    if (timeTableMaster.Monday) {
      mondayMasterTimeTable = timeTableMaster.Monday;
      // C.c(mondayMasterTimeTable);
    }
    let tuesdayMasterTimeTable = [];
    if (timeTableMaster.Tuseday) {
      tuesdayMasterTimeTable = timeTableMaster.Tuseday;
      // C.c(tuesdayMasterTimeTable);
    }
    let wednesdayMasterTimeTable = [];
    if (timeTableMaster.Wednesday) {
      wednesdayMasterTimeTable = timeTableMaster.Wednesday;
      // C.c(wednesdayMasterTimeTable);
    }
    let thursdayMasterTimeTable = [];
    if (timeTableMaster.Thursday) {
      thursdayMasterTimeTable = timeTableMaster.Thursday;
      // C.c(thursdayMasterTimeTable);
    }
    let fridayMasterTimeTable = [];
    if (timeTableMaster.Friday) {
      fridayMasterTimeTable = timeTableMaster.Friday;
      // C.c(fridayMasterTimeTable);
    }
    let saturdayMasterTimeTable = [];
    if (timeTableMaster.Saturday) {
      saturdayMasterTimeTable = timeTableMaster.Saturday;
      // C.c(saturdayMasterTimeTable);
    }
    let sundayMasterTimeTable = [];
    if (timeTableMaster.Sunday) {
      sundayMasterTimeTable = timeTableMaster.Sunday;
      // C.c(sundayMasterTimeTable);
    }

    // let row_id = cell.row.id + 1;
    let row_id = cell.row.No_of_day;

    if (cell.field == "Monday") {
      mondayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].Monday = "click";
          // alert("Removed");
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "Tuseday") {
      // C.c(tuesdayMasterTimeTable);
      tuesdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].Tuseday = "click";
          // alert("Removed");
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "Wednesday") {
      wednesdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].Wednesday = "click";
          // alert("Removed");
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "Thursday") {
      thursdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].Thursday = "click";
          // alert("Removed");
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "Friday") {
      fridayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].Friday = "click";
          // alert("Removed");
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "Saturday") {
      saturdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].Saturday = "click";
          // alert("Removed");
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "Sunday") {
      sundayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id
          // rec.standardName == standardName &&
          // rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].Sunday = "click";
          // alert("Removed");
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
  };
  let navigate = useNavigate();

  const onNextPage = async () => {
    console.log("next page");
    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "false",
        buildSchoolName: "feestructure",
        buildSchoolStatus: "false",
        buildSchoolStep: "five",
        buildSchoolYear: "2022",
      });
    } catch (error) {
      alert("Error creating buildSchool " + error);
    }
    // Navigate("/dashborad", { replace: true });
    navigate("/timetable2", { replace: true });
  };

  const onSaveClassTimeTable = async () => {
    C.c(rows);
    C.c(timeTableMaster);
    timeTableMaster.Monday.foreach((rec) => {
      //    try {
      //   let response = await TimeTableService.saveStdDivTimeTable(rec);
      // } catch (error) {
      //   C.c(error)
      // }
    });
    // {
    //   "day": "string",
    //   "divisionID": "string",
    //   "divisionName": "string",
    //   "endTime": "string",
    //   "staffID": "string",
    //   "staffName": "string",
    //   "staffProfilePhoto": "string",
    //   "standardID": "string",
    //   "standardName": "string",
    //   "startTime": "string",
    //   "subjectID": "string",
    //   "subjectName": "string",
    //   "timeTableID": "string",
    //   "title": "string",
    //   "typeOfPeriod": "string",
    //   "yearID": "string",
    //   "yearName": "string"
    // }

    // Monday: "STANDARD4 D social qwer";
    // No_of_day: "P0";
    // Tuseday: "STANDARD4 D geography undefined";
    // Wednesday: "STANDARD4 D social undefined";
    // id: 0;
    //   try {
    //     let response = await TimeTableService.saveStdDivTimeTable();
    //   } catch (error) {}
  };

  return (
    <div>
      <Topbar
        text={"Creating Teachers Time Table has two steps (Step 1 & Step 2)"}
      />
      <Box sx={{ m: 2, textAlign: "center" }}>
        <Typography sx={{ fontWeight: "bold", color: "blue" }}>
          {" "}
          Select the Staff
        </Typography>
        <Grid container spacing={1}>
          {staff.map((element) => (
            <Grid item xs={1}>
              <Item>
                <Box sx={{ width: 80, height: 80 }}>
                  <Avatar
                    variant="square"
                    sx={{ mx: 4, my: 1 }}
                    alt={element.staffName}
                    src={element.staffProfilePhoto}
                  />
                  {/* <Typography>{element.staffName}</Typography> */}
                  <ToggleButton
                    value={element.staffName}
                    id={element.staffID}
                    selected={staffSelected}
                    onChange={onStaffSelection}
                    size="small"
                    sx={{ height: 20 }}
                  >
                    {element.staffName}
                  </ToggleButton>
                </Box>
              </Item>
            </Grid>
          ))}
        </Grid>
        {/* <Stack direction="row" spacing={1}>
          {staff.map((element) => (
            <Box sx={{ border: "1px solid", width: 80, height: 80 }}>
              <Avatar
                variant="square"
                sx={{ mx: 2, my: 1 }}
                alt={element.staffName}
                src="..."
              />
              <ToggleButton
                value={element.staffName}
                id={element.staffID}
                selected={staffSelected}
                onChange={onStaffSelection}
                size="small"
                sx={{ height: 20 }}
              >
                {element.staffName}
              </ToggleButton>
            </Box>
          ))}
        </Stack> */}
      </Box>
      <Box sx={{ m: 2, textAlign: "center" }}>
        <Typography sx={{ fontWeight: "bold", color: "blue" }}>
          Select The Standard
        </Typography>
        <Grid container spacing={1}>
          {standardListAll.map((element) => (
            <Grid item xs={1}>
              {/* <Item> */}
              <Button
                variant="outlined"
                id={element.standardID}
                name={element.standardName}
                onClick={onStandardSelect}
              >
                {element.standardName}
              </Button>
              {/* </Item> */}
            </Grid>
          ))}
        </Grid>
        {/* <Stack direction="row" spacing={1}>
          {standardListAll.map((element) => (
            <Button
              id={element.standardID}
              name={element.standardName}
              onClick={onStandardSelect}
            >
              {element.standardName}
            </Button>
          ))}
        </Stack> */}
      </Box>
      <Box sx={{ m: 2, textAlign: "center" }}>
        <Typography sx={{ fontWeight: "bold", color: "blue" }}>
          Select The Subject
        </Typography>
        <Grid container spacing={1}>
          {standardSubjectListAll.map((element) => (
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="outlined"
                id={element.subjectID}
                name={element.subjectName}
                onClick={onSubjectSelect}
              >
                {element.subjectName}
              </Button>
            </Grid>
          ))}
        </Grid>
        {/* <Stack direction="row" spacing={1}>
          {standardSubjectListAll.map((element) => (
            <Button
              id={element.subjectID}
              name={element.subjectName}
              onClick={onSubjectSelect}
            >
              {element.subjectName}
            </Button>
          ))}
        </Stack> */}
      </Box>
      <Box sx={{ m: 2, textAlign: "center" }}>
        <Typography sx={{ fontWeight: "bold", color: "blue" }}>
          Select The Division
        </Typography>
        <Grid container spacing={1}>
          {standardDivisionListAll.map((element) => (
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="outlined"
                id={element.divisionName}
                onClick={onDivisionSelect}
              >
                {element.divisionName}
              </Button>
            </Grid>
          ))}
        </Grid>
        {/* <Stack direction="row" spacing={1}>
          {standardDivisionListAll.map((element) => (
            <Button id={element.divisionName} onClick={onDivisionSelect}>
              {element.divisionName}
            </Button>
          ))}
        </Stack> */}
      </Box>

      <Box
        style={{
          m: 2,
          height: 700,
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold", color: "blue" }}>
          TimeTable
        </Typography>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={12}
          rowsPerPageOptions={[12]}
          // onRowDoubleClick={setTimeTable}
          onCellClick={oncellselect}
          onCellDoubleClick={onCellDelete}
          // onCellClick={onCellDelete}
          // onCellDoubleClick={oncellselect}
          // onCellDoubleClick
          // getCellClassName
          // onColumnHeaderClick={setTimeTable1}
        />
      </Box>

      <Box sx={{ m: 2, flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              sx={{ m: 2 }}
            >
              <Button
                type="submit"
                onClick={onSaveClassTimeTable}
                variant="contained"
              >
                Save Class Timetable
              </Button>
              <Button
                type="submit"
                // onClick={onSaveTimeTable}
                variant="contained"
                onClick={onNextPage}
              >
                Freeze and Review
              </Button>
              <Button
                disabled
                variant="contained"
                // onSubmit={onSaveTimeTable}
              >
                Pause and Continue Setup Later
              </Button>
              <Button variant="outlined" onClick={onNextPage}>
                SKIP Creating Time for now
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

// * ++++++++++++++++++++++++++++++ old code ++++++++++++++++++++++++++++

// import * as React from "react";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";
// import { tableCellClasses } from "@mui/material/TableCell";

// import { TextField } from "@mui/material";

// import {
//   Avatar,
//   Button,
//   Stack,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   ToggleButton,
//   Typography,
// } from "@mui/material";
// import { StaffService } from "../../../services/StaffService";
// import { AddStandarsService } from "../../../services/addStandardsService";
// import Topbar from "../../MainTopbar/Topbar";
// import { StandardSubjectService } from "../../../services/StandardSubject";
// import { AddStandarsStrengthService } from "../../../services/standardStrength";
// import { StandardDivision } from "../../../services/StandardDIvision";
// import { TimeTableService } from "../../../services/TimeTableService";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

// const TeacherItem = styled(Paper)(({ theme }) => ({
//   // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   // ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   border: "1px solid",
//   height: 80,
//   // color: theme.palette.text.secondary,
// }));
// const Item0 = styled(Paper)(({ theme }) => ({
//   // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   // ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   // color: theme.palette.text.secondary
// }));
// const Item1 = styled(Paper)(({ theme }) => ({
//   // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   // ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   border: "1px solid",
//   // color: theme.palette.text.secondary
// }));
// const TableItem = styled(Paper)(({ theme }) => ({
//   // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   // ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   width: 100,
//   // color: theme.palette.text.secondary
// }));
// const TableSubItem = styled(Paper)(({ theme }) => ({
//   // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   // ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   width: 80,
//   // color: theme.palette.text.secondary
// }));

// // ("==================for table =================");

// function createData(name, stan) {
//   return { name, stan };
// }

// const rows = [
//   createData("1", "A"),
//   createData("2", "B"),
//   createData("3", "C"),
//   createData("4", "D"),
//   createData("5", "E"),
// ];
// // ("==================for table =================");

// export default function BasicGrid() {
//   React.useEffect(() => {
//     onStaffLoad();
//     onStandardLoad();
//     // onStandardSubjectLoad();
//   }, []);

//   function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
//   }

//   const rows = [
//     createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//     createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//     createData("Eclair", 262, 16.0, 24, 6.0),
//     createData("Cupcake", 305, 3.7, 67, 4.3),
//     createData("Gingerbread", 356, 16.0, 49, 3.9),
//   ];

//   // const [subjectLoop, onSubjectLoop] = React.useState([]);

//   // ("================staff Load===================");
//   const [staff, staffList] = React.useState([]);

//   const [noOFDaysList, noOFDaysListFunc] = React.useState([]);
//   const [titleList, titleListFunc] = React.useState([]);
//   const [timeTableMainDataList, timeTableMainDataListFunc] = React.useState([]);

//   const onStaffLoad = async () => {
//     try {
//       let response = await StaffService.staffListAll();
//       // console.log(response.data.data);

//       staffList(response.data.data);
//       // console.log(staff);
//     } catch (error) {
//       console.log(error);
//       // alert("Failure");
//     }
//   };
//   // ("================staff Load===================");

//   // ("================standard Load===================");
//   const [standardListAll, updateStandard] = React.useState([]);

//   const onStandardLoad = async () => {
//     // console.log("hello standard");

//     try {
//       let response = await AddStandarsStrengthService.standardStrengthListAll();
//       //console.log(response.data.data);

//       updateStandard(response.data.data);
//     } catch (error) {
//       console.log(error);
//       // alert("Failure");
//     }
//     // console.log(standardListAll);
//   };
//   // ("================standard Load===================");

//   // ("================standard subject Load===================");
//   const [standardSubjectListAll, updateStandardSubject] = React.useState([]);

//   const onStandardSubjectLoad = async (standardID) => {
//     // console.log("hello standardsubject");
//     // console.log("=====================" + standardID);

//     try {
//       let response =
//         await StandardSubjectService.findSubjectListByStandardIDYearID(
//           "75c2fd74-6b90-4784-bc28-646e4412a739",
//           standardID
//         );
//       console.log(response.data.data);

//       updateStandardSubject(response.data.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const onTimeTableStaffSubClick = async (e) => {
//     // let standard
//     let day = e.currentTarget.value;
//     let title = e.currentTarget.id;
//     let endTime = e.currentTarget.parentElement.id;
//     // let startTime = e.currentTarget.parentElement.accessKey;
//     let startTime = "9:30";
//     console.log(day);
//     try {
//       console.log("onTimeTableStaffSubClick select");
//       let obj = {
//         day: day,
//         divisionID: standardDivisionID,
//         divisionName: standardDivisionName,
//         endTime: endTime,
//         staffID: staffID,
//         staffName: staffName,
//         standardID: standardID,
//         // standardName: standardName,
//         standardName: "LKG",
//         startTime: startTime,
//         subjectID: standardSubjectID,
//         subjectName: standardSubjectname,
//         title: title,
//       };

//       let response = await TimeTableService.saveStdDivTimeTable(obj);
//     } catch (error) {}
//   };

//   // ("================standard subject Load===================");

//   // ("================division Load===================");
//   const [standardDivisionListAll, updateStandardDivision] = React.useState([]);

//   const onStandardDivisionLoad = async (standardID) => {
//     // console.log("hello onStandardDivisionLoad");
//     // console.log("=====================" + standardID);
//     try {
//       let response = await StandardDivision.StandardDivisionWiseStandard(
//         "75c2fd74-6b90-4784-bc28-646e4412a739",
//         standardID
//       );
//       console.log(response.data.data);
//       updateStandardDivision(response.data.data);
//     } catch (error) {
//       console.log(error);
//     }
//     // console.log(standardDivisionListAll);
//   };
//   // ("================division Load===================");

//   // ("================standard select===================");
//   const [standardID, setstandardID] = React.useState();
//   const [standardName, setstandardName] = React.useState();

//   const onSelectStandardSelect = (e) => {
//     // console.log(e.currentTarget.id);
//     // let standardID = e.currentTarget.id;
//     setstandardID(e.currentTarget.id);
//     setstandardName(e.currentTarget.value);
//     onStandardSubjectLoad(standardID);
//     onStandardDivisionLoad(standardID);
//     standardSelect(standardID, standardName);
//   };
//   // ("================standard select===================");

//   // ("================subject select===================");
//   const [standardSubjectID, setstandardSubjectID] = React.useState();
//   const [standardSubjectname, setstandardSubjectName] = React.useState();

//   const onSelectSubjectSelect = (e) => {
//     console.log(e.currentTarget.id, e.currentTarget.value);
//     setstandardSubjectID(e.currentTarget.id);
//     setstandardSubjectName(e.currentTarget.value);
//   };
//   // ("================subject select===================");

//   // ("================division select===================");
//   const [standardDivisionID, setstandardDivisionID] = React.useState();
//   const [standardDivisionName, setstandardDivisionName] = React.useState();

//   const onSelectDivisionSelect = (e) => {
//     console.log(e.currentTarget.id, e.currentTarget.value);
//     setstandardDivisionID(e.currentTarget.id);
//     setstandardDivisionName(e.currentTarget.value);
//     timeTable();
//   };
//   // ("================division select===================");

//   // "===============onStaffSelection============"
//   const [staffSelected, setStaffsSelected] = React.useState(false);
//   const [staffID, setStaffID] = React.useState();
//   const [staffName, setStaffName] = React.useState();

//   const onStaffSelection = (e) => {
//     console.log(e.currentTarget.value);
//     console.log(e.currentTarget.id);
//     setStaffID(e.currentTarget.id);
//     setStaffName(e.currentTarget.value);
//     setStaffsSelected(!staffSelected);
//   };
//   // "===============onStaffSelection============"
//   // "===============standardSelect============"
//   const [standardSelectedID, setstandardSelectedID] = React.useState();
//   const [standardSelectedname, setstandardSelectedName] = React.useState();

//   const standardSelect = () => {
//     console.log(standardID, standardName);
//     setstandardSelectedID(standardID);
//     setstandardSelectedName(standardName);
//   };
//   // "===============standardSelect============"
//   // "===============TimeTable============"

//   const [day, setDay] = React.useState([]);
//   const timeTable = async () => {
//     try {
//       let response = await TimeTableService.StandardTimeTable(
//         standardID,
//         standardName,
//         standardDivisionID,
//         standardDivisionName
//         // "A",
//         // "A"
//       );

//       console.log(response.data.data);

//       console.log(response.data.data.timeTableMasterList.Monday);

//       console.log("4---------------------");

//       console.log(response.data.data.timeTableMasterList.tuesday);
//       //  setDay(response.data.data);

//       noOFDaysListFunc(response.data.data.daysList);
//       titleListFunc(response.data.data.titleList);
//       timeTableMainDataListFunc(response.data.data.timeTableMasterList.Monday);

//       console.log(timeTableMainDataList);
//       /*

// [
// {"stdID":"11112",
// "day":"Monday",
//   "stdName":"std2 Div: A  StaffName: Sam ",
//   "div": "A",
//   "title":"Prayer1",
//   "StaffName":"Sam",
//   "subjectName":"English"
// },
// {"stdID":"111134",
// "stdName":"std2 Div: A  StaffName: Sam ",
//   "div": "A",
//   "title":"Prayer2",
//   "StaffName":"arun",
//   "subjectName":"Social"
// },
// {"stdID":"4234324",
// "stdName":"std2 Div: A  StaffName: Sam ",
//   "stdName":"std2",
//   "div": "A",
//   "title":"Break",
//   "StaffName":"siddhu",
//   "subjectName":"Kannada"
// },
// {"stdID":"43432444",
// "stdName":"std2 Div: A  StaffName: Sam ",
//   "stdName":"std2",
//   "div": "A",
//   "title":"Break",
//   "StaffName":"ram",
//   "subjectName":"Maths"
// },

// {
//   "stdID":"44444",
// "stdName":"std2 Div: A  StaffName: Sam ",
//   "stdName":"std2",
//   "div": "A",
//   "title":"Prayer1",
//   "StaffName":"prashanth",
//   "subjectName":"English"
// },
// {
//   "stdID":"555555",
// "stdName":"std2 Div: A  StaffName: Sam ",
//   "div": "A",
//   "title":"Prayer2",
//   "StaffName":"rohith",
//   "subjectName":"Social"
// },
// {
//   "stdID":"111131",
// "stdName":"std2 Div: A  StaffName: Sam ",
//   "stdName":"std2",
//   "div": "A",
//   "title":"Break",
//   "StaffName":"sandeep",
//   "subjectName":"Kannada"
// },
// {
//   "stdID":"1111",
// "stdName":"std2 Div: A  StaffName: Sam ",
//   "div": "A",
//   "title":"Break",
//   "StaffName":"prasanna",
//   "subjectName":"Maths"
// },

// ]

// );

// */
//       //console.log(day);
//       //  day.filter((element, index, array) => array.indexOf(element) !== index);
//       //console.log(day);

//       // updateStandardDivision(response.data.data);
//     } catch (error) {
//       console.log(error);
//     }
//     // console.log(standardDivisionListAll);
//   };
//   // "===============TimeTable============"

//   return (
//     <div>
//       <Topbar
//         text={"Creating Teachers Time Table has two steps (Step 1 & Step 2)"}
//       />
//       <Box sx={{ flexGrow: 1 }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12}>
//             <TeacherItem>
//               <Stack direction="row" spacing={1}>
//                 {staff.map((element) => (
//                   <Box sx={{ border: "1px solid", width: 80, height: 80 }}>
//                     <Avatar
//                       variant="square"
//                       sx={{ mx: 2, my: 1 }}
//                       alt={element.staffName}
//                       src="..."
//                     />
//                     {/* <Typography>{element.staffName}</Typography> */}
//                     <ToggleButton
//                       value={element.staffName}
//                       id={element.staffID}
//                       selected={staffSelected}
//                       onChange={onStaffSelection}
//                       size="small"
//                       sx={{ height: 20 }}
//                     >
//                       {element.staffName}
//                     </ToggleButton>
//                   </Box>
//                 ))}
//               </Stack>
//             </TeacherItem>
//           </Grid>
//           <Grid item xs={6}>
//             <Item>
//               <Typography>
//                 Select the subject After selecting the Standard
//               </Typography>
//               <Box sx={{ flexGrow: 1, p: 2 }}>
//                 <Grid container spacing={2}>
//                   {standardListAll.map((element) => (
//                     <Grid item xs={2.4} sx={{ border: "2px solid" }}>
//                       <Box>
//                         <Button
//                           id={element.standardID}
//                           value={element.standardName}
//                           size="small"
//                           onClick={onSelectStandardSelect}
//                         >
//                           {element.standardName}
//                         </Button>
//                       </Box>
//                     </Grid>
//                   ))}
//                 </Grid>
//               </Box>

//               <Typography>
//                 Select Standard and Division after selecting Teacher and subject
//               </Typography>
//               <Box sx={{ flexGrow: 1 }}>
//                 <Grid container spacing={0}>
//                   <Grid item xs={10}>
//                     <Item0>
//                       <Grid container spacing={0}>
//                         {standardSubjectListAll.map((element) => (
//                           <Grid item xs={4}>
//                             <Item1>
//                               <Button
//                                 id={element.subjectID}
//                                 value={element.subjectName}
//                                 onClick={onSelectSubjectSelect}
//                                 size="small"
//                               >
//                                 {element.subjectName}
//                               </Button>
//                             </Item1>
//                             {/* <Item1>asdfghjkl</Item1> */}
//                           </Grid>
//                         ))}
//                       </Grid>
//                     </Item0>
//                   </Grid>
//                   <Grid item xs={2}>
//                     <Item0>
//                       <Grid container spacing={2}>
//                         {standardDivisionListAll.map((element) => (
//                           <Grid item xs={12}>
//                             <Item1>
//                               <Button
//                                 id={element.divisionID}
//                                 value={element.divisionName}
//                                 size="small"
//                                 onClick={onSelectDivisionSelect}
//                               >
//                                 {element.divisionName}
//                               </Button>
//                             </Item1>
//                             {/* <Item1>{element.divisionName}</Item1> */}
//                           </Grid>
//                         ))}
//                       </Grid>
//                     </Item0>
//                   </Grid>
//                 </Grid>
//               </Box>
//             </Item>
//           </Grid>
//           <Grid item xs={6}>
//             <Item>
//               <Stack direction="row" spacing={2}>
//                 <Item>
//                   <Stack spacing={2}>
//                     <Item>Title</Item>
//                   </Stack>

//                   <Stack spacing={2}>
//                     <Item>Prayer</Item>
//                   </Stack>

//                   <Stack spacing={2}>
//                     <Item>Break</Item>
//                   </Stack>
//                 </Item>

//                 <Item>
//                   <Stack spacing={2}>
//                     <Item>Monday</Item>
//                     <div>
//                       {timeTableMainDataList.map((row, index) => {
//                         if (row.staffName === " ") {
//                           console.log("inside if");

//                           return (
//                             <Item
//                               key={row.stdID}
//                               accessKey={row.startTime}
//                               id={row.endTime}
//                             >
//                               {" "}
//                               <Button
//                                 value={row.day}
//                                 id={row.title}
//                                 accessKey={row.startTime}
//                                 // key={row.endTime}
//                                 onClick={onTimeTableStaffSubClick}
//                               >
//                                 . Click To add Staff{" "}
//                               </Button>{" "}
//                             </Item>
//                           );
//                         } else {
//                           return (
//                             <Item key={row.stdID}>{row.standardName} </Item>
//                           );
//                         }
//                       })}
//                     </div>
//                   </Stack>
//                 </Item>

//                 <Item>
//                   <Stack spacing={2}>
//                     <Item>Tuesday</Item>
//                     {timeTableMainDataList.map((row) => (
//                       <Item key={row.stdID}>{row.standardName} </Item>
//                     ))}
//                   </Stack>
//                 </Item>
//               </Stack>
//             </Item>
//           </Grid>
//         </Grid>
//       </Box>

//       <Box sx={{ flexGrow: 1 }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12}>
//             <Stack
//               direction="row"
//               justifyContent="flex-end"
//               alignItems="center"
//               spacing={1}
//               sx={{ m: 2 }}
//             >
//               <Button variant="outlined">Freeze and Review</Button>
//               {/* <Button variant="outlined" onClick={onNextPage}> */}
//               {/* SKIP Creating Time for now */}
//               {/* </Button> */}
//               <Button variant="outlined">Pause and Continue Setup Later</Button>
//             </Stack>
//           </Grid>
//         </Grid>
//       </Box>
//     </div>
//   );
// }
