import React, { useState } from "react";
import axios from "axios";
import { C } from "../components/util/C";
import { Avatar, Box, Button } from "@mui/material";
import { ImageConverter } from "./imgaeToBase";

function App() {
  const [file, setFile] = useState();

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  function handleSubmit(event) {
    event.preventDefault();
    const url = "http://143.110.188.253:1919/file/upload";
    const formData = new FormData();
    formData.append("file", file);
    C.c(formData.get("file"));
    // formData.append('fileName', file.name);
    // const config = {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // };
    axios.post(url, formData).then((response) => {
      console.log(response.data);
    });
  }

  const [imageSrc, setImageSrc] = React.useState();
  const fileBrowseHandler = (event) => {
    let value = URL.createObjectURL(event.currentTarget.files[0]);
    setImageSrc(event.currentTarget.files[0]);
    console.log(value);
    setImageUrl(value);
    console.log(imageSrc);
  };

  const [postImage, setPostImage] = useState({
    myFile: "",
  });
  const [imageUrl, setImageUrl] = React.useState("");

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await ImageConverter.convertToBase64(file);
    // setPostImage({ ...postImage, myFile: base64 });
    console.log(base64);
    setImageUrl(base64);
  };

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <h1>React File Upload</h1>
        <input type="file" onChange={handleChange} />
        <button type="submit">Upload</button>
      </form>
      <Box>
        <Avatar
          alt="A"
          src={imageUrl}
          sx={{ width: 100, height: 100, margin: 8, borderRadius: "5%" }}
          variant="square"
        />
        <input
          style={{ display: "none" }}
          id="contained-button-file"
          type="file"
          accept="image/*"
          name="studentImg"
          // onChange={fileBrowseHandler}
          onChange={handleFileUpload}
        />
        <label htmlFor="contained-button-file">
          <Button
            required
            variant="contained"
            color="primary"
            component="span"
            sx={{ ml: 0, borderRadius: "25px" }}
          >
            Upload
          </Button>
        </label>
      </Box>
    </div>
  );
}

export default App;
