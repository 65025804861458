import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TopBar from "../../MainTopbar/Appbar";
import Student from "./studentSearch";
import StudentInfo from "./studentInfo";
import StudentAttendance from "./studentAttendence";
import StudentFees from "./studentFeeRecipt";
import StudentExam from "./studentExam";
import StudentLibrary from "./studentLibrary";
import Supplies from "./studentSupplies";
import Document from "./studentDocument";
import Remarks from "./studentRemarks";
import GovtInfo from "./studentGovtInfo";
import Uploads from "./studentUpload";

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: "100%", width: "100%" }}>{children}</Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  //   const [highlight, setHighlight] = React.useState(false);

  const Click = (newValue) => {
    setValue(newValue);
    // setHighlight(newValue);
    console.log(value);
    // console.log(highlight);
  };

  return (
    <div>
      <TopBar />
      <Box sx={{ border: "2px solid black" }}>
        <Grid container spacing={2} py={0.5}>
          <Grid item xs={1}></Grid>
          <Grid item xs={1.5}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              onClick={() => Click(0)}
              sx={{ fontWeight: "bold" }}
              //   onClick={onSelectStudent}
            >
              STUDENT’S INFO
            </Button>
          </Grid>
          {/* <Grid item xs={1}>
            <Button fullWidth size="small" onClick={onSelectStudent}>
              SELECT STUDENT
            </Button>
          </Grid> */}
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(1)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 1 ? "black" : "",
                color: value == 1 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              INFORMATION
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              onClick={() => Click(2)}
              size="small"
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 2 ? "black" : "",
                color: value == 2 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              ATTENDANCE
            </Button>
          </Grid>
          <Grid item xs={0.5}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(3)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 3 ? "black" : "",
                color: value == 3 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              FEES
            </Button>
          </Grid>
          <Grid item xs={0.5}>
            <Button
              fullWidth
              size="small"
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 4 ? "black" : "",
                color: value == 4 ? "white" : "black",
                fontWeight: "bold",
              }}
              onClick={(e) => {
                alert("Coming Soon !");
              }}
              // onClick={() => Click(4)}
            >
              EXAMS
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(5)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 5 ? "black" : "",
                color: value == 5 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              LIBRARY
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(6)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 6 ? "black" : "",
                color: value == 6 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              SUPPLIES
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(7)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 7 ? "black" : "",
                color: value == 7 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              DOCUMENTS
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(8)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 8 ? "black" : "",
                color: value == 8 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              REM N RECOG
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(9)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 9 ? "black" : "",
                color: value == 9 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              GOVT INFO
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={() => Click(10)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 10 ? "black" : "",
                color: value == 10 ? "white" : "black",
                fontWeight: "bold",
              }}
            >
              UPLOADS
            </Button>
          </Grid>
        </Grid>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <Student />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <StudentInfo />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <StudentAttendance />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <StudentFees />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
        <StudentExam />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={5}>
        <StudentLibrary />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={6}>
        <Supplies />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={7}>
        <Document />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={8}>
        <Remarks />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={9}>
        <GovtInfo />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={10}>
        <Uploads />
      </CustomTabPanel>
    </div>
  );
}
