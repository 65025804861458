import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class StudentService {
  // static serverURL = "http://143.110.188.253:1919/student/";
  static service = "student/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static addStudent(contact) {
    var dataURL = this.serverURL + "create";
    return axios.post(dataURL, contact);
  }

  // * Student list
  static studentListAll(contact) {
    var dataURL = this.serverURL + "list/standardID?standardID=" + contact;

    return axios.get(dataURL);
  }
  // * Student list
  static studentstandardListAll(s, contact) {
    var dataURL =
      this.serverURL +
      "list/standardID?standardID=" +
      s +
      "&yearName=" +
      contact;

    return axios.get(dataURL);
  }

  // * Student list by approve status
  static approvedstudentListAll(contact) {
    var dataURL = this.serverURL + "list/standardID?standardID=" + contact;

    return axios.get(dataURL);
  }

  // * Student list by dividsion and aprove status
  static approvedStandardStudentListAll(yearID, yearName, standardID) {
    //143.110.188.253:1919/student/list/standardID/division/approveStatus?approveStatus=true&divisionID=A&standardID=94c2e240-91b9-4462-b8ab-43c3e511fcea&yearID=2077
    var dataURL =
      this.serverURL +
      "list/standardID/division/approveStatus?approveStatus=true&standardID=" +
      standardID +
      "&yearID=" +
      yearID +
      "&yearName=" +
      yearName;

    return axios.get(dataURL);
  }
  // * Student list by dividsion and aprove status
  static approveddivisionstudentListAll(yearID, divisionID, standardID) {
    //143.110.188.253:1919/student/list/standardID/division/approveStatus?approveStatus=true&divisionID=A&standardID=94c2e240-91b9-4462-b8ab-43c3e511fcea&yearID=2077
    var dataURL =
      this.serverURL +
      "list/standardID/division/approveStatus?approveStatus=true&divisionID=" +
      divisionID +
      "&standardID=" +
      standardID +
      "&yearID=" +
      yearID;

    return axios.get(dataURL);
  }
  // * Student list by dividsion and aprove status
  static unApproveddivisionstudentListAll(yearID, divisionID, standardID) {
    //143.110.188.253:1919/student/list/standardID/division/approveStatus?approveStatus=true&divisionID=A&standardID=94c2e240-91b9-4462-b8ab-43c3e511fcea&yearID=2077
    var dataURL =
      this.serverURL +
      "list/standardID/division/approveStatus?approveStatus=false&divisionID=" +
      divisionID +
      "&standardID=" +
      standardID +
      "&yearID=" +
      yearID;

    return axios.get(dataURL);
  }
  // * Student update
  static studentDivUpdate(contact) {
    // /student/update/division
    console.log(contact);
    var dataURL = this.serverURL + "update/division";

    return axios.put(dataURL, contact);
  }

  static StandardDivisionGenderCount(standardID, yearName, yearID) {
    var dataURL =
      this.serverURL +
      "list/standardID/divgendercount?standardID=" +
      standardID +
      "&yearName=" +
      yearName;

    return axios.get(dataURL);
  }
}
