import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class TimeTableService {
  // static serverURL = "http://143.110.188.253:1919/schoolTimetable/";

  static service = "schoolTimetable/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createSchoolTimeTableMaster(contact) {
    var dataURL = this.serverURL + "create";

    return axios.post(dataURL, contact);
  }

  static deleteDesignation(contact) {
    var dataURL = this.serverURL + "delete?designationID=" + contact;

    return axios.delete(dataURL);
  }

  static designationListAll() {
    var dataURL = this.serverURL + "listall";

    return axios.get(dataURL);
  }

  static StandardTimeTable(standardID, standard, divisionID, division) {
    console.log("##########@@@@@@@@@@@@@@@@@@");

    console.log(standardID);
    console.log(standard);
    console.log(divisionID);
    console.log(division);

    var dataURL =
      this.serverURL +
      "getStandaredTimetable?division=" +
      division +
      "&divisionID=" +
      division +
      "&standard=" +
      "LKG" +
      "&standardID=" +
      "c";

    console.log(dataURL);
    return axios.get(dataURL);
  }

  static saveStdDivTimeTable(staffSubjectNameSave) {
    console.log(staffSubjectNameSave);
    var dataURL = this.serverURL + "saveStdDivTimeTable";
    return axios.post(dataURL, staffSubjectNameSave);
  }
}
