import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class StaffAttendenanceService {
  static service = "staffAttendance/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createAttendance(data) {
    var dataURL = this.serverURL + "create";
    return axios.post(dataURL, data);
  }
  static getAllAttendance(data) {
    var dataURL = this.serverURL + "getstaffattendanceList?month=" + data;
    return axios.get(dataURL);
  }
  static getStaffAttendance(i, m, n, y) {
    var dataURL =
      this.serverURL +
      "getstaffattendance?SSID=" +
      i +
      "&month=" +
      m +
      "&staffName=" +
      n +
      "&yearName=" +
      y;
    return axios.get(dataURL);
  }
  static getIndividualStaffAttendance(i, n, y) {
    var dataURL =
      this.serverURL +
      "getstaffIndividualMonthattendance?SSID=" +
      i +
      "&staffName=" +
      n +
      "&yearName=" +
      y;
    return axios.get(dataURL);
  }
  static getAllAttendanceMonth(data, yearName) {
    var dataURL =
      this.serverURL +
      "getStaffMonthWiseReport?monthName=" +
      data +
      "&yearName=" +
      yearName;
    return axios.get(dataURL);
  }
  static getDateStaffAttendance(date, month, yearName) {
    var dataURL =
      this.serverURL +
      "getDaystaffattendance?day=" +
      date +
      "&month=" +
      month +
      "&year=" +
      yearName;
    return axios.get(dataURL);
  }
  static DayStaffAttendance(day, yearName) {
    var dataURL =
      this.serverURL + "StaffDayAttendance?date=" + day + "&year=" + yearName;
    return axios.get(dataURL);
  }
}
//localhost:1919/staffAttendance/
