import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class FeeCollectionStructure {
  static service = "feesCollectionController/";
  static reportservice = "feesCollectionReportController/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;
  static reportserverURL = this.baseURL + this.reportservice;

  static getFeesResiept(y) {
    var dataURL = this.serverURL + "getFeesResiept?yearName=" + y;
    return axios.get(dataURL);
  }
  static StudentFeesCollectionList(y) {
    var dataURL = this.serverURL + "studentFeesCollectionList?yearName=" + y;
    return axios.get(dataURL);
  }
  static getIndividualstudentappliedFeereceipts(sname, usn, y) {
    var dataURL =
      this.serverURL +
      "getIndividualstudentappliedFeereceipts?studentName=" +
      sname +
      "&studentUSN=" +
      usn +
      "&yearName=" +
      y;

    return axios.get(dataURL);
  }
  static getIndividualstudentreceipts(sname, usn, y) {
    var dataURL =
      this.serverURL +
      "getPreviousStudentFeeReceipts?studentName=" +
      sname +
      "&studentUSN=" +
      usn +
      "&yearName=" +
      y;

    return axios.get(dataURL);
  }
  static getStudentAcceptFeeAmount(sname, usn, y) {
    var dataURL =
      this.serverURL +
      "getStudentAcceptFeeAmount?studentName=" +
      sname +
      "&studentUSN=" +
      usn +
      "&yearName=" +
      y;

    return axios.get(dataURL);
  }
  static createStudentAppliedFeesCollectionReciept(data) {
    var dataURL = this.serverURL + "createStudentAppliedFeesCollectionReciept";

    return axios.post(dataURL, data);
  }
  static createStudentFeesCollection(data) {
    var dataURL = this.serverURL + "createStudentFeesCollection";

    return axios.post(dataURL, data);
  }

  static createStudentFeesReciept(data) {
    var dataURL = this.serverURL + "createStudentFeesReciept";

    return axios.post(dataURL, data);
  }

  static updateStudentFeesTitleCollection(data) {
    var dataURL = this.serverURL + "updateStudentFeesTitleCollection";

    return axios.post(dataURL, data);
  }

  static getFeesCollectedLaunchPad(day, month, yearName) {
    var dataURL =
      this.serverURL +
      "getFeesCollectedLaunchPad?day=" +
      day +
      "&month=" +
      month +
      "&yearName=" +
      yearName;
    return axios.get(dataURL);
  }

  static getFeescollectionsData(sname, usn, y) {
    var dataURL1 =
      this.serverURL +
      "getIndividualstudentappliedFeereceipts?studentName=" +
      sname +
      "&studentUSN=" +
      usn +
      "&yearName=" +
      y;
    let getIndividualstudentappliedFeereceipts = axios.get(dataURL1);
    // return axios.get(dataURL);

    var dataURL2 =
      this.serverURL +
      "getStudentAcceptFeeAmount?studentName=" +
      sname +
      "&studentUSN=" +
      usn +
      "&yearName=" +
      y;
    let getStudentAcceptFeeAmount = axios.get(dataURL2);

    return [getIndividualstudentappliedFeereceipts, getStudentAcceptFeeAmount];
  }
  static getFeesReciptData(num, y) {
    var dataURL =
      this.serverURL +
      "getstudentPaidFeereceiptsFeesTitle?FeesReceiptNumber=" +
      num +
      "&yearName=" +
      y;
    return axios.get(dataURL);
    //getstudentPaidFeereceiptsFeesTitle?FeesReceiptNumber=2023-2024-3&yearName=2023-2024
  }

  // =========================report Fee=========================
  static monthFeesCollectionreport(y) {
    var dataURL =
      this.reportserverURL + "monthFeesCollectionreport?yearName=" + y;

    return axios.get(dataURL);
  }
  static monthindivisualFeesCollectionreport(m, y) {
    var dataURL =
      this.reportserverURL +
      "monthindivisualFeesCollectionreport?month=" +
      m +
      "&yearName=" +
      y;

    return axios.get(dataURL);
  }
  static monthstandardFeesCollectionreport(m, y) {
    var dataURL =
      this.reportserverURL +
      "monthstandardFeesCollectionreport?month=" +
      m +
      "&yearName=" +
      y;

    return axios.get(dataURL);
  }
  static getFeesCollectedLaunchPad(d, m, y) {
    var dataURL =
      this.reportserverURL +
      "getFeesCollectedLaunchPad?date=" +
      d +
      "&month=" +
      m +
      "&yearName=" +
      y;

    return axios.get(dataURL);
  }
}
//localhost:3000/feesCollectionReportController/monthFeesCollectionreport?yearName=2077
//localhost:1919/feesCollectionReportController/monthFeesCollectionreport?yearName=2077
