import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import Topbar from "../../MainTopbar/Topbar";
import { useEffect, useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import { AddStandarsService } from "../../../services/addStandardsService";
import { TimeTableService } from "../../../services/TimeTableService";
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";
import AlertModal from "./popup/alertPopUp";
import { useNavigate } from "react-router-dom";
import { StandardDivision } from "../../../services/StandardDIvision";
import { TimeTable } from "../../../services/TimeTable";

const style = {
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  height: 250,
  borderRadius: "20px",
};

const styleAlert = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid red",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

const CreateTimeTable = () => {
  const [alertVal, setAlertVal] = useState("");
  const [openAlert, setAlert] = useState(false);
  const [status, setStatus] = useState();
  const [four, setFour] = useState();
  const [fourId, setFourId] = useState();
  const [five, setFive] = useState();
  const [fiveId, setFiveId] = useState();

  const [fulldayStartValue, setFulldayStartValue] = useState();
  const [fulldayEndValue, setFulldayEndValue] = useState();
  const [openFullday, setOpenFullday] = useState(false);
  const [value, setValue] = useState(dayjs("2022-04-17T15:30"));
  const [breakvalue, setBreakValue] = useState([]);
  const [standardvalue, setStandardValue] = useState();
  const [standardList, setStandardList] = useState();

  const [open, setOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [startValue, setStartValue] = useState();
  const [endvalue, setEndValue] = useState();

  const [fullDayClass, setFullDayClass] = useState();
  const [fullDayBreaks, setFullDayBreaks] = useState();

  const [sunChecked, setSunChecked] = useState(false);
  const [monChecked, setMonChecked] = useState(false);
  const [tueChecked, setTueChecked] = useState(false);
  const [WedChecked, setWedChecked] = useState(false);
  const [thuChecked, setThuChecked] = useState(false);
  const [friChecked, setFriChecked] = useState(false);
  const [satChecked, setSatChecked] = useState(false);

  let navigate = useNavigate();
  const [openf, setOpenf] = useState(false);

  const [fColumn, setFColumn] = useState([]);
  const [fRow, setFRow] = useState([
    { id: 1, name: "Start Time", sortable: false },
    { id: 2, name: "End Time", sortable: false },
  ]);

  useEffect(() => {
    onStandardLoad();
    onLoadBuildSchool();
  }, []);

  const handleAlertOpen = () => setAlert(true);
  const handleAlertClose = () => setAlert(false);

  const onStandardLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      //console.log(response.data.data);
      setStandardList(response.data.data);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  const [sColumn, setSColumn] = useState([]);
  const [sRow, setSRow] = useState([]);

  const onLoadBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let status;
    try {
      let response = await BuildSchoolControll.buildSchoolStatusListAll(
        data.yearName
      );
      console.log(response.data.data[3].buildSchoolStatus);
      setStatus(response.data.data[3].buildSchoolStatus);
      setFourId(response.data.data[3].buildSchoolID);
      setFiveId(response.data.data[4].buildSchoolID);
      status = response.data.data[3].buildSchoolStatus;
    } catch (error) {
      console.error(error);
    }

    if (status == "true") {
      try {
        let response = await StandardDivision.StandardDivision(
          data.yearName,
          data.yearID
        );
        if (response.data.data) {
          let periodstype = [];
          let rowData = [];
          try {
            let resp = await TimeTable.StandardTimeTable(
              response.data.data[0].standardID,
              response.data.data[0].standardName,
              response.data.data[0].divisionName,
              data.yearName
            );
            // resp.data.data.titleList.forEach((r, i) => {
            //   periodstype.push({
            //     field: `p${i.toString()}`,
            //     headerName: r,
            //     flex: 1,
            //   });
            // });
            console.log(resp.data.data);
            // console.log(periodstype);
            setSColumn(resp.data.data.daysList);

            console.log(rowData);
            setSRow(resp.data.data.timeTableMasterList.Monday);
          } catch (er) {
            console.log(er);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCheckBoxChange = (event) => {
    // console.log(event.target.value);
    if (event.target.value == "monday") setMonChecked(event.target.checked);
    if (event.target.value == "tuesday") setTueChecked(event.target.checked);
    if (event.target.value == "wednesday") setWedChecked(event.target.checked);
    if (event.target.value == "thursday") setThuChecked(event.target.checked);
    if (event.target.value == "friday") setFriChecked(event.target.checked);
    if (event.target.value == "saturday") setSatChecked(event.target.checked);
    if (event.target.value == "sunday") setSunChecked(event.target.checked);
  };

  const createFulldayTimeTable = (value) => {
    let table = [];
    let totalperiod = parseInt(fullDayClass) + value;
    setOpenFullday(true);

    table.push({
      field: "name",
      headerName: "Title",
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
    });
    setTimeout(() => {
      for (let i = 0; i < totalperiod; i++) {
        table.push({
          field: i + "period",
          headerName: `Period ${i}`,
          flex: 1,
          sortable: false,
          align: "center",
          headerAlign: "center",
        });
      }
      table.forEach((r, i) => {
        // console.log(r);

        if (r.field == "0period") {
          let data = fRow[0];
          data["0period"] = format(fulldayStartValue.$d, "HH:mm:ss");
        }
        let lastperiod = `${table.length - 2}period`;
        // console.log(table.length - 2);
        if (r.field == lastperiod) {
          let data = fRow[1];
          data[lastperiod] = format(fulldayEndValue.$d, "HH:mm:ss");
        }
      });

      setFColumn(table);
      setOpenFullday(false);
    }, 1000);
  };

  const onTimeEnter = (v) => {
    let data = fRow;
    fColumn.forEach((r, i) => {
      if (r.field == "0period" || r.field == "name") {
        // console.log("1");
        // console.log(r.field);
        // let data = fRow[0];
        setStartValue(fulldayStartValue);
      } else {
        // console.log("2");
        // console.log(r.field);
        // setStartValue();
        setStartValue(preTime);
      }
    });
    console.log(v);
    console.log(v.row.id);
    setSelectedPeriod(v.colDef.headerName);
    setOpen(true);
  };

  const [preTime, setPreTime] = useState();

  const onEnterTime = (v, x, y) => {
    let data = fRow;
    const formattedTime = format(v.$d, "HH:mm:ss");
    console.log(formattedTime);

    setEndValue(v);

    setTimeout(function () {
      fColumn.forEach((rec, i) => {
        if (rec.headerName == selectedPeriod) {
          let e = data[1];
          let pet = `${i - 1}periodtime`;
          let pe = `${i - 1}period`;
          e[pet] = v;
          e[pe] = formattedTime;

          let s = data[0];
          let pst = `${i}periodtime`;
          let ps = `${i}period`;
          s[pst] = v;
          s[ps] = formattedTime;
          setPreTime(v);
        }
      });
    }, 1000);

    console.log(fRow);
  };

  const saveTimeTable = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    if (breakvalue.length != fullDayBreaks) {
      alert("Please select the proper breaks");
      return;
    }

    let period_time = [];
    let standards = [];
    let daysCollect = [];
    let days = [];
    let startTimeData = fRow[0];
    let endTimeData = fRow[1];
    // console.log(startTimeData);
    // console.log(endTimeData);
    let iterateValue = parseInt(fullDayBreaks) + parseInt(fullDayClass);
    // console.log(iterateValue);
    for (let i = 0; i < iterateValue; i++) {
      let periodTitle = `Period ${i}`;
      let periodSTime = i + "period";

      period_time.push({
        endTime: endTimeData[`${periodSTime}`],
        startTime: startTimeData[`${periodSTime}`],
        title: periodTitle,
        typeOfPeriod: "class",
        yearID: data.yearID,
        yearName: data.yearName,
      });
    }
    period_time.forEach((r, i) => {
      breakvalue.forEach((v, i) => {
        if (r.title == v.headerName) {
          r.typeOfPeriod = "break";
        }
      });
    });

    standardList.forEach((v, i) => {
      standards.push(v.standardName);
    });

    daysCollect.push(
      sunChecked ? "Sunday" : null,
      monChecked ? "Monday" : null,
      tueChecked ? "Tuesday" : null,
      WedChecked ? "Wednesday" : null,
      thuChecked ? "Thursday" : null,
      friChecked ? "Friday" : null,
      satChecked ? "Saturday" : null
    );

    daysCollect.forEach((v) => {
      if (v) {
        days.push(v);
      }
    });

    let obj = {
      breaks: parseInt(fullDayBreaks),
      classes: parseInt(fullDayClass),
      endTime: format(fulldayEndValue.$d, "HH:mm:ss"),
      numberOfSessions: parseInt(fullDayClass),
      schoolPeriodTimings: period_time,
      schoolWorkingDays: days,
      standards: standards,
      startTime: format(fulldayStartValue.$d, "HH:mm:ss"),
      yearID: data.yearID,
      yearName: data.yearName,
    };
    console.log(obj);

    try {
      let response = await TimeTableService.createSchoolTimeTableMaster(obj);
      // navigate("/dashborad", { replace: true });
      console.log(response.data.data);
      setAlertVal("Time Table Has been Created successfully !");
      handleAlertOpen();
    } catch (error) {
      console.log(error);
      // setAlertVal("Error on Creating !");
    }

    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: fourId,
        buildSchoolName: "timetable",
        buildSchoolStatus: "true",
        buildSchoolStep: "four",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      setAlertVal("Error creating buildSchool " + error);
      handleAlertOpen();
    }
    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: fiveId,
        buildSchoolName: "feestructure",
        buildSchoolStatus: "false",
        buildSchoolStep: "five",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      setAlertVal("Error creating buildSchool " + error);
      handleAlertOpen();
    }
    setTimeout(() => {
      handleAlertClose();
    }, 2000);

    // Navigate("/dashborad", { replace: true });
  };

  const onNextPage = async () => {
    console.log("next page");
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: fourId,
        buildSchoolName: "timetable",
        buildSchoolStatus: "true",
        buildSchoolStep: "four",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      alert("Error creating buildSchool " + error);
    }
    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: fiveId,
        buildSchoolName: "feestructure",
        buildSchoolStatus: "false",
        buildSchoolStep: "five",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      alert("Error creating buildSchool " + error);
    }
    navigate("/dashborad", { replace: true });
    // navigate("/timetable1", { replace: true });
  };
  const onFreeze = async () => {
    console.log("next page");
    setOpenf(true);
    // navigate("/dashborad", { replace: true });
  };
  const onBuildSchool = (e) => {
    saveTimeTable();

    navigate("/dashborad", { replace: true });
  };

  return (
    <div>
      <Topbar text={"Step 4 : Creating Time Table for School"} />
      {/* <Box> */}
      <Box
        display={status == "true" ? "none" : "block`"}
        sx={{ flexGrow: 1, margin: 5 }}
      >
        <Grid container spacing={2}>
          <Grid xs={7} md={10}>
            {/* <Box textAlign={"center"}> */}
              <Typography fontWeight={"bold"} textAlign={{xs:"", md:"center"}}>
                Kindly let us know your school runs in one session or two
                sessions Please inform us about the two sessions days timings
                including{" "}
              </Typography>
            {/* </Box> */}
          </Grid>
          <Grid xs={4.5} md={2} marginLeft={{xs:2, md:'0'}}>
            <Box display={"flex"} justifyContent={"center"}>
              <Button variant="contained">Session I</Button>
              <Button disabled variant="contained">
                Session II
              </Button>
            </Box>
          </Grid>
          <Grid xs={12} mt={2}>
            <Box display={"flex"} justifyContent={"center"}>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="monday"
                      checked={monChecked}
                      onChange={handleCheckBoxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={<strong>Monday</strong>}
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="tuesday"
                      checked={tueChecked}
                      onChange={handleCheckBoxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={<strong>Tuesday</strong>}
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="wednesday"
                      checked={WedChecked}
                      onChange={handleCheckBoxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={<strong>Wednesday</strong>}
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="thursday"
                      checked={thuChecked}
                      onChange={handleCheckBoxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={<strong>Thursday</strong>}
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="friday"
                      checked={friChecked}
                      onChange={handleCheckBoxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={<strong>Friday</strong>}
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="saturday"
                      checked={satChecked}
                      onChange={handleCheckBoxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={<strong>Saturday</strong>}
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="sunday"
                      checked={sunChecked}
                      onChange={handleCheckBoxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={<strong>Sunday</strong>}
                  labelPlacement="top"
                />
              </FormGroup>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box display={"flex"} justifyContent={"space-evenly"}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimeField
                  label="Start Time"
                  value={fulldayStartValue}
                  onChange={(newValue) => setFulldayStartValue(newValue)}
                />
                <TimeField
                  disabled={fulldayStartValue ? false : true}
                  label="End Time"
                  value={fulldayEndValue}
                  onChange={(newValue) => setFulldayEndValue(newValue)}
                />
              </LocalizationProvider>
              <Box display={"flex"} justifyContent={"space-evenly"}>
                <TextField
                  type="number"
                  id="Class"
                  label="Class"
                  variant="outlined"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  value={fullDayClass}
                  disabled={fulldayStartValue ? false : true}
                  onChange={(e) => {
                    //   if (fullDayClass.length() >= 2) {
                    //     return;
                    //   } else {
                    setFullDayClass(e.target.value);
                    //   }
                  }}
                />
                <TextField
                  type="number"
                  id="Break"
                  label="Break"
                  variant="outlined"
                  disabled={fullDayClass ? false : true}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  value={fullDayBreaks}
                  onChange={(e) => {
                    if (parseInt(fullDayClass) <= parseInt(e.target.value)) {
                      // } else {
                      alert("Please enter valid Breaks");
                      // setFullDayBreaks("0");
                      return;
                    }
                    setFullDayBreaks(e.target.value);
                    createFulldayTimeTable(parseInt(e.target.value));
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12}>
            {fullDayBreaks ? (
              <Box sx={{ height: 220, width: "100%" }}>
                <DataGrid
                  disableColumnMenu
                  rows={fRow}
                  columns={fColumn}
                  // initialState={{
                  //   pagination: {
                  //     paginationModel: {
                  //       pageSize: 5,
                  //     },
                  //   },
                  // }}
                  hideFooterPagination
                  // pageSizeOptions={[5]}
                  onCellClick={onTimeEnter}
                />
                <Box>
                  <Autocomplete
                    multiple
                    value={breakvalue}
                    onChange={(event, newValue) => {
                      setBreakValue(newValue);
                    }}
                    id="tags-outlined"
                    options={fColumn}
                    getOptionLabel={(option) => option.headerName}
                    // getOptionDisabled={
                    //   (rec) =>
                    //     console.log(breakvalue.length, fullDayBreaks.length)
                    //   // breakvalue
                    //   //   ? breakvalue.length >= fullDayBreaks
                    //   //     ? rec
                    //   //     : []
                    //   //   : []
                    // }
                    // defaultValue={ : []}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} label="Select Breaks" />
                    )}
                    sx={{
                      marginY: 4,
                    }}
                  />
                  {/* <Autocomplete
                    multiple
                    value={standardvalue}
                    onChange={(event, newValue) => {
                      setStandardValue(newValue);
                    }}
                    id="tags-outlined"
                    options={standardList}
                    getOptionLabel={(option) => option.standardName}
                    // defaultValue={ : []}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} label="Select Standard" />
                    )}
                    sx={{
                      marginY: 2,
                    }}
                  /> */}
                </Box>
                {/* <Button variant="contained">
                  Save Time Table
                </Button> */}
                {/* <Button variant="contained" onClick={onNextPage}>
                  Skip Time Table
                </Button> */}
                <Box paddingBottom={"25px"}>
                  <Button variant="contained" onClick={onFreeze}>
                    Review and Freeze
                  </Button>
                </Box>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={openf}
                  // onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={openf}>
                    <Box sx={style}>
                      <Typography
                        id="transition-modal-title"
                        variant="body1"
                        component="h2"
                      >
                        You have clicked on
                      </Typography>
                      <Typography
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        FREEZ INFORMATION
                      </Typography>
                      <Typography
                        id="transition-modal-description"
                        sx={{ mt: 2 }}
                      >
                        We request you to kindly review the information once
                        again and then freeze the data, as once its FREEZE can’t
                        be changed. Thank you
                      </Typography>
                      <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                        <Button
                          variant="contained"
                          size="small"
                          color="success"
                          onClick={onBuildSchool}
                        >
                          FREEZE
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          onClick={() => setOpenf(false)}
                        >
                          Review
                        </Button>
                      </Stack>
                    </Box>
                  </Fade>
                </Modal>
              </Box>
            ) : (
              " "
            )}

            <Modal
              open={open}
              // onClose={() => {
              //   setOpen(false);
              //   setEndValue();
              // }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {selectedPeriod}
                </Typography>
                <Box sx={{ margin: 5 }}>
                  <Grid container spacing={2}>
                    <Grid xs={4}>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                      >
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimeField
                            label="Controlled field"
                            value={startValue}
                            size="small"
                            onChange={(newValue) => setStartValue(newValue)}
                          />
                          <TimeField
                            label="Controlled field"
                            value={endvalue}
                            size="small"
                            onChange={(newValue) => setEndValue(newValue)}
                          />
                        </LocalizationProvider> */}
                      </Box>
                    </Grid>
                    <Grid xs={4}>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          {selectedPeriod == "Period 0" ? (
                            <TimeField
                              label="Start Time"
                              value={fulldayStartValue}
                              // onChange={(newValue) => setStartValue(newValue)}
                              disabled
                              sx={{
                                marginBottom: 2,
                              }}
                            />
                          ) : (
                            <TimeField
                              label="Start Time"
                              value={startValue}
                              // onChange={(newValue) => setStartValue(newValue)}
                              disabled
                              sx={{
                                marginBottom: 2,
                              }}
                            />
                          )}
                          <TimeField
                            label="End Time"
                            value={endvalue}
                            // onChange={(time) => {
                            //   console.log(time);
                            //   const formattedTime = format(time.$d, "HH:mm:ss");
                            //   console.log(formattedTime);
                            //   // field.onChange(formattedTime);
                            //   setEndValue(time);
                            //   debugger;
                            // }}
                            onChange={(newValue, a, b) =>
                              onEnterTime(newValue, a, b)
                            }
                          />
                        </LocalizationProvider>
                        <Button
                          variant="contained"
                          sx={{ mt: "10px" }}
                          color="success"
                          onClick={() => {
                            setOpen(false);
                            setEndValue();
                          }}
                        >
                          OK
                        </Button>
                      </Box>
                    </Grid>
                    <Grid xs={4}>
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          label="Controlled field"
                          value={startValue}
                          size="small"
                          onChange={(newValue) => setStartValue(newValue)}
                        />
                        <TimeField
                          label="Controlled field"
                          value={endvalue}
                          size="small"
                          onChange={(newValue) => setEndValue(newValue)}
                        />
                      </LocalizationProvider> */}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Modal>
            <Modal
              open={openAlert}
              onClose={handleAlertClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div sx={styleAlert}>
                <AlertModal text={alertVal} />
              </div>
            </Modal>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openFullday}
            >
              <CircularProgress color="inherit">
                {/* <Avatar src="logo.png" alt="" variant="square"></Avatar> */}
              </CircularProgress>
            </Backdrop>
          </Grid>
        </Grid>
      </Box>
      <Box display={status == "true" ? "block" : "none"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 490,
          }}
        >
          <h1>"TimeTable 📅 has been Freezed❗"</h1>
          {/* <Box sx={{ height: 220, width: "100%" }}>
            <DataGrid
              rows={sRow}
              columns={sColumn}
              // initialState={{
              //   pagination: {
              //     paginationModel: {
              //       pageSize: 5,
              //     },
              //   },
              // }}
              hideFooterPagination
              // pageSizeOptions={[5]}
            />
          </Box> */}
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography sx={{ fontWeight: 800 , fontSize:"18px"}} variant="subtitle1">
              School Day :
            </Typography>
            <Grid container>
            {sColumn.map((r) => (
              <Grid item xs={4} md={2} >
                <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                sx={{ m: 5, fontWeight: 800 }}
              >
                <Typography sx={{ fontWeight: 800 }} variant="subtitle1">
                  {r}
                </Typography>
              </Box>
              </Grid>
            ))}
            </Grid>
          </Box>
          {/* <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} border={"2px solid black"}> */}
          <Grid container spacing={0} sx={{ minHeight: 40 }}  border={"2px solid black"}>
            {sRow.map((r) => (
              <Grid item xs={4} md={2}>
                 <Box border={"3px solid #a6ffbe"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                sx={{ m: 3, fontWeight: 800 }}
              >
                <Typography sx={{ fontWeight: 800 }} textAlign={"center"} variant="subtitle1">
                  {r.title}
                </Typography>
                <Typography variant="subtitle1" textAlign={"center"}>
                  {r.startTime} - {r.endTime}
                </Typography>
              </Box>
              </Grid>
            ))}
          </Grid>
          {/* </Box> */}
        </Box>
      </Box>
    </div>
  );
};
export default CreateTimeTable;
