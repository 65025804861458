import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Topbar from "../../MainTopbar/Topbar";
import { Button, Fade, Modal, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom"; //for browser back button navigation handling
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";
import Backdrop from "@mui/material/Backdrop";
// import BG from "./background.jpeg";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: 10,
  height: 200,
  textAlign: "center",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export default function DashBoard() {
  //browser back button redirection to dashboard
  const Navigate = useNavigate();
  const [finishStatus, setfinishStatus] = React.useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (
        window.confirm(
          "You Can't Leave the dasboard without finishing the process"
        )
      ) {
        setfinishStatus(true);
        // your logic
        Navigate("/dashborad");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  React.useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  // back button logic ends

  const [yearName, setYearName] = React.useState("");
  const [yearID, setYearID] = React.useState("");
  React.useEffect(() => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    setYearName(data.yearName);
    setYearID(data.yearID);
    onLoadBuildSchool();
  }, []);

  const [status, setStatus] = React.useState([]);

  const [one, setOne] = React.useState();
  const [two, setTwo] = React.useState();
  const [three, setThree] = React.useState();
  const [four, setFour] = React.useState();
  const [five, setFive] = React.useState();
  const [six, setSix] = React.useState();

  const onLoadBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await BuildSchoolControll.buildSchoolStatusListAll(
        data.yearName
      );
      setStatus(response.data.data);
      // console.log(response.data.data[0]);
      setOne(response.data.data[0].activeStatus);
      setTwo(response.data.data[1].activeStatus);
      setThree(response.data.data[2].activeStatus);
      setFour(response.data.data[3].activeStatus);
      setFive(response.data.data[4].activeStatus);
      setSix(response.data.data[5].activeStatus);
      setOpen(response.data.data[6].buildSchoolStatus);
    } catch (error) {
      console.error(error);
    }
  };

  const [open, setOpen] = React.useState(false);
  const nextPage = (e) => {
    // navigate("/dashborad", { replace: true });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const stepOne = () => {
    console.log("hello world 1");
    Navigate("/addstandards", { replace: true });
  };
  const stepTwo = () => {
    console.log("hello world 2");
    Navigate("/language", { replace: true });
  };

  const stepThree = () => {
    console.log("hello world 3");
    Navigate("/designationandrole", { replace: true });
  };
  const stepFour = () => {
    console.log("hello world 4");
    // alert("please skip the time table");
    Navigate("/timetable", { replace: true });
  };
  const stepFive = () => {
    console.log("hello world 5");
    Navigate("/feesStructure", { replace: true });
  };

  const stepSix = () => {
    console.log("hello world 6");
    Navigate("/exammarkssetup", { replace: true });
  };

  const onBuildSchoolComplete = () => {
    console.log("onBuildSchoolComplete");
    Navigate("/launchpadlogin");
  };

  return (
    <div>
      <Topbar
        text={
          "There are six steps of building school online for complete management kindly proceed towards the providing us the information about your school"
        }
      />
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "scroll",
          // backgroundImage: `url("/background.jpeg")`,
          backgroundSize: "cover",
        }}
      >
        <Grid container spacing={0} sx={{}}>
          <Grid item lg={4} >
            <Item
              sx={{
                border: "4px solid orange",
                borderRadius: 8,
                // display:"grid",
                background: "transparent",
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "800" }}>
                <label
                  style={{
                    backgroundColor: "#036273",
                    color: "white",
                    padding: "0px 10px",
                  }}
                >
                  Step 1:
                </label>
                <label
                  style={{
                    backgroundColor: "#20f7f1",
                    padding: "0px 10px",
                  }}
                >
                  {" "}
                  Standard and Divisions{" "}
                </label>
              </Typography>
              <Typography variant="subtitle1" mt={1} sx={{ textAlign: "justify" }}>
                {/* {one == "active" ? (
                  <p>
                    "Please provide us the information about all the standard
                    and division are there in your school along with the seating
                    capacity, this will help us to report you about fees and
                    admission details in future"
                  </p>
                ) : (
                  <h2>"completed"</h2>
                )} */}
                Please provide us the information about all the standard and
                division are there in your school along with the seating
                capacity, this will help us to report you about fees and
                admission details in future.
              </Typography>
              {/* {<Alert severity="error">This is an error alert — check it out!</Alert>} */}
              <Box display={"flex"} justifyContent={"center"}  marginTop={{md:2 ,xs:5}}>
                <Button
                  color="primary"
                  sx={{
                    cursor: "pointer",
                    fontWeight: "bold"
                   
                  }}
                  disabled={one == "active" ? false : true}
                  variant="contained"
                  size="small"
                  onClick={stepOne}
                >
                  CLICK HERE
                </Button>
              </Box>
            </Item>
          </Grid>
          <Grid item lg={4}>
            <Item
              sx={{
                border: "4px solid lightGreen",
                borderRadius: 8,
                // display:"grid",
                background: "transparent",
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "800" }}>
                <label
                  style={{
                    backgroundColor: "#036273",
                    color: "white",
                    padding: "0px 10px",
                  }}
                >
                  Step 2:{" "}
                </label>
                <label
                  style={{ backgroundColor: "#20f7f1", padding: "0px 10px" }}
                >
                  Subject/Syllabus
                </label>
              </Typography>
              <Typography  mt={1} variant="subtitle1" sx={{ textAlign: "justify" }}>
                Please provide us the information about all the Subject thought
                in every standard you have provided us in the first step. This
                will help us to create timetable and exam reports in details in
                future.
              </Typography>
              <Box display={"flex"} justifyContent={"center"}  marginTop={{md:5 ,xs:5}}>
                <Button
                  color="primary"
                  sx={{
                    cursor: "pointer",
                    fontWeight:"bold",
                    // mt:3
                  
                  }}
                  disabled={two == "active" ? false : true}
                  variant="contained"
                  size="small"
                  onClick={stepTwo}
                >
                  CLICK HERE
                </Button>
              </Box>
            </Item>
          </Grid>
          <Grid item lg={4}>
            <Item
              sx={{
                border: "4px solid violet",
                borderRadius: 8,
                // display:"grid",
                background: "transparent",
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "800" }}>
                <label
                  style={{
                    backgroundColor: "#036273",
                    color: "white",
                    padding: "0px 10px",
                  }}
                >
                  Step 3:{" "}
                </label>
                <label
                  style={{ backgroundColor: "#20f7f1", padding: "0px 10px" }}
                >
                  Designation and Roles
                </label>
              </Typography>
              <Typography variant="subtitle1" sx={{ textAlign: "justify" }} mt={1}>
                Please provide us the information about all the Designation of
                the staff this will help us to setup the rights to use of this
                software and report you about salaries personal info and many
                more details in future.
              </Typography>
              <Box display={"flex"} justifyContent={"center"} marginTop={{md:2 ,xs:5}}>
                <Button
                  color="primary"
                  sx={{
                    cursor: "pointer",
                    fontWeight:"bold",
                  }}
                  disabled={three == "active" ? false : true}
                  variant="contained"
                  size="small"
                  onClick={stepThree}
                >
                  CLICK HERE
                </Button>
              </Box>
            </Item>
          </Grid>
          <Grid item lg={4}>
            <Item
              sx={{
                border: "4px solid yellow",
                borderRadius: 8,
                cursor: "pointer",
                // display:"grid",
                background: "transparent",
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "800" }}>
                <label
                  style={{
                    backgroundColor: "#036273",
                    color: "white",
                    padding: "0px 10px",
                  }}
                >
                  Step 4:{" "}
                </label>
                <label
                  style={{ backgroundColor: "#3bfa1e", padding: "0px 10px" }}
                >
                  Teachers/Class TIME TABLE
                </label>
              </Typography>
              <Typography variant="subtitle1" sx={{ textAlign: "justify" }} mt={1}>
                Please continue creating teachers/class time table, this step
                can also be completed at a later stage once all the subject
                teachers information is entered. This report will give you the
                information about the teachers class routine.
              </Typography>
              <Box display={"flex"} justifyContent={"center"} marginTop={{md:2 ,xs:2}}>
                <Button
                  color="primary"
                  sx={{
                    cursor: "pointer",
                    fontWeight:"bold",
                    // mr: 5,
                  }}
                  variant="contained"
                  size="small"
                  disabled={four == "active" ? false : true}
                  onClick={stepFour}
                >
                  CLICK HERE
                </Button>
              </Box>
            </Item>
          </Grid>
          <Grid item lg={4}>
            <Item
              sx={{
                border: "4px solid skyBlue",
                borderRadius: 8,
                cursor: "pointer",
                // display:"grid",
                background: "transparent",
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "800" }}>
                <label
                  style={{
                    backgroundColor: "#036273",
                    color: "white",
                    padding: "0px 10px",
                  }}
                >
                  Step 5:{" "}
                </label>
                <label
                  style={{ backgroundColor: "#20f7f1", padding: "0px 10px" }}
                >
                  FEES STRUCTURE
                </label>
              </Typography>
              <Typography variant="subtitle1" sx={{ textAlign: "justify" }} mt={1}>
                Please give us the detailed information about all the fees
                structure this step can tag every individual student a different
                fees in return we can report you details of all fees collection
                and receipts balances and more.
              </Typography>
              <Box display={"flex"} justifyContent={"center"} marginTop={{md:2 ,xs:2}}>
                <Button
                sx={{fontWeight:"bold"}}
                  color="primary"
                  variant="contained"
                  size="small"
                  disabled={five == "active" ? false : true}
                  onClick={stepFive}
                  cursor= {"pointer"}
                >
                  CLICK HERE
                </Button>
              </Box>
            </Item>
          </Grid>
          <Grid item lg={4}>
            <Item
              sx={{
                border: "4px solid #20f7f1",
                borderRadius: 8,
                cursor: "pointer",
                // display:"grid",
                background: "transparent",
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "800" }}>
                <label
                  style={{
                    backgroundColor: "#036273",
                    color: "white",
                    padding: "0px 10px",
                  }}
                >
                  Step 6:{" "}
                </label>
                <label
                  style={{ backgroundColor: "#3bfa1e", padding: "0px 10px" }}
                >
                  Exam Marks Setup
                </label>
              </Typography>
              <Typography variant="subtitle1" sx={{ textAlign: "justify" }} mt={1}>
                Please inform us to get your exam setup of calculation of marks
                and create result for every individual student. This will 
                help you saving time and create students report card and results
                in no time.
              </Typography>
              <Box display={"flex"} justifyContent={"center"} marginTop={{md:5 ,xs:5}}>
                <Button
                  color="primary"
                  sx={{cursor: "pointer", fontWeight:"bold" }}
                  variant="contained"
                  size="small"
                  disabled={six == "active" ? false : true}
                  onClick={stepSix}
                >
                  CLICK HERE
                </Button>
                {/* <Button
                  sx={{ backgroundColor: "red", color: "black" }}
                  variant="contained"
                  size="small"
                >
                  SKIP for Now
                </Button> */}
              </Box>
            </Item>
          </Grid>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open == "true" ? true : false}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography
                  id="transition-modal-title"
                  variant="h5"
                  component="h5"
                  color="primary"
                  sx={{ fontWeight: "bold" }}
                >
                  CONGRATULATIONS
                </Typography>
                <Typography
                  id="transition-modal-title"
                  variant="body1"
                  component="h2"
                  sx={{ mt: 2 }}
                >
                  School Building 🏢 has been successfully completed.
                </Typography>
                <Typography id="transition-modal-description">
                  Kindly re-login to start managing your school
                </Typography>
                {/* <Stack direction="row" spacing={1} sx={{ mt: 1 }}> */}
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  sx={{ mt: 2, px: 8 }}
                  onClick={onBuildSchoolComplete}
                >
                  Login
                </Button>
                {/* </Stack> */}
              </Box>
            </Fade>
          </Modal>
        </Grid>
      </Box>
      {/* <Box sx={{ m: 1,alignItems:"flex-end" }}>
      <Button variant="contained">Pause and Continue setup later</Button>
    </Box> */}
    </div>
  );
}
