// import * as React from "react";
// import Box from "@mui/material/Box";
// import { styled } from "@mui/material/styles";
// import { DataGrid } from "@mui/x-data-grid";
// import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
// import { C } from "../../util/C";
// import { AddStandarsService } from "../../../services/addStandardsService";
// import { StandardDivision } from "../../../services/StandardDIvision";
// import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
// import { StandardSubjectService } from "../../../services/StandardSubject";
// import { BuildSchoolExam } from "../../../services/examStandards";
// import { StudentService } from "../../../services/studentService";
// import { ExamMarksService } from "../../../services/examMarksService";
// import { useNavigate } from "react-router-dom";

// const ItemS = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(1),
//   textAlign: "center",
//   border: "1px solid black",
//   background: "#aaecac",
//   "&:hover": {
//     background: "#035e06",
//     color: "white",
//   },
// }));
// const ItemD = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(1),
//   textAlign: "center",
//   border: "1px solid black",
//   background: "#03dff9",
//   "&:hover": {
//     background: "#038da6",
//     color: "white",
//   },
// }));
// const ItemSb = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(1),
//   textAlign: "center",
//   border: "1px solid black",
//   background: "#37bc98",
//   "&:hover": {
//     background: "#03946d",
//   },
//   color: "white",
// }));
// const ItemE = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(1),
//   textAlign: "center",
//   border: "1px solid black",
//   // background: "rgb(255,148,121)",
//   background: "#5485e594",
//   "&:hover": {
//     background: "#3661b494",
//   },
// }));

// const Item = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(1),
//   textAlign: "center",
//   border: "1px solid black",
//   background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
// }));
// let Item1 = {
//   border: "1px solid black",
//   textAlign: "center",
//   width: "200px",
//   variant: "body1",
//   backgroundColor: "#B5E2F4",
//   fontWeight: "bold",
// };

// const columns = [
//   {
//     field: "id",
//     headerName: "ROLL",
//     flex: 1,
//     headerAlign: "center",
//     align: "center",
//     editable: false,
//     sortable: false,
//     renderHeader: () => <strong style={{ fontSize: 15 }}>{"ROLL"}</strong>,
//     headerClassName: "super-app-theme--header",
//   },
//   {
//     field: "name",
//     headerName: " NAME",
//     headerAlign: "center",
//     align: "center",
//     flex: 1,
//     editable: false,
//     sortable: false,
//     valueFormatter: (params) => params.value.toUpperCase(),
//     renderHeader: () => <strong style={{ fontSize: 15 }}>{"NAME"}</strong>,
//     headerClassName: "super-app-theme--header",
//   },
//   {
//     field: "marks",
//     headerName: " MARKS",
//     type: "number",
//     flex: 1,
//     headerAlign: "center",
//     align: "center",
//     editable: true,
//     sortable: false,
//     renderHeader: () => <strong style={{ fontSize: 15 }}>{"MARKS"}</strong>,
//     headerClassName: "super-app-theme--header",
//   },
// ];
// export default function DataGridDemo() {
//   const [Standard, setStandard] = React.useState([]);
//   const [rows, setRows] = React.useState([]);
//   const [active, setActive] = React.useState("");

//   React.useEffect(() => {
//     onstandardsLoad();
//   }, []);

//   const handleClick = (event) => {
//     setActive(event.target.id);
//   };

//   const onstandardsLoad = async () => {
//     let data = JSON.parse(localStorage.getItem("academicYear"));

//     try {
//       let response = await AddStandarsService.standardListAll(data.yearName);
//       C.c(response.data.data);
//       setStandard(response.data.data);
//     } catch (error) {
//       C.c(error);
//     }
//   };

//   const onMarkSheetLoad = async (e) => {
//     let data = JSON.parse(localStorage.getItem("academicYear"));
//     try {
//       let response = await ExamMarksService.marksList(
//         DivisionName,
//         StandardID,
//         e.target.id,
//         subjectID,
//         data.yearName
//       );
//       console.log(response.data.data);
//     } catch (error) {
//       alert(error);
//     }
//   };

//   const [StandardName, setStandardName] = React.useState();
//   const [StandardID, setStandardID] = React.useState();
//   const [DivisionName, setDivisionName] = React.useState();
//   const [Division, setDivision] = React.useState([]);
//   const [StandardSubjectListAll, updateStandardSubject] = React.useState([]);
//   const [subjectID, setSubjectID] = React.useState();
//   const [standardDivision, setStandardDivision] = React.useState();
//   const [standardDivisionId, setStandardDivisionId] = React.useState();
//   const [subjectName, setSubjectName] = React.useState();
//   const [testName, setTestName] = React.useState();
//   const [testNameID, setTestNameID] = React.useState();
//   const [standardTest, updateStandardTest] = React.useState([]);
//   async function onStandardSelect(e, index) {
//     // e.preventDefault();
//     setStandardName(e.target.textContent);
//     setStandardID(e.target.id);
//     C.c(StandardName);
//     C.c(e.target.value);
//     C.c(e.currentTarget.id);
//     let highlightStandardList = [...Standard];
//     highlightStandardList = highlightStandardList.map(
//       ({ isSelected, ...highlightStandardList }) => highlightStandardList
//     );
//     setStandard(highlightStandardList);
//     highlightStandardList[index]["isSelected"] = highlightStandardList[index][
//       "iSSelected"
//     ]
//       ? false
//       : true;

//     let data = JSON.parse(localStorage.getItem("academicYear"));

//     try {
//       let response = await StandardDivision.StandardDivisionWiseStandard(
//         e.currentTarget.id,
//         data.yearName,
//         data.yearID
//       );
//       C.c(response.data.data);
//       setDivision(response.data.data);
//       C.c(Division);
//     } catch (error) {
//       C.c(error.message);
//     }
//     try {
//       let response =
//         await StandardSubjectService.findSubjectListByStandardIDYearID(
//           data.yearName,
//           e.target.id
//         );
//       console.log(response.data.data);
//       response.data.data.sort(function (a, b) {
//         return a.subjectOrder - b.subjectOrder;
//       });
//       updateStandardSubject(response.data.data);
//     } catch (error) {
//       console.log(error);
//     }
//     console.log(StandardSubjectListAll);

//     // api call for test list
//     try {
//       let response = await BuildSchoolExam.showBuildSchoolStandardTest(
//         e.target.id,
//         data.yearName
//       );
//       console.log(response.data.data);
//       response.data.data.sort(function (a, b) {
//         return a.testOrder - b.testOrder;
//       });
//       updateStandardTest(response.data.data);
//     } catch (error) {
//       console.log(error);
//     }
//     console.log(StandardSubjectListAll);
//   }
//   async function onDivisionSelect(e, index) {
//     C.c(e.target.id);
//     setDivisionName(e.target.id);
//     setStandardDivisionId(e.target.id);
//     setStandardDivision(e.target.id);
//     C.c(standardDivision);
//     C.c(StandardID);

//     let highlightDivision = [...Division];
//     highlightDivision = highlightDivision.map(
//       ({ isSelected, ...highlightDivision }) => highlightDivision
//     );
//     setDivision(highlightDivision);
//     highlightDivision[index]["isSelected"] = highlightDivision[index][
//       "isSelected"
//     ]
//       ? false
//       : true;

//     let data = JSON.parse(localStorage.getItem("academicYear"));

//     let students = [];
//     try {
//       let response = await StudentService.approveddivisionstudentListAll(
//         data.yearName,
//         e.target.id,
//         StandardID
//       );

//       response.data.data.forEach((rec, i) => {
//         students.push({
//           id: i + 1,
//           name: rec.studentName.toUpperCase(),
//         });
//         // C(rec)
//       });
//       setRows(students);
//       C.c(rows);
//     } catch (error) {
//       C.c(error.message);
//     }
//   }

//   async function onSubjectSelect(e, index) {
//     C.c(e.target.textContent);
//     let highlightSubject = [...StandardSubjectListAll];
//     highlightSubject = highlightSubject.map(
//       ({ isSelected, ...highlightSubject }) => highlightSubject
//     );
//     updateStandardSubject(highlightSubject);
//     highlightSubject[index]["isSelected"] = highlightSubject[index][
//       "isSelected"
//     ]
//       ? false
//       : true;
//   }

//   async function onTestSelect(e, index, et) {
//     C.c(e.target.textContent);
//     let highlightTest = [...standardTest];
//     highlightTest = highlightTest.map(

//       ({ isSelected, ...highlightTest }) => highlightTest
//     );
//     updateStandardTest(highlightTest);
//     highlightTest[index]["isSelected"] = highlightTest[index]["isSelected"]
//       ? false
//       : true;
//       ontestValues(et);
//   }

//   const onMarksEnter = async (rec) => {
//     let MarksOBJ;
//     MarksOBJ = {
//       divisionID: Division,
//       divisionName: standardDivision,
//       standardName: StandardName,
//       standardID: StandardID,
//       // studentName: rec.row.erty,
//       subjectName: subjectName,
//       // deleteStatus: true,
//       // standardTest:,
//       subjectID: subjectID,
//       // testTitleName: rec.row.,
//     };
//     C.c(MarksOBJ);
//     try {
//       let response = await ExamMarksService.createMarks(MarksOBJ);
//       console.log(response.data.data);
//     } catch (error) {
//       C.c(error.message);
//     }
//   };

//   // "createdTime": 0,
//   //     "grade": "string",
//   //       "markSheet": 0,
//   //         "markSheetID": "string",
//   //           "monthName": 0,
//   //             "studentUSN": "string",
//   //               "subjectID": "string",
//   //                   "yearName": 0

//   const Demo = async (e) => {
//     console.log(e.currentTarget.id);
//   };

//   let navigate = useNavigate();
//   const onLaunchpad = () => {
//     navigate("/launchpad", { replace: true });
//   };
//   return (
//     <React.Fragment>
//       <LaunchpadTopBar id="dataentry" />
//       <Typography
//         variant="h6"
//         sx={{
//           textAlign: "center",
//           backgroundColor: "lightblue",
//           width: "100&",
//           boxSizing: "border-box",
//           margin: "0 10px",
//           mt: 2,
//           fontWeight: "bold",
//         }}
//       >
//         SELECT STANDARD, DIVISION, EXAM AND SUBJECT - PRINT OR DOWNLOAD BLANK
//         FORM
//       </Typography>
//       <Box
//         sx={{
//           flexGrow: 1,
//           mt: 1,
//           width: "100%",
//           padding: "0 10px",
//           boxSizing: "border-box",
//         }}
//       >
//         <Grid container spacing={1}>
//           <Grid item xs={8}>
//             <Item>
//               <Grid container spacing={0} sx={{ minHeight: 40 }}>
//                 {Standard.map((element, index) => (
//                   <Grid item xs={2} sx={{ cursor: "pointer" }}>
//                     <ItemS
//                       id={element.standardID}
//                       value={element.standardName}
//                       onClick={(e) => {
//                         onStandardSelect(e, index);
//                       }}
//                       className={element.isSelected ? "Selected" : ""}
//                       sx={{ fontWeight: "bold" }}
//                     >
//                       {element.standardName}
//                     </ItemS>
//                   </Grid>
//                 ))}
//               </Grid>
//             </Item>
//           </Grid>
//           <Grid item xs={4}>
//             <Item>
//               <Grid container spacing={0} sx={{ minHeight: 40 }}>
//                 {Division.map((element, index) => (
//                   <Grid item xs={2} sx={{ cursor: "pointer" }}>
//                     <ItemD
//                       sx={{
//                         fontWeight: "bold",
//                       }}
//                       onClick={(e) => {
//                         onDivisionSelect(e, index);
//                       }}
//                       id={element.divisionName}
//                       className={element.isSelected ? "selected" : ""}
//                     >
//                       {" "}
//                       {element.divisionName}
//                     </ItemD>
//                   </Grid>
//                 ))}
//               </Grid>
//             </Item>
//           </Grid>
//         </Grid>
//       </Box>
//       <Grid
//         container
//         spacing={1}
//         sx={{ width: "100%", padding: "0 10px", boxSizing: "border-box" }}
//       >
//         <Grid item xs={8}>
//           <Item>
//             <Grid container spacing={0} sx={{ minHeight: 40 }}>
//               {StandardSubjectListAll.map((element, index) => (
//                 <Grid item xs={2} sx={{ cursor: "pointer" }}>
//                   <ItemSb
//                     sx={{ fontWeight: "bold" }}
//                     id={element.standardSubjectID}
//                     value={element.subjectName}
//                     onClick={(e) => {
//                       onSubjectSelect(e, index);
//                       setSubjectName(e.target.textContent);
//                       setSubjectID(e.target.id);
//                     }}
//                     className={element.isSelected ? "selectSubject" : ""}
//                   >
//                     {element.subjectName.toUpperCase()}
//                   </ItemSb>
//                 </Grid>
//               ))}
//             </Grid>
//           </Item>
//         </Grid>
//       </Grid>

//       <Grid
//         container
//         spacing={1}
//         sx={{ width: "100%", padding: "0 10px", boxSizing: "border-box" }}
//       >
//         <Grid item xs={8}>
//           <Item>
//             <Grid container spacing={0} sx={{ minHeight: 40 }}>
//               {standardTest.map((element, index) => (
//                 <Grid item xs={2} sx={{ cursor: "pointer" }}>
//                   <ItemE
//                     sx={{ fontWeight: "bold" }}
//                     id={element.standardTestID}
//                     onClick={(e) => {
//                       onTestSelect(e, index);
//                       setTestName(e.target.textContent);
//                       onMarkSheetLoad(e);
//                     }}
//                     className={element.isSelected ? "selectedTest" : ""}
//                   >
//                     {element.testTitleName.toUpperCase()}
//                   </ItemE>
//                 </Grid>
//               ))}
//             </Grid>
//           </Item>
//         </Grid>
//       </Grid>
//       <Box
//         sx={{
//           my: 1,
//           border: "1px solid black",
//           display: "inline",
//         }}
//       >
//         <Stack direction="row" sx={{ width: "100%" }}>
//           <Typography style={Item1} ml="5%">
//             {!StandardName ? "STANDARD" : StandardName}
//           </Typography>
//           <Typography style={Item1}>
//             {!DivisionName ? "DIVISION" : DivisionName}
//           </Typography>
//           <Typography style={Item1}>
//             {!subjectName ? "SUBJECT" : subjectName}
//           </Typography>
//           <Typography style={Item1}>{!testName ? "TEST" : testName}</Typography>

//           <Typography style={Item1}>MAX</Typography>
//           <Typography style={Item1}>MIN </Typography>
//         </Stack>
//       </Box>
//       <Box
//         sx={{
//           height: 500,
//           width: "100%",
//           padding: "0 30px",
//           boxSizing: "border-box",
//           "& .super-app-theme--header": {
//             backgroundColor: "#c0c0c0",
//             //  color:'white',
//             fontWeight: "bold",
//             fontSize: "15px",
//             border: "1px solid white",
//           },
//         }}
//       >
//         <DataGrid
//           rows={rows}
//           columns={columns}
//           pageSize={100}
//           rowsPerPageOptions={[500]}
//           disableSelectionOnClick
//           disableColumnMenu
//           experimentalFeatures={{ newEditingApi: true }}
//           sx={{ border: "1px solid black" }}
//         />
//       </Box>
//       <Grid item xs={12} sx={{ mt: 2 }}>
//         <Button
//           variant="contained"
//           color="success"
//           sx={{
//             fontSize: 15,
//             fontWeight: "bold",
//             borderRadius: "30px",
//             ml: 55,
//           }}
//         >
//           SUBMIT TO SUPERVISOR FOR APPROVAL
//         </Button>
//         <Button
//           variant="contained"
//           color="info"
//           sx={{
//             fontSize: 15,
//             fontWeight: "bold",
//             borderRadius: "30px",
//             ml: 5,
//             width: "120px",
//           }}
//           onClick={onMarksEnter}
//         >
//           UPDATE
//         </Button>
//         <Button
//           variant="contained"
//           color="error"
//           sx={{
//             fontSize: 15,
//             fontWeight: "bold",
//             borderRadius: "30px",
//             ml: 5,
//             width: "120px",
//             backgroundColor: "#ca424f",
//             "&:hover": { backgroundColor: "#ca424f" },
//           }}
//           onClick={onLaunchpad}
//         >
//           EXIT{" "}
//         </Button>
//       </Grid>
//     </React.Fragment>
//   );
// }

import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { C } from "../../util/C";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate } from "react-router-dom";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardDivision } from "../../../services/StandardDIvision";
import {
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { StandardSubjectService } from "../../../services/StandardSubject";
import { BuildSchoolExam } from "../../../services/examStandards";
import { StudentService } from "../../../services/studentService";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import jsPDF from "jspdf";
import { autoTable } from "jspdf-autotable";
import { localStorageAvailable } from "@mui/x-data-grid/utils/utils";

const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));
const ItemD = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#03dff9",
  "&:hover": {
    background: "#038da6",
    color: "white",
  },
}));
const ItemSb = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#37bc98",
  "&:hover": {
    background: "#03946d",
  },
  color: "white",
}));
const ItemE = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  // background: "rgb(255,148,121)",
  background: "#5485e594",
  "&:hover": {
    background: "#3661b494",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));
const ItemBg = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));
let Item1 = {
  // borderRight: "1px solid black",
  textAlign: "center",
  width: "200px",
  variant: "body1",
  backgroundColor: "#B5E2F4",
  fontWeight: "bold",
  // height: '40px',
  // py: 2,
};
let button = {
  fontSize: "20px",
  borderRadius: "30px ",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

const columns = [
  {
    field: "id",
    headerName: "NO.",
    flex: 1,
    headerAlign: "center",
    editable: false,
    sortable: false,
    align: "center",
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO."}</strong>,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "name",
    headerName: " NAME",
    flex: 1,
    headerAlign: "center",
    editable: false,
    sortable: false,
    align: "center",
    valueFormatter: (params) => params.value.toUpperCase(),
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"NAME"}</strong>,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "marks",
    headerName: " MARKS",
    flex: 1,
    headerAlign: "center",
    align: "center",
    type: "number",
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"MARKS"}</strong>,
    headerClassName: "super-app-theme--header",
    editable: false,
    sortable: false,
  },
  {
    field: "grade",
    headerName: " GRADE",
    flex: 1,
    editable: false,
    disabled: "true",
    headerAlign: "center",
    align: "center",
    sortable: false,
    // valueFormatter: (params) => params.value.toUpperCase(),
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"GRADE"}</strong>,
    headerClassName: "super-app-theme--header",
  },
];

export default function DataGridDemo() {
  const [Standard, setStandard] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  // const [open, setOpen] = React.useState(false);
  // const handleClose = () => setOpen(false);
  React.useEffect(() => {
    onstandardsLoad();
  }, []);

  const onstandardsLoad = async () => {

    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };
  const [StandardName, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [DivisionName, setDivisionName] = React.useState();
  const [Division, setDivision] = React.useState([]);
  const [StandardSubjectListAll, updateStandardSubject] = React.useState([]);
  const [standardTest, updateStandardTest] = React.useState([]);
  const [subjectName, setSubjectName] = React.useState();
  const [subjectID, setSubjectID] = React.useState();
  const [testName, setTestName] = React.useState();

  async function onStandardSelect(e, index) {
    e.preventDefault();
    setRows("");
    setStandardName(e.target.textContent);
    setStandardID(e.target.id);
    C.c(e.currentTarget.id);
    let highlightStandardList = [...Standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index][
      "iSSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await StandardDivision.StandardDivisionWiseStandard(
        e.currentTarget.id,
        data.yearName,
        data.yearID
      );
      C.c(response.data.data);
      setDivision(response.data.data);
    } catch (error) {
      C.c(error.message);
    }
    try {
      let response =
        await StandardSubjectService.findSubjectListByStandardIDYearID(
          data.yearName,
          e.target.id
        );
      console.log(response.data.data);
      response.data.data.sort(function (a, b) {
        return a.subjectOrder - b.subjectOrder;
      });
      updateStandardSubject(response.data.data);
    } catch (error) {
      console.log(error);
    }
    console.log(StandardSubjectListAll);

    try {
      let response = await BuildSchoolExam.showBuildSchoolStandardTest(
        e.target.id,
        data.yearName
      );
      console.log(response.data.data);

      response.data.data.sort(function (a, b) {
        return a.testOrder - b.testOrder;
      });
      updateStandardTest(response.data.data);
    } catch (error) {
      console.log(error);
    }
    console.log(StandardSubjectListAll);
  }

  async function onDivisionSelect(e, index) {
    C.c(e.target.id);
    C.c(StandardID);
    setDivisionName(e.target.id);
    let highlightDivision = [...Division];
    highlightDivision = highlightDivision.map(
      ({ isSelected, ...highlightDivision }) => highlightDivision
    );
    setDivision(highlightDivision);
    highlightDivision[index]["isSelected"] = highlightDivision[index][
      "isSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    let students = [];
    try {
      let response = await StudentService.approveddivisionstudentListAll(
        data.yearName,
        e.target.id,
        StandardID
      );
response.data.data.sort((a,b)=> a.studentName.localeCompare(b.studentName));
      response.data.data.forEach((rec, i) => {
        students.push({
          id: i + 1,
          name: rec.studentName,
          marks: "",
          grade: "",
        });
        // C(rec)
      });
      setRows(students);
      C.c(rows);
    } catch (error) {
      C.c(error.message);
    }
  }

  async function onSubjectSelect(e, index) {
    C.c(e.target.textContent);
    let highlightSubject = [...StandardSubjectListAll];
    highlightSubject = highlightSubject.map(
      ({ isSelected, ...highlightSubject }) => highlightSubject
    );
    updateStandardSubject(highlightSubject);
    highlightSubject[index]["isSelected"] = highlightSubject[index][
      "isSelected"
    ]
      ? false
      : true;
  }

  async function onTestSelect(e, index, et) {
    C.c(e.target.textContent);
    let highlightTest = [...standardTest];
    highlightTest = highlightTest.map(
      ({ isSelected, ...highlightTest }) => highlightTest
    );
    updateStandardTest(highlightTest);
    highlightTest[index]["isSelected"] = highlightTest[index]["isSelected"]
      ? false
      : true;
    ontestValues(et);
  }

  let [testvalue, setTestValue] = React.useState([]);
  let [maxvalue, setMaxValue] = React.useState([]);
  let [minvalue, setMinValue] = React.useState([]);

  async function ontestValues(testName) {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    // console.log(selectedStandardID);
    // console.log(selectedSubjectID);
    console.log(testName);
    try {
      let response = await BuildSchoolExam.getStandardSubjectTestValue(
        StandardID,
        subjectID,
        testName,
        data.yearName
      );
      console.log(response.data.data);
      setTestValue(response.data.data);
      if (response.data.data) {
        const MaxFound = response.data.data.find(
          (element) => element.standardTestkey == "MAX"
        );
        setMaxValue(MaxFound.standardTestvalue);
        const MinFound = response.data.data.find(
          (element) => element.standardTestkey == "MIN"
        );
        setMinValue(MinFound.standardTestvalue);
      }
    } catch (error) {
      console.log("error: " + error);
    }
  }

  function onMarksEntry(v, e) {
    // let maxMark = testvalue.shift();
    // let minMark = testvalue.shift();
    // console.log(v);
    // console.log(maxMark);
    // console.log(minMark);
    // console.log(testvalue);
    // console.log(rows);
    let re = false;
    let r = [];
    testvalue.forEach((r) => {
      if (r.standardTestkey != "MAX") {
        if (r.standardTestkey != "MIN") {
          if (re == false) {
            // debugger;
            if (r.standardTestvalue >= v.value) {
              // console.log(r);
              let recdata = rows.find((element) => element.id == v.id);
              console.log(r.standardTestvalue);
              console.log(v.value);
              console.log(recdata);
              recdata.marks = v.value;
              recdata.grade = r.standardTestkey;
              re = true;
              // console.log(recdata);
            }
          } else {
          }
        }
      }
    });
    setTimeout(() => {
      setRows(rows.map((row) => ({ ...row, rows })));
    }, 1000);

    console.log(rows);
  }
const academic = JSON.parse(localStorage.getItem("academicYear"));
const school = JSON.parse(localStorage.getItem("schoolDetails"));

  const generatePDF = () => {
   
    const doc = new jsPDF("p","pt","a4");
  doc.setFontSize(10);
  doc.text(`${academic.yearName}`, 500,35);

  const text = `${school.schoolNameOnDocs.toUpperCase()}`;
  const fontSize = 18;
  const pageWidth = doc.internal.pageSize.getWidth();
  doc.setFontSize(fontSize);
  doc.setFont("Helvetica", "bold");
  doc.text(text, pageWidth / 2, 35, { align: 'center' });
  
  const img = `${school.schoolLogoUrl}`;
  doc.addImage(img, 'JPEG', 20, 10, 80, 70);

const tableData = rows.map((row)=> [
  row.id,
  row.name
]);

const std = StandardName;
const div = DivisionName;
const sub = subjectName;
const tes = testName;
const min = minvalue.toString();
const max = maxvalue.toString(); 
doc.setFontSize(13);
doc.setFont("Times-Roman", "bold");
doc.text(std, 105,105);
doc.text(div, 220,105);
doc.text(tes, 300,105);
doc.text(sub, 450,105);
doc.text("MAX : "+max, 120,135);
doc.text("MIN : " +min, 400,135);
doc.autoTable({
  startY: 150,
  tableLineColor: [0,0,0],
  head : [
    [
      "NO.",
    "NAME",
    "MARKS",
    "GRADE"
    ],
  ],
  body : tableData,
  headStyles: {
    valign: "middle",
    halign: "center",
    // fillColor: [250, 152, 95],
    
  },
  bodyStyles: {
    valign: "middle",
    halign: "center",
    lineColor: [0,0,0],
   
  },
  tableLineWidth: 0.95,
  theme: "grid",
});
doc.setFontSize(10);
    // doc.text("Copyright: Technotharanga Solutions Pvt.ltd", 190, 800);
    doc.save("Marks Entry Form -  "+ StandardName+" - " + DivisionName+" - " + testName+" - " + subjectName + " .pdf")
  }

  let navigate = useNavigate();
  const onLaunchPad = () => {
    console.log("launchpad");
    navigate("/launchpad", { replace: true });
  };

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="dataentry" /> */}
      <Typography
        variant="h6"
        sx={{
          backgroundColor: "lightblue",
          width: "100&",
          boxSizing: "border-box",
          margin: "0 10px",
          mt: 2,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        SELECT STANDARD, DIVISION, EXAM AND SUBJECT - PRINT OR DOWNLOAD BLANK
        FORM
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          mt: 1,
          width: "100%",
          padding: "0 10px",
          boxSizing: "border-box",
        }}
      >
        <Grid container spacing={1}>
          <Grid item md={8} sm={8} xs={8}>
            <ItemBg>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Standard.map((element, index) => (
                  <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      value={element.standardName}
                      onClick={(e) => {
                        onStandardSelect(e, index);
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </ItemBg>
          </Grid>
          <Grid item md={4} sm={4} xs={4}>
            <ItemBg>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Division.map((element, index) => (
                  <Grid item md={1.5} xs={4} sx={{ cursor: "pointer" }}>
                    <ItemD
                      sx={{
                        fontWeight: "bold",
                      }}
                      onClick={(e) => {
                        onDivisionSelect(e, index);
                      }}
                      id={element.divisionName}
                      className={element.isSelected ? "selected" : ""}
                    >
                      {" "}
                      {element.divisionName}
                    </ItemD>
                  </Grid>
                ))}
              </Grid>
            </ItemBg>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        spacing={1}
        sx={{ width: "100%", mt: "10px", padding: "0 10px", boxSizing: "border-box" }}
      >
        <Grid item xs={8}>
          <ItemBg>
            <Grid container spacing={1} sx={{ minHeight: 40 }}>
              {StandardSubjectListAll.map((element, index) => (
                <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                  <ItemSb
                    sx={{ fontWeight: "bold" }}
                    id={element.standardSubjectID}
                    value={element.subjectName}
                    onClick={(e) => {
                      onSubjectSelect(e, index);
                      setSubjectName(e.target.textContent);
                      setSubjectID(e.target.id);
                    }}
                    className={element.isSelected ? "selectSubject" : ""}
                  >
                    {element.subjectName.toUpperCase()}
                  </ItemSb>
                </Grid>
              ))}
            </Grid>
          </ItemBg>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{ width: "100%", mt: "10px", padding: "0 10px", boxSizing: "border-box" }}
      >
        <Grid item xs={8}>
          <ItemBg>
            <Grid container spacing={1} sx={{ minHeight: 40 }}>
              {standardTest.map((element, index) => (
                <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                  <ItemE
                    sx={{ fontWeight: "bold" }}
                    onClick={(e) => {
                      onTestSelect(e, index, element.testTitleName);
                      setTestName(e.target.textContent);
                    }}
                    className={element.isSelected ? "selectedTest" : ""}
                  >
                    {element.testTitleName.toUpperCase()}
                  </ItemE>
                </Grid>
              ))}
            </Grid>
          </ItemBg>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "75%",
          backgroundColor: "lightblue",
          marginLeft: "10%",
          my: 1,
          border: "1px solid black",
          // borderRight: "none",
          // borderLeft: "none",
          padding:"2px"

        }}
      >
        <Stack direction="row">
          <Typography style={Item1} sx={{borderRight:"1px solid black"}}>
            {!StandardName ? "STANDARD" : StandardName}
          </Typography>
          <Typography style={Item1} sx={{borderRight:"1px solid black"}}>
            {!DivisionName ? "DIVISION" : DivisionName}
          </Typography>
          <Typography style={Item1} sx={{borderRight:"1px solid black"}}>
            {!subjectName ? "SUBJECT" : subjectName}
          </Typography>
          <Typography style={Item1} sx={{borderRight:"1px solid black"}}>{!testName ? "TEST" : testName}</Typography>

          <Typography style={Item1} sx={{borderRight:"1px solid black"}}>MAX : {maxvalue}</Typography>
          <Typography style={Item1}>
            MIN : {minvalue}
          </Typography>
        </Stack>
      </Box>

      <Box
        sx={{
          height: 500,
          width: "100%",
          padding: "0 30px",
          boxSizing: "border-box",
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
            //  color:'white',
            fontWeight: "bold",
            fontSize: "15px",
            border: "1px solid white",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          onCellEditCommit={onMarksEntry}
          // disableSelectionOnClick
          hideFooter
          disableColumnMenu
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-evenly", mt:2,paddingBottom:"25px" }}>
        <Button  variant="contained"
          color="error"
          sx={{
            color: "white",
            borderRadius: "30px",
            fontSize: 15,
            width: 120,
            fontWeight: "bold",
          }}
          onClick={generatePDF}
        
        >
          pdf
        </Button>
        <Button
              onClick={onLaunchPad}
              variant="contained"
              sx={{
                backgroundColor: "#ca424f",
                fontSize: 15,
                width: "120px",
                borderRadius: "30px",
                "&:hover": { backgroundColor: "#ca424f" },
              }}
            >
              exit
            </Button>
        {/* <Grid container spacing={0}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                fontSize: 15,
                borderRadius: "30px",
                fontWeight: "bold",
                ml: 65,
              }}
            >
              Freeze and Review
            </Button>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography
                    id="transition-modal-title"
                    variant="body1"
                    component="h2"
                  >
                    You have clicked on
                  </Typography>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    FREEZ INFORMATION
                  </Typography>
                  <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    We request you to kindly review the information once again
                    and then freeze the data, as once its FREEZE can’t be
                    changed. Thank you
                  </Typography>
                  <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                    >
                      FREEZE
                    </Button>
                    <Button variant="contained" size="small" color="error">
                      Review
                    </Button>
                  </Stack>
                </Box>
              </Fade>
            </Modal>
            <Button
              variant="contained"
              color="warning"
              sx={{
                fontSize: 15,
                fontWeight: "bold",
                borderRadius: "30px",
                ml: 3,
              }}
            >
              Pause and Continue Setup Later
            </Button>
          </Grid>
        </Grid> */}
      </Box>
    </React.Fragment>
  );
}
