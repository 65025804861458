import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class LaunchPadSetting {
  // static serverURL = "http://143.110.188.253:1919/settings/";
  static service = "settings/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createExamTiming(data) {
    var dataURL = this.serverURL + "createExamTiminges";
    return axios.post(dataURL, data);
  }
}
