import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useState } from "react";
import { HolidayService } from "../../../services/holidaysService";
import DeleteIcon from "@mui/icons-material/Delete";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid green",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

const AddEvent = () => {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [value, setValue] = useState(new Date());
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    onListEvent();
  }, []);

  async function onListEvent() {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await HolidayService.Holidaylist(data.yearName);
      console.log(response.data.data);
      setHolidays(response.data.data);
    } catch (error) {
      alert(error.message);
    }
  }

  async function onAddEvent(e) {
    console.log(name, type, description, value);
    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    let data = {
      date: value.toString(),
      dateTime: value.getDate(),
      holidayDesc: description,
      holidayType: type,
      monthName: value.getMonth(),
      yearID: yearData.yearID,
      yearName: yearData.yearName,
    };

    console.log(data);
    try {
      let response = await HolidayService.createHoliday([data]);
      // alert("Saved Successfully");
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 2000);
      setType("");
      setDescription("");
      setValue(new Date());
      onListEvent();
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }

  async function onDelete(id) {
    console.log(id);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await HolidayService.deleteHoliday(id, data.yearName);
      // alert("Deleted Successfully");
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
      onListEvent();
    } catch (error) {
      console.log(error);
    }
  }
  //     "divisionID": "string",
  //     "divisionName": "string",
  //     "holidaymasterID": "string",
  //     "standardID": "string",
  //     "standardName": "string",}
  let navigate = useNavigate();
  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };
  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        sx={{
          backgroundColor: "lightblue",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            width: "100&",
            boxSizing: "border-box",
            margin: "0 10px",
            // mt: 2,
            // paddingBottom:2,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          ENTER THE EVENT
        </Typography>
      </Box>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
          display: "flex",
          justifyContent: "center",
          height: "100px",
          alignItems: "center",
        }}
        noValidate
        autoComplete="off"
      >
        {/* <TextField
          id="name"
          label="Event Name"
          variant="filled"
          value={name}
          onChange={(e) => setName(e.target.value)}
        /> */}
        {/* <TextField
          id="type"
          label="Event Type"
          variant="filled"
          value={type}
          onChange={(e) => setType(e.target.value)}
        /> */}
        <FormControl fullWidth>
          <InputLabel id="typeID">Event Type</InputLabel>
          <Select
            labelId="typeID"
            id="type"
            value={type}
            label="Event Type"
            variant="filled"
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <MenuItem value="Holiday">Holiday</MenuItem>
            <MenuItem value="Festival">Festival</MenuItem>
            <MenuItem value="Event">Event</MenuItem>
            <MenuItem value="Birthday">Birthday</MenuItem>
            <MenuItem value="Exam">Exam</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="decription"
          label="Event Description"
          variant="filled"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Controlled picker"
            value={value}
            onChange={(newValue) => setValue(newValue)}
          />
        </LocalizationProvider>
      </Box>
      <Box display={"flex"} justifyContent={"center"} m={2}>
        <Button sx={{ m: 2 }} variant="contained" onClick={onAddEvent}>
          Save Event
        </Button>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        sx={{
          backgroundColor: "lightblue",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            width: "100&",
            boxSizing: "border-box",
            margin: "0 10px",
            // mt: 2,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          EVENT LIST
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // height: "100px",
          alignItems: "center",
          p: 5,
        }}
      >
        <Grid container spacing={2}>
          {holidays.map((r) => {
            let dataString = new Date(r.date);

            return (
              <Grid key={r.holidaymasterID} item md={3} xs={6}>
                <Card sx={{ minWidth: "100%", minHeight:200 }}>
                  <CardContent>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography variant="h5" component="div">
                        {r.holidayDesc}
                      </Typography>
                      <CardActions
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          onClick={() => onDelete(r.holidaymasterID)}
                          size="small"
                        >
                          <DeleteIcon color="error" />
                        </Button>
                      </CardActions>
                    </Box>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      {r.holidayType}
                      <br />
                      {dataString.toDateString()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Modal
                open={open}
                // onClose={() => setSucess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div sx={style}>
                  <SucessModal
                    text="Event Created Successfully "
                    name="Event Created Successfully "
                  />
                </div>
              </Modal>

              <Modal
                open={success}
                // onClose={() => setSucess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div sx={style}>
                  <SucessModal
                    text="Event Deleted Successfully "
                    name="Event Deleted Successfully "
                  />
                </div>
              </Modal>

      <Box display={"flex"} justifyContent={"center"}>
        <Button
          variant="contained"
          color="error"
          sx={{
            fontSize: 15,
            fontWeight: "bold",
            borderRadius: "30px",
            // ml: 5,
            width: "120px",
            backgroundColor: "#ca424f",
            "&:hover": { backgroundColor: "#ca424f" },
          }}
          onClick={onLaunchpad}
        >
          EXIT{" "}
        </Button>
      </Box>
    </Box>

  );
};
export default AddEvent;
