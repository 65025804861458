export class AppRootUrl {
  // ===========================server========================
  // production server
  // static serverURL = "http://103.38.50.85:1920/";
  static serverURL = "http://64.227.128.154:1919/";


  // ========================localhost========================
  // static serverURL = "http://localhost:1919/";
  // static serverURL = "http://192.168.1.114:1919/";

  static Rooturl() {
    const rootUrl = this.serverURL;
    return rootUrl;
  }
}
