import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class StandardDivision {
  // static serverURL = "http://143.110.188.253:1919/standarddivisionmaster/";
  static service = "standarddivisionmaster/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static StandardDivisionWiseStandard(standardID, yearName, yearID) {
    var dataURL =
      this.serverURL +
      "standrd/division/list?standardID=" +
      standardID +
      "&yearID=" +
      yearID +
      "&yearName=" +
      yearName;

    return axios.get(dataURL);
  }
  static StandardDivision(yearName, yearID) {
    var dataURL =
      this.serverURL +
      "year/division/list?yearID=" +
      yearID +
      "&yearName=" +
      yearName;

    return axios.get(dataURL);
  }

  //localhost:1919/standarddivisionmaster/year/division/list?yearID=e8443bde-6a78-4f43-81dc-4e415bd9a45b&yearName=2023-2024
}
