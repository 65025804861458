import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center"
  // color: theme.palette.text.secondary
}));

const columns = [
    { field: 'id', headerName: 'No.', width: 90 },
    {
        field:'div',
        headerName:'DIV',
        width:100,
        editable: true,        
    },
    
    
    
    {
      field: 'secA',
      headerName: 'A',
      width: 150,
      editable: true,
    },
    
    {
      field: 'secB',
      headerName: 'B',

      width: 150,
      editable: true,
    },
  
    {
      field: 'secC',
      headerName: 'C',
      width: 150,
     },
     
     {
      field: 'secD',
      headerName:'D',
    width: 150
    },
  
    {
      field: 'total',
      headerName: 'Total',
      width: 220,
      editable: true,
    },
    
    
    
  ];
  
  const rows = [
    { id: 1,div:'STD/FEES',secA:'Expected|Recieved',secB:'Expected|Recieved',secC:'Expected|Recieved',secD:'Expected|Recieved',total:'Expected|Recieved|Difference'},
    {id: 2,div:'PRE KG',secA:'000|000',secB:'000|000',secC:'000|000',secD:'000|000',total:'000|000|000'},
    {id: 3,div:'Total',secA:'000|000',secB:'000|000',secC:'000|000',secD:'000|000',total:'000|000|000'},
   
  
  ];

export default function BasicGrid() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
      <Box sx={{ mt: 5, ml: 50 }}>
       <Typography>
       FEES COLLECTION SUMMARY FOR THE YEAR 22-23
       </Typography>
       </Box>
        <Box sx={{ height: 450, width: '88%' ,mt:5 ,ml:10 }}>
      <DataGrid
        showColumnRightBorder={true}
        showCellRightBorder={true}
        rows={rows}
        columns={columns}
        //  pageSize={7}
         rowsPerPageOptions={[10]}
        // checkboxSelection
        // disableSelectionOnClick
        // experimentalFeatures={{ newEditingApi: true }}
sx={{showCellRightBorder:'2px solid black'}}
        
        />
      
    </Box>
        
      </Grid>
      <Grid>
      <Button sx={{ border: "1px solid black",ml:120,mt:8 }}>
      PDF
      </Button>
      <Button sx={{ border: "1px solid black", mt:8}}>
      Excel
      </Button>
      <Button sx={{ border: "1px solid black", mt:8}}>
      Print
      </Button>
      <Button sx={{ border: "1px solid black", mt:8}}>
      Exit
      </Button>


      </Grid>
      
    </Box>
  );
}
