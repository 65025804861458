import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { yearService } from "../../../services/yearService";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { SchoolMaster } from "../../../services/SchoolMaster";
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";
import { ImageConverter } from "../../../programs/imgaeToBase";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: "15px",
  textAlign: "center",
  border: "3px solid green",
  // height: 1060,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Year = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  border: "1px solid ",
}));

export default function BasicTabs() {
  const Navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [schoolName, setschoolName] = React.useState("");
  const [schoolNameDisplay, setschoolNameDisplay] = React.useState(" ");
  const [trustName, settrustName] = React.useState("");
  const [nameErrorText, setNameErrorText] = React.useState("");
  const [authPerson1, setauthPerson1] = React.useState("");
  const [authPerson2, setauthPerson2] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [phoneNoOne, setphoneNoOne] = React.useState("");
  const [phoneNoTwo, setphoneNoTwo] = React.useState("");
  const [phoneNoErrorText, setPhoneNoErrorText] = React.useState("");
  const [imageUrl, setImageUrl] = React.useState("");

  const [yearList, setYearList] = React.useState([]);

  React.useEffect(() => {
    onYearLoad();
  }, []);

  const [year, setYear] = React.useState("");
  const [yearID, setYearID] = React.useState("");

  const handleChange = (event) => {
    // debugger;
    console.log(event.target.value);
    setYear(event.target.value);
  };

  const onYearLoad = async () => {
    try {
      let response = await yearService.yearListAll(0);
      setYearList(response.data.data);
      response.data.data.length == 0 ? setValue(1) : setValue(0);
    } catch (error) {
      console.log(error);
    }
  };

  const refForm = React.useRef();
  const onAcademicYear = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    // console.log(data.get("year"));

    let yearFind = yearList.find((rec) => rec.yearID === year);
    // console.log(yearFind.yearName);
    console.log(refForm.current.buttonId);

    try {
      let response = await yearService.getYear(data.get("year"));
      // console.log(response.data.data);
      localStorage.setItem("academicYear", JSON.stringify(response.data.data));
      let schoolArray;

      try {
        let schoolresp = await SchoolMaster.schoolList();
        schoolArray = schoolresp.data.data;

        const found = schoolArray.find(
          (element) => element.currentAcademicYearStatus == true
        );

        localStorage.setItem("schoolDetails", JSON.stringify(found));
      } catch (error) {
        alert(error.message);
        console.log(error);
      }

      if (refForm.current.buttonId == "buildSchool") {
        // onBuildSchool(data.get("year"), yearFind.yearName);
        handleOpen();
        setTimeout(() => {
          Navigate("/dashborad", { replace: true });
          handleClose();
        }, 2000);
      } else if (refForm.current.buttonId == "launchpad") {
        handleOpen();
        setTimeout(() => {
          Navigate("/launchpad", { replace: true });
          handleClose();
        }, 2000);
      } else {
        handleOpen();
        setTimeout(() => {
          alert("something went wrong, please logout and try again");
          handleClose();
        }, 2000);
        // let reply = confirm("something went wrong, please logout and try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await ImageConverter.convertToBase64(file);
    // setPostImage({ ...postImage, myFile: base64 });
    console.log(base64);
    setImageUrl(base64);
  };
  // const onAcademicYearSelect = async (e) => {
  //   // e.preventDefault();
  //   // const data = new FormData(e.currentTarget);
  //   // console.log(data.get("year"));

  //   let yearFind = yearList.find((rec) => rec.yearID === year);
  //   // console.log(yearFind.yearName);
  //   console.log(refForm.current.buttonId);
  //   console.log(e.target.textContent);

  //   try {
  //     let response = await yearService.getYear(e.target.textContent);
  //     // console.log(response.data.data);
  //     localStorage.setItem("academicYear", JSON.stringify(response.data.data));
  //     let schoolArray;

  //     try {
  //       let schoolresp = await SchoolMaster.schoolList();
  //       schoolArray = schoolresp.data.data;

  //       const found = schoolArray.find(
  //         (element) => element.currentAcademicYearStatus == true
  //       );

  //       localStorage.setItem("schoolDetails", JSON.stringify(found));
  //     } catch (error) {
  //       alert(error.message);
  //       console.log(error);
  //     }

  //     if (refForm.current.buttonId == "buildSchool") {
  //       // onBuildSchool(data.get("year"), yearFind.yearName);
  //       Navigate("/dashborad", { replace: true });
  //     } else if (refForm.current.buttonId == "launchpad") {
  //       Navigate("/launchpad", { replace: true });
  //     } else {
  //       alert("something went wrong, please logout and try again");
  //       // let reply = confirm("something went wrong, please logout and try again");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const onBuildSchool = async (yearID, yearName) => {
    // console.log("onBuildSchool");

    let standard = [
      "standard",
      "subject",
      "designation",
      "timetable",
      "feestructure",
      "exam",
      "buildschoolcomplete",
    ];

    let step = ["one", "two", "three", "four", "five", "six", "seven"];

    for (let i = 0; i <= 6; i++) {
      console.log("onBuildSchool" + i);
      if (i == 0) {
        console.log("if");

        try {
          let response = await BuildSchoolControll.createBuildSchoolStatus({
            activeStatus: "active",
            buildSchoolName: standard[i],
            buildSchoolStatus: "false",
            buildSchoolStep: step[i],
            buildSchoolYear: yearName,
            yearID: yearID,
            yearName: yearName,
          });
        } catch (error) {
          alert("Error creating buildSchool " + error);
        }
      } else {
        console.log("else");

        try {
          let response = await BuildSchoolControll.createBuildSchoolStatus({
            activeStatus: "inactive",
            buildSchoolName: standard[i],
            buildSchoolStatus: "false",
            buildSchoolStep: step[i],
            buildSchoolYear: yearName,
            yearID: yearID,
            yearName: yearName,
          });
        } catch (error) {
          alert("Error creating buildSchool " + error);
        }
      }
    }

    // Navigate("/dashborad", { replace: true });
  };
  const textInput = React.useRef("");
  const onlyCharaters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^zA-Z ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const onlyNumbers = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9-]+/gi, "")
      : "";
    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const phoneNumber = (e, callback) => {
    const value = e.target.value ? e.target.value.replace(/[^0-9]+/gi, "") : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const onRegister = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    var schoolData = {
      schoolName: data.get("schoolName"),
      schoolNameOnDocs: data.get("schoolNameDisplay"),
      schoolTrustName: data.get("trustName"),
      schoolAddress: data.get("address"),
      schoolOfficialEmailID: data.get("email"),
      schoolOfficialMobileOne: data.get("number"),
      schoolOfficialMobileTwo: data.get("number"),
      schoolAuthorizedPersonOneName: data.get("authPerson1"),
      schoolAuthorizedPersonTwoName: data.get("authPerson2"),
      schoolAuthorizedPersonOneEmail: data.get("authPersonEmail1"),
      schoolAuthorizedPersonTwoEmail: data.get("authPersonEmail2"),
      schoolAuthorizedPersonMobile: data.get("number"),
      schoolAuthorizedPersonTwoMobile: data.get("number"),
      schoolLogoUrl: imageUrl,
      yearName: data.get("academicYear"),
      currentAcademicYearStatus:
        data.get("currentYear") == "true" ? true : false,
    };

    console.log(schoolData);

    if (data.get("academicYear")) {
      try {
        let response = await SchoolMaster.createSchool(schoolData);
        console.log(response.data.data);
        localStorage.setItem(
          "academicYear",
          JSON.stringify({
            yearName: response.data.data.yearName,
            yearID: response.data.data.yearID,
          })
        );
        Navigate("/welcome");
      } catch (error) {
        console.log(error.message);
      }
    }
    localStorage.setItem("schoolDetails", JSON.stringify(schoolData));
    textInput.current.value = "";
    if (!schoolName) {
      setNameErrorText("Please enter name");
      return;
    } else {
      setNameErrorText("");

      if (schoolName.length < 3) {
        setNameErrorText("Username should be of minimum three characters");
        return;
      } else {
        setNameErrorText("");
      }
    }
    if (!schoolNameDisplay) {
      setNameErrorText("Please enter name");
      return;
    } else {
      setNameErrorText("");

      if (schoolNameDisplay.length < 3) {
        setNameErrorText("Username should be of minimum three characters");
        return;
      } else {
        setNameErrorText("");
      }
    }
    if (!trustName) {
      setNameErrorText("Please enter name");
      return;
    } else {
      setNameErrorText("");

      if (trustName.length < 3) {
        setNameErrorText("Username should be of minimum three characters");
        return;
      } else {
        setNameErrorText("");
      }
    }
    if (!authPerson1) {
      setNameErrorText("Please enter name");
      return;
    } else {
      setNameErrorText("");

      if (authPerson1.length < 3) {
        setNameErrorText("Username should be of minimum three characters");
        return;
      } else {
        setNameErrorText("");
      }
    }
    if (!authPerson2) {
      setNameErrorText("Please enter name");
      return;
    } else {
      setNameErrorText("");

      if (authPerson2.length < 3) {
        setNameErrorText("Username should be of minimum three characters");
        return;
      } else {
        setNameErrorText("");
      }
    }
    //VALIDATE PHONE NO
    if (!phoneNo && phoneNo.length !== 10) {
      setPhoneNoErrorText("Please enter phone number");
    } else {
      setPhoneNoErrorText("");
    }
    if (!phoneNoOne && phoneNoOne.length !== 10) {
      setPhoneNoErrorText("Please enter phone number");
    } else {
      setPhoneNoErrorText("");
    }
    if (!phoneNoTwo && phoneNoTwo.length !== 10 && phoneNo == "Number") {
      setPhoneNoErrorText("Please enter phone number");
    } else {
      setPhoneNoErrorText("");
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          centered
          value={value}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab
            label="Enter Academic Year"
            {...a11yProps(0)}
            disabled={yearList.length == 0 ? true : false}
          />
          <Tab label="Create Academic Year" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box component="form" onSubmit={onAcademicYear} ref={refForm}>
          {/* <Grid container spacing={2}>
            {yearList.map((year, i) => (
              <Grid item xs={2} onClick={onAcademicYearSelect} id={year.id}>
                <Year>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                    variant="subtitle1"
                  >
                    {year.yearName}
                  </Typography>
                </Year>
              </Grid>
            ))}

            <Grid item xs={2}>
              <Year>
                <Typography variant="subtitle1">xs=8</Typography>
              </Year>
            </Grid>
          </Grid> */}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Year</InputLabel>
            <Select
              labelId="year-label"
              id="year"
              value={year}
              name="year"
              label="Age"
              onChange={handleChange}
            >
              {yearList.map((year, i) => (
                <MenuItem key={i} id={year.yearID} value={year.yearID}>
                  {year.yearName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            id="buildSchool"
            variant="contained"
            sx={{ margin: 2 }}
            disabled={year ? false : true}
            type="submit"
            onClick={(e) => (refForm.current.buttonId = e.target.id)}
          >
            Enter buildSchool
          </Button>
          <Button
            id="launchpad"
            variant="contained"
            sx={{ margin: 2 }}
            disabled={year ? false : true}
            type="submit"
            onClick={(e) => (refForm.current.buttonId = e.target.id)}
          >
            Enter Launchpad
          </Button>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {/* <Grid item xs={10}> */}
              <Item component="form" onSubmit={onRegister}>
                <Box
                  border="1px solid blue"
                  backgroundColor="lightblue"
                  borderRadius="10px"
                >
                  <Typography
                    variant="h4"
                    fontWeight="400"
                    margin="20px"
                    color="blue"
                  >
                    School Software Registration
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: 5,
                    mt: 3,
                  }}
                >
                  <Avatar
                    alt="A"
                    src={imageUrl}
                    sx={{
                      width: 150,
                      height: 160,
                      // margin: 1,
                      // mt: 0.5,
                      // ml: 1,
                    }}
                    variant="square"
                  />
                  <input
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    accept="image/*"
                    name="studentImg"
                    onChange={handleFileUpload}
                  />
                  {/* ----------------------------------STAFF IMAGE--------------------------- */}
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      sx={{
                        marginTop: 2,
                        borderRadius: 9,
                        fontWeight: 700,
                      }}
                    >
                      UPLOAD LOGO
                    </Button>
                  </label>
                </Box>
                <Box>
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    // id="filled-basic"
                    value={schoolName}
                    label="Name of the School "
                    variant="filled"
                    name="schoolName"
                    inputRef={textInput}
                    onInput={(e) => onlyCharaters(e)}
                    error={!!nameErrorText}
                    helperText={nameErrorText}
                    onChange={(e) => setschoolName(e.target.value)}
                    type="string"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    required
                  />
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    // id="filled-basic"
                    label="Name of the School displayed / printed on all documents"
                    variant="filled"
                    name="schoolNameDisplay"
                    inputRef={textInput}
                    onInput={(e) => onlyCharaters(e)}
                    error={!!nameErrorText}
                    helperText={nameErrorText}
                    onChange={(e) => setschoolNameDisplay(e.target.value)}
                    type="string"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    required
                  />
                </Box>
                <Box>
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    // id="filled-basic"
                    label="Name of the Trust under which school is running "
                    variant="filled"
                    name="trustName"
                    inputRef={textInput}
                    onInput={(e) => onlyCharaters(e)}
                    error={!!nameErrorText}
                    helperText={nameErrorText}
                    onChange={(e) => settrustName(e.target.value)}
                    type="string"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    required
                  />
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    // id="filled-basic"
                    label="Address of the School"
                    variant="filled"
                    name="address"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    required
                  />
                </Box>
                <Box>
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    // id="filled-basic"
                    label="School’s official E-Mail address"
                    variant="filled"
                    name="email"
                    type="email"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    required
                  />
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    // id="filled-basic"
                    label="School’s official phone number 1 / number 2"
                    variant="filled"
                    name="number"
                    // type="number"
                    maxLength="8"
                    value={phoneNo}
                    error={!!phoneNoErrorText}
                    helperText={phoneNoErrorText}
                    onInput={(e) => phoneNumber(e)}
                    inputProps={{ maxLength: 11 }}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    required
                  />
                </Box>
                <Box>
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    // id="filled-basic"
                    label="Name of the Authorised person 1"
                    variant="filled"
                    name="authPerson1"
                    inputRef={textInput}
                    onInput={(e) => onlyCharaters(e)}
                    error={!!nameErrorText}
                    helperText={nameErrorText}
                    onChange={(e) => setauthPerson1(e.target.value)}
                    type="string"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    required
                  />
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    // id="filled-basic"
                    label="Name of the Authorised person 2"
                    variant="filled"
                    name="authPerson2"
                    inputRef={textInput}
                    onInput={(e) => onlyCharaters(e)}
                    error={!!nameErrorText}
                    helperText={nameErrorText}
                    onChange={(e) => setauthPerson2(e.target.value)}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    type="string"
                  />
                </Box>
                <Box>
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    // id="filled-basic"
                    label="E-Mail address of the Authorised person 1 "
                    variant="filled"
                    name="authPersonEmail1"
                    type="email"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    required
                  />
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    // id="filled-basic"
                    label="E-Mail address of the Authorised person 2"
                    variant="filled"
                    name="authPersonEmail2"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    type="email"
                  />
                </Box>
                <Box>
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    // id="filled-basic"
                    label="Phone number of the Authorised person 1 "
                    variant="filled"
                    name="authPersonEmail1"
                    // type="number"
                    maxLength="10"
                    value={phoneNoOne}
                    error={!!phoneNoErrorText}
                    helperText={phoneNoErrorText}
                    onInput={(e) => phoneNumber(e)}
                    inputProps={{ maxLength: 11 }}
                    onChange={(e) => setphoneNoOne(e.target.value)}
                    required
                  />
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    // id="filled-basic"
                    label="Phone Number of the Authorised person 2"
                    variant="filled"
                    name="authPersonEmail2"
                    // type="number"
                    maxLength="10"
                    value={phoneNoTwo}
                    error={!!phoneNoErrorText}
                    helperText={phoneNoErrorText}
                    onInput={(e) => phoneNumber(e)}
                    inputProps={{ maxLength: 11 }}
                    onChange={(e) => setphoneNoTwo(e.target.value)}
                  />
                </Box>
                <Box>
                  <TextField
                    sx={{ m: 1, width: 500 }}
                    // id="filled-basic"
                    name="academicYear"
                    label="Academic Year "
                    variant="filled"
                    onInput={(e) => onlyNumbers(e)}
                    required
                  />
                  {/* <Input */}
                  {/* <input
                    accept="image/*"
                    // className={classes.input}
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      variant="raised"
                      component="span"
                      // className={classes.button}
                    >
                      Upload
                    </Button>
                  </label> */}
                  {/* <TextField
                    sx={{ margin: 1, width: 500 }}
                    // id="filled-basic"
                    label="Upload School LOGO"
                    name="schoolLogo"
                    variant="filled"
                    type="file"
                    focused
                    required
                  ></TextField> */}
                </Box>
                <Box>
                  <FormControlLabel
                    value="true"
                    control={<Switch color="primary" />}
                    label="Current Year"
                    name="currentYear"
                    labelPlacement="start"
                  />
                </Box>
                <Box margin="20px">
                  <Button
                    size="large"
                    variant="contained"
                    padding="0px 40px"
                    type="submit"
                  >
                    Register
                  </Button>
                </Box>
                <Box margin="20px">
                  <Typography marginX="100px" variant="h6" fontWeight="400">
                    PLEASE VERIFY THE INFORMATION PROVIDED FOR REGISTRATION OF
                    THE SCHOOL THE ABOVE INFORMATION CANNOT BE EDITED FOR THE
                    ACADEMIC YEAR PROVIDED HERE
                  </Typography>
                </Box>
                <Box margin="20px">
                  <Typography
                    marginX="100px"
                    variant="subtitle1"
                    fontWeight="400"
                  >
                    By clicking REGISTER you agree to the{" "}
                    <a href="#">Terms and Conditions </a> {" "}
                     of the use of the online school management software
                  </Typography>
                </Box>
              </Item>
            {/* </Grid> */}
          </Grid>
        </Box>
      </TabPanel>
      {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
