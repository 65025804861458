import {
  Box,
  Button,
  FormControl,
  Grid,
  Modal,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DesignationService } from "../../../services/DesignationService";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { StaffService } from "../../../services/StaffService";
import { parseISO } from "date-fns";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid green",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

const StaffDetails = (props) => {
  const [doj, setDoj] = useState("");
  const [dob, setDob] = useState("");

  useEffect(() => {
    onDesignationload();

    const dobdateString = props.data.staffDOB;
    const dojdateString = props.data.stafDOJ;
    console.log(dobdateString);
    console.log(dojdateString);

    if (dobdateString) {
      const [day, month, year] = dobdateString.split("/");
      const bdate = new Date(year, month - 1, day);
      setDob(bdate);
      console.log(bdate);
    }

    if (dojdateString) {
      const [day, month, year] = dojdateString.split("/");
      const jdate = new Date(year, month - 1, day);
      setDoj(jdate);
      console.log(jdate);
    }

    if (props.data.staffMobileNo.length === 1) {
      setPhoneNo1(props.data.staffMobileNo[0]);
    } else if (props.data.staffMobileNo.length === 2) {
      setPhoneNo1(props.data.staffMobileNo[0]);
      setPhoneNo2(props.data.staffMobileNo[1]);
    }
  }, []);
  const [bankDetails, getBankDetails] = useState([]);
  const [designationVal, setDesignationVal] = useState([]);
  const onDesignationload = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await DesignationService.designationListAll(data.yearName);
      console.log(response.data.data);
      setDesignationVal(response.data.data);
    } catch (error) {
      console.log(error);
    }

    try{
      let resp = await StaffService.getStaffBankDetails(props.data.ssid, data.yearName);
      console.clear();
      console.log(resp.data.data);
     console.log(resp.data.data.bankDetails.bankName);
   
      setBank(resp.data.data.bankDetails.bankName);
      setAccount(resp.data.data.bankDetails.accountNo);
      setIfsc(resp.data.data.bankDetails.ifscDetails);
      setBranch(resp.data.data.bankDetails.branch);
      setPan(resp.data.data.bankDetails.panDetails);
      setBankid(resp.data.data.bankDetails.staffBankID);

    }catch(error){
      alert(error);
    }
  };

  const [staffName, setStaffName] = useState(props.data.staffName);
  const [fatherName, setFatherName] = useState(props.data.staffFatherName);
  const [motherName, setMotherName] = useState(props.data.staffMotherName);
  const [maritals, setMaritals] = useState(props.data.staffMaritals);
  const [spouseName, setSpouseName] = useState(props.data.staffSpouse);
  const [childNum, setChildNum] = useState(props.data.staffChildren);
  const [designation, setDesignation] = useState(props.data.designation);
  const [staffType, setStaffType] = useState(props.data.staffType);
  const [qualification, setQualification] = useState(
    props.data.staffQualification
  );
  const [staffClass, setStaffClass] = useState(props.data.staffClass);
  const [gender, setStaffGender] = useState(props.data.staffGender);
  const [staffBloodGroup, setStaffBloodGroup] = useState(props.data.bloodGroup);
  const [aadhar, setAadhar] = useState(props.data.staffAadharNo);
  const [address, setAddress] = useState(props.data.staffAddress);
  const [email, setEmail] = useState(props.data.staffEmailID);
  const [phoneNo1, setPhoneNo1] = useState("");
  const [phoneNo2, setPhoneNo2] = useState("");
  const [staffReligion, setStaffReligion] = useState(props.data.staffReligion);
  const [bank, setBank] = useState();
  const [account, setAccount] = useState();
  const [ifsc, setIfsc] = useState();
  const [branch, setBranch] = useState();
  const [pan, setPan] = useState();
  const [bankid, setBankid] = useState();
  const [open, setOpen] = useState(false);

  const onlyCharaters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };
  const onlyNumbers = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9 ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };
  const onlyCharatersAddr = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z,-@# ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };
  const onlySpecialCharaters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^zA-Z. ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const onlyCharatersBg = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^A-Z+-]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const onUpdate = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    let staffOBJ = {
      staffName: staffName,
      staffFatherName: fatherName,
      staffMotherName: motherName,
      staffMaritals: maritals,
      staffSpouse: spouseName,
      staffChildren: childNum,
      designation: designation,
      staffType: staffType,
      staffQualification: qualification,
      stafDOJ: doj.toLocaleDateString("en-GB"),
      staffClass: staffClass,
      staffGender: gender,
      staffDOB: dob.toLocaleDateString("en-GB"),
      bloodGroup: staffBloodGroup,
      staffReligion: staffReligion,
      staffAadharNo: aadhar,
      staffAddress: address,
      staffEmailID: email,
      staffMobileNo: [phoneNo1, phoneNo2],
      ssid: props.data.ssid,
      staffPan: props.data.staffPan,
      monthlySalary: props.data.monthlySalary,
      staffProfilePhoto: props.data.staffProfilePhoto,
      staffID: props.data.staffID,
      staffSubjectList: props.data.staffSubjectList,
      updatedSalary: props.data.updatedSalary,
      yearID: data.yearID,
      yearName: data.yearName,
    };
    console.log(staffOBJ);

    try {
      let response = await StaffService.createStaff(staffOBJ);
      // alert("Staff updated successfully");
      console.log(response.data.data);
      let bankDet = {
        accountNo: account,
        bankName: bank,
        branch: branch,
        ifscDetails: ifsc,
        panDetails: pan,
        ssid: response.data.data.ssid,
        staffBankID : bankid,
        staffName: response.data.data.staffName,
      //   staffBankDetails: data.get("salary"),
        yearID: data.yearID,
        yearName: data.yearName, 
      };
      bankSubmit(bankDet);

      setOpen(true);
      setTimeout(()=>{
        setOpen(false);
      },2000);
    } catch (error) {
      console.log(error);
    }
  };

  const bankSubmit = async(bank) => {
    try{
      let response = await StaffService.salaryDetails(bank);
      console.log(response.data.data);
    } catch(error) {
      alert(error);
    }
  }

  return (
    <Box
      sx={{ flexGrow: 1 }}
      // onSubmit={onStaffDetailSave}
      component="form"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row">
            <TextField
              fullWidth
              label="NAME"
              name="staffName"
              value={staffName}
              variant="filled"
              InputProps={{
                inputProps: {
                  minLength: 2,
                },
              }}
              onChange={(e) => {
                onlyCharaters(e);
                setStaffName(e.target.value.toUpperCase());
              }}
            />

            <TextField
              fullWidth
              label="FATHER'S NAME"
              name="fatherName"
              variant="filled"
              value={fatherName}
              InputProps={{
                inputProps: {
                  minLength: 2,
                },
              }}
              // error={!!FnameErrorText}
              // helperText={FnameErrorText}
              onChange={(e) => {
                onlyCharaters(e);
                setFatherName(e.target.value.toUpperCase());
              }}
              required
            />

            <TextField
              fullWidth
              label="MOTHER'S NAME"
              name="motherName"
              value={motherName}
              // inputRef={textInput}
              InputProps={{
                inputProps: {
                  minLength: 2,
                },
              }}
              onChange={(e) => {
                onlyCharaters(e);
                setMotherName(e.target.value.toUpperCase());
              }}
              variant="filled"
              required
            />

            <FormControl fullWidth required>
              <InputLabel>MARITAL STATUS</InputLabel>
              <Select
                labelId="designationTypeID"
                id="designationType"
                value={maritals}
                label="MARRIED STATUS"
                variant="filled"
                name="maritals"
                InputProps={{
                  inputProps: {
                    minLength: 2,
                  },
                }}
                onChange={(e) => {
                  onlyCharaters(e);
                  setMaritals(e.target.value);
                }}
              >
                <MenuItem value="SINGLE">SINGLE</MenuItem>
                <MenuItem value="MARRIED">MARRIED</MenuItem>
                <MenuItem value="WTDOWED">WIDOWED</MenuItem>
                <MenuItem value="SEPARATED">SEPARATED</MenuItem>
                <MenuItem value="DIVORCED">DIVORCED</MenuItem>
              </Select>
            </FormControl>

            {/*........... SPOUSE NAME IF MARRIED ..........*/}
            <TextField
              fullWidth
              label="SPOUSE NAME IF MARRIED"
              name="spousename"
              value={spouseName}
              variant="filled"
              inputProps={{ style: { textTransform: "uppercase" } }}
              onChange={(e) => {
                onlyCharaters(e);
                setSpouseName(e.target.value.toUpperCase());
              }}
            />

            {/* ...........NO OF CHILDREN IF ANY.......... */}
            <TextField
              fullWidth
              label="NO OF CHILDREN"
              name="childnum"
              variant="filled"
              value={childNum}
              onChange={(e) => {
                onlyNumbers(e);
                setChildNum(e.target.value);
              }}
            />
            <FormControl fullWidth>
              <InputLabel>DESIGNATION</InputLabel>
              <Select
                labelId="designationID"
                id="designation"
                variant="filled"
                value={designation}
                onChange={(e) => {
                  onlyCharaters(e);
                  setDesignation(e.target.value);
                }}
              >
                {designationVal.map((element, i) => (
                  <MenuItem key={i} value={element.designation}>
                    {element.designation}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row">
            {/* .............STAFF TYPE............. */}
            <FormControl fullWidth>
              <InputLabel id="designationTypeID">STAFF TYPE</InputLabel>
              <Select
                labelId="designationTypeID"
                id="designationType"
                value={staffType}
                label="Teaching"
                variant="filled"
                onChange={(e) => {
                  setStaffType(e.target.value);
                }}
                required
              >
                <MenuItem value="TEACHING">TEACHING</MenuItem>
                <MenuItem value="NON-TEACHING">NON-TEACHING</MenuItem>
              </Select>
            </FormControl>

            {/* .............STAFF QUALIFICATION............ */}
            <TextField
              fullWidth
              label="QUALIFICATION"
              name="qualification"
              variant="filled"
              value={qualification}
              InputProps={{
                inputProps: {
                  minLength: 2,
                },
              }}
              onChange={(e) => {
                onlySpecialCharaters(e);
                setQualification(e.target.value.toUpperCase());
              }}
            />

            {/*.............. STAFF DOJ............ */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // inputFormat="dd/MM/yyyy"
                // format="dd/MM/yyyy"
                label="DATE OF JOINING"
                value={doj}
                onChange={(newDate) => {
                  setDoj(newDate);
                }}
                sx={{
                  backgroundColor: "#eeeeee",
                  border: "2px solid transparent",
                  width: "100%",
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    variant="filled"
                    name="DOJ"
                  />
                )}
              />
            </LocalizationProvider>

            {/* ..........STAFF CLASS........... */}
            <TextField
              fullWidth
              label="CLASS TEACHER"
              name="staffClass"
              value={staffClass}
              variant="filled"
              inputProps={{
                style: { minLength: 2, textTransform: "uppercase" },
              }}
              onChange={(e) => {
                onlyCharaters(e);
                setStaffClass(e.target.value.toUpperCase());
              }}
            />

            {/* ...........STAFF GENDER............ */}
            <FormControl fullWidth variant="filled" required>
              <InputLabel id="staffGender">GENDER</InputLabel>
              <Select
                variant="filled"
                value={gender}
                onChange={(e) => {
                  setStaffGender(e.target.value);
                }}
                name="staffGender"
              >
                <MenuItem value={"Male"}>👦🏽 Male</MenuItem>
                <MenuItem value={"Female"}>👧🏽 Female</MenuItem>
              </Select>
            </FormControl>

            {/* ...........STAFF DOB............ */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                label="DOB"
                value={dob}
                onChange={(newDate) => {
                  setDob(newDate);
                }}
                sx={{
                  backgroundColor: "#eeeeee",
                  border: "2px solid transparent",
                  width: "90%",
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    variant="filled"
                    name="DOB"
                  />
                )}
              />

              {/* .............STAFF BLOOD GROUP......... */}
            </LocalizationProvider>

            <TextField
              fullWidth
              label="BLOOD GROUP"
              name="staffBloodGroup"
              inputProps={{
                style: { textTransform: "uppercase" },
              }}
              variant="filled"
              value={staffBloodGroup}
              onChange={(e) => {
             onlyCharatersBg(e);
                if (e.target.value.length >= 0) {
                  setStaffBloodGroup(e.target.value.toUpperCase());
                }
              }}
              // required
            />

            {/*........... STAFF RELIGION........... */}
            <TextField
              fullWidth
              label="RELIGION"
              name="religion"
              variant="filled"
              value={staffReligion}
              inputProps={{
                style: { minLength: 2, textTransform: "uppercase" },
              }}
              onChange={(e) => {
                onlyCharaters(e);
                setStaffReligion(e.target.value.toUpperCase());
              }}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row">
            {/* ...........STAFF AADHAR NO............. */}
            <TextField
              fullWidth
              label="AADHAR NO"
              
              name="aadharno"
              variant="filled"
              value={aadhar}
              onChange={(e) => 
                {
                  onlyNumbers(e);
                setAadhar(e.target.value)
                }}
              inputProps={{ maxLength: 12, }}
              required
            />

            {/* ...........STAFF ADDRESS.......... */}
            <TextField
              fullWidth
              label="ADDRESS"
              name="address"
              variant="filled"
              value={address}
              InputProps={{
                inputProps: {
                  minLength: 2,
                },
              }}
              onChange={(e) => {
                onlyCharatersAddr(e);
                setAddress(e.target.value.toUpperCase());
              }}
              required
            />

            {/* .........STAFF E-MAIL......... */}
            <TextField
              fullWidth
              label="E-MAIL ADDRESS"
              type="email"
              name="email"
              variant="filled"
              value={email}
              onChange={(e) => setEmail(e.target.value.toUpperCase())}
              required
            />

            {/*.........STAFF PHONE NO.......... */}
            <TextField
              fullWidth
              label="PHONE NO"
              name="phone1"
            inputProps={{
              maxLength:10,
            }}
           
              value={phoneNo1}
              onChange={(e) => {
                onlyNumbers(e);
                setPhoneNo1(e.target.value);
              }}
              variant="filled"
            />

            {/*.........STAFF ALT PHONE NO.......... */}
            <TextField
              fullWidth
              label="ALTERNATE PHONE NO"
              name="phone2"
              variant="filled"
              inputProps={{
              maxLength:10,
              }}
             
              // onInput = {(e)=>onlyNumbers(e)}
              value={phoneNo2}
              onChange={(e) => {
                onlyNumbers(e);
                setPhoneNo2(e.target.value);
              }}
              
            />
          </Stack>

          {/* ..........END BUTTON...........*/}
        </Grid>

        <Grid item xs={12} sx={{display:"flex"}}>
          {/* ..........staff bank details....... */}
          <TextField
                      id="bank"
                      label="BANK"
                      variant="filled"
                      sx={{width:"100%"}}
                      value={bank}
                      name="bank"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        style: { textTransform: "uppercase" },
                      }}
                      onChange={(e) => {
                        onlyCharaters(e);
                        setBank(e.target.value.toUpperCase());
                      }}
                    />

<TextField
                      id="acc"
                      type="number"
                      label="ACCOUNT NO"
                      variant="filled"
                      sx={{width:"100%"}}
                      name="account_no"
                      value={account}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setAccount(e.target.value);
                      }}
                    />

<TextField
                      id="ifsc"
                      label="IFSC CODE"
                      variant="filled"
                      sx={{width:"100%"}}
                      value={ifsc}
                      name="ifsc_code"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      onChange={(e) => {
                        // onlyCharNum(e);
                        setIfsc(e.target.value.toUpperCase());
                      }}
                    />

<TextField
                      id="branch"
                      label="BRANCH"
                      variant="filled"
                      sx={{width:"100%"}}
                      value={branch}
                      name="branch"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      onChange={(e) => {
                        // onlyCharNum(e);
                        setBranch(e.target.value.toUpperCase());
                      }}
                    />

<TextField
                      id="pan"
                      label="PAN"
                      variant="filled"
                    
                      sx={{width:"100%"}}
                      value={pan}
                      name="pan"
                      InputLabelProps={{
            shrink: true,
          }}
                      inputProps={{
                        maxLength: 10,
                        style: { textTransform: "uppercase" },
                      }}
                      onChange={(e) => {
                        // onlyCharNum(e);
                        setPan(e.target.value);
                      }}
                      // inputRef={textInput}
                    />
        </Grid>
      </Grid>

      <Box display={"flex"}>
        <Button
          variant="contained"
          sx={{ m: 2, px: 4, ml: 1 }}
          // disabled={toggle ? true : false}
          onClick={onUpdate}
        >
          Update
        </Button>

        <Modal
                open={open}
                // onClose={() => setSucess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div sx={style}>
                  <SucessModal
                    text="Staff Details Updated Successfully"
                    name="Staff Details Updated Successfully"
                  />
                </div>
              </Modal>

      </Box>
    </Box>
  );
};
export default StaffDetails;
