import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class yearService {
  // static serverURL = "http://143.110.188.253:1919/year/";
  static service = "year/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createYear(contact) {
    var dataURL = this.serverURL + "create";
    return axios.post(dataURL, contact);
  }

  static getYear(yearID) {
    var dataURL = this.serverURL + "get?yearID=" + yearID;
    return axios.get(dataURL);
  }
  static yearListAll(pageNo) {
    var dataURL = this.serverURL + "list?pageNo=" + pageNo;
    return axios.get(dataURL);
  }
}
