import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #ffb02e",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

export default function AlertModal(props) {
  return (
    <div>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          ⚠️ {props.text}
        </Typography>
      </Box>
    </div>
  );
}
