import React from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { C } from "../../util/C";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import Backdrop from "@mui/material/Backdrop";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardDivision } from "../../../services/StandardDIvision";
import { StandardSubjectService } from "../../../services/StandardSubject";
import { BuildSchoolExam } from "../../../services/examStandards";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "@mui/material/Modal";
import { Report } from "../../../services/ReportService";
import { LaunchPadSetting } from "../../../services/SettingService";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  // fontWeight:'bolder'
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));

const style = {
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 250,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));
const ItemD = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background:
    "radial-gradient(circle, rgba(121,255,123,1) 0%, rgba(0,156,7,1) 100%)",
  "&:hover": {
    background:
      "radial-gradient(circle, rgba(0,156,7,1) 0%, rgba(121,255,123,1) 100%) ",
  },
}));
const ItemE = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#5485e594",
  "&:hover": {
    background: "#3661b494",
  },
}));

const Item1 = styled(Paper)(({ theme }) => ({
  // padding: theme.spacing(1),
  textAlign: "center",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",

  // fontWeight:'bold',
}));

const columns = [
  {
    field: "id",
    headerName: "NO.",
    width: 90,
    sortable: false,
    headerAlign: "center",
    headerClassName: "super-app-theme--header",
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO."}</strong>,
    align: "center",
    //   renderHeader: () => (
    //   <strong style={{ fontSize: 17 }}>
    //     {'NO '}
    //   </strong>
    // ),
  },
  {
    field: "subjectName",
    headerName: "Subject Name",
    // width: 150,
    // id:{standardSubjectID},
    headerAlign: "center",
    sortable: false,
    headerClassName: "super-app-theme--header",
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"SUBJECT NAME"}</strong>,
    align: "center",
    flex: 1,
    editable: true,
    renderCell: (params) => (
      <p style={{ textTransform: "uppercase" }}>{params.value}</p>
    ),
    // renderHeader: () => (
    //   <strong style={{ fontSize: 17 }}>
    //     {"subject Name"}
    //   </strong>
    // ),
  },
  // {
  //   field: 'testdate',
  //   headerName: 'Exam Date',
  //   flex:1,
  //   type:'date'
  //   // renderHeader: () => (
  //   //   <strong style={{ fontSize: 17 }}>
  //   //     {'Date' }
  //   //   </strong>
  //   // ),

  // },
  // {
  //   field: 'day',
  //   headerName: 'Exam Day',
  //   flex:1,
  //   type: 'Text',
  //   editable: true,
  //   // renderHeader: () => (
  //   //   <strong style={{ fontSize: 17 }}>
  //   //     {'Day '}
  //   //   </strong>
  //   // ),
  // },
  {
    field: "startdate",
    headerName: "Start Date Time",
    sortable: false,
    flex: 1,
    type: "dateTime",
    headerAlign: "center",
    editable: true,
    headerClassName: "super-app-theme--header",
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"START DATE & TIME"}</strong>,
    align: "center",
  },
  {
    field: "enddate",
    headerName: "End Date Time",
    sortable: false,
    flex: 1,
    type: "dateTime",
    headerAlign: "center",
    editable: true,
    headerClassName: "super-app-theme--header",
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"END DATE & TIME"}</strong>,
    align: "center",
  },

  // { field: 'date', headerName: 'Date', renderCell: (params) => (
  //   <DatePicker
  //     selected={selectedDate}
  //     onChange={date => setSelectedDate(date)}
  //   />
  // ),
  // valueGetter: (params) =>
  //      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  //      },
];

export default function SetExamTimings() {
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const onCellEdit = (e) => {
    // debugger
    console.log(e);

    const found = rows.find((element) => element.id == e.id);
    console.log(found);

    if (e.field === "startdate") {
      found.startdate = e.value.toDateString();
      found.starttime = e.value.toLocaleString();
      // found.starttime = e.value.toTimeString();
    }
    if (e.field === "enddate") {
      found.enddate = e.value.toDateString();
      found.endtime = e.value.toLocaleString();
      // found.endtime = e.value.toTimeString();
    }

    console.log(rows);
    console.log(found.startDate);
  };
  //   const [value, setValue] = React.useState(null);

  //  const [checked, setChecked] = React.useState([1]);

  //   const handleToggle = (value) => () => {
  //     const currentIndex = checked.indexOf(value);
  //     const newChecked = [...checked];

  //     if (currentIndex === -1) {
  //       newChecked.push(value);
  //     } else {
  //       newChecked.splice(currentIndex, 1);
  //     }

  //     setChecked(newChecked);
  //   };

  const [Standard, setStandard] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [freeze, setFreeze] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose=()=> setOpen(false);

  React.useEffect(() => {
    onstandardsLoad();
  }, []);

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };

  const [Standardname, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [DivisionName, setDivisionName] = React.useState();
  const [Subject, setSubject] = React.useState([]);
  const [Division, setDivision] = React.useState([]);
  const [standardTest, updateStandardTest] = React.useState([]);
  const [timingid, setTimingid] = React.useState();

  async function onStandardSelect(e, index) {
    e.preventDefault();
    setStandardName(e.currentTarget.textContent);
    setStandardID(e.target.id);
    // C.c(e.currentTarget.id);
    C.c(e.currentTarget.value);

    let highlightStandardList = [...Standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index][
      "isSelected"
    ]
      ? false
      : true;

    let stdId = e.currentTarget.id;
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response =
        await StandardSubjectService.findSubjectListByStandardIDYearID(
          data.yearName,
          stdId
        );
      console.log(response.data.data);

      response.data.data.sort(function (a, b) {
        return a.subjectOrder - b.subjectOrder;
      });

      setSubject(response.data.data);
      // console.log(subject);
      let yearData = JSON.parse(localStorage.getItem("academicYear"));
      console.log(yearData);
      let subjectArray = [];
      response.data.data.forEach(function (data,i) {
        console.log(data);
        subjectArray.push({
          // id: data.standardSubjectID,
          id: i+1,
          subjectName: data.subjectName,
        });
      });
      setRows(subjectArray);
      // C.c(subjectArray);
    } catch (error) {
      // C.c(error.message);
    }

    try {
      let response = await BuildSchoolExam.showBuildSchoolStandardTest(
        e.target.id,
        data.yearName
      );
      console.log(response.data.data);

response.data.data.sort(function (a, b) {
        return a.testOrder - b.testOrder;
      });

      updateStandardTest(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  // const [testName,setTestName]=React.useState("");
  // async function onStandardSelectTest(e) {
  //   setTestName(e.currentTarget.id)

  // }

  const [day, setDay] = React.useState("");
  const onDateSelect = (val) => {
    // debugger

    console.log(val);
    if (val === "Sun") {
      return "Sunday";
    } else if (val === "Mon") {
      return "Monday";
    } else if (val === "Tue") {
      return "Tuesday";
    } else if (val === "Wed") {
      return "Wednesday";
    } else if (val === "Thu") {
      return "Thursday";
    } else if (val === "Fri") {
      return "Friday";
    } else if (val === "Sat") {
      return "Saturday";
    } else {
      return "Invalid day";
    }

    // Sun
    // Mon
    // Tue
    // Wed
    // Thu
    // Fri
    // Sat
  };

  const [Testname, setTestName] = React.useState("");

  const Testkey = async(e, index) => {
    console.log(e);
    // debugger
    setTestName(e.target.textContent);
    console.log(setTestName);

    let highlightTest = [...standardTest];
    highlightTest = highlightTest.map(
      ({ isSelected, ...highlightTest }) => highlightTest
    );
    updateStandardTest(highlightTest);
    highlightTest[index]["isSelected"] = highlightTest[index]["isSelected"]
      ? false
      : true;

      try{
        let response = await Report.getHallTicketData(Standardname, e.target.textContent);
        console.log(response.data.data);
        // setTimingid(response.data.data.setExamTimingesID);
        if(response.data.data !== null) {
          setFreeze(true);
        } else{
          setFreeze(false);
        }
      } catch(error) {
        alert(error);
      }
  };


  const handelSubmit = async (e) => {
    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    let timingesDatasArray = [];
    rows.forEach((rec) => {
      // C.c(rec);
      let Eday, Edate;
      if (rec.startdate) {
        let daydate = rec.startdate.split(" ");
        Eday = onDateSelect(daydate[0]);
        Edate = daydate[2] + "-" + daydate[1] + "-" + daydate[3];
        C.c(daydate);
      }
      // debugger
      timingesDatasArray.push({
        date: Edate,
        day: Eday,
        endDate: rec.endtime,
        startDate: rec.starttime,
        subject: rec.subjectName,
        yearID: yearData.yearID,
        yearName: yearData.yearName,
      });
    });

    let setTimeOBJ = {
      setExamTimingsID : timingid,
      standard: Standardname,
      testTitleName: Testname,
      timingesDatas: timingesDatasArray,
      yearID: yearData.yearID,
      yearName: yearData.yearName,
    };

    C.c(setTimeOBJ);

    try {
      let response = await LaunchPadSetting.createExamTiming(setTimeOBJ);
      C.c(response.data.data);
      setOpen(true)
      setTimeout(()=>{
        setSuccess(false);
        setOpen(false);
      },2000);
    } catch (error) {
      C.c(error.message);
    }
    setRows("");
    setTestName("");

  };

  // const [Testname, setTestName] = React.useState("");

  // const Testkey = (e, index) => {
  //   console.log(e);
  //   // debugger
  //   setTestName(e.target.textContent);
  //   console.log(setTestName);

  //   let highlightTest = [...standardTest];
  //   highlightTest = highlightTest.map(
  //     ({ isSelected, ...highlightTest }) => highlightTest
  //   );
  //   updateStandardTest(highlightTest);
  //   highlightTest[index]["isSelected"] = highlightTest[index]["isSelected"]
  //     ? false
  //     : true;
  // };
  let navigate = useNavigate();
  const onLogout = () => {
    console.log("onLogout");
    navigate("/launchpad", { replace: true });
  };

  return (
    <div>
      {/* <LaunchpadTopBar id="settings" /> */}
      <Box sx={{width:"100%", padding:"0 10px", boxSizing:"border-box"}}>
        <Grid container>
          <Grid item xs={12} align="center">
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                backgroundColor: "lightblue",
                my: 1,
                fontWeight: "bold",
              }}
            >
              SET EXAM TIMINGS FOR HALL TICKETS
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mt: "1%", paddingLeft:"8px" }}>
          <Grid Item1 xs={12}>
            <Item1>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Standard.map((element, index) => (
                  <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      name={element.standardName}
                      onClick={(e) => {
                        onStandardSelect(e, index);
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </Item1>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Item>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {standardTest.map((element, index) => (
                  <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                    <ItemE
                      id={element.standardTestID}
                      value={element.testTitleName}
                      className={element.isSelected ? "selectedTest" : ""}
                      onClick={(e) => {
                        Testkey(e, index);
                      }}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.testTitleName.toUpperCase()}
                    </ItemE>
                  </Grid>
                ))}
              </Grid>
            </Item>
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            backgroundColor: "lightblue",
            my: 1,
            fontWeight: "bold",
          }}
        >
          INPUT DATE AND TIME FOR THE SUBJECTS BELOW
        </Typography>
        <Box sx={{ height: 400, mt: 1}}>
          <Box component="form" sx={{ height: 375}}>
            <Box
              sx={{
                height: 350,
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#c0c0c0",
                  fontWeight: "bold",
                  fontSize: "15px",
                  border: "1px solid white",
                },
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={15}
                disableColumnMenu
                rowsPerPageOptions={[15]}
                onCellEditCommit={onCellEdit}

                // experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={{display:"flex", justifyContent:"space-evenly",mt:2, paddingBottom:"25px"}}> 
        {/* <Button
              onClick={handelSubmit}
              variant="contained"
              sx={{
                backgroundColor: "00ACC1",
                color: "white",
                borderRadius: "20px",
                padding: "10px 40px 10px 40px",
                fontWeight: "bold",  
              }}
              disabled={freeze}
              
            >
              SAVE AND CONTINUE
            </Button> */}
            <Button
              variant="contained"
              sx={{
                fontSize: 15,
                borderRadius: "30px",
                fontWeight: "bold",

                // ml: 65,
              }}
              onClick={() => {
                setSuccess(true);
              }}
              disabled={freeze}
            >
              Review and Freeze
            </Button>

        <Button
              onClick={onLogout}
              variant="contained"
              sx={{
                backgroundColor: "#ca424f",
                borderRadius: "30px",
                fontSize: 15,
                fontWeight: "bold",
                width: "120px",
                "&:hover": { backgroundColor: "#ca424f" },
              }}
            >
              EXIT
            </Button>
        </Box>

        <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={success}
              // onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={success}>
                <Box sx={style}>
                  <Box sx={{display:"grid"}}>
                  <Typography
                    id="transition-modal-title"
                    variant="body1"
                    component="h2"
                    sx={{fontWeight:"550",textAlign:"center",fontSize:18}}
                  >
                    You have clicked on
                  </Typography>
            
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                    textAlign={"center"}
                    fontWeight={"bold"}
                  >
                   FREEZE INFORMATION
                  </Typography>
                  </Box>
                  <Typography id="transition-modal-description"  sx={{mt:2,  }}>
                    We request you to kindly review the test timetable data once again
                    and then freeze the data, as once its FREEZE can’t be
                    changed. Thank you
                  </Typography>
                  <Box  sx={{ mt: 4,display:"flex",justifyContent:"space-evenly" }}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="success"
                      fontWeight={"bolder"}
                      onClick={handelSubmit}
                    >
                      FREEZE
                    </Button>
                    <Button
                      variant="contained"
                      size="medium"
                      color="error"
                      fontWeight={"bolder"}
                      onClick={() => {
                        setSuccess(false);
                      }}
                    >
                      Review
                    </Button>
                  </Box>
                </Box>
              </Fade>
            </Modal>

        <Modal
            open={open}
            // onClose={() => setSucess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div sx={style}>
              <SucessModal
                text="Test/Exam Time Table Saved Successfully"
                name="Test/Exam Time Table Saved Successfully"
              />
            </div>
          </Modal>
      </Box>
    </div>
  );
}
