


import React from "react";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
// import Grid from "@mui/system/Unstable_Grid";
import styled from "@mui/system/styled";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";

const Item = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#444d58" : "#ced7e0",
  padding: theme.spacing(1),
  borderRadius: "4px",
  textAlign: "flex-start"
}));
function FreezePopUp() {
  return (
    <div>

      <Box sx={{ m: 20 }}>
        <Grid container spacing={2} sx={{ rowGap: 6 }}>
          <Grid xs={6}>
            <Stack direction="row" spacing={1}>
              <Item>
                <Typography variant="body2">Form No. 22-156 Name:</Typography>
              </Item>
              <Item>
                <Typography variant="body1" fontWeight="fontWeightBold">
                  Mohammed Salim
                </Typography>
              </Item>
            </Stack>
          </Grid>
          <Grid xs={6}>
            <Stack direction="row" spacing={1}>
              <Item>
                <Typography variant="body2">Father:</Typography>
              </Item>
              <Item>
                <Typography variant="body1" fontWeight="fontWeightBold">
                  Mohammed Suhail
                </Typography>
              </Item>
            </Stack>
          </Grid>

          <Grid xs={4}>
            <Stack direction="row">
              <Item>
                <Typography variant="body2">Fees Details :</Typography>
              </Item>
              <Item>
                <Typography variant="body2">Total Yearly Fees</Typography>
              </Item>
            </Stack>
          </Grid>

          <Grid xs={2} border="2px solid black"></Grid>

          <Grid xs={2} border="2px solid black" sx={{ ml: 8 }}>
            RTE Student
          </Grid>

          <Grid xs={0.8} border="2px solid black" borderLeft="none">
            <Button variant="text" sx={{ color: "black" }}>
              yes
            </Button>
          </Grid>

          <Grid
            xs={0.8}
            border="2px solid red"
            sx={{ ml: 5, backgroundColor: "green" }}
          >
            <Button variant="text" sx={{ color: "yellow" }}>
              no
            </Button>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 3 }}>
          <Grid
            item
            xs={1}
            align="center"
            border="2px solid black"
            borderRight="none"
          >
            <Stack>
              <Item>No.</Item>
              <Item>1</Item>
              <Item>2</Item>
              <Item>3</Item>
              <Item>4</Item>
              <Item>5</Item>
              <Item>6</Item>
            </Stack>
          </Grid>

          <Grid
            item
            xs={2.5}
            align="center"
            border="2px solid black"
            borderRight="none"
          >
            <Stack>
              <Item>Fees Heads</Item>
              <Item>Admission</Item>
              <Item>Books/Uniform/Stationary</Item>
              <Item>Tuition x 12</Item>
              <Item>Transport</Item>
              <Item>Computer</Item>
              <Item>Miscellanies</Item>
              <Item>Total</Item>
            </Stack>
          </Grid>

          <Grid
            item
            xs={1.5}
            align="center"
            border="2px solid black"
            borderRight="none"
          >
            <Stack>
              <Item>Fees Amount</Item>
              <Item>10500</Item>
              <Item>6500</Item>
              <Item>500</Item>
              <Item>6000</Item>
              <Item>1200</Item>
              <Item>600</Item>
            </Stack>
          </Grid>

          <Grid
            item
            xs={1.8}
            align="center"
            border="2px solid black"
            borderRight="none"
          >
            <Stack>
              <Item>Click to Apply</Item>
              <Item sx={{ height: 25 }}>
                <Button size="small">yes</Button>
                <Button size="small">no</Button>
              </Item>
              <Item sx={{ height: 25 }}>
                <Button size="small">yes</Button>
                <Button size="small">no</Button>
              </Item>
              <Item sx={{ height: 25 }}>
                <Button size="small">yes</Button>
                <Button size="small">no</Button>
              </Item>
              <Item sx={{ height: 25 }}>
                <Button size="small">yes</Button>
                <Button size="small">no</Button>
              </Item>
              <Item sx={{ height: 25 }}>
                <Button size="small">yes</Button>
                <Button size="small">no</Button>
              </Item>
              <Item sx={{ height: 25 }}>
                <Button size="small"> yes</Button>
                <Button size="small">no</Button>
              </Item>
            </Stack>
          </Grid>

          <Grid item xs={2} align="center" border="2px solid black">
            <Stack>
              <Item>Total</Item>
              <Item>10500</Item>
              <Item>6500</Item>
              <Item>500</Item>
              <Item>6000</Item>
              <Item>1200</Item>
              <Item>600</Item>
              <Item>24800</Item>
            </Stack>
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={5}
            align="center"
            border="2px solid black"
            borderTop="none"
          >
            <Item>
              <Typography fontWeight="fontWeightBold">
                {" "}
                FREEZE THE FEES INFORMATION AND SEND SMS
              </Typography>
            </Item>
          </Grid>

          <Grid
            item
            xs={0.9}
            border="2px solid black"
            borderLeft="none"
            borderTop="none"
          >
            <Item>
              <Button
                sx={{
                  fontWeight: "fontWeightBold",
                  fontSize: "18px",
                  color: "blue"
                }}
              >
                yes
              </Button>
            </Item>
          </Grid>

          <Grid
            item
            xs={0.91}
            border="2px solid black"
            borderLeft="none"
            borderTop="none"
          >
            <Item>
              <Button sx={{ color: "red" }}>no</Button>
            </Item>
          </Grid>

          <Grid
            item
            xs={2}
            border="2px solid black"
            borderTop="none"
            borderLeft="none"
          ></Grid>
        </Grid>
      </Box>

    </div>
  );
}

export default FreezePopUp;
