import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class StudentFeeStructure {
  static service = "studentfees/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static studentsFeesAppliedCreate(data) {
    var dataURL = this.serverURL + "studentsFeesAppliedCreate";

    return axios.post(dataURL, data);
  }
  static GetStudentsFeesApplied(s_id, y) {
    var dataURL =
      this.serverURL +
      "list/standard/student/applied?standardID=" +
      s_id +
      "&yearName=" +
      y;

    return axios.get(dataURL);
  }
}
