

import React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { ButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FeeCollectionStructure } from "../../../services/Feescollectionservice";

function AcceptRequestForTc() {
  const columns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      editable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO."}</strong>,
      headerClassName: "super-app-theme--header",
    },

{
      field: "studentName",
      headerName: "NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      editable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NAME"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },

    {
      field: "studentGender",
      headerName: "GENDER",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      editable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"GENDER"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },

    {
      field: "studentUSN",
      headerName: "USID",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      editable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"USID"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: true,
    },

   {
      field: "standardWithDivision",
      headerName: "STD/DIV",
      // description: 'This column has a value getter and is not sortable.',
      editable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      editable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"STD/DIV"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "fathersName",
      headerName: "FATHER'S NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      editable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"FATHER'S NAME"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },

  ];

React.useEffect(()=>{
  onload();
},[]);

const [rows,setRows] = React.useState([]);

const onload = async()=>{
  let data = JSON.parse(localStorage.getItem('academicYear'));
let arr=[];

try{
  let response = await FeeCollectionStructure.StudentFeesCollectionList(data.yearName);
  console.log(response.data.data);

let list = response.data.data.filter((student)=> student.balance == 0);

  list.forEach((rec,i)=>{
    arr.push({
      id: i + 1,
          studentName: rec.studentName,
          studentGender: rec.gender,
          studentUSN: rec.usid,
          standardWithDivision: rec.standardName + " | " + rec.division,
          fathersName: rec.fatherName,
    });
  });
  setRows(arr);
}catch(error){
  console.log(error);
}
  
}

  // const rows = [
  //   {
  //     id: 1,
  //     name: "Sali Amir",
  //     gender: "Boy",
  //     usid: "SA0013",
  //     std: "1/A",
  //     fathersName: "Suhail Mohammed",
  //     mothersName: "Shaheena",
  //   },
  //   {
  //     id: 2,
  //     name: "Aesalan Khan",
  //     gender: "Boy",
  //     usid: "MS0012",
  //     std: "2/B",
  //     fathersName: "Mohammed Usman",
  //     mothersName: "Ayesha",
  //   },
  //   {
  //     id: 3,
  //     name: "Salim Shaikh",
  //     gender: "Boy",
  //     usid: "SS0016",
  //     std: "9/A",
  //     fathersName: "Sarfaraz Shaikh",
  //     mothersName: "Aqsa",
  //   },
  // ];

  let navigate = useNavigate();

  const grantAndReject = () => {
    console.log("grantAndReject");
    navigate("/grantandreject", { replace: true });
  };

  const requestForTc = () => {
    setClicked2(true);
    console.log("requestForTc");
    navigate("/acceptrequestfortc", { replace: true });
  };

  const fillNewForm = () => {
    console.log("fillNewForm");
    navigate("/newadmission", { replace: true });
  };

  const[clicked2,setClicked2]=React.useState(false);

 
  return (
    <div>
      {/* <LaunchpadTopBar id="afterfinalexam" /> */}

      <Grid
        container
        spacing={2}
        // display={props.id == "startacademicyear1" ? "" : "none"}
        sx={{ py: 1, pl: 4 }}
      >
        <Grid
          item
          xs={1.5}
          // display="flex"
          //  style={{ display: "flex", gap: "1rem" }}
        >
          {/* <ButtonGroup variant="outlined" aria-label="outlined button group"> */}
            {/* <Button
              size="small"
              sx={{
                color: "black",
                fontWeight: "bold",
                variant: "outlined",
                border: "1px solid black",
                borderRadius: "1px",
                width: "450px",
                // borderRight: "none",
                ml:20,
                ":hover": {
                  backgroundColor: "#1565c0 ",
                  color: "white",
                  boxShadow: "5px 5px 5px  black",
                },
              }}
              onClick={fillNewForm}
            >
              FILL NEW ADMISSION FORM FOR THE NEXT ACADEMIC YEAR{" "}
            </Button> */}
            {/* ----------------------------------------------GRANT / REJECT--------------------------------------------------------*/}

            {/* <Button
              size="small"
              sx={{
                color: "black",
                fontWeight: "bold",
                variant: "outlined",
                border: "1px solid black",
                borderRadius: "1px",
                width: "450px",
                // borderRight: "none",
                ":hover": {
                  backgroundColor: "#1565c0 ",
                  color: "white",
                  boxShadow: "5px 5px 5px  black",
                },
              }}
              onClick={grantAndReject}
            >
              GRANT / REJECT ADMISSION FOR NEXT ACADEMIC YEAR{" "}
            </Button> */}

            {/*--------------------------------------------------------- ACCEPT REQUEST FOR TC AND LC ------------------------------------------*/}
            {/* <Button
              size="small"
              sx={{
                color: "black",
                fontWeight: "bold",
                variant: "outlined",
                border: "1px solid black",
                borderRadius: "1px",
                width: "450px",
                // borderRight: "none",
                ":hover": {
                  backgroundColor: "#1565c0 ",
                  color: "white",
                  boxShadow: "5px 5px 5px  black",
                },
                backgroundColor: clicked2?'yellow':'white'

              }}
              onClick={requestForTc}
            >
              ACCEPT REQUEST FOR TC AND LC{" "}
            </Button> */}
          {/* </ButtonGroup> */}
        </Grid>
      </Grid>
      
      <Typography
        variant="h6"
        color="darkblue"
        align="center"
        fontWeight="fontWeightBold"
        sx={{ mb: 1, mt: 1 }}
      >
        ACCEPTED REQUEST FOR TC/LC FOR FOLLOWING STUDENT
      </Typography>

   
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box sx={{ height: 390, width: "85%",border:"2px solid black",
       "& .super-app-theme--header": {
        backgroundColor: "#c0c0c0",
        //  color:'white',
        fontWeight: "bold",
        fontSize: "15px",
        border: "1px solid white",
      },
      }}>
         <DataGrid rows={rows} columns={columns}
         disableColumnMenu
         disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          hideFooter={true}
         components={{ Toolbar: GridToolbar }}
         componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            hideFooter: "true",
            hideToolbar: "true",
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
         />
        </Box>
      </Box>
      <Typography 
      sx={{fontWeight:"bold",textAlign:"center"}}
      >
        THE ABOVE LIST CONTAINS THE LIST OF STUDENTS WHO HAD CLEARED THEIR FEES WITH ZERO BALANCE. IF THE STUDENT HADN'T FOUND IN THE LIST THEN PLEASE CLEAR THE BALANCE FEE AMOUNT
      </Typography>
      <Box sx={{ marginTop: 2.5 }}>
        <Button
          variant="contained"
          sx={{
            ml: 145,
            borderRadius: "25px",
            fontWeight: "fontWeightBold",
            width:"150px"
          }}
        >
          confirm
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{
            color: "white",
            marginLeft: 3,
            borderRadius: "25px",
            fontWeight: "fontWeightBold",
            width: 140,
            // backgroundColor:"#f44336",
          }}
        >
          EXIT
        </Button>
      </Box>
    </div>
  );
}

export default AcceptRequestForTc;
