import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { LibraryService } from "../../../services/libraryService";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import { autoTable } from "jspdf-autotable";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";

export default function Books() {
  React.useEffect(() => {
    onBookLoad();
  }, []);
  const [columns, setColumns] = React.useState([
    // { field: 'id', headerName: 'NO', width: 250 },
    {
      field: "no",
      headerName: "NO",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      editable: false,
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bookNumber",
      headerName: "BOOK NUMBER",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      editable: false,
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"BOOK NUMBER"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bookTitle",
      headerName: "BOOK TITLE",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      editable: false,
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"BOOK TITLE"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bookType",
      headerName: "TYPE",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      editable: false,
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"TYPE"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "authorPublication",
      headerName: "AUTHORPUBLICATION",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      editable: false,
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"AUTHOR/PUBLICATION"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "condition",
      headerName: "CONDITION ",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      editable: false,
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"CONDITION"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
  ]);
  const [rows, setRows] = React.useState([]);

  const [book, setBook] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const onBookLoad = async () => {
    setLoading(true);
    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await LibraryService.listAllTypeWise(yearData.yearName);
      console.log(response.data.data);
      // setBook(response.data.data);
      let book_array = [];
      response.data.data.sort((a,b)=>a.bookType.localeCompare(b.bookType));
      response.data.data.forEach(function (rec, i) {
        console.log(rec);
        book_array.push({
          id: rec.libraryID,
          no: i + 1,
          bookNumber: rec.bookNumber.toUpperCase(),
          bookTitle: rec.bookTitle,
          bookType: rec.bookType,
          authorPublication: rec.bookAuthorPublication,
          condition: rec.bookCondition,
        });
      });
      setRows(book_array);
      var hema = book_array.length;
      console.log(rows);
      console.log(hema);
      setLoading(false);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  let navigate = useNavigate();
  const onLaunchPad = () => {
    console.log("launchpad");
    navigate("/launchpad", { replace: true });
  };

  const [academicYear, setacademicYear] = React.useState(
    JSON.parse(localStorage.getItem("academicYear"))
  );
  const [schoolName, setschoolName] = React.useState(
    JSON.parse(localStorage.getItem("schoolDetails"))
  );

  const generatePDF = () => {
    const doc = new jsPDF("p", "pt", "a4");
    const sch = schoolName.schoolNameOnDocs.toUpperCase();
    const acad = academicYear.yearName;
    doc.setFont("helvetica", "bold");
    var fontSize = 18;
    var text = `${sch}`;
    var pageWidth = doc.internal.pageSize.getWidth();
    var textWidth =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    var centerX = (pageWidth - textWidth) / 2;
    doc.text(text, centerX, 35);

    doc.setFontSize(10);
    doc.text(` ${acad}`, 500, 35);

    doc.setFontSize(15);
    doc.text("Library Books", 240, 75);

    const img = `${schoolName.schoolLogoUrl}`;
    doc.addImage(img, "JPEG", 20, 10, 80, 70);

    const tableData = rows.map((row) => [
      row.bookNumber,
      row.bookTitle,
      row.bookType,
      row.authorPublication,
      row.condition,
    ]);

    doc.autoTable({
      tableLineColor: [0,0,0],
      startY: 90,
      head: [
        [
          "BOOK NUMBER",
          "BOOK TITLE",
          "TYPE",
          "AUTHOR/PUBLICATION",
          "CONDITION",
        ],
      ],
      body: tableData,
      headStyles: {
        valign: "middle",
        backgroundColor: "lightgray",
        halign: "center",
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
        lineColor: [0,0,0],
      },
      theme: "grid",
      tableLineWidth: 0.95,
    });
    doc.setFontSize(10);
    // doc.text("Copyright: Technotharanga Solutions Pvt.ltd", 190, 800);
    doc.save("Library-List book type wise.pdf");
  };

  const fileType = "csv";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(rows);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, "Library-List book type wise" + ".csv");
  };

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="library" /> */}
      {loading ? (
        <div style={{padding:"180px 0px", display:"flex", justifyContent:"center",}}>
        <div className = "spinner-container">
        <div className = "loading-spinner">
        </div>
      </div>
      </div>
      ) : (
      <Box  padding= "0 10px">
      <Box>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            mt: 2,
            fontWeight: "bold",
            backgroundColor: "lightblue",
          }}
        >
          LIBRARY BOOKS
        </Typography>
      </Box>
      <Box
      sx={{
          mt: 2,
          height: 420,
          width: "100%",
          textAlign: "center",
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
            //  color:'white',
            fontWeight: "bold",
            fontSize: "15px",
            border: "1px solid white",
          },
          overflowX:"auto",
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          // hideFooter
          disableColumnMenu
          disableSelectionOnClick
          // rowsPerPageOptions={[10]}
          sx={{minWidth:1000}}
          // onCellClick={oncellselect}
          // getRowHeight={() => 'auto'}
          showCellRightBorder={true}
          showColumnRightBorder={true}
        />
      </Box>
      <Box sx={{display:"flex", justifyContent:"space-evenly",mt:2, paddingBottom:"25px"}}>
        <Button
          variant="contained"
          color="error"
          sx={{
            color: "white",
            borderRadius: "30px",
            fontSize: 15,
            width: "120px",
            fontWeight: "bold",
          }}
          onClick={generatePDF}
        >
          PDF
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={exportToCSV}
          sx={{
            color: "white",
            borderRadius: "30px",
            fontSize: 15,
            width: "120px",
            fontWeight: "bold",
          }}
        >
          EXCEL
        </Button>
  
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#ca424f",
            borderRadius: "30px",
            fontSize: 15,
            fontWeight: "bold",
            width: "120px",
            "&:hover": { backgroundColor: "#ca424f" },
          }}
          onClick={onLaunchPad}
        >
          EXIT
        </Button>
      </Box>
      </Box>
      )}
    </React.Fragment>
  );
}
