import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import { HolidayService } from "../../../services/holidaysService";
import { hover } from "@testing-library/user-event/dist/hover";
import ListEventPast from "./eventPast";
import ListEventFuture from "./eventFuture";
import ListEventPresent from "./eventPresent";
import ListEventNextMonth from "./eventNextMonth";
import CalendarEvent from "./event";

const CardItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: 150,
  // color: theme.palette.text.secondary
}));
const EventList = () => {
  const [holidays, setHolidays] = useState([]);
  let data = JSON.parse(localStorage.getItem("academicYear"));

  useEffect(() => {
    onListEvent();
  }, []);

  async function onListEvent() {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let month = new Date();
    try {
      let response = await HolidayService.Holidaylist(data.yearName);
      console.log(response.data.data);
      let monthDates = response.data.data.filter(
        (item) => item.monthName == month.getMonth()
      );
      setHolidays(monthDates);
    } catch (error) {
      alert(error.message);
    }
  }

  const date = new Date();
  const today = {
    day: "2-digit",
    month: "long",
    year: "numeric",
    weekday: "long",
  };
  const textDate = date.toLocaleDateString("eng-GB", today);
  return (
    <Grid container spacing={0}>
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ overflow: "scroll" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <CardItem sx={{ backgroundColor: "#e3f2ff" }}>
                  <Typography>
                    <b>UPCOMING EVENTS</b>
                  </Typography>
                  {/* <Skeleton variant="rounded" width={350} height={120} /> */}
                  <Box
                    sx={{
                      height: "80%",
                      overflowY: "scroll",
                    }}
                  >
                    <ListEventFuture />
                  </Box>
                </CardItem>
              </Grid>
              <Grid item xs={6} md={4}>
                <CardItem sx={{ backgroundColor: "#e3f2ff" }}>
                  <Typography>
                    <b>PRESENT EVENTS</b>
                  </Typography>
                  {/* <Skeleton variant="rounded" width={350} height={120} /> */}
                  <Box
                    sx={{
                      height: "80%",
                      overflowY: "scroll",
                    }}
                  >
                    <ListEventPresent />
                  </Box>
                </CardItem>
              </Grid>
              <Grid item xs={6} md={4}>
                <CardItem sx={{ backgroundColor: "#e3f2ff" }}>
                  <Typography>
                    <b>PAST EVENTS</b>
                  </Typography>
                  {/* <Skeleton variant="rounded" width={350} height={120} /> */}
                  <Box
                    sx={{
                      height: "80%",
                      overflowY: "scroll",
                    }}
                  >
                    <ListEventPast />
                  </Box>
                </CardItem>
              </Grid>
              <Grid item xs={6} md={12}>
                <CardItem sx={{ backgroundColor: "#e3f2ff" }}>
                  <Typography>
                    <b>FUTURE EVENTS</b>
                  </Typography>
                  {/* <Skeleton variant="rounded" width={350} height={120} /> */}
                  <Box
                    sx={{
                      height: "80%",
                      overflowY: "scroll",
                    }}
                  >
                    <ListEventNextMonth />
                  </Box>
                </CardItem>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
export default EventList;
