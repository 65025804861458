import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import styled from "@emotion/styled";
import { Avatar, Button, ButtonGroup, Grid, Stack, Typography,Paper} from "@mui/material";
import { StudentInfoService } from "../../../services/StudentInfo/StudentList";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";

const FormItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: 180,
  // height: 80
  // color: theme.palette.text.secondary,
}));
const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "center",
  // fontWeight:"bold"
  // color: theme.palette.text.secondary
}));

export default function DataGridDemo() {

  const [imageUrl, setImageUrl] = React.useState("");
  const [imageSrc, setImageSrc] = React.useState();
  const [excel, setExcel] = React.useState([]);
  const [studentInfo, updateStudentInfo] = React.useState({});
  const [infoEditable, updateInfoEditable] = React.useState(false);

  React.useEffect(() => {
    onFeeLoad();
  }, []);


  const onFeeLoad = async()=> {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let studentUSN = localStorage.getItem("studentUSN");

    try {
      let response = await StudentInfoService.studentInfoShowStudentDetails(
        studentUSN,
        data.yearName
      );
      console.log(response.data.data);
      updateStudentInfo(response.data.data);
      // DocumentLoad(response.data.data.studentUSN);
    } catch (error) {
      console.log(error);
    }


  }

  const [columns, setColumns] = React.useState([
    {
      field: "id",
      headerName: "NO. ",
      flex:1,
      sortable:false,
      headerAlign:"center",
      align:"center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO."}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "title",
      headerName: "FEES TITLE ",
      flex:1,
      sortable:false,
      headerAlign:"center",
      align:"center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"FEES TITLE"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "amount",
      headerName: "AMOUNT",
      flex:1,
      sortable:false,
      headerAlign:"center",
      align:"center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"AMOUNT"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "receipt",
      headerName: "RECEIPT No.",
      flex:1,
      sortable:false,
      headerAlign:"center",
      align:"center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"RECIPT NO."}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "paid",
      headerName: "PAID",
      flex:1,
      sortable:false,
      headerAlign:"center",
      align:"center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"PAID"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "concession",
      headerName: "CONCESSION",
      flex:1,
      sortable:false,
      headerAlign:"center",
      align:"center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"CONCESSION"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "remark",
      headerName: "REMARKS",
      flex:1,
      sortable:false,
      headerAlign:"center",
      align:"center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"REMARKS"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "balance",
      headerName: "BALANCE",
      flex:1,
      sortable:false,
      headerAlign:"center",
      align:"center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"BALANCE"}</strong>,
      headerClassName: "super-app-theme--header",
    },
  ]);
  const [rows, setRows] = React.useState([]);

//   const [staff, setStaff] = React.useState([]);

//   const onStaffLoad = async () => {
//     try {
//       let response = await StaffService.staffListAll();
//       console.log(response.data.data);

//       setStaff(response.data.data);
//       let staff_array = [];
//       response.data.data.forEach(function (data) {
//         console.log(data);
//         staff_array.push({
//           id: data.staffID,
//           ssid: data.ssid,
//           staffName: data.staffName,
//           designantion: data.designation,
//           monthlySalary: data.monthlySalary,
//           changeSalary: " ",
//         });
//       });
//       setRows(staff_array);
//       console.log(rows);
//     } catch (error) {
//       console.log(error);
//       // alert("Failure");
//     }
//   };

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="studentinfo" /> */}
      
      <Box>
        <Box sx={{ width: "100%", paddingLeft: "30px", boxSizing: "border-box", mt: 2}}>
          <Grid container>
            <Grid item md={10} xs={8}>
              <Box
                display={"flex"}
                alignContent={"center"}
                justifyContent={"center"}


              >
                <Stack direction="column" sx={{ width: "100%",  boxSizing: "border-box", mt: 2 }}>
                  <Item >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      STUDENT NAME
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      {studentInfo ? studentInfo.studentName : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
              <Box
                sx={{ width: "100%", boxSizing: "border-box", my: "1%" }}
              >
                <Grid  container spacing={{ xs: 1, sm: 1, md: 2 }} sx={{ width: "100%", boxSizing: "border-box", mt: 2 }}>
                  <Grid item md={1} xs={3} >
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        USN
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentUSN : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        STANDARD | DIVISION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.standardName +
                          " | " +
                          studentInfo.divisionID
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={1} xs={3}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        CLASS TR
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {"--"}
                        {/* {studentInfo ? studentInfo.classteacher : "--"} */}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        MEDICAL ATTENTION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.studentMedicalAttentionDetail
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        BLOOD GROUP
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentBloodGroup : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        DOB
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentDOB : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  {/* <Typography
              variant="body1"
              width="250px"
              sx={{ textAlign: "center" }}
            >
              {studentInfo.studentAge + " Age"}
            </Typography> */}
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item>
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        AGE
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentAge : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={2} xs={4}>
              <Box display={"flex"} justifyContent={"center"}  sx={{mt:"2%"}}>
                <Avatar
                  alt="Remy Sharp"
                  src={studentInfo ? studentInfo.studentProfileUrl : ""}
                  //   id={element.staffID}
                  variant="square"
                  sx={{
                    width: 150,
                    height: 190,
                    mx: 2,
                    // mt: 1,
                    border: "3px solid skyblue",
                    borderRadius: "5%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box  sx={{ width: "100%", boxSizing: "border-box", mt: 2}} paddingLeft= {{md:"30px", xs:"15px"}}>
          <Grid container spacing={{xs:2,md:""}} paddingRight={{xs:"20px", md:"58px"}}>
            {/* <Grid item md={3} xs={12}></Grid> */}
                <Grid item md={3} sm={6} xs={6}>
                  <Item >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      TOTAL FEES
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                     50000
                    </Typography>
                  </Item>
                </Grid>
                <Grid item md={3} sm={6} xs={6}>
                  <Item >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      CONCESSION
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                     80000
                    </Typography>
                  </Item>
                </Grid>
                <Grid item md={3} sm={6} xs={6}>
                  <Item >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      PAID
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                 40000
                    </Typography>
                  </Item>
                </Grid>
                <Grid item md={3} sm={6} xs={6}>
                  <Item >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      BALANCE
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      800000
                    </Typography>
                  </Item>
                </Grid>
              {/* <Grid item md={3} xs={12}></Grid> */}
            </Grid>
        </Box>
        <Grid container>
          <Grid item md={11.35} xs={11.5}>
            <Box
            sx={{
              height: 400,
              ml: "2%",
              width: "100%",
              // p: "0 20px",
              marginTop: "15px",
              // paddingLeft:"30px",
              // display:"flex",
              // justifyContent:"center",
              "& .super-app-theme--header": {
                backgroundColor: "#c0c0c0",
                //  color:'white',
                fontWeight: "bold",
                fontSize: "15px",
                border: "1px solid white",
              },
            }}
          >
            <DataGrid
              disableColumnMenu
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              // getRowHeight={() => "55px"}
            />
            </Box>
          </Grid>
        </Grid>
        
      </Box>
      
      
    </React.Fragment>
  );
}
