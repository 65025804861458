import * as React from "react";
import { Button, Box, Typography, Stack, Grid } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { StudentAttendenanceService } from "../../../services/studentAttendance";
import Paper from "@mui/material/Paper";
import { C } from "../../util/C";
import { useNavigate } from "react-router-dom";
import { AddStandarsService } from "../../../services/addStandardsService";
import AttendenceTotal from "./attendenceTotal";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";

const ItemBg = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));
const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));

export default function VariantButtonGroup(props) {
  const [isloading, setisLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  // const columns = [];
  const [selectedMonth, setMonth] = React.useState("");
  const [excel, setExcel] = React.useState([]);
  const [StandardName, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [Standard, setStandard] = React.useState([]);
  const [highlight, setHighlight] = React.useState();

  let navigate = useNavigate();
  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };

  const onMonthSelect = (e) => {
    setColumns([]);
    onstandardsLoad();
    // setStandardID();
    setRows([]);
    // setStandardName();
    setMonth(e.target.value);
    setHighlight(e.target.value);
  };

  useEffect(() => {
    console.log(props.data);
    onstandardsLoad();
    // onload();
  }, []);

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };

  function onload(data) {
    let col = [];
    col.push({
      field: "date",
      headerName: "Date",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"DATE"}</strong>,
      headerClassName: "super-app-theme--header",
    });
    data.column.forEach((rec, i) => {
      col.push({
        field: i.toString(),
        headerName: rec.division + " - Present || Absent - " + rec.total,
        flex: 1,
        headerClassName: "super-app-theme--header",
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderHeader: () => (
          <strong style={{ fontSize: 15 }}>
            {rec.division} - {rec.total} PR
            <span
              style={{
                // color: "#fff",
                padding: 2,
                backgroundColor: "#ffc0cb",
              }}
            >
              [ G ]
            </span>
            <span
              style={{
                // color: "#fff",
                padding: 2,
                backgroundColor: "#ADD8E6",
              }}
            >
              [ B ]
            </span>{" "}
            || AB
            <span
              style={{
                // color: "#fff",
                padding: 2,
                backgroundColor: "#ffc0cb",
              }}
            >
              [ G ]
            </span>
            <span
              style={{
                // color: "#fff",
                padding: 2,
                backgroundColor: "#ADD8E6",
              }}
            >
              [ B ]
            </span>
          </strong>
        ),
      });
    });

    col.push({
      field: "total_absent",
      headerName: "Total Absent",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"TOTAL ABSENT"}</strong>
      ),
    });
    col.push({
      field: "total_present",
      headerName: "Total Present",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"TOTAL PRESENT"}</strong>
      ),
    });
    setColumns(col);

    let row = [];
    data.rows.forEach((rec, i) => {
      let obj = {
        id: i,
        date: rec.date,
        total_absent: rec.totalAbsent,
        total_present: rec.totalPresent,
        0: rec.a ? rec.a : "--",
        1: rec.b ? rec.b : "--",
        2: rec.c ? rec.c : "--",
        3: rec.d ? rec.d : "--",
      };
      row.push(obj);
    });
    setRows(row);
    console.log(col);
    // console.log(row);

    setisLoading(false);
  }

  const [attendenceClass, setAttendenceClass] = React.useState([]);

  async function onStandardSelect(e, index) {
    e.preventDefault();
    setStandardName(e.target.textContent);
    setStandardID(e.target.id);
    console.log(e.currentTarget.id);
    let highlightStandardList = [...Standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index][
      "iSSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response =
        await StudentAttendenanceService.getmonthStandaredabsentreport(
          selectedMonth,
          e.target.textContent,
          data.yearName
        );
      setAttendenceClass(response.data.data);
      onload(response.data.data);
      console.log(response.data.data);
      let co = response.data.data.column.map((res)=>[
        res.division + "-"+res.total
      ]);
      // console.log(co);

      let stud = [];
  response.data.data.rows.forEach((res)=>{
        stud.push({
          DATE : res.date,
         "A-30 PRESENT[G][B] || ABSENT[G][B]": res.a,
          "TOTAL ABSENT" : res.totalAbsent,
          "TOTAL PRESENT" : res.totalPresent
        });
      });
      // console.clear();
      console.log(stud);
      setExcel(stud);
    } catch (error) {
      console.error(error);
    }

    // try {
    //   let response = await StandardDivision.StandardDivisionWiseStandard(
    //     e.currentTarget.id,
    //     data.yearName,
    //     data.yearID
    //   );
    //   C.c(response.data.data);
    //   setDivision(response.data.data);
    // } catch (error) {
    //   C.c(error.message);
    // }
  }
const schoolName = JSON.parse(localStorage.getItem("schoolDetails"));
const academicYear = JSON.parse(localStorage.getItem("academicYear"));
  const generatePDF = () => {
    const doc = new jsPDF("p","pt","a4");

    const sch = schoolName.schoolNameOnDocs.toUpperCase();
    const acad = academicYear.yearName;
    const img = schoolName.schoolLogoUrl;
    doc.setFont("helvetica", "bold");
    var fontSize = 18;
    var text = `${sch}`;
    var pageWidth = doc.internal.pageSize.getWidth();
    var textWidth =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    var centerX = (pageWidth - textWidth) / 2;
    doc.text(text, centerX, 35);

    doc.addImage(img, "JPEG", 20,10,80,70);
    doc.setFontSize(10);
    doc.text(` ${acad}`, 500, 35);
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("MONTHLY CLASSWISE ATTENDANCE REPORT", 140, 75);

    doc.text(StandardName,102,110);
    doc.text(selectedMonth.toUpperCase(),420,110);
    const headData = attendenceClass.column.map((col)=>[
      col.division + "-" + col.total+"PRESENT[G][B] || ABSENT[G][B]"
    ]);
    
    const header = [["DATE",...headData,"TOTAL ABSENT", "TOTAL PRESENT"]];
    const tableData = attendenceClass.rows.map((row)=>[
    row.date, row.a, row.totalAbsent, row.totalPresent
    ])
    doc.autoTable({
      startY: 140,
      head: header,
      body:tableData,
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
      },
      theme:"grid"
    })
    doc.save(selectedMonth+"-"+StandardName+" "+"Attendance Report .pdf" );  
  }

  const fileType = "csv";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(excel);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, " Monthly Attendance.csv");
  };


  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          backgroundColor: "lightblue",
          m: "10px 4px",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        SELECT STANDARD, DIVISION FOR ATTENDANCE REPORT
      </Typography>
      <Grid container  spacing={2}>
       <Grid item md={1} sm={2} xs={4}>
          <Button
            fullWidth
            onClick={onMonthSelect}
            value="May"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: 20,
              backgroundColor:
                 highlight === "May" ? "primary" : "lightblue",
              color:  highlight === "May" ? "white" : "black",
            }}
          >
            MAY
          </Button>
        </Grid>
        <Grid item md={1} sm={2} xs={4}>
          <Button
          fullWidth
            // onClick={onMonthSelect}
            onClick={onMonthSelect}
            value="June"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: 20,
              backgroundColor:
                 highlight === "June" ? "primary" : "lightblue",
              color:  highlight === "June" ? "white" : "black",
            }}
          >
            JUNE
          </Button>
        </Grid>
        <Grid item md={1} sm={2} xs={4}>
          <Button
            fullWidth
            // onClick={onMonthSelect}
            onClick={onMonthSelect}
            value="July"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: 20,
              backgroundColor:
                 highlight === "July" ? "primary" : "lightblue",
              color:  highlight === "July" ? "white" : "black",
            }}
          >
            JULY
          </Button>
        </Grid>
        <Grid item md={1} sm={2} xs={4}>
          <Button
            fullWidth
            onClick={onMonthSelect}
            value="August"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: 20,
              backgroundColor:
                 highlight === "August" ? "primary" : "lightblue",
              color:  highlight === "August" ? "white" : "black",
            }}
          >
            AUGUST
          </Button>
        </Grid>
        <Grid item md={1} sm={2} xs={4}>
          <Button
            fullWidth 
            onClick={onMonthSelect}
            value="September"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: 20,
              backgroundColor:
                 highlight === "September" ? "primary" : "lightblue",
              color:  highlight === "September" ? "white" : "black",
            }}
          >
            SEPTEMBER
          </Button>
        </Grid>
        <Grid item md={1} sm={2} xs={4}>
          <Button
            fullWidth
            onClick={onMonthSelect}
            value="October"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: 20,
              backgroundColor:
                 highlight === "October" ? "primary" : "lightblue",
              color:  highlight === "October" ? "white" : "black",
            }}
          >
            OCTOBER
          </Button>
        </Grid>
        <Grid item md={1} sm={2} xs={4}>
          <Button
            fullWidth
            onClick={onMonthSelect}
            value="November"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: 20,
              backgroundColor:
                 highlight === "November" ? "primary" : "lightblue",
              color:  highlight === "November" ? "white" : "black",
            }}
          >
            NOVEMBER
          </Button>
        </Grid>
        <Grid item md={1} sm={2} xs={4}>
          <Button
            fullWidth
            onClick={onMonthSelect}
            value="December"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: 20,
              backgroundColor:
                 highlight === "December" ? "primary" : "lightblue",
              color:  highlight === "December" ? "white" : "black",
            }}
          >
            DECEMBER
          </Button>
        </Grid>
        <Grid item md={1} sm={2} xs={4}>
          <Button
            fullWidth
            onClick={onMonthSelect}
            value="January"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: 20,
              backgroundColor:
                 highlight === "January" ? "primary" : "lightblue",
              color:  highlight === "January" ? "white" : "black",
            }}
          >
            JANUARY
          </Button>
        </Grid>
        <Grid item md={1} sm={2} xs={4}>
          <Button
            fullWidth
            onClick={onMonthSelect}
            value="February"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: 20,
              backgroundColor:
                 highlight === "February" ? "primary" : "lightblue",
              color:  highlight === "February" ? "white" : "black",
            }}
          >
            FEBRUARY
          </Button>
        </Grid>
        <Grid item md={1} sm={2} xs={4}>
          <Button
            fullWidth
            onClick={onMonthSelect}
            value="March"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: 20,
              backgroundColor:
                 highlight === "March" ? "primary" : "lightblue",
              color:  highlight === "March" ? "white" : "black",
            }}
          >
            March
          </Button>
        </Grid>
        <Grid item md={1} sm={2} xs={4}>
          <Button
            fullWidth
            onClick={onMonthSelect}
            value="April"
            variant="contained"
            sx={{
              fontWeight: "bold",
              borderRadius: 20,
              backgroundColor:
                 highlight === "April" ? "primary" : "lightblue",
              color:  highlight === "April" ? "white" : "black",
            }}
          >
            April
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, mt: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ItemBg>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Standard.map((element, index) => (
                  <Grid item md={2} sm={3} xs={4} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      value={element.standardName}
                      onClick={(e) => {
                        onStandardSelect(e, index);
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </ItemBg>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          mt: "15px",
          height: 423,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
            //  color:'white',
            fontWeight: "bold",
            fontSize: "15px",
            border: "1px solid white",
          },
        }}
      >
        {isloading ? (
          <Typography variant="h1"> </Typography>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            disableColumnMenu
            // components={{ Toolbar: GridToolbar }}
            // slots={{
            //   toolbar: CustomToolbar,
            // }}
            // slotProps={{ toolbar: { csvOptions } }}
          />
        )}
      </Box>
      <AttendenceTotal></AttendenceTotal>

      <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
      <Button variant="contained" size="large" sx={{borderRadius:"15px",width:"140px",borderRadius:"30px",fontWeight:"bold"}} color="error" onClick={generatePDF}>pdf</Button>
        <Button variant="contained" size="large" sx={{borderRadius:"15px",width:"140px",borderRadius:"30px", ml: 3,fontWeight:"bold"}} color="success" onClick={exportToCSV}>excel</Button>
        <Button
                  variant="contained"
                  onClick={onLaunchpad}
                  sx={{
                    backgroundColor: "#ca424f",
                    fontSize: 15,
                    fontWeight: "bold",
                    borderRadius: "30px",
                    width: "140px",
                    ml: 3,
                    "&:hover": { backgroundColor: "#ca424f" },
                  }}
                >
                  exit
                </Button>
      </Box>
    </Box>
  );
}
