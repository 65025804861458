// import * as React from "react";
// import Box from "@mui/material/Box";
// import { DataGrid } from "@mui/x-data-grid";
// import { Avatar, Button, ButtonGroup, Grid } from "@mui/material";
// import { avatar } from "@mui/material";
// import EditIcon from '@mui/icons-material/Edit';

// export default function DataGridDemo() {

//   // const [imageUrl, setImageUrl] = React.useState("");
//   // const [imageSrc, setImageSrc] = React.useState();

//   // const fileBrowseHandler = (event) => {
//   //   let value = URL.createObjectURL(event.currentTarget.files[0]);
//   //   setImageSrc(event.currentTarget.files[0]);
//   //   // C.c(value);
//   //   // C.c(filevalue);
//   //   setImageUrl(value);
//   //   // setImageSrc(filevalue);
//   //   // C.c(imageSrc);
//   // };

//   const [file,setFile] = React.useState(null);
//   const [imageUrl,setImageUrl] = React.useState(null);

//   const handleChange = (e) => {
//     setFile(e.target.files[0]);
//     setImageUrl(URL.createObjectURL(e.target.files[0]));
//   };

//   const [columns, setColumns] = React.useState([
//     { field: "id", headerName: "ID", width: 90 },
//     {
//       field: "documentName",
//       headerName: "Discription of Document",
//       width: 500,
//       editable: true,
//     },
//     {
//       field: "image",
//       headerName: "IMAGE",
//       width: 400,
//       editable: true,
//       renderCell: () => {
//         return(
//           <>
//      {/* <Avatar
//             alt="A"
//             src={imageUrl}
//             sx={{ width: 120, height: 120, }} /><input
//               style={{ display: "none", ml: 5 }}
//               id="contained-button-file"
//               type="file"
//               accept="image/*"
//               name="bookImg" />
//               <label>
//               <Button
//                variant="contained"
//                color="primary"
//                component="span">
//               Upload
//             </Button>
//        </label>

//         )
//     } */}

//     {
//       field: "edit",
//         headerName: "Edit",
//         sortable: false,
//         width: 130,
//         disableClickEventBubbling: true,
//         renderCell: () => {
//           return (
//             <Button variant="contained" color="primary" startIcon={<EditIcon />}>
//               Edit
//             </Button>
//           );
//       },
//     },
//     // {
//     //   field: "edit/update",
//     //   headerName: "EDIT/UPDATE",
//     //   width: 150,
//     //   editable: true,
//     // },

//     // {
//     //   field: "age",
//     //   headerName: "Age",
//     //   type: "number",
//     //   width: 110,
//     //   editable: true,
//     // },
//     // {
//     //   field: "fullName",
//     //   headerName: "Full name",
//     //   description: "This column has a value getter and is not sortable.",
//     //   sortable: false,
//     //   width: 160,
//     //   valueGetter: (params) =>
//     //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
//     // },
//   ]);
//   const [rows, setRows] = React.useState([
//     // {
//     //   id: 1,
//     //   lastName: "Snow",
//     //   firstName: "Jon",
//     //   age: 35,

//     // },
//     // { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
//     // { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
//     // { id: 4, lastName: "Lannister", firstName: "Jaime", age: 45 },
//   ]);

//   React.useEffect(() => {}, []);

//   const onAddRoW = (e) => {
//     console.log(rows.length);
//     let new_row = {
//       id: rows.length + 1,
//       lastName: "",
//       firstName: "",
//       age: 0,
//     };
//     setRows((rows) => [...rows, new_row]);
//     console.log(rows);
//   };
//   return (
//     <Box sx={{ height: 400, width: "100%" }}>
//       <DataGrid
//         rowHeight={150}
//         rows={rows}
//         columns={columns}
//         pageSize={5}
//         rowsPerPageOptions={[5]}
//       />
//       <Button variant="contained" color="success" onClick={onAddRoW}>
//         Add Row
//       </Button>

//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <ButtonGroup
//             variant="outlined"
//             aria-label="outlined button group"
//             sx={{ mt:"35px", ml:"100px"}}
//           >
//             <Button>SCREEN SHOT PDF </Button>
//             <Button>SCREEN SHOT PRINT</Button>
//             <Button>EDIT INFO</Button>
//             <Button>UPDATE INFO</Button>
//             <Button>EXCEL SHEET</Button>
//             <Button>PRINT SHEET</Button>
//             <Button sx={{backgroundColor:'red',color:'yellow'}}>EXIT</Button>
//           </ButtonGroup>
//         </Grid>
//       </Grid>
//     </Box>
//     </>
//   );
// }

import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Button, ButtonGroup, Grid, Avatar } from "@mui/material";
import { C } from "../../util/C";
import { StaffService } from "../../../services/StaffService";
import { CompressOutlined } from "@mui/icons-material";



export default function DataGridDemo(props) {
 
  const [columns, setColumns] = React.useState([
    {
      field: "id",
      headerName: "NO",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
      headerClassName: "super-app-theme--header",
      align: "center",
    },
    {
      field: "description",
      headerName: "DESCRIPTION OF DOCUMENT",
      flex : 1,
      headerAlign:"center",
      align:"center",
      sortable: false,
      editable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"DESCRIPTION OF DOCUMENT"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "image",
      headerName: "IMAGE",
      flex: 1,
      height: "20%",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"IMAGE"}</strong>,
      headerClassName: "super-app-theme--header",
      renderCell: (rec) => {
        return (
          <Box>
            <Avatar
              component="a"
              variant="square"
              alt="book image"
              src={rec.value}
              sx={{ width: 56, height: 56 }}
              downloadFile
            />
          </Box>
        );
      },
    },

    {
      field: "imageDownload",
      headerName: "DOWNLOAD",
      flex: 1,
      headerAlign: "center",
      align: "center",
      height: "20%",
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"DOWNLOAD"}</strong>
      ),
      renderCell: (rec) => {
        return (
          // <FormItem>
          <>
            <Button
              variant="contained"
              component="label"
              sx={{ fontWeight: "bold" }}
            >
              <a href={rec.value} download style={{ color: "white" }}>
                Download
              </a>
            </Button>
            {/* <Avatar alt="A" src={imageUrl} sx={{ width: 50, height: 50 }} /> */}
            {/* <input
              style={{ display: "none", ml: 5 }}
              id="contained-button-file"
              type="file"
              accept="image/*"
              name="bookImg"
              onChange={fileBrowseHandler}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                size="small"
              >
                Upload
              </Button>
            </label> */}
          </>
          // </FormItem>
        );
      },
    },
  ]);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    console.log(props.data)
    onDocumentLoad();
  }, []);

  const onDocumentLoad = async () => {
    let staffDoc = [];
    let ssid = props.data.ssid;
    console.log(ssid);
    try{
      let response = await StaffService.getStaffDocumentsRemarks(ssid);
      console.log(response.data.data);
      response.data.data.forEach((rec,i) => {
        staffDoc.push({
          id: i+1,
          description: rec.description.toUpperCase(),
          image: rec.documentImgURL,
          imageDownload : rec.documentImgURL,
        });
      });
      setRows(staffDoc);
    }catch(error) {
      console.log(error);
    }
  }
  // const onAddRoW = (e) => {
  //   console.log(rows.length);
  //   let new_row = {
  //     id: rows.length + 1,
  //     lastName: "",
  //     firstName: "",
  //     age: 0,
  //   };
  //   setRows((rows) => [...rows, new_row]);
  //   console.log(rows);
  // };

  

 
  return (
    <Box sx={{ height: 410, width: "100%" }}  >
      <DataGrid
      sx={{
        width: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "#c0c0c0",
              // color:'white',
              fontWeight: "bold",
              fontSize: "15px",
              border: "1px solid white",
            },
      }}
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
       disableColumnMenu
      />
      
      
    </Box>
  );
}
