import * as React from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { User } from "../../services/User";
// import { Ayear } from "../util/Year";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"By clicking Login you agree to the  "}
      <Link color="inherit" href="https://mui.com/">
        Terms and Conditions
      </Link>{" "}
      {"of the use of the Online School Management Software"}
      {"."}
    </Typography>
  );
}

export default function Login() {
  // * "========================Login Route===================================="
  let navigate = useNavigate();

  const BuildSchoolLogin = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // try {
    //   let response = await LoginService.loginuser({
    //     emailID: data.get("userEmailID"),
    //     passWord: data.get("passWord"),
    //   });
    try {
      let response = await User.UserLogin({
        userEmailID: data.get("userEmailID"),
        userPassword: data.get("passWord"),
      });

      console.log(response);

      if (response.data.success) {
        console.log("succss");
        // alert("Successfully Login ");
        navigate("/registerschool", { replace: true });
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.log("error");
      alert("Failure");
    }
  };
  // * "========================Login Route===================================="

  return (
    <Box
      sx={{
        border: "2px solid black",
        height: "700px",
        backgroundImage: `url("book7.jpeg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          backgroundColor: "#f1f8e9",
          border: "2px solid green",
          borderRadius: "10px",
          mt: 10,
        }}
      >
        <Box
          component="form"
          onSubmit={BuildSchoolLogin}
          noValidate
          sx={{
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            margin="normal"
            // required
            fullWidth
            id="userEmailID1"
            label="Login ID"
            name="userEmailID"
            // autoComplete="email"
            // autoFocus
          />
          <TextField
            margin="normal"
            // required
            fullWidth
            name="passWord"
            label="VERIFY"
            type="password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            // onClick={BuildSchoolLogin}
          >
            LOGIN
          </Button>
        </Box>
        <Copyright sx={{ mt: 1, mb: 4 }} />
      </Container>
    </Box>
  );
}
