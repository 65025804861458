import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Topbar from "../../MainTopbar/Topbar";
import { AddStandarsService } from "../../../services/addStandardsService";
import DeleteModal from "../Dashboard/popup/deletePopUp";
import AlertModal from "../Dashboard/popup/alertPopUp";
import SucessModal from "../Dashboard/popup/sucessPopUp";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "2px solid skyblue",
  display: "flex",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid red",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

const onlyCharaters = (e, callback) => {
  const value = e.target.value
    ? e.target.value.replace(/[^0-9a-zA-Z ]+/gi, "")
    : "";

  if (e.target.value !== value) {
    e.target.value = value;
  }

  if (typeof callback === "function") {
    return callback(value);
  }
};

export default function AddStandard() {
  // * =============browser back button redirection to dashboard================
  const Navigate = useNavigate();
  const [finishStatus, setfinishStatus] = React.useState(false);
  const textInput = React.useRef("");

  const [status, setStatus] = React.useState();

  const onLoadBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.buildSchoolStatusListAll(
        data.yearName
      );
      // console.log(response.data.data[0]);
      setStatus(response.data.data[0].buildSchoolStatus);
    } catch (error) {
      console.error(error);
    }
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to go back to Dashboard?")) {
        setfinishStatus(true);
        // your logic
        Navigate("/dashborad");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  React.useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  // * ==================browser back button logic ends===========================

  let [yearID, setYearID] = React.useState();
  let [yearName, setYearName] = React.useState();

  React.useEffect(() => {
    let academicYear = JSON.parse(localStorage.getItem("academicYear"));
    setYearID(academicYear.yearID);
    setYearName(academicYear.yearName);
    console.log(yearID, yearName);
    onStandardload();
    onLoadBuildSchool();
  }, []);

  let navigate = useNavigate();

  const [disable, setDisble] = React.useState("false");

  // * =======================window Pop=====================================
  // *delete
  const [openDelete, setDelete] = React.useState(false);
  const handleDeleteOpen = () => setDelete(true);
  const handleDeleteClose = () => setDelete(false);

  // *alert
  const [openAlert, setAlert] = React.useState(false);
  const [alertVal, setAlertVal] = React.useState("");
  const handleAlertOpen = () => setAlert(true);
  const handleAlertClose = () => setAlert(false);

  // *sucess
  const [openSucess, setSucess] = React.useState(false);
  const handleSucessOpen = () => setSucess(true);
  const handleSucessClose = () => setSucess(false);

  // * =======================window Popup end=====================================
  const onStandardload = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    console.log("hello");

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      console.log(response.data.data);

      updateBooks(response.data.data);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  const onNextPage = () => {
    console.log("onNextPage");
    navigate("/standards", { replace: true });
  };

  const [standardList, updateBooks] = React.useState([]);
  const [standardName, setStandardName] = React.useState([]);
  const [val, setVal] = React.useState();

  // const onInputChange = (e) => {
  //   const { value } = e.target;
  //   if (isLetters(value)) {
  //     setVal(value);
  //   }
  // };

  const onAddStandard = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    console.log(data.get("standard").toUpperCase());

    let standard = data.get("standard");

    if (standard.trim().length === 0) {
      console.log(data.get("standard"));
      setAlertVal("Please enter a valid standard");
      handleAlertOpen();
      // alert("Please enter a valid standard");
    } else {
      console.log("not isEmpty");
      try {
        let response = await AddStandarsService.createAddStandards({
          standardName: data.get("standard").toUpperCase(),
          yearID: yearID,
          yearName: yearName,
        });

        let nObj = {
          standardName: response.data.data.standardName,
          standardID: response.data.data.standardID,
        };

        updateBooks([...standardList, nObj]);

        if (response.data.success) {
          console.log("success");
          console.log("Designation Added Successfully");
          setVal("");
        } else {
          alert(response.data.message);
        }
        data.set("standard", "v");
      } catch (error) {
        console.log(data.get("standard").toUpperCase() + " Already Exist !");
        setAlertVal(data.get("standard").toUpperCase() + " Already Exist !");
        handleAlertOpen();
        // alert("Failure");
      }
    }
    textInput.current.value = "";

    setTimeout(() => {
      handleAlertClose();
    }, 2000);
  };

  const onStandardSelect = async (e) => {
    console.log(e);
    console.log(e.currentTarget.accessKey);
    let standardID = e.currentTarget.accessKey;
    setStandardName(e.currentTarget.name);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      handleDeleteOpen();
      let response = await AddStandarsService.deleteStandard(
        e.currentTarget.accessKey,
        data.yearName
      );

      // onStandardload();
      // debugger;
      // updateBooks(
      //   standardList.filter((item) => item.standardID !== standardID)
      // );
      // console.log(response.data.status);
      // if (response.data.status) {
      updateBooks(
        standardList.filter((item) => item.standardID !== standardID)
      );
      // }
      console.log(standardList);
    } catch (error) {
      console.log("error");
      alert("Failure");
    }

    setTimeout(() => {
      handleDeleteClose();
    }, 2000);
  };

  return (
    <div>
      <Topbar
        text={
          "Step 1 of Building School, Create Standards and Division – Please Click to Select Standard, Enter Division and Seating Capacity"
        }
      />
      <Box
        sx={{
          flexGrow: 1,
          border: "2px solid skyblue",
          borderRadius: 5,
          height: 300,
          overflow: "auto",
          p: 2,
          m: 1,
        }}
      >
        <Grid container spacing={2}>
          {standardList.map((element) => (
            <Grid key={element.standardID} item md={3} xs={4}>
              <Item
                component="form"
                sx={{
                  background: "#aaecac",
                  "&:hover": {
                    background: "#035e06",
                    color: "white",
                  },
                }}
                // onMouseOver={() => {
                //   console.log("over");
                //   setDisble("true");
                // }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {element.standardName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      aria-label="delete"
                      accessKey={element.standardID}
                      name={element.standardName}
                      onClick={onStandardSelect}
                      size="small"
                      color="error"
                      disabled={status == "true" ? true : false}
                    >
                      <CancelIcon fontSize="inherit"></CancelIcon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          height: 100,
          p: 2,
          m: 1,
        }}
      >
        <Box component="form" onSubmit={onAddStandard}>
          <Grid container spacing={1}>
            <Grid item md={2} xs={3}>
              <TextField
                label="Enter Standard"
                variant="outlined"
                size="small"
                name="standard"
                inputRef={textInput}
                onInput={(e) => onlyCharaters(e)}
                inputProps={{ style: { textTransform: "uppercase" } }}
                // value={val}
              />
            </Grid>
            <Grid item md={2} xs={5}>
              <Button
              fullWidth
                type="submit"
                variant="contained"
                color="success"
                sx={{
                  py: 0.9,
                  borderRadius: "25px",
                }}
                disabled={
                  standardList.length == 25 || status == "true" ? true : false
                }
              >
                Add Standard Name
              </Button>
            </Grid>
            {/* <Grid item md={5} xs={0.1} border={"2px solid black"}></Grid> */}
            <Grid item md={6} xs={4} display={"flex"} justifyContent={"flex-end"}>
             <Box>
              <Button
                fullWidth
                  onClick={onNextPage}
                  variant="contained"
                  disabled={standardList == 0 ? true : false}
                  sx={{
                    py: 0.9,
                    borderRadius: "25px",
                    px: 5,
                  }}
                >
                  Next Page
                </Button>
             </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Modal
        open={openSucess}
        onClose={handleSucessClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <SucessModal name={standardName} />
        </div>
      </Modal>
      <Modal
        open={openAlert}
        // onClose={handleAlertClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <AlertModal text={alertVal} />
        </div>
      </Modal>
      <Modal
        open={openDelete}
        // onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <DeleteModal name={standardName} />
        </div>
      </Modal>
    </div>
  );
}
// ========================================
{
  /* <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            ❌ Deleted {standardName}
          </Typography>
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */
}
{
  /* <Button variant="outlined" color="error" onClick={handleClose}>
            Okay
          </Button>
        </Box>
      </Modal> */
}
