import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ClassWise from "./ClassWise";
import Remainder from "./Remainder";
import Summary from "./Summary";
import Collection from "./Collection";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {/* <LaunchpadTopBar id="fees" /> */}
      <Box>
        <Box sx={{ width: "100%", bgcolor: "background.paper", display:"flex", justifyContent:"center"}}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false} 
            aria-label="scrollable prevent tabs example"
          >
            <Tab
              label="Collections"
              {...a11yProps(0)}
              sx={{ fontWeight: "bold", fontSize: 15 }}
            />
            <Tab
              label="Classwise"
              {...a11yProps(1)}
              sx={{ fontWeight: "bold", fontSize: 15 }}
            />
            <Tab
              label="Summary"
              {...a11yProps(2)}
              sx={{ fontWeight: "bold", fontSize: 15 }}
            />
            <Tab
              label="Reminder"
              {...a11yProps(3)}
              sx={{ fontWeight: "bold", fontSize: 15 }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Collection />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ClassWise />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* <Summary /> */}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {/* <Remainder /> */}
        </TabPanel>
      </Box>
    </div>
  );
}
