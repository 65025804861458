import { useEffect, useState } from "react";
import { AddStandarsService } from "../../../services/addStandardsService";
import { Avatar, Box, Button, Grid, Typography, Modal, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { StaffService } from "../../../services/StaffService";
import { StandardDivision } from "../../../services/StandardDIvision";
import { TimeTable } from "../../../services/TimeTable";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid orange",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

function TeacheRoutine() {
  const [StandardList, setStandardList] = useState([]);
  const [SelectStandard, setSelectStandard] = useState("");

  const [divisionList, setDivisionList] = useState([]);
  const [SelectDivision, setSelectDivision] = useState("");

  const [staffList, setStaffList] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState("");

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [timeTableMaster, setTimeTableMaster] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  let navigate = useNavigate();
  const onLogout = () => {
    console.log("onLogout");
    navigate("/launchpad", { replace: true });
  };


  useEffect(() => {
    onstandardsLoad();
    onStaffLoad();
  }, []);

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      setStandardList(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onStaffLoad = async () => {
    try {
      let response = await StaffService.staffListAll();
      setStaffList(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
    // setStaff(stafD);
  };

  const onSelectStandard = async (e) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    if (StandardList.length == 0) {
      return;
    }
    const found = StandardList.find(
      (element) => element.standardID == e.target.id
    );
    setSelectStandard(found);
    try {
      let response = await StandardDivision.StandardDivisionWiseStandard(
        e.currentTarget.id,
        data.yearName,
        data.yearID
      );
      setDivisionList(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onSelectDivision = async (e) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    if (divisionList.length == 0) {
      return;
    }
    const found = divisionList.find(
      (element) => element.divisionName == e.target.id
    );
    setSelectDivision(found);

   

   

    
    // setColumns(periodColumn);

    // setRows(dayColumn);
  };

  const onSelectStaff = async(e) => {
    setRows([]);
    setColumns([]);
    const found = staffList.find((element) => element.staffID == e.target.id);
    // if (found.staffSubjectList.length == 0) {
    //   return;
    // }
    setSelectedStaff(found);
    let daysList = [];
    let typeList = [];
    let dayColumn = [];
    let data = JSON.parse(localStorage.getItem("academicYear"));
    // console.log(found);
    try {
      let response = await TimeTable.StaffStandardTimeTable(
        found.staffID,
        found.staffName,
        data.yearName
      );
      console.log(response.data)

      daysList = response.data.data.daysList;
      if (response.data.data.titleList == null) {
        alert("Title List is null can't create table");
      } else {
        typeList = response.data.data.titleList;
        alert("Table Created");
      }
      setTimeTableMaster(response.data.data.timeTableMasterList);
      console.log(response.data.data.timeTableMasterList);
      const { Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday } =
        response.data.data.timeTableMasterList;
      console.log(Monday);
      // console.log(Tuesday);
      // console.log(Wednesday);
      // console.log(Thursday);
      // console.log(Friday);
      // console.log(Saturday);
      // console.log(Sunday);
      if (Monday) {
        let monObj = {
          id: 0,
          Timetable: "Mon",
          Period0: "---",
          Period1: "---",
          Period2: "---",
          Period3: "---",
          Period4: "---",
          Period5: "---",
          Period6: "---",
          Period7: "---",
          Period8: "---",
          Period9: "---",
          Period10: "---",
          Period11: "---",
          Period12: "---",
          Period13: "---",
          Period14: "---",
          timeTableID: "",
        };
        Monday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0" && element.subjectName !== null) {
            console.log("if", element); 
            monObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName ;
            // } else {
            //   console.log("else");
            //   monObj.Period0 = "--";
          }
          // else{
          //   monObj.Period0 = "Not Assigned"
          // }
          if (element.title == "Period 1" && element.subjectName !== null) {
            monObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period1 = "--";
          }
          //  else{
          //   monObj.Period1 = "Not Assigned"
          // }
          if (element.title == "Period 2" && element.subjectName !== null) {
            monObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period2 = "--";
          }
          //  else{
          //   monObj.Period2 = "Not Assigned"
          // }
          if (element.title == "Period 3" && element.subjectName !== null) {
            monObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period3 = "--";
          }
          //  else{
          //   monObj.Period3 = "Not Assigned"
          // }
          if (element.title == "Period 4" && element.subjectName !== null) {
            monObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period4 = "--";
          }
          //  else{
          //   monObj.Period4 = "Not Assigned"
          // }
          if (element.title == "Period 5" && element.subjectName !== null) {
            monObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period5 = "--";
          }
          //  else{
          //   monObj.Period5 = "Not Assigned"
          // }
          if (element.title == "Period 6" && element.subjectName !== null) {
            monObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period6 = "--";
          }
          //  else{
          //   monObj.Period6 = "Not Assigned"
          // }
          if (element.title == "Period 7" && element.subjectName !== null) {
            monObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period7 = "--";
          }
          //  else{
          //   monObj.Period7 = "Not Assigned"
          // }
          if (element.title == "Period 8" && element.subjectName !== null) {
            monObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period8 = "--";
          }
          //  else{
          //   monObj.Period8 = "Not Assigned"
          // }
          if (element.title == "Period 9" && element.subjectName !== null) {
            monObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period9 = "--";
          }
          //  else{
          //   monObj.Period9 = "Not Assigned"
          // }
          if (element.title == "Period 10" && element.subjectName !== null) {
            monObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period10 = "--";
          }
          //  else{
          //   monObj.Period10 = "Not Assigned"
          // }
          if (element.title == "Period 11" && element.subjectName !== null) {
            monObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period11 = "--";
          }
          //  else{
          //   monObj.Period11 = "Not Assigned"
          // }
          if (element.title == "Period 12" && element.subjectName !== null) {
            monObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period12 = "--";
          }
          //  else{
          //   monObj.Period12 = "Not Assigned"
          // }
          if (element.title == "Period 13" && element.subjectName !== null) {
            monObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period13 = "--";
          }
          //  else{
          //   monObj.Period13 = "Not Assigned"
          // }
          if (element.title == "Period 14" && element.subjectName !== null) {
            monObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   monObj.Period14 = "--";
          }
          //  else{
          //   monObj.Period14 = "Not Assigned"
          // }
        });
        dayColumn.push(monObj);
      }
      if (Tuesday) {
        let tueObj = {
          id: 1,
          Timetable: "Tue",
          Period0: "---",
          Period1: "---",
          Period2: "---",
          Period3: "---",
          Period4: "---",
          Period5: "---",
          Period6: "---",
          Period7: "---",
          Period8: "---",
          Period9: "---",
          Period10: "---",
          Period11: "---",
          Period12: "---",
          Period13: "---",
          Period14: "---",
        };
        Tuesday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0" && element.subjectName !== null) {
            tueObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period0 = "--";
          }
          //  else{
          //   tueObj.Period0 = "Not Assigned"
          // }
          if (element.title == "Period 1" && element.subjectName !== null) {
            tueObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period1 = "--";
          }
          //  else{
          //   tueObj.Period1 = "Not Assigned"
          // }
          if (element.title == "Period 2" && element.subjectName !== null) {
            tueObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period2 = "--";
          }
          //  else{
          //   tueObj.Period2 = "Not Assigned"
          // }
          if (element.title == "Period 3" && element.subjectName !== null) {
            tueObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period3 = "--";
          }
          //  else{
          //   tueObj.Period3 = "Not Assigned"
          // }
          if (element.title == "Period 4" && element.subjectName !== null) {
            tueObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period4 = "--";
          }
          //  else{
          //   tueObj.Period4 = "Not Assigned"
          // }
          if (element.title == "Period 5" && element.subjectName !== null) {
            tueObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period5 = "--";
          }
          //  else{
          //   tueObj.Period5 = "Not Assigned"
          // }
          if (element.title == "Period 6" && element.subjectName !== null) {
            tueObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period6 = "--";
          }
          //  else{
          //   tueObj.Period6 = "Not Assigned"
          // }
          if (element.title == "Period 7" && element.subjectName !== null) {
            tueObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period7 = "--";
          }
          //  else{
          //   tueObj.Period7 = "Not Assigned"
          // }
          if (element.title == "Period 8" && element.subjectName !== null) {
            tueObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period8 = "--";
          }
          //  else{
          //   tueObj.Period8 = "Not Assigned"
          // }
          if (element.title == "Period 9" && element.subjectName !== null) {
            tueObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period9 = "--";
          }
          //  else{
          //   tueObj.Period9 = "Not Assigned"
          // }
          if (element.title == "Period 10" && element.subjectName !== null) {
            tueObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period10 = "--";
          }
          //  else{
          //   tueObj.Period10 = "Not Assigned"
          // }
          if (element.title == "Period 11" && element.subjectName !== null) {
            tueObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period11 = "--";
          }
          //  else{
          //   tueObj.Period11 = "Not Assigned"
          // }
          if (element.title == "Period 12" && element.subjectName !== null) {
            tueObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period12 = "--";
          }
          //  else{
          //   tueObj.Period12 = "Not Assigned"
          // }
          if (element.title == "Period 13" && element.subjectName !== null) {
            tueObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period13 = "--";
          }
          //  else{
          //   tueObj.Period13 = "Not Assigned"
          // }
          if (element.title == "Period 14" && element.subjectName !== null) {
            tueObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   tueObj.Period14 = "--";
          }
          //  else{
          //   tueObj.Period14 = "Not Assigned"
          // }
        });
        dayColumn.push(tueObj);
      }
      if (Wednesday) {
        let wedObj = {
          id: 2,
          Timetable: "Wed",
          Period0: "---",
          Period1: "---",
          Period2: "---",
          Period3: "---",
          Period4: "---",
          Period5: "---",
          Period6: "---",
          Period7: "---",
          Period8: "---",
          Period9: "---",
          Period10: "---",
          Period11: "---",
          Period12: "---",
          Period13: "---",
          Period14: "---",
        };
        Wednesday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0" && element.subjectName !== null) {
            wedObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period0 = "--";
          }
          //  else{
          //   wedObj.Period0 = "Not Assigned"
          // }
          if (element.title == "Period 1" && element.subjectName !== null) {
            wedObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period1 = "--";
          }
          //  else{
          //   wedObj.Period1 = "Not Assigned"
          // }
          if (element.title == "Period 2" && element.subjectName !== null) {
            wedObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period2 = "--";
          }
          //  else{
          //   wedObj.Period2 = "Not Assigned"
          // }
          if (element.title == "Period 3" && element.subjectName !== null) {
            wedObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period3 = "--";
          }
          //  else{
          //   wedObj.Period3 = "Not Assigned"
          // }
          if (element.title == "Period 4" && element.subjectName !== null) {
            wedObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period4 = "--";
          }
          //  else{
          //   wedObj.Period4 = "Not Assigned"
          // }
          if (element.title == "Period 5" && element.subjectName !== null) {
            wedObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period5 = "--";
          }
          //  else{
          //   wedObj.Period5 = "Not Assigned"
          // }
          if (element.title == "Period 6" && element.subjectName !== null) {
            wedObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period6 = "--";
          }
          //  else{
          //   wedObj.Period6 = "Not Assigned"
          // }
          if (element.title == "Period 7" && element.subjectName !== null) {
            wedObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period7 = "--";
          }
          //  else{
          //   wedObj.Period7 = "Not Assigned"
          // }
          if (element.title == "Period 8" && element.subjectName !== null) {
            wedObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period8 = "--";
          }
          //  else{
          //   wedObj.Period8 = "Not Assigned"
          // }
          if (element.title == "Period 9" && element.subjectName !== null) {
            wedObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period9 = "--";
          }
          //  else{
          //   wedObj.Period9 = "Not Assigned"
          // }
          if (element.title == "Period 10" && element.subjectName !== null) {
            wedObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period10 = "--";
          }
          //  else{
          //   wedObj.Period10 = "Not Assigned"
          // }
          if (element.title == "Period 11" && element.subjectName !== null) {
            wedObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period11 = "--";
          }
          //  else{
          //   wedObj.Period11 = "Not Assigned"
          // }
          if (element.title == "Period 12" && element.subjectName !== null) {
            wedObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period12 = "--";
          }
          //  else{
          //   wedObj.Period12 = "Not Assigned"
          // }
          if (element.title == "Period 13" && element.subjectName !== null) {
            wedObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period13 = "--";
          }
          //  else{
          //   wedObj.Period13 = "Not Assigned"
          // }
          if (element.title == "Period 14" && element.subjectName !== null) {
            wedObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   wedObj.Period14 = "--";
          }
          //  else{
          //   wedObj.Period14 = "Not Assigned"
          // }
        });
        dayColumn.push(wedObj);
      }
      if (Thursday) {
        let thuObj = {
          id: 3,
          Timetable: "Thu",
          Period0: "---",
          Period1: "---",
          Period2: "---",
          Period3: "---",
          Period4: "---",
          Period5: "---",
          Period6: "---",
          Period7: "---",
          Period8: "---",
          Period9: "---",
          Period10: "---",
          Period11: "---",
          Period12: "---",
          Period13: "---",
          Period14: "---",
        };
        Thursday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0" && element.subjectName !== null) {
            thuObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period0 = "--";
          }
          //  else{
          //   thuObj.Period0 = "Not Assigned"
          // }
          if (element.title == "Period 1" && element.subjectName !== null) {
            thuObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period1 = "--";
          }
          //  else{
          //   thuObj.Period1 = "Not Assigned"
          // }
          if (element.title == "Period 2" && element.subjectName !== null) {
            thuObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period2 = "--";
          }
          //  else{
          //   thuObj.Period2 = "Not Assigned"
          // }
          if (element.title == "Period 3" && element.subjectName !== null) {
            thuObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period3 = "--";
          }
          //  else{
          //   thuObj.Period3 = "Not Assigned"
          // }
          if (element.title == "Period 4" && element.subjectName !== null) {
            thuObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period4 = "--";
          }
          //  else{
          //   thuObj.Period4 = "Not Assigned"
          // }
          if (element.title == "Period 5" && element.subjectName !== null) {
            thuObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period5 = "--";
          }
          //  else{
          //   thuObj.Period5 = "Not Assigned"
          // }
          if (element.title == "Period 6" && element.subjectName !== null) {
            thuObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period6 = "--";
          }
          //  else{
          //   thuObj.Period6 = "Not Assigned"
          // }
          if (element.title == "Period 7" && element.subjectName !== null) {
            thuObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period7 = "--";
          }
          //  else{
          //   thuObj.Period7 = "Not Assigned"
          // }
          if (element.title == "Period 8" && element.subjectName !== null) {
            thuObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period8 = "--";
          }
          //  else{
          //   thuObj.Period8 = "Not Assigned"
          // }
          if (element.title == "Period 9" && element.subjectName !== null) {
            thuObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period9 = "--";
          }
          //  else{
          //   thuObj.Period9 = "Not Assigned"
          // }
          if (element.title == "Period 10" && element.subjectName !== null) {
            thuObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period10 = "--";
          }
          //  else{
          //   thuObj.Period10 = "Not Assigned"
          // }
          if (element.title == "Period 11" && element.subjectName !== null) {
            thuObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period11 = "--";
          }
          //  else{
          //   thuObj.Period11 = "Not Assigned"
          // }
          if (element.title == "Period 12" && element.subjectName !== null) {
            thuObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period12 = "--";
          }
          //  else{
          //   thuObj.Period12 = "Not Assigned"
          // }
          if (element.title == "Period 13" && element.subjectName !== null) {
            thuObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period13 = "--";
          }
          //  else{
          //   thuObj.Period13 = "Not Assigned"
          // }
          if (element.title == "Period 14" && element.subjectName !== null) {
            thuObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   thuObj.Period14 = "--";
          }
          //  else{
          //   thuObj.Period14 = "Not Assigned"
          // }
        });
        dayColumn.push(thuObj);
      }
      if (Friday) {
        let friObj = {
          id: 4,
          Timetable: "Fri",
          Period0: "---",
          Period1: "---",
          Period2: "---",
          Period3: "---",
          Period4: "---",
          Period5: "---",
          Period6: "---",
          Period7: "---",
          Period8: "---",
          Period9: "---",
          Period10: "---",
          Period11: "---",
          Period12: "---",
          Period13: "---",
          Period14: "---",
        };
        Friday.forEach((element) => {
          if (element.title == "Period 0" && element.subjectName !== null) {
            friObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period0 = "--";
          }
          //  else{
          //   friObj.Period0 = "Not Assigned"
          // }
          if (element.title == "Period 1" && element.subjectName !== null) {
            friObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period1 = "--";
          }
          //  else{
          //   friObj.Period1 = "Not Assigned"
          // }
          if (element.title == "Period 2" && element.subjectName !== null) {
            friObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period2 = "--";
          }
          //  else{
          //   friObj.Period2 = "Not Assigned"
          // }
          if (element.title == "Period 3" && element.subjectName !== null) {
            friObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period3 = "--";
          }
          //  else{
          //   friObj.Period3 = "Not Assigned"
          // }
          if (element.title == "Period 4" && element.subjectName !== null) {
            friObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period4 = "--";
          }
          //  else{
          //   friObj.Period4 = "Not Assigned"
          // }
          if (element.title == "Period 5" && element.subjectName !== null) {
            friObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period5 = "--";
          }
          //  else{
          //   friObj.Period5 = "Not Assigned"
          // }
          if (element.title == "Period 6" && element.subjectName !== null) {
            friObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period6 = "--";
          }
          //  else{
          //   friObj.Period6 = "Not Assigned"
          // }
          if (element.title == "Period 7" && element.subjectName !== null) {
            friObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period7 = "--";
          }
          //  else{
          //   friObj.Period7 = "Not Assigned"
          // }
          if (element.title == "Period 8" && element.subjectName !== null) {
            friObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period8 = "--";
          }
          //  else{
          //   friObj.Period8 = "Not Assigned"
          // }
          if (element.title == "Period 9" && element.subjectName !== null) {
            friObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period9 = "--";
          }
          //  else{
          //   friObj.Period9 = "Not Assigned"
          // }
          if (element.title == "Period 10" && element.subjectName !== null) {
            friObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period10 = "--";
          }
          //  else{
          //   friObj.Period10 = "Not Assigned"
          // }
          if (element.title == "Period 11" && element.subjectName !== null) {
            friObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period11 = "--";
          }
          //  else{
          //   friObj.Period11 = "Not Assigned"
          // }
          if (element.title == "Period 12" && element.subjectName !== null) {
            friObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period12 = "--";
          }
          //  else{
          //   friObj.Period12 = "Not Assigned"
          // }
          if (element.title == "Period 13" && element.subjectName !== null) {
            friObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period13 = "--";
          }
          //  else{
          //   friObj.Period13 = "Not Assigned"
          // }
          if (element.title == "Period 14" && element.subjectName !== null) {
            friObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   friObj.Period14 = "--";
          }
          //  else{
          //   friObj.Period14 = "Not Assigned"
          // }
        });
        dayColumn.push(friObj);
      }
      if (Saturday) {
        let satObj = {
          id: 5,
          Timetable: "Sat",
          Period0: "---",
          Period1: "---",
          Period2: "---",
          Period3: "---",
          Period4: "---",
          Period5: "---",
          Period6: "---",
          Period7: "---",
          Period8: "---",
          Period9: "---",
          Period10: "---",
          Period11: "---",
          Period12: "---",
          Period13: "---",
          Period14: "---",
        };
        Saturday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0" && element.subjectName !== null) {
            satObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period0 = "--";
          }
          //  else{
          //   satObj.Period0 = "Not Assigned"
          // }
          if (element.title == "Period 1" && element.subjectName !== null) {
            satObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period1 = "--";
          }
          //  else{
          //   satObj.Period1 = "Not Assigned"
          // }
          if (element.title == "Period 2" && element.subjectName !== null) {
            satObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period2 = "--";
          }
          //  else{
          //   satObj.Period2 = "Not Assigned"
          // }
          if (element.title == "Period 3" && element.subjectName !== null) {
            satObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period3 = "--";
          }
          //  else{
          //   satObj.Period3 = "Not Assigned"
          // }
          if (element.title == "Period 4" && element.subjectName !== null) {
            satObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period4 = "--";
          }
          //  else{
          //   satObj.Period4 = "Not Assigned"
          // }
          if (element.title == "Period 5" && element.subjectName !== null) {
            satObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period5 = "--";
          }
          //  else{
          //   satObj.Period5 = "Not Assigned"
          // }
          if (element.title == "Period 6" && element.subjectName !== null) {
            satObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period6 = "--";
          }
          //  else{
          //   satObj.Period6 = "Not Assigned"
          // }
          if (element.title == "Period 7" && element.subjectName !== null) {
            satObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period7 = "--";
          }
          //  else{
          //   satObj.Period7 = "Not Assigned"
          // }
          if (element.title == "Period 8" && element.subjectName !== null) {
            satObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period8 = "--";
          }
          //  else{
          //   satObj.Period8 = "Not Assigned"
          // }
          if (element.title == "Period 9" && element.subjectName !== null) {
            satObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period9 = "--";
          }
          //  else{
          //   satObj.Period9 = "Not Assigned"
          // }
          if (element.title == "Period 10" && element.subjectName !== null) {
            satObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period10 = "--";
          }
          //  else{
          //   satObj.Period10 = "Not Assigned"
          // }
          if (element.title == "Period 11" && element.subjectName !== null) {
            satObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period11 = "--";
          }
          //  else{
          //   satObj.Period11 = "Not Assigned"
          // }
          if (element.title == "Period 12" && element.subjectName !== null) {
            satObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period12 = "--";
          }
          //  else{
          //   satObj.Period12 = "Not Assigned"
          // }
          if (element.title == "Period 13" && element.subjectName !== null) {
            satObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period13 = "--";
          }
          //  else{
          //   satObj.Period13 = "Not Assigned"
          // }
          if (element.title == "Period 14" && element.subjectName !== null) {
            satObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   satObj.Period14 = "--";
          }
          //  else{
          //   satObj.Period14 = "Not Assigned"
          // }
        });
        dayColumn.push(satObj);
      }
      if (Sunday) {
        let sunObj = {
          id: 6,
          Timetable: "Sun",
          Period0: "---",
          Period1: "---",
          Period2: "---",
          Period3: "---",
          Period4: "---",
          Period5: "---",
          Period6: "---",
          Period7: "---",
          Period8: "---",
          Period9: "---",
          Period10: "---",
          Period11: "---",
          Period12: "---",
          Period13: "---",
          Period14: "---",
        };
        Sunday.forEach((element) => {
          console.log(element);
          if (element.title == "Period 0" && element.subjectName !== null) {
            sunObj.Period0 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period0 = "--";
          }
          //  else{
          //   sunObj.Period0 = "Not Assigned"
          // }
          if (element.title == "Period 1" && element.subjectName !== null) {
            sunObj.Period1 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period1 = "--";
          }
          //  else{
          //   sunObj.Period1 = "Not Assigned"
          // }
          if (element.title == "Period 2" && element.subjectName !== null) {
            sunObj.Period2 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period2 = "--";
          }
          //  else{
          //   sunObj.Period2 = "Not Assigned"
          // }
          if (element.title == "Period 3" && element.subjectName !== null) {
            sunObj.Period3 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period3 = "--";
          }
          //  else{
          //   sunObj.Period3 = "Not Assigned"
          // }
          if (element.title == "Period 4" && element.subjectName !== null) {
            sunObj.Period4 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period4 = "--";
          }
          //  else{
          //   sunObj.Period4 = "Not Assigned"
          // }
          if (element.title == "Period 5" && element.subjectName !== null) {
            sunObj.Period5 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period5 = "--";
          }
          //  else{
          //   sunObj.Period5 = "Not Assigned"
          // }
          if (element.title == "Period 6" && element.subjectName !== null) {
            sunObj.Period6 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period6 = "--";
          }
          //  else{
          //   sunObj.Period6 = "Not Assigned"
          // }
          if (element.title == "Period 7" && element.subjectName !== null) {
            sunObj.Period7 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period7 = "--";
          }
          //  else{
          //   sunObj.Period7 = "Not Assigned"
          // }
          if (element.title == "Period 8" && element.subjectName !== null) {
            sunObj.Period8 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period8 = "--";
          }
          //  else{
          //   sunObj.Period8 = "Not Assigned"
          // }
          if (element.title == "Period 9" && element.subjectName !== null) {
            sunObj.Period9 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period9 = "--";
          }
          //  else{
          //   sunObj.Period9 = "Not Assigned"
          // }
          if (element.title == "Period 10" && element.subjectName !== null) {
            sunObj.Period10 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period10 = "--";
          }
          //  else{
          //   sunObj.Period10 = "Not Assigned"
          // }
          if (element.title == "Period 11" && element.subjectName !== null) {
            sunObj.Period11 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period11 = "--";
          }
          //  else{
          //   sunObj.Period11 = "Not Assigned"
          // }
          if (element.title == "Period 12" && element.subjectName !== null) {
            sunObj.Period12 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period12 = "--";
          }
          //  else{
          //   sunObj.Period12 = "Not Assigned"
          // }
          if (element.title == "Period 13" && element.subjectName !== null) {
            sunObj.Period13 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period13 = "--";
          }
          //  else{
          //   sunObj.Period13 = "Not Assigned"
          // }
          if (element.title == "Period 14" && element.subjectName !== null) {
            sunObj.Period14 = `${element.subjectName.toUpperCase()} - ${element.standardName}`+'-'+element.divisionName;
            // } else {
            //   sunObj.Period14 = "--";
          }
          //  else{
          //   sunObj.Period14 = "Not Assigned"
          // }
        });
        dayColumn.push(sunObj);
      }

    } catch (error) {
      console.log(error);
      setOpen(true);
      
    }

    let periodColumn = [];
    periodColumn.unshift({
      field: "Timetable",
      headerName: "Timetable",
      editable: false,
      flex: 1.1,
      ClassName: "Timetable",
      sortable: false,
      headerClassName: "super-app-theme--header",
    });
    for (let i = 0; i < typeList.length; i++) {
      let period = typeList[i].startTime + " " + typeList[i].endTime;
      console.log(typeList[i]);
      let st = typeList[i].startTime
        ? typeList[i].startTime.split(":")
        : "00:00";
      let et = typeList[i].endTime ? typeList[i].endTime.split(":") : "00:00";
      let type = typeList[i].typeOfPeriod == "class" ? "C" : "B";
      // let title = typeList[i].title == "class" ? "C" : "B";
      periodColumn.push({
        field: typeList[i].title.split(" ").join(""),
        headerName:
          st[0] + ":" + st[1] + " - " + et[0] + ":" + et[1] ,
          // + " [" + type + "]",
        editable: false,
        renderHeader: () => (
          <strong style={{ fontSize: 13,lineHeight:"16px" }}>
            {st[0] +
              ":" +
              st[1] +
              " - " +
              et[0] +
              ":" +
              et[1] 
              // " [" +
              // type +
              // "]"
              }
            <br />
            {typeList[i].title}
          </strong>
        ),
        headerClassName: "super-app-theme--header",
        flex: 2.5,
        headerAlign:"center",
        align:"center",
        ClassName: typeList[i].title,
        sortable: false,

      });
      console.log(periodColumn);
    }
    setColumns(periodColumn);

    setRows(dayColumn);

  };

  const generatePDF =()=> {
    const doc = new jsPDF("landscape");

    const academic = JSON.parse(localStorage.getItem("academicYear"));
    const school = JSON.parse(localStorage.getItem("schoolDetails"))

  //  const head = columns.map(c=>c.headerName+" "+c.ClassName);
  const head = columns.map((obj, index) => [
    index !== 0 ? obj.headerName+" "+obj.ClassName : "Timetable"
  ]);
  
   const header = [[...head]];

   const tableData = rows.map((r)=>[
    r.Timetable,r.Period0,r.Period1,r.Period2,r.Period3,
    r.Period4,r.Period5,r.Period6,r.Period7,r.Period8,
    r.Period9
   ]);

   doc.setFontSize(10);
    doc.setFont("Helvetica", "bold")
    doc.text(`${academic.yearName}`, 250, 20);

    const text = `${school.schoolNameOnDocs.toUpperCase()}`;
    const fontSize = 18;
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFontSize(fontSize);
    doc.setFont("Helvetica", "bold");
    doc.text(text, pageWidth / 2, 22, { align: "center" });

    const img = `${school.schoolLogoUrl}`;
    doc.addImage(img, "JPEG", 20, 5, 27, 27);

    doc.setFontSize(15);
    doc.text(selectedStaff.staffName+" - "+selectedStaff.ssid+" TIMETABLE", 150, 40, 'center');

    doc.autoTable({
      startY:50,
      tableLineColor:[0,0,0],
      margin: 5,
      head:header,
      body:tableData,
      styles: { cellWidth:26, fontSize:10,fontStyle:'bold'},
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
        fontSize:8.1,
        lineColor:[0,0,0],
        fontStyle:'bold'   
      },
      tableLineWidth: 0.45,
      theme: "grid",
    })
    doc.save(selectedStaff.staffName+"-"+selectedStaff.ssid+" Timetable.pdf");
  }


  return (
    <div>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          backgroundColor: "lightblue",
          mt: 2,
          fontWeight: "bold",
        }}
      >
        CLICK THE STAFF FOR THE PARTICULAR STAFF TIMETABLE
      </Typography>
      <Box sx={{ flexGrow: 1, m: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {staffList.map((element) => (
                <Grid item xs={6} sm={4} md={3}>
                  <Item>
                    <Box display={"flex"}>
                      <Avatar
                        variant="square"
                        sx={{ mx: 4, my: 1 }}
                        alt={element.staffName}
                        src={element.staffProfilePhoto}
                      />
                      {/* <Typography>{element.staffName}</Typography> */}
                      <Button
                        variant="text"
                        value={element.staffName}
                        id={element.staffID}
                        size="small"
                        sx={{ mt: 1 }}
                        onClick={onSelectStaff}
                      >
                        {element.staffName}
                      </Button>
                    </Box>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {selectedStaff ?
      <Typography
      variant="h6"
      sx={{
        textAlign: "center",
        backgroundColor: "lightblue",
        mt: 2,
        fontWeight: "bold",
      }}
    >
      TIME TABLE SCHEDULE FOR {selectedStaff.staffName + ' - ' + selectedStaff.ssid}
    </Typography>
    :
    <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          backgroundColor: "lightblue",
          mt: 2,
          fontWeight: "bold",
        }}
      >
        ------------------
        {/* TIME TABLE SCHEDULE FOR {selectedStaff.staffName + ' - ' + selectedStaff.ssid} */}
      </Typography>
}
      <Box 
       sx={{
        m: 2,
        height: 435,
        width: "100%",
        textAlign: "center",
        "& .super-app-theme--header": {
          backgroundColor: "#c0c0c0",
          //  color:'white',
          fontWeight: "bold",
          fontSize: "15px",
          border: "1px solid white",
        },
        overflowX:"auto",
      }}
      >
        {/* <Grid container spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              {StandardList.map((element) => (
                <Grid item xs={2}>
                  <Button
                    variant="text"
                    value={element.standardName}
                    id={element.standardID}
                    size="small"
                    sx={{
                      mt: 1,
                      background: "#aaecac",
                      "&:hover": {
                        background: "#035e06",
                      },
                      color: "white",
                    }}
                    onClick={onSelectStandard}
                    fullWidth
                  >
                    {element.standardName}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              {divisionList.map((element) => (
                <Grid item xs={2}>
                  <Button
                    variant="text"
                    value={element.divisionName}
                    id={element.divisionName}
                    size="small"
                    sx={{
                      mt: 1,
                      background: "#03dff9",
                      "&:hover": {
                        background: "#038da6",
                      },
                      color: "white",
                    }}
                    onClick={onSelectDivision}
                    fullWidth
                  >
                    {element.divisionName}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid> */}

<DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          hideFooter
          disableColumnMenu
          rowsPerPageOptions={[10]}
          sx={{minWidth:1200}}
          // onCellClick={oncellselect}
          getRowHeight={() => 'auto'}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          getRowId={(row) => row.id}
          getRowClassName={(params) =>
            `myRowClassName ${params.id }`
          }
          
          // autoHeaderHeight={true}
          // columnHeaderHeight="500px"
          // onCellDoubleClick={onCellDelete}
        />

      </Box>
      <Box sx={{display:"flex",justifyContent:'space-evenly', paddingBottom:"25px"}}>
        <Button variant="contained" color="error"
        size="large" 
        sx={{borderRadius:"15px",width:"140px",borderRadius:"30px",fontWeight:"bold",mt:1}}
        onClick={generatePDF}
        >pdf
        </Button>

        <Button variant="contained" 
        size="large" 
        sx={{
          backgroundColor: "#ca424f",
          fontSize: 15,
          mt:1,
          fontWeight: "bold",
          borderRadius: "30px",
          width: "140px",
          "&:hover": { backgroundColor: "#ca424f" },
        }}
        onClick={onLogout}
        >
          exit
        </Button>

      </Box>

      {/* <Modal
        open={open}
        // onClose={() => setSucess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <SucessModal
            text="Selected Staff had assigned to same period for different divisions or Standards. Please verify in class routine module "
            name="Selected Staff had assigned to same period for different divisions or Standards. Please verify in class routine module "
          />
        </div>
      </Modal> */}

<Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              // onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography id="transition-modal-description" sx={{ mt: 2,fontWeight:"bold" }}>
                  Selected Staff has been assigned to same period of different divisions or Standards.
                  Please verify and reassign the timetable in class routine module
                  </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      close
                    </Button>
                </Box>
              </Fade>
            </Modal>


    </div>
  );
}
export default TeacheRoutine;
