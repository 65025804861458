import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, Typography } from '@mui/material';
import { color } from '@mui/system';
import LaunchpadTopBar from '../../MainTopbar/LaunchPadTopbar';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function BasicGrid() {
    let navigate = useNavigate();
    return (
        <React.Fragment>
            {/* <LaunchpadTopBar id="afterfinalexam" /> */}
            <Typography
                variant="h6"
        sx={{
          textAlign: "center",
          backgroundColor: "lightblue",
          my: 1,
          fontWeight: "bold",
        }}
      >

                            START PROCESS FOR NEXT ACADEMIC YEAR 
                    </Typography >
            <Box sx={{ flexGrow: 1 }}>
                <Grid container sx={{ display: 'flex', justifyContent: 'center' }} >
                </Grid>
                <Grid container spacing={2} mt='10px'>
                    <Grid item xs={5} border="2px solid black" borderRadius="15px" sx={{ marginLeft: 10 }} >
                        <Typography component='div'>
                            <h5>THIS PROCESS WILL ALLOW YOU TO START ACCEPTING NEW  ADMISSION
                                FORMS BUT NEW FEE STRUCTURE WILL BE AVAILABLE ONLY AFTER DATA
                                IS MIGRATED AND NEW ACADEMIC YEAR IS STARTED.
                            </h5>
                        </Typography >

                        <Typography color='darkblue'>
                            <h5 >
                                THIS PROCESS WILL MIGRATE ALL THE PROMOTED STUDENTS TO THE NEXT
                                STANDARD, EXCEPT STUDENTS WHO REQUESTED FOR TC/LC AND MIGRATE
                                STAFF EXCEPT THOSE WHO RESIGNED
                            </h5>
                        </Typography>
                        <Typography color='darkblue'>
                            <h5>
                                THIS PROCESS WILL ALLOW YOU TO ACCEPT REQUEST FOR TC/LC AND STAFF
                                RESIGNATION
                            </h5>
                        </Typography >

                    </Grid>
                    <Grid item xs={5} border="2px solid black" borderRadius="15px" sx={{ marginLeft: 10 }}>
                        <Typography color='darkblue'>
                            <h5>LET US KNOW IF THERE IS ANY CHANGE IN STANDARD AND DIVISION,
                                SUBJECTS AND FEE STRUCTURE FOR NEW ACADEMIC YEAR. THIS WILL BE
                                APLICABLE FOR THE NEW ADMITTED STUDENTS AND ONCE MIGRATED WILL
                                BE APPLICABLE FOR ALL THE PROMOTED STUDENTS AS WELL.
                                TIME TABLE CAN BE REDESIGNED DURING THE ACADEMIC YEAR
                            </h5>
                        </Typography>

                        <Typography fontWeight="fontWeightBold" color='black'>
                            <h4>
                                PLEASE FOLLOW THREE STEPS OF MIGRATION
                            </h4>
                        </Typography>
                        <Typography color='darkblue'>
                            <h5>
                                ONCE THE DATA IS FREEZE, ALL OPTION FOR THIS ACADEMIC YEAR WILL GET
                                DEACTIVATED AND WILL BE AVAILABLE FOR NEW ACADEMIC YEAR ONLY.
                            </h5>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <Grid item xs={5} >
                        {/* <item>
                            <Button variant='contained' color='success' sx={{ borderRadius: '16px', mb: 3 ,width: "89%"}} >CLICK HERE TO START THE PROCESS FOR NEXT ACADEMIC YEAR</Button>
                        </item>

                        <item>
                            <Button variant='contained' color='warning' sx={{ borderRadius: '16px', justifyContent: 'center', backgroundColor: 'yellow', color: 'red', fontWeight: 'fontWeightBold', width: "89%", mb: 3 }}  >PASSWORD</Button>
                        </item>

                        <item>
                            <Button variant='contained' sx={{ borderRadius: '16px', fontWeight: 'fontWeightBold', color: 'black', width: "89%", mb: 3 }}  >PLEASE ENTER VERIFICATION SENT TO YOUR MOBILE</Button> */}
                        {/* </item> */}

                        <item>
                            <Button variant='contained' sx={{ borderRadius: '16px', fontWeight: 'fontWeightBold', color: 'white', backgroundColor: '#616161', ml: 25 }} onClick={()=> navigate("/create-year")} >START PROCESS</Button>
                        </item>

                        <item>
                            <Button variant='contained' color='error' sx={{ borderRadius: '16px', fontWeight: 'fontWeightBold', color: 'white', ml: 100, width: "25%" }} onClick={()=> navigate("/launchpad")} >EXIT</Button>
                        </item>
                    </Grid>
                </Grid>

            </Box>
        </React.Fragment>
    );
}