import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Avatar,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { StaffService } from "../../../services/StaffService";
import StaffEdit from "./staffEdit";
import { C } from "../../util/C";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesignationService } from "../../../services/DesignationService";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import StaffSubject from "../staffEntry/StaffSubject";
import StaffDocument from "../staffEntry/staffDocument";
import StaffPersonalDetail from "./staffDetails";
import StaffRemarkRecognition from "../staffEntry/staffRemarkRecognition";
import StaffFreeze from "../staffEntry/staffFreeze";
import StaffSalary from "../staffEntry/staffSalaryDetails";
import IndividualAttendence from "../staffEntry/individualAttendence";
import ClearIcon from "@mui/icons-material/Clear";
import { UploadFileService } from "../../../services/FileUploadService";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  // textAlign: "center",
}));

const FormItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  height: 180,
}));

const modalstyle = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "94%",
  height: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicGrid() {
  const [value, setValue] = React.useState(0);
  const [dob, setDob] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    onStaffLoad();
    onBankLoad();
    onDesignationload();
  }, []);

  const [staff, setStaff] = React.useState([]);
  const onStaffLoad = async () => {
    setLoading(true);
    try {
      let response = await StaffService.staffListAll();
      // console.log(response.data.data);
      // response.data.data.forEach(async (rec) => {
      //   console.log(rec);
      //   try {
      //     let responseFile = await UploadFileService.downloadFile(
      //       rec.staffProfilePhoto
      //     );
      //     console.log(responseFile.request.responseURL);
      //     rec.imgURL = responseFile.request.responseURL;
      //   } catch (error) {
      //     console.log(error.message);
      //     rec.imgURL = "profile.jpg";
      //   }
      // });
      // setTimeout(() => {
      setStaff(response.data.data);
      setLoading(false);
      // stafD = response.data.data;
      // }, 2000);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
    // setStaff(stafD);
  };

  const onBankLoad = async () => {
    const date = new Date(); // 2009-11-10
    const month = date.toLocaleString("default", { month: "long" });
    console.log(month);
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await StaffService.bankDetails(month, data.yearName);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [designation, setDesignation] = React.useState([]);

  const onDesignationload = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await DesignationService.designationListAll(data.yearName);
      setDesignation(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedStaff, setSelectedStaff] = React.useState();

  const [selectedStaffProfile, setSelectedStaffProfile] = React.useState();
  const [selectedStaffName, setSelectedStaffName] = React.useState();
  const [selectedStaffSSID, setSelectedStaffSSID] = React.useState();
  const [selectedStaffID, setSelectedStaffID] = React.useState();
  const [selectedstaffClass, setSelectedstaffClass] = React.useState();

  const handleStaffClassChange = (event) => {
    setSelectedstaffClass(event.target.value);
  };

  const [selectedStaffDesignation, setSelectedStaffDesignation] =
    React.useState();
  const handleStaffDesigChange = (event) => {
    setSelectedStaffDesignation(event.target.value);
  };

  const [selectedstaffQualification, setSelectedstaffQualification] =
    React.useState();
  const handleStaffQualChange = (event) => {
    setSelectedstaffQualification(event.target.value);
  };

  const [selectedstaffDob, setSelectedstaffDob] = React.useState();
  const handleStaffDobChange = (event) => {
    setSelectedstaffDob(event.target.value);
  };

  const [selectedstaffGender, setSelectedstaffGender] = React.useState();
  const handleStaffGenderChange = (event) => {
    setSelectedstaffGender(event.target.value);
  };

  const [selectedstaffBlood, setSelectedstaffBlood] = React.useState();
  const handleStaffBloodChange = (event) => {
    setSelectedstaffBlood(event.target.value);
  };
  const [selectedstaffPhone, setSelectedstaffPhone] = React.useState([]);
  const handleStaffPhoneChange = (event) => {
    setSelectedstaffPhone(event.target.value);
  };
  const [selectedstaffMother, setSelectedstaffMother] = React.useState();
  const handleStaffMotherChange = (event) => {
    setSelectedstaffMother(event.target.value);
  };
  const [selectedstaffFather, setSelectedstaffFather] = React.useState();
  const handleStaffFatherChange = (event) => {
    setSelectedstaffFather(event.target.value);
  };
  const [selectedstaffMaritals, setSelectedstaffMaritals] = React.useState();
  const handleStaffMaritalsChange = (event) => {
    setSelectedstaffMaritals(event.target.value);
  };
  const [selectedstaffAddress, setSelectedstaffAddress] = React.useState();
  const handleStaffAddressChange = (event) => {
    setSelectedstaffAddress(event.target.value);
  };

  const onStaffSelect = (e) => {
    C.c(e.currentTarget.id);
    let selectedS = staff.find((r) => r.staffID == e.currentTarget.id);
    // let selectedS = staff.find((r) => console.log(r));

    // let selStaff = staff.find((element) => {
    //   // localStorage.setItem("StaffId", JSON.stringify(selectedStaffName));
    //   if (element.staffID == e.currentTarget.id) {
    //     C.c(element);
    //     localStorage.setItem("selectedStaff", JSON.stringify(element));

    //     //   setSelectedStaffSSID(element.ssid);
    //     //   setSelectedStaffID(element.staffID);
    //     //   setSelectedStaffName(element.staffName.toUpperCase());
    //     //   setSelectedStaffDesignation(element.designation);
    //     //   setSelectedstaffClass(element.staffClass);
    //     //   setSelectedstaffQualification(element.staffQualification);
    //     //   setSelectedstaffDob(element.staffDob);
    //     //   setSelectedstaffGender(element.staffGender);
    //     //   setSelectedstaffBlood(element.bloodGroup);
    //     //   setSelectedstaffPhone(element.staffMobileNo);
    //     //   setSelectedstaffMother(element.staffMotherName);
    //     //   setSelectedstaffFather(element.staffFatherName);
    //     //   setSelectedstaffMaritals(element.staffType);
    //     //   setSelectedstaffAddress(element.staffAddress);
    //     //   setSelectedStaffProfile(element.imgURL);
    //   }
    // });

    setSelectedStaff(selectedS);
    C.c(selectedS);
    setOpen(true);
  };

  const [open, setOpen] = React.useState(false);
  const handleStaffClose = () => {
    setOpen(false);
    onStaffLoad();
  };
  localStorage.setItem("StaffId", JSON.stringify(selectedStaffName));
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    console.log(data.get("CLASS"));
    let staffOBJ = {
      // designation: data.get("class"),
      designation: data.get("designation"),
      staffQualification: data.get("qualification"),
      staffDOB: data.get("  "),
      bloodGroup: data.get("blood group"),
      staffMobileNo: [data.get("phone no")],
      staffMotherName: data.get("mother's name"),
      staffFatherName: data.get("father's name"),
      staffMaritals: data.get("maritals"),
      staffAdress: data.get("address"),
      //
      staffClass: data.get("staffClass"),
    };
    console.log(staffOBJ);
  };

  const [file, setFile] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(null);
  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setImageUrl(URL.createObjectURL(e.target.files[0]));
  };

  let navigate = useNavigate();
  const onLogout = () => {
    console.log("onLogout");
    navigate("/launchpad", { replace: true });
  };

  return (
    <div
      style={{
        backgroundImage: `url("bgColor8.png")`,
        backgroundSize: "cover",
      }}
    >
      {/* <LaunchpadTopBar id="staff" /> */}
      {loading ? (
        <div
          style={{
            padding: "180px 0px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        </div>
      ) : (
        <Box>
          {/* STAFF DATA LIST */}
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              backgroundColor: "lightblue",
              my: 1,
              fontWeight: "bold",
            }}
          >
            CLICK ON THE IMAGE BELOW TO GET INDIVIDUAL INFORMATION
          </Typography>
          <Box sx={{ flexGrow: 1, mt: 2 }} key="Box">
            <Grid container spacing={1}>
              {staff.map((element) => (
                <Grid item md={2} xs={4} sx={{ textAlign: "center" }}>
                  <Item sx={{ border: "2px solid black" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        // alignContent: "center",
                        // ml: -2,
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={element.staffProfilePhoto}
                        id={element.staffID}
                        name={element.staffName}
                        variant="square"
                        sx={{
                          width: 159,
                          height: 210,
                          mx: 5,
                        }}
                        onClick={onStaffSelect}
                      />
                    </Box>
                    <Typography>{element.staffName.toUpperCase()}</Typography>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", paddingBottom: "25px" }}>
            <Button
              variant="contained"
              size="large"
              onClick={onLogout}
              sx={{
                backgroundColor: "#ca424f",
                fontSize: 15,
                fontWeight: "bold",
                borderRadius: "30px",
                width: "120px",
                "&:hover": { backgroundColor: "#ca424f" },
                mt: 2,
              }}
            >
              exit
            </Button>
          </Box>
        </Box>
      )}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ mt: 25 }}
      >
        {selectedStaff ? (
          <Box
            sx={modalstyle}
            style={{
              backgroundImage: `url("bgColor8.png")`,
              backgroundSize: "cover",
              // width: "100%",
              // height: "195%",
            }}
          >
            <Box component="form" onSubmit={handleSubmit} sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  {/* <Box sx={{ m: 2 }}> */}
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      textAlign: "center",
                      color: "black",
                      // mt: 1,
                      backgroundColor: "lightblue",
                      width: "100%",
                      textTransform: "uppercase",
                    }}
                  >
                    {selectedStaff.staffName} - {selectedStaff.ssid}
                  </Typography>
                  {/* </Box> */}
                </Grid>
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleStaffClose}
                  >
                    {/* <ClearIcon onClick={handleStaffClose} /> */}
                    Close
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={10} xs={10}>
                  <Box sx={{ m: 2, mt: 5, ml: 0 }}>
                    {/* STAFF DETAILS */}
                    <Box
                      sx={{ width: "100%", p: "0 32px", boxSizing: "border-box", my: "1%" }}
                    >

                      <Grid container textAlign={"center"} spacing={{ xs: 1, sm: 1, md: 1 }}>
                        {/* STAFF DESIGNATION */}
                        <Grid item md={1.5} sm={4} xs={6} >
                          <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "18px",
                              backgroundColor: "lightgray",
                            }}
                          >
                            DESIGNATION
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ textAlign: "center" }}
                          >
                            {selectedStaff.designation}
                          </Typography>
                        </Grid>

                        <Grid item xs={0.1}></Grid>

                        {/* STAFF CLASS */}
                        <Grid item md={2} sm={4} xs={5.5} >
                          <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "18px",
                              backgroundColor: "lightgray",
                            }}
                          >
                            CLASS
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                            }}
                          >
                            {selectedStaff.staffClass
                              ? selectedStaff.staffClass
                              : "--"}
                          </Typography>
                        </Grid>

                        {/* STAFF QUALIFICATION */}
                        <Grid item md={2} sm={4} xs={6} >
                          <Item sx={{ width: "170px" }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              sx={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "18px",
                                backgroundColor: "lightgray",
                              }}
                            >
                              QUALIFICATION
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              sx={{ textAlign: "center" }}
                            >
                              {selectedStaff.staffQualification}
                            </Typography>
                          </Item>
                        </Grid>
                        <Grid item xs={0.1}></Grid>

                        {/* STAFF BLOOD GROUP */}
                        <Grid item md={2} sm={4} xs={5.5} >
                          <Item sx={{ width: "170px" }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              sx={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "18px",
                                backgroundColor: "lightgray",
                              }}
                            >
                              BLOOD GROUP
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              sx={{
                                textAlign: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              {selectedStaff.bloodGroup
                                ? selectedStaff.bloodGroup
                                : "--"}
                            </Typography>
                          </Item>
                        </Grid>

                        {/* STAFF GENDER */}
                        <Grid item md={2} sm={4} xs={6} >
                          <Item sx={{ width: "170px" }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              sx={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "18px",
                                backgroundColor: "lightgray",
                              }}
                            >
                              GENDER
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              sx={{
                                textAlign: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              {selectedStaff.staffGender
                                ? selectedStaff.staffGender
                                : "--"}
                            </Typography>
                          </Item>
                        </Grid>
                        <Grid item xs={0.1}></Grid>

                        {/* STAFF PHONE NO. */}
                        <Grid item md={2} sm={4} xs={5.5} >
                          <Item sx={{ width: "170px" }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              sx={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "18px",
                                backgroundColor: "lightgray",
                              }}
                            >
                              PHONE NO.
                            </Typography>
                            {selectedStaff.staffMobileNo.map((element) => (
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                sx={{ textAlign: "center" }}
                              >
                                {element}
                              </Typography>
                            ))}
                          </Item>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      sx={{ width: "100%", p: "0 32px", boxSizing: "border-box", my: "1%" }}
                    >

                      <Grid container textAlign={"center"} spacing={{ xs: 1, sm: 1, md: 2 }}>

                        {/* STAFF MOTHER'S NAME*/}

                        <Grid item md={2.5} sm={4} xs={6} >
                          <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "18px",
                              backgroundColor: "lightgray",
                            }}
                          >
                            MOTHER'S NAME
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                            }}
                          >
                            {selectedStaff.staffMotherName
                              ? selectedStaff.staffMotherName
                              : "--"}
                          </Typography>
                        </Grid>
                      

                      {/* STAFF FATHER'S NAME*/}
                      <Grid item md={2.5} sm={4} xs={6} >

                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "18px",
                            backgroundColor: "lightgray",
                          }}
                        >
                          FATHER'S NAME
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}
                        >
                          {selectedStaff.staffFatherName
                            ? selectedStaff.staffFatherName
                            : "--"}
                        </Typography>
                      </Grid>

                      {/* STAFF MARITAL STATUS */}
                      <Grid item md={2} sm={4} xs={6} >

                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "18px",
                            backgroundColor: "lightgray",
                          }}
                        >
                          MARITAL STATUS
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ textAlign: "center" }}
                        >
                          {selectedStaff.staffMaritals
                            ? selectedStaff.staffMaritals
                            : "--"}
                        </Typography>
                      </Grid>

                      {/* STAFF ADDRESS */}
                      <Grid item md={5} sm={4} xs={6} >

                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "18px",
                            backgroundColor: "lightgray",
                          }}
                        >
                          ADDRESS
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ textAlign: "center" }}
                        >
                          {selectedStaff.staffAddress
                            ? selectedStaff.staffAddress
                            : "--"}
                        </Typography>
                      </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Avatar
                    alt="A"
                    variant="square"
                    src={selectedStaff.staffProfilePhoto}
                    sx={{
                      width: 140,
                      height: 160,
                      mt: 5,
                      border: "2px solid #84ffff",
                      ml: 8,
                      mr: 10,
                    }}
                  />

                  {/* <input
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    accept="image/*"
                    name="studentImg"
                    //  onChange={fileBrowseHandler}
                  />

                  <label htmlFor="contained-button-file">
                    <Button
                      variant="outlined"
                      component="span"
                      sx={{
                        border: "2px solid black",
                        borderRadius: "11px",
                        color: "black",
                        fontWeight: "bold",
                        backgroundColor: "white",
                        mt: 2,
                        ml: 6,
                      }}
                    >
                      UPLOAD IMAGE
                    </Button>
                  </label> */}
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ width: "100%", mt: 3 }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  centered
                >
                  {/*<Tab label="CLASS ROUTINE" {...a11yProps(1)} /> */}
                  <Tab
                    label="ATTENDANCE"
                    {...a11yProps(0)}
                    sx={{ fontSize: 18, fontWeight: "bold", color: "black" }}
                  />
                  <Tab
                    label="SUBJECTS"
                    {...a11yProps(1)}
                    sx={{ fontSize: 18, fontWeight: "bold", color: "black" }}
                  />
                  <Tab
                    label="DOCUMENTS"
                    {...a11yProps(2)}
                    sx={{ fontSize: 18, fontWeight: "bold", color: "black" }}
                  />
                  <Tab
                    label="SALARIES DETAIL"
                    {...a11yProps(3)}
                    sx={{ fontSize: 18, fontWeight: "bold", color: "black" }}
                  />
                  <Tab
                    label="PERSONAL INFORMATION "
                    {...a11yProps(4)}
                    sx={{ fontSize: 18, fontWeight: "bold", color: "black" }}
                  />
                  <Tab
                    label=" REMARKS AND RECOGNISITION "
                    {...a11yProps(5)}
                    sx={{ fontSize: 18, fontWeight: "bold", color: "black" }}
                  />
                  <Tab
                    label="UPLOADS"
                    {...a11yProps(6)}
                    sx={{ fontSize: 18, fontWeight: "bold", color: "black" }}
                  />
                </Tabs>
              </Box>
              {/*<TabPanel value={value} index={1}>
                CLASS ROUTINE
              </TabPanel>{" "}
              */}
              <TabPanel value={value} index={0}>
                <IndividualAttendence data={selectedStaff} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <StaffSubject data={selectedStaff} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <StaffDocument data={selectedStaff} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <StaffSalary data={selectedStaff} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <StaffPersonalDetail data={selectedStaff} />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <StaffRemarkRecognition data={selectedStaff} />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <StaffFreeze data={selectedStaff} />
              </TabPanel>
            </Box>
          </Box>
        ) : (
          <h2>"loading"</h2>
        )}
      </Modal>
    </div>
  );
}
