import * as React from "react";
import { styled } from "@mui/material/styles";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Avatar,
  Stack,
  Typography,
  Button,
  Modal,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { UploadFileService } from "../../../services/FileUploadService";
import { StudentDcoumentService } from "../../../services/StudentDocument";
import { StudentInfoService } from "../../../services/StudentInfo/StudentList";
import { ImageConverter } from "../../../programs/imgaeToBase";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "center",
  // boxShadow: "",

  // color: theme.palette.text.secondary,
}));

const style = {
  borderRadius:"15px",
  textAlign:"center",
  position: "absolute",
  top: "80%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 150,
  bgcolor: "white",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  // overflow: "scroll",
};

export default function BasicGrid() {
  const [alignment, setAlignment] = React.useState("supplies");
  const [description,setDescription] = React.useState(true);
  const [request, setRequest] = React.useState(true);
  const [acknowledgement, setAcknowledgement] = React.useState(true);
  const [remake, setRemake] = React.useState(false);
  const [details, setdetails] = React.useState(false);
  const [image, setImage] = React.useState(false);
  const [descriptions, setDescriptions] = React.useState();
  const [requests, setRequests] = React.useState();
  const [acknowledgements, setAcknowledgements] = React.useState();
  const [remakes, setRemakes] = React.useState();
  const [detail, setDetail] = React.useState();
  const [imageUrl, setImageUrl] = React.useState("");
  const [imageSrc, setImageSrc] = React.useState();
  const [studentInfo, updateStudentInfo] = React.useState({});
  const [open , setOpen] = React.useState(false);
  const textInput = React.useRef("");
  React.useEffect(() => {
    studentDetailsLoad();
  }, []);

  let navigate = useNavigate();
  const onLaunchPad = ()=>{
    navigate("/launchpad", {replace: true});
  }

  const studentDetailsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let studentUSN = localStorage.getItem("studentUSN");
    try {
      // alert(data.yearName);
      let response = await StudentInfoService.studentInfoShowStudentDetails(
        studentUSN,
        data.yearName
      );
      console.log(response.data.data);
      updateStudentInfo(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    console.log(newAlignment);
    if (newAlignment == "supplies") {
      setRequest(true);
      setAcknowledgement(true);
      setRemake(false);
      setdetails(false);
      setImage(false);
    }
    if (newAlignment == "document") {
      setRequest(false);
      setAcknowledgement(false);
      setRemake(false);
      setdetails(false);
      setImage(true);
    }
    if (newAlignment == "remAndReg") {
      setRequest(false);
      setAcknowledgement(false);
      setRemake(true);
      setdetails(false);
      setImage(true);
    }
    if (newAlignment == "govDoc") {
      setRequest(false);
      setAcknowledgement(false);
      setRemake(false);
      setdetails(true);
      setImage(true);
    }
  };
  // const fileBrowseHandler = (event) => {
  //   let value = URL.createObjectURL(event.currentTarget.files[0]);
  //   setImageSrc(event.currentTarget.files[0]);
  //   console.log(value);
  //   setImageUrl(value);
  //   console.log(imageSrc);
  // };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await ImageConverter.convertToBase64(file);
    // setPostImage({ ...postImage, myFile: base64 });
    console.log(base64);
    setImageUrl(base64);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);
    console.log({
      description: data.get("description"),
      request: data.get("request"),
      acknowledgement: data.get("acknowledgement"),
      remake: data.get("remake"),
      details: data.get("details"),
      date: new Date().toLocaleDateString(),
    });

    // const fileData = new FormData();
    // fileData.append("file", imageSrc);
    // console.log(fileData.get("file"));
    // let fileUrl;
    // try {
    //   let response = await UploadFileService.upLoadFile(fileData);
    //   fileUrl = response.data;
    //   console.log(response.data);
    // } catch (error) {
    //   console.log(error.message);
    // }

    let docCbject = {
      acknowledgement: data.get("acknowledgement"),
      date: new Date().toLocaleDateString(),
      description: data.get("description"),
      documentImgURL: imageUrl,
      documentType: alignment,
      remark: data.get("remake") ? data.get("remake") : data.get("details"),
      requestStatus: data.get("request"),
      details: data.get("details"),
      studentId: studentInfo.studentID,
      studentName: studentInfo.studentName,
      usid: studentInfo.studentUSN,
    };

    console.log(docCbject);
    try {
      let resp = await StudentDcoumentService.addStudentDocument(docCbject);
      console.log(resp.data.data);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      },1500);
    } catch (error) {
      console.log(error);
    }
    // textInput.current.value = "";
    setDescriptions("")
    setRequests("")
    setAcknowledgements("")
    setRemakes("")
    setDetail("")
    setImageUrl("");
    // setDescription("")
    // setRequest("");
    // setImageUrl("");
    // setAcknowledgement("");
    // setRemake("");
    // setdetails("");
    // setImage("");
  };
  const handledescriptions = (event) => {
    setDescriptions(event.target.value);

  };
  const handlerequests = (event) => {
    setRequests(event.target.value);
  }

  const handleacknowledgements = (event) => {
    setAcknowledgements(event.target.value);
  }

  const handleremakes = (event) => {
    setRemakes(event.target.value);
  }

  const handledetail = (event) => {
    setDetail(event.target.value);
  }

  return (
    <div>
      {/* <LaunchpadTopBar id="studentinfo" /> */}

      <Box sx={{ width: "100%", paddingLeft: "30px", boxSizing: "border-box", mt: 2}}>
          <Grid container>
            <Grid item md={10} xs={8}>
              <Box
                display={"flex"}
                alignContent={"center"}
                justifyContent={"center"}


              >
                <Stack direction="column" sx={{ width: "100%",  boxSizing: "border-box", mt: 2 }}>
                  <Item >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      STUDENT NAME
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      {studentInfo ? studentInfo.studentName : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
              <Box
                sx={{ width: "100%", boxSizing: "border-box", my: "1%" }}
              >
                <Grid  container spacing={{ xs: 1, sm: 1, md: 2 }} sx={{ width: "100%", boxSizing: "border-box", mt: 2 }}>
                  <Grid item md={1} xs={3} >
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        USN
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentUSN : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        STANDARD | DIVISION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.standardName +
                          " | " +
                          studentInfo.divisionID
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={1} xs={3}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        CLASS TR
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {"--"}
                        {/* {studentInfo ? studentInfo.classteacher : "--"} */}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        MEDICAL ATTENTION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.studentMedicalAttentionDetail
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        BLOOD GROUP
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentBloodGroup : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        DOB
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentDOB : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  {/* <Typography
              variant="body1"
              width="250px"
              sx={{ textAlign: "center" }}
            >
              {studentInfo.studentAge + " Age"}
            </Typography> */}
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item>
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        AGE
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentAge : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
             
            </Grid>
            <Grid item md={2} xs={4}>
              <Box display={"flex"} justifyContent={"center"}  sx={{mt:"2%"}}>
                <Avatar
                  alt="Remy Sharp"
                  src={studentInfo ? studentInfo.studentProfileUrl : ""}
                  //   id={element.staffID}
                  variant="square"
                  sx={{
                    width: 150,
                    height: 190,
                    mx: 2,
                    // mt: 1,
                    border: "3px solid skyblue",
                    borderRadius: "5%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

      <Box component="form" sx={{ flexGrow: 1 }} onSubmit={handleSubmit}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          margin={1}
          // alignContent="center"
        >
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="supplies">Supplies</ToggleButton>
            <ToggleButton value="document">Document</ToggleButton>
            <ToggleButton value="remAndReg">
              Remarks
            </ToggleButton>
            <ToggleButton value="govDoc">Government'S Document</ToggleButton>
          </ToggleButtonGroup>

          {/* <Box margin={1}>
            <h3>Students Documents Form </h3>
          </Box> */}
        </Box>
        <hr />
        <Grid container spacing={1}>
          <Grid item xs={8} md={8}>
            <Box paddingLeft={"30px"}>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <TextField
                    // fullWidth
                    id="description"
                    name="description"
                    value={descriptions}
                    label="Description"
                    variant="filled"
                    sx={{
                      width:"95%",
                      margin: "10px 10px 10px 0px",
                      textTransform: "uppercase",
                    }}
                    onChange={handledescriptions}
                    // width = {{md:"100%", xs:"95%"}}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    inputRef={textInput}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                {request ? (
                    <TextField
                      id="request"
                      name="request"
                      label="Request"
                      value={requests}
                      variant="filled"
                      display={"none"}
                      onChange={handlerequests}
                      sx={{
                        width:"90%",
                        margin: "10px 10px 10px 0px",
                        textTransform: "uppercase",
                      }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      inputRef={textInput}
                    />
                  
                  ) : (
                ""
                )}  
                </Grid>
                <Grid item md={6} xs={12}>
                {acknowledgement ? (
                
                  <TextField
                    id="acknowledgement"
                    name="acknowledgement"
                    label="Acknowledgement"
                    value={acknowledgements}
                    variant="filled"
                    onChange={handleacknowledgements}
                    sx={{
                      width: "90%",
                      margin: "10px 10px 10px 0px",
                      textTransform: "uppercase",
                    }}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    inputRef={textInput}
                  />  
                    ) : (
                      ""
                    )}
                 </Grid>
                <Grid item md={12} xs={12}>
                {remake ? (
                  
                  <TextField
                    id="remake"
                    name="remake"
                    label="Remarks"
                    value={remakes}
                    onChange={handleremakes}
                    variant="filled"
                    sx={{
                      width: "364px",
                      margin: "10px 10px 10px 0px",
                      textTransform: "uppercase",
                    }}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    inputRef={textInput}
                  />
                
                ) : (
                  ""
                )}
                </Grid>
                <Grid item md={12} xs={12}>
                    {details ? (
                      
                      <TextField
                        id="details"
                        name="details"
                        label="Details"
                        variant="filled"
                        onChange={handledetail}
                        value={detail}
                        sx={{
                          width: "364px",
                          margin: "10px 10px 10px 0px",
                          textTransform: "uppercase",
                        }}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        inputRef={textInput}
                      />
                      
                    ) : (
                      ""
                    )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={4} md={4} sx={{display:"flex",justifyContent:"center"}}>
            {image ? (
              <div>
                {/* <Box display="flex" justifyContent="center"> */}
                  <Avatar
                    alt="A"
                    src={imageUrl}
                    sx={{ width: 200, height: 230, margin: 1, mt: 0.5, ml: 1 }}
                    variant="square"
                  />
                {/* </Box> */}
                <input
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  accept="image/*"
                  name="studentImg"
                  onChange={handleFileUpload}
                />
                <Box display="flex" justifyContent="center">
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      sx={{
                        marginTop: 2,
                        borderRadius: 9,
                      
                        // ml:8,
                        fontWeight: 700,
                      }}
                      inputRef={textInput}

                    >
                      UPLOAD IMAGE
                    </Button>
                  </label>  
                </Box>
              </div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
       <Box mt="1%" sx={{display:"flex",justifyContent:"space-evenly"}}>
            <Button type="submit" color="success" variant="contained"
             sx={{
                  borderRadius: "30px",
                  width: "240px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                }} >
              Submit Document
            </Button>

            <Button
                variant="contained"
                size="large"
                onClick={onLaunchPad}
                sx={{
                  borderRadius: "30px",
                  backgroundColor:"#ca424f",
                  color:"white",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                  "&:hover":{backgroundColor:"#ca424f",color:"white"}
                }}
              >
                exit
              </Button>
          </Box>
      </Box>

     < Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{paddingTop:"60px"}}>
            <Typography variant="h6" textAlign="center" fontWeight="bold">
              UPLOADED SUCCESSFULLY
            </Typography>
           </Box>
          </Box>
        </Modal>

    </div>
  );
}
