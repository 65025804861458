import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class AddStandarsStrengthService {
  // static serverURL = "http://143.110.188.253:1919/standard/";
  static service = "standard/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  //   static createAddStandards(contact) {
  //     var dataURL = this.serverURL + "create";

  //     return axios.post(dataURL, contact);
  //   }

  //   //   static deleteStandard(contact) {
  //   //     var dataURL = this.serverURL + "create";

  //   //     return axios.post(dataURL, contact);
  //   //   }

  //   static deleteStandard(contact) {
  //     var dataURL = this.serverURL + "delete?standardID=" + contact;

  //     return axios.delete(dataURL);
  //   }

  //   static standardListAll() {
  //     var dataURL = this.serverURL + "listall";

  //     return axios.get(dataURL);
  //   }

  static standardStrengthListAll() {
    var dataURL = this.serverURL + "listall";

    return axios.get(dataURL);
  }
}
