import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TopBar from '../../MainTopbar/Appbar';
import StaffCopy from './staff_copy';
import StaffAttendance from './staffAttendence';
import StaffList from './staffList';
import Salary from './salaryDetails';
import PaySalary from './paySalaries';
import BankDetail from './bankDetail';
import Add from './addStaff';



function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height:"100%", width:"100%" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs() {

React.useEffect(()=>{
  window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, null, window.location.href);
    };
},[])

  const [value, setValue] = React.useState(0);

  const Click = (newValue) => {
    setValue(newValue);
    console.log(value);
  };

  return (
    <div>

<TopBar/>
     
<Box sx={{border:"2px solid black",   background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",}}>
<Grid
          container
          py={0.5}
          spacing={2}
        >
          <Grid item md={0.5} xs={0.5}></Grid>
          <Grid item md={1} xs={2.5}>
            <Button
              size="small"
              variant="contained"
              color="warning"
              sx={{ fontWeight: "bold" }}
            onClick={()=> Click(0)}
            >
              STAFF
            </Button>
          </Grid>
          <Grid item md={1.5} xs={3}>
            <Button
              size="small"
onClick={()=> Click(1)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 1 ? 'black' : '' ,
                color: value == 1 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
              ATTENDANCE
            </Button>
          </Grid>
          <Grid item md={1.5} xs={2.8}>
            <Button
              size="small"
             onClick={()=> Click(2)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 2 ? 'black' : '' ,
                color: value == 2 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
              LIST STAFF
            </Button>
          </Grid>
          <Grid item md={2} xs={3.2}>
            <Button
              size="small"
             onClick={()=> Click(3)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 3 ? 'black' : '' ,
                color: value == 3 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
              SALARIES DETAIL
            </Button>
          </Grid>
          <Grid item md={1.8} xs={3}>
            <Button
              size="small"
             onClick={()=>Click(4)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 4 ? 'black' : '' ,
                color: value == 4 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
              PAY SALARIES
            </Button>
          </Grid>
          <Grid item md={1.8} xs={3}>
            <Button
              size="small"
              onClick={()=>Click(5)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 5 ? 'black' : '' ,
                color: value == 5 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
              DETAIL TO BANK
            </Button>
          </Grid>
          <Grid item md={1} xs={3}>
            <Button
              size="small"
              onClick={()=> Click(6)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 6 ? 'black' : '' ,
                color: value == 6 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
              ADD NEW
            </Button>
          </Grid>
        </Grid>
      </Box>

      <CustomTabPanel value={value} index={0}>
      <StaffCopy/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
       <StaffAttendance/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
      <StaffList/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
       <Salary/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
      <PaySalary/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={5}>
       <BankDetail/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={6}>
       <Add/>
      </CustomTabPanel>
      

    </div>
  );
}
