import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class StudentDcoumentService {
  // static serverURL = "http://143.110.188.253:1919/student/";
  static service = "student/document/student/";
  static service1 = "student/document/staff/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;
  static serverURL1 = this.baseURL + this.service1;

  static addStudentDocument(contact) {
    var dataURL = this.serverURL + "create";
    return axios.post(dataURL, contact);
  }
  static getStudentDocument(contact) {
    var dataURL = this.serverURL + "list?usid=" + contact;
    return axios.get(dataURL);
  }
  static getAllDocument() {
    var dataURL = this.serverURL1 + "listAll";
    return axios.get(dataURL);
  }
}
