import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Avatar, Button, Typography } from "@mui/material";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  height: 80,
  // color: theme.palette.text.secondary,
}));

export default function BasicGrid() {
  return (
    <div>
      <LaunchpadTopBar
        id="dataentry"

        // text={
        //   "There are six steps of building school online for complete management kindly proceed towards the providing us the information about your school"
        // }
      />
      <Box sx={{ flexGrow: 1, m: 8, textAlign: "center" }}>
        <Typography variant="h6">
          CLICK ON IMAGE TO ZOOM VIEW AND PRINT OR DOWNLOAD
        </Typography>
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <Item>
              <Typography>No.</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography>APPLICATION / REQUEST FORMS</Typography>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <Typography>PDF FILE </Typography>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <Typography>EDIT / UPDATE</Typography>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <Typography>1</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography>STUDY CERTIFICATE REQUEST</Typography>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <Avatar>H</Avatar>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <Button>EDIT / UPDATE</Button>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <Typography>2</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography>LEAVE REQUEST</Typography>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <Avatar>G</Avatar>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <Button>EDIT / UPDATE</Button>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <Typography>3</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Typography>ADMISSION FORM</Typography>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <Avatar>J</Avatar>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <Button>EDIT / UPDATE</Button>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
