import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Button, ButtonGroup, Grid, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SchoolMaster } from "../../services/SchoolMaster";
import SucessModal from "../../components/buildSchool/Dashboard/popup/alertPopUp";
import { UploadFileService } from "../../services/FileUploadService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid green",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

const LaunchpadTopBar = (props) => {
  const [schoolName, setschoolName] = React.useState("");
  const [schoolImgID, setschoolImgID] = React.useState("");
  const [schoolImg, setschoolImg] = React.useState("");
  const [academicYear, setacademicYear] = React.useState("");
  const [userData, setUserData] = React.useState(
    JSON.parse(localStorage.getItem("userData"))
  );
  const [value, setValue] = React.useState();

  const [currentPage, setCurrentPage] = React.useState("");
  const getButtonClass = (buttonName) => {
    switch (buttonName) {
      case "blankApplication":
        return currentPage === "blankApplication" ? "highlight" : "";
      case "examEntryForm":
        return currentPage === "examEntryForm" ? "highlight" : "";
      case "examEntry":
        return currentPage === "examEntry" ? "highlight" : "";
      case "addStudent":
        return currentPage === "addStudent" ? "highlight" : "";
      case "studentApprove":
        return currentPage === "studentApprove" ? "highlight" : "";
      case "events":
        return currentPage === "events" ? "highlight" : "";
      case "bookEntry":
        return currentPage === "bookEntry" ? "highlight" : "";
      case "bookList":
        return currentPage === "bookList" ? "highlight" : "";
      case "bookListType":
        return currentPage === "bookListType" ? "highlight" : "";
      case "bookBorrow":
        return currentPage === "bookBorrow" ? "highlight" : "";
      case "bookSearch":
        return currentPage === "bookSearch" ? "highlight" : "";
      case "book":
        return currentPage === "book" ? "highlight" : "";
      case "hallTicket":
        return currentPage === "hallTicket" ? "highlight" : "";
      case "allStaffInfo":
        return currentPage === "allStaffInfo" ? "highlight" : "";
      case "allStudentInfo":
        return currentPage === "allStudentInfo" ? "highlight" : "";
      case "examResult":
        return currentPage === "examResult" ? "highlight" : "";
      case "idCard":
        return currentPage === "idCard" ? "highlight" : "";
      case "info":
        return currentPage === "info" ? "highlight" : "";
      case "studentAttendance":
        return currentPage === "studentAttendance" ? "highlight" : "";
      case "studentFee":
        return currentPage === "studentFee" ? "highlight" : "";
      case "studentExam":
        return currentPage === "studentExam" ? "highlight" : "";
      case "studentLibrary":
        return currentPage === "studentLibrary" ? "highlight" : "";
      case "studentUpload":
        return currentPage === "studentUpload" ? "highlight" : "";
      case "staffAttendance":
        return currentPage === "staffAttendance" ? "highlight" : "";
      case "staffList":
        return currentPage === "staffList" ? "highlight" : "";
      case "staffSalary":
        return currentPage === "staffSalary" ? "highlight" : "";
      case "paySalary":
        return currentPage === "paySalary" ? "highlight" : "";
      case "bankDetails":
        return currentPage === "bankDetails" ? "highlight" : "";
      case "addStaff":
        return currentPage === "addStaff" ? "highlight" : "";
      case "dailyAttendance":
        return currentPage === "dailyAttendance" ? "highlight" : "";
      case "approveAttendance":
        return currentPage === "approveAttendance" ? "highlight" : "";
      case "attendanceReport":
        return currentPage === "attendanceReport" ? "highlight" : "";
      case "startNextYear":
        return currentPage === "startNextYear" ? "highlight" : "";
      case "newAdmission":
        return currentPage === "newAdmission" ? "highlight" : "";
      case "freezeData":
        return currentPage === "freezeData" ? "highlight" : "";
      case "setExamTimings":
        return currentPage === "setExamTimings" ? "highlight" : "";
      case "userManagement":
        return currentPage === "userManagement" ? "highlight" : "";
      case "backUp":
        return currentPage === "backUp" ? "highlight" : "";
      case "classRoutine":
        return currentPage === "classRoutine" ? "highlight" : "";
      case "teacherRoutine":
        return currentPage === "teacherRoutine" ? "highlight" : "";

      default:
        return setCurrentPage;
    }
  };

  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState("");

  React.useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, null, window.location.href);
    };
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date();
    let year = date.getFullYear();
    let month = months[date.getMonth()];
    let day = date.getDate();

    setDate(`${day} ${month} ${year}`);

    const user = JSON.parse(localStorage.getItem("userData"));
    const schoolData = JSON.parse(localStorage.getItem("schoolDetails"));
    const academicData = JSON.parse(localStorage.getItem("academicYear"));
    setschoolName(
      schoolData ? (
        <b>{schoolData.schoolNameOnDocs.toUpperCase()}</b>
      ) : (
        "NEW INTERNATIONAL SCHOOL"
      )
    );
    setacademicYear(academicData.yearName);
    setschoolImgID(schoolData.schoolLogoUrl);
    setUserData(user);
    console.log(user.userName);
    onschoolLoad();
  }, []);

  const onschoolLoad = async () => {
    try {
      let response = await SchoolMaster.schoolList();
      setschoolImg(response.data.data[0].schoolLogoUrl);
      console.log(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  // * "========================Route===================================="

  let navigate = useNavigate();

  const onLaunchPad = () => {
    console.log("launchpad");
    navigate("/launchpad", { replace: true });
  };
  const onDataEntry = () => {
    console.log("dataentry");
    navigate("/click", { replace: true });
  };
  const onStudentAdd = () => {
    console.log("onStudentAdd");
    setCurrentPage("addStudent");
    navigate("/addStudent", { replace: true });
  };
  const onStudentApprove = () => {
    console.log("onStudentApprove");
    setCurrentPage("studentApprove");
    navigate("/students-list", { replace: true });
  };
  const onExamEntryForm = () => {
    console.log("onExamEntryForm");
    setCurrentPage("examEntryForm");
    navigate("/examMarksEntryForm", { replace: true });
  };
  const onExamEntry = () => {
    console.log("onExamEntry");
    setCurrentPage("examEntry");
    navigate("/examMarksEntry", { replace: true });
  };
  const onBlankEntry = () => {
    console.log("onExamEntry");
    setCurrentPage("blankApplication");
    navigate("/studentBlankForm", { replace: true });
  };
  const onEvents = () => {
    console.log("onEvents");
    setCurrentPage("events");
    navigate("/events", { replace: true });
  };

  //staff
  const onStaff = () => {
    console.log("onStaff");
    // navigate("/staff_copy", { replace: true });
    navigate("/staffClick", { replace: true });
  };
  const onStaffList = () => {
    console.log("onStaffList");
    setCurrentPage("staffList");
    navigate("/staffList", { replace: true });
  };
  const onStaffAdd = () => {
    console.log("onStaffAdd");
    setCurrentPage("addStaff");
    navigate("/addstaff", { replace: true });
  };
  const onStaffAttendence = () => {
    console.log("onStaffAttendence");
    setCurrentPage("staffAttendance");
    navigate("/staffattendance", { replace: true });
  };
  const onStaffSalary = () => {
    console.log("onStaffSalary");
    setCurrentPage("staffSalary");
    navigate("/staff-salaryDetails", { replace: true });
  };
  const onStaffPaySalary = () => {
    console.log("onStaffPaySalary");
    setCurrentPage("paySalary");
    navigate("/staff-salaryPay", { replace: true });
  };
  const onBankDetails = () => {
    console.log("onBankDetails");
    setCurrentPage("bankDetails");
    navigate("/staffBankDetail", { replace: true });
  };

  // library
  const onBookEntry = () => {
    console.log("onBookEntry");
    setCurrentPage("bookEntry");
    navigate("/libraryClick", { replace: true });
  };
  const onBookList = () => {
    console.log("onBookList");
    setCurrentPage("bookList");
    navigate("/booksList", { replace: true });
  };
  const onBookTypewise = () => {
    console.log("onBookTypewise");
    setCurrentPage("bookListType");
    navigate("/listBookTypeWise", { replace: true });
  };
  const onBookBorrow = () => {
    console.log("onBookBorrow");
    setCurrentPage("bookBorrow");
    navigate("/borrowBook", { replace: true });
  };
  const onSearch = () => {
    console.log("onSearch");
    setCurrentPage("bookSearch");
    navigate("/searchBook", { replace: true });
  };
  const onBorrowReturn = () => {
    console.log("onSearch");
    setCurrentPage("book");
    navigate("/book ", { replace: true });
  };

  const onLogout = () => {
    console.log("onLogout");
    navigate("/launchpadlogin", { replace: true });
  };
  const onAtendenceEntry = () => {
    console.log("onLogout");
    setCurrentPage("dailyAttendance");
    navigate("/attendance", { replace: true });
  };
  const onAtendenceReortApprovel = () => {
    console.log("onLogout");
    setCurrentPage("approveAttendance");
    navigate("/attendenceApproval", { replace: true });
  };
  const onAtendenceReort = () => {
    console.log("onLogout");
    setCurrentPage("attendanceReport");
    navigate("/attendenceReport", { replace: true });
  };

  const onHallTicket = () => {
    console.log("onHallTicket");
    setCurrentPage("hallTicket");
    navigate("/reportClick", { replace: true });
  };
  const onStaffInformation = () => {
    console.log("onStaffInformation");
    setCurrentPage("allStaffInfo");
    navigate("/staffReport", { replace: true });
  };
  const onStudentInformation = () => {
    console.log("onStudentInformation");
    setCurrentPage("allStudentInfo");
    navigate("/studentReport", { replace: true });
  };
  const onExamResult = () => {
    console.log("onExamResult");
    setCurrentPage("examResult");
    navigate("/studentExamResult", { replace: true });
  };
  const onIdcards = () => {
    console.log("onIdcards");
    setCurrentPage("idCard");
    navigate("/studentIdCards", { replace: true });
  };

  //settings
  const onExamTime = () => {
    console.log("onExamTime");
    setCurrentPage("setExamTimings");
    navigate("/settingClick", { replace: true });
  };
  const onfees = () => {
    console.log("onLogout");
    navigate("/FeeClick", { replace: true });
  };

  const onFeeCollection = () => {
    console.log("onLogout");
    navigate("/FeesCollection", { replace: true });
  };

  const onEditStudentsFeesTag = () => {
    console.log("onLogout");
    navigate("/EditStudentsFeesTag", { replace: true });
  };

  const onEditStudentsFeesTag1 = () => {
    console.log("onLogout");
    navigate("/EditStudentsFeesTag1", { replace: true });
  };

  const onFeeReport = () => {
    console.log("Fee Reporrt");
    navigate("/FeeReport", { replace: true });
  };

  const onCollection = () => {
    console.log("onLogout");
    navigate("/Collection", { replace: true });
  };
  const onRights = () => {
    console.log("onRights");
    navigate("/rightsmanagement", { replace: true });
  };
  const onUser = () => {
    console.log("onUser");
    setCurrentPage("userManagement");
    navigate("/userEntry", { replace: true });
  };
  const onBackUp = () => {
    console.log("onBackUp");
    setCurrentPage("backUp");
    navigate("/backup", { replace: true });
  };

  //---------------------------------------------------------------- AFTER FINAL EXAM----------------------------------------------------------------
  const onfreeze = () => {
    console.log("onfreeze");
    setCurrentPage("freezeData");
    navigate("/freezedata", { replace: true });
  };
  const onNewAddmisson = () => {
    console.log("onNewAddmisson");
    setCurrentPage("newAdmission");
    navigate("/newadmission", { replace: true });
  };
  const onNextAcademicYear = () => {
    console.log("onNewAddmisson");
    // setCurrentPage("startNextyear");
    navigate("/after", { replace: true });
  };

  //staffinfo
  const onStudentInfo = () => {
    console.log("onStudentInfo");
    navigate("/studentsearch", { replace: true });
  };
  const onSelectStudent = () => {
    console.log("onStudentInfo");
    navigate("/studentsearch", { replace: true });
    // navigate("/studentInfo", { replace: true });
  };
  const onSelectInfo = () => {
    console.log("onStudentInfo");
    setCurrentPage("info");
    navigate("/studentInfo", { replace: true });
  };
  const onSelectAttendence = () => {
    console.log("onSelectAttendence");
    setCurrentPage("studentAttendance");
    navigate("/studentAttendance", { replace: true });
  };
  const onStudentFeeReceipt = () => {
    console.log("onStudentFeeReceipt");
    setCurrentPage("studentFee");
    navigate("/studentFeeRecipt", { replace: true });
  };
  const onStudentExam = () => {
    console.log("onStudentExam");
    setCurrentPage("studentExam");
    navigate("/studentExam", { replace: true });
  };
  const onStudentLibrary = () => {
    console.log("onStudentLibrary");
    setCurrentPage("studentLibrary");
    navigate("/studentInfoLibrary", { replace: true });
  };
  const onUpload = () => {
    console.log("onUpload");
    setCurrentPage("studentUpload");
    navigate("/studentUpload", { replace: true });
  };
  const onSupplies = () => {
    console.log("onSupplies");
    navigate("/studentSupplies", { replace: true });
  };
  const onDocuments = () => {
    console.log("onDocuments");
    navigate("/studentDocument", { replace: true });
  };
  const onRemarks = () => {
    console.log("onRemarks");
    navigate("/studentRemarks", { replace: true });
  };
  const onGovtinfo = () => {
    console.log("onGovtinfo");
    navigate("/studentGovernmentRecords", { replace: true });
  };
  const onRoutine = () => {
    console.log("onGovtinfo");
    navigate("/classClick", { replace: true });
  };
  const onClassRoutine = () => {
    console.log("onGovtinfo");
    setCurrentPage("classRoutine");
    navigate("/classroutine", { replace: true });
  };
  const onTeacherRoutine = () => {
    console.log("onGovtinfo");
    setCurrentPage("teacherRoutine");
    alert("Teacher Routine coming soon...");
    // navigate("/teacherroutine", { replace: true });
  };

  const studentClick = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 4000);
  };
  // const onAtendence = () => { };

  // * "========================Route===================================="

  const [LaunchPadDisplay, setLaunchpadDisplay] = React.useState("none");
  const [dataEntryDisplay, setDataEntryDisplay] = React.useState("none");

  return (
    <div>
      <AppBar position="static" color="transparent">
        <Container maxWidth="xl">
          <Toolbar sx={{ height: 140 }} disableGutters>
            <Box marginTop={2}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              {/* <Tooltip title="writting future"> */}
              <Avatar
                variant="square"
                sx={{
                  width: 90,
                  height: 90,
                }}
                alt="MySchool Book"
                src={schoolImg ? schoolImg : ""}
              />
              {/* </Tooltip> */}
              <Typography
                variant="subtitle1"
                noWrap
                component="div"
                fontWeight="bold"
                sx={{ flexGrow: 1 }}
              >
                {userData.userName ? userData.userName : "ADMIN"}
                {/* Academic Year : {academicYear ? academicYear : "invalid year"} */}
              </Typography>
            </Box>
            <Box marginTop={2}
              sx={{
                flexGrow: 1,
                display: {
                  xs: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                {schoolName}
                {/* VENUS INTERNATIONAL SCHOOL */}
              </Typography>
              {/* <Tooltip sx={{}} title="writting future"> */}
              <IconButton sx={{ p: 0 }}>
                <Avatar
                  alt="Myschool Book"
                  src="ms2.jpg"
                  variant="square"
                  sx={{
                    width: 145,
                    height: 80,
                  }}
                  onClick={onLaunchPad}
                />
              </IconButton>
              {/* </Tooltip> */}
            </Box>

            <Box marginTop={2}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              {/* <Tooltip title="writting future"> */}
              <Avatar
                alt="Myschool Book"
                src="logo2.png"
                variant="square"
                sx={{
                  width: 90,
                  height: 90,
                }}
              />
              {/* </Tooltip> */}
              <Typography
                variant="subtitle1"
                noWrap
                component="div"
                fontWeight="bold"
                sx={{ flexGrow: 1 }}
              >
                MySchool BooK
              </Typography>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          flexGrow: 1,
          border: "2px solid black",
          display: { LaunchPadDisplay },
          background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
        }}
      >
        <Grid
          container
          spacing={2}
          py={0.5}
          display={props.id == "launchpad" ? "" : "none"}
        >
          <Grid item md={1} xs={3}>
            <Button
              fullWidth
              size="small"
              onClick={onAtendenceEntry}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              disabled={
                userData.designation == "ADMIN" ||
                userData.designation == "teacher" ||
                userData.designation == "administrator"
                  ? false
                  : true
              }
            >
              {/* <Button fullWidth size="small" sx={{ border: "2px solid" }}> */}
              ATTENDANCE
            </Button>
          </Grid>

          <Grid item md={1.2} xs={3}>
            <Button
              fullWidth
              size="small"
              onClick={onStudentInfo}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              disabled={
                userData.designation == "ADMIN" ||
                userData.designation == "teacher" ||
                userData.designation == "administrator" ||
                userData.designation == "principal" ||
                userData.designation == "student"
                  ? false
                  : true
              }
            >
              {/* <Button fullWidth size="small" sx={{ border: "2px solid" }}> */}
              STUDENTS INFO
            </Button>
          </Grid>

          <Grid item md={1.5} xs={3}>
            <Button
              fullWidth
              size="small"
              onClick={onRoutine}
              // onClick={(e) => {
              //   alert("Coming Soon !");
              // }}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              disabled={
                userData.designation == "ADMIN" ||
                userData.designation == "teacher" ||
                userData.designation == "administrator"
                  ? false
                  : true
              }
            >
              {/* <Button fullWidth size="small" sx={{ border: "2px solid" }}> */}
              CLASS ROUTINE
            </Button>
          </Grid>

          <Grid item md={0.8} xs={3}>
            <Button
              fullWidth
              size="small"
              onClick={onStaff}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              disabled={
                userData.designation == "ADMIN" ||
                userData.designation == "teacher" ||
                userData.designation == "administrator"
                  ? false
                  : true
              }
            >
              {/* <Button fullWidth size="small" sx={{ border: "2px solid" }}> */}
              STAFF
            </Button>
          </Grid>

          <Grid item md={1} xs={3}>
            <Button
              fullWidth
              size="small"
              onClick={onBookEntry}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              disabled={
                userData.designation == "ADMIN" ||
                userData.designation == "librarian" ||
                userData.designation == "administrator"
                  ? false
                  : true
              }
            >
              {/* <Button fullWidth size="small" sx={{ border: "2px solid" }}> */}
              LIBRARY
            </Button>
          </Grid>

          <Grid item md={1} xs={3}>
            <Button
              fullWidth
              size="small"
              onClick={onfees}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              disabled={
                userData.designation == "ADMIN" ||
                userData.designation == "accountant" ||
                userData.designation == "administrator"
                  ? false
                  : true
              }
            >
              {/* <Button fullWidth size="small" sx={{ border: "2px solid" }}> */}
              FEES
            </Button>
          </Grid>

          <Grid item md={1} xs={3}>
            <Button
              fullWidth
              size="small"
              onClick={onDataEntry}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              disabled={
                userData.designation == "ADMIN" ||
                userData.designation == "principal" ||
                userData.designation == "administrator"
                  ? false
                  : true
              }
            >
              {/* <Button fullWidth size="small" sx={{ border: "2px solid" }}> */}
              DATA ENTRY
            </Button>
          </Grid>
          <Grid item md={1} xs={3}>
            <Button
              fullWidth
              size="small"
              onClick={onHallTicket}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              disabled={
                // userData.designation == "principal" ||
                userData.designation == "ADMIN" ||
                userData.designation == "administrator"
                  ? false
                  : true
              }
            >
              {/* <Button fullWidth size="small" sx={{ border: "2px solid" }}> */}
              REPORTS
            </Button>
          </Grid>
          <Grid item md={1} xs={3}>
            <Button
              fullWidth
              size="small"
              onClick={onExamTime}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              disabled={
                userData.designation == "administrator" ||
                userData.designation == "ADMIN"
                  ? false
                  : true
              }
            >
              {/* <Button fullWidth size="small" sx={{ border: "2px solid" }}> */}
              SETTINGS
            </Button>
          </Grid>
          <Grid item md={1.5} xs={3}>
            <Button
              fullWidth
              size="small"
              onClick={onNextAcademicYear}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              disabled={
                userData.designation == "administrator" ||
                userData.designation == "ADMIN"
                  ? false
                  : true
              }
            >
              {/* <Button fullWidth size="small" sx={{ border: "2px solid" }}> */}
              AFTER FINAL EXAM
            </Button>
          </Grid>
          <Grid item md={1} xs={3}>
            <Button
              fullWidth
              color="error"
              size="small"
              onClick={onLogout}
              sx={{
                "&:hover": {
                  color: "white",
                  backgroundColor: "Red",
                },
                fontWeight: "bold",
              }}
            >
              {/* <Button fullWidth size="small" sx={{ border: "2px solid" }}> */}
              LOGOUT
            </Button>
          </Grid>
        </Grid>
        {/* ------------------------------staff----------------------------- */}
        {/* <Grid
          container
          spacing={2}
          py={0.5}
          display={props.id == "dataentry" ? "" : "none"}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="warning"
              fullWidth
              size="small"
              sx={{ fontWeight: "bold" }}
            >
              DATA ENTRY
            </Button>
          </Grid>
          <Grid item xs={1.6}>
            <Button
              fullWidth
              size="small"
              className={`${getButtonClass("blankApplication")}`}
              onClick={onBlankEntry}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              BLANK APPLICATION
            </Button>
          </Grid>
          <Grid item xs={1.8}>
            <Button
              fullWidth
              size="small"
              className={`${getButtonClass("examEntryForm")}`}
              onClick={onExamEntryForm}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              EXAM MARKS ENTRY FORM
            </Button>
          </Grid>
          <Grid item xs={1.4}>
            <Button
              fullWidth
              size="small"
              className={`${getButtonClass("examEntry")}`}
              onClick={onExamEntry}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              EXAM MARKS ENTRY
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              onClick={onStudentAdd}
              className={`${getButtonClass("addStudent")}`}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              ADD STUDENT
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              size="small"
              className={`${getButtonClass("studentApprove")}`}
              onClick={onStudentApprove}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              ASSIGN DIVISION TO STUDENTS
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              className={`${getButtonClass("events")}`}
              onClick={onEvents}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              EVENTS
            </Button>
          </Grid>
        </Grid> */}
        {/* ----------------------library-------------------- */}
        {/* <Grid
          container
          spacing={2}
          py={0.5}
          display={props.id == "library" ? "" : "none"}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Button
              size="small"
              variant="contained"
              color="warning"
              sx={{ fontWeight: "bold" }}
            >
              LIBRARY
            </Button>
          </Grid>
          <Grid item xs={1.2}>
            <Button
              fullWidth
              size="small"
              className={`${getButtonClass("bookEntry")}`}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              onClick={onBookEntry}
            >
          
              BOOKS ENTRY
            </Button>
          </Grid>
          <Grid item xs={1.2}>
            <Button
              fullWidth
              size="small"
              className={`${getButtonClass("bookList")}`}
              onClick={onBookList}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
           
              LIST ALL BOOKS
            </Button>
          </Grid>
          <Grid item xs={1.8}>
            <Button
              fullWidth
              size="small"
              className={`${getButtonClass("bookListType")}`}
              onClick={onBookTypewise}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
           
              LIST BOOKS TYPE WISE
            </Button>
          </Grid>
          <Grid item xs={1.8}>
            <Button
              fullWidth
              size="small"
              onClick={onBookBorrow}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              className={`${getButtonClass("bookBorrow")}`}
            >
           
              LIST BOOKS BORROWED
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <Button
              fullWidth
              size="small"
              className={`${getButtonClass("bookSearch")}`}
              onClick={onSearch}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
             
              SEARCH A BOOK
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <Button
              fullWidth
              size="small"
              className={`${getButtonClass("book")}`}
              onClick={onBorrowReturn}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
          
              BORROW / RETURN
            </Button>
          </Grid>
        </Grid> */}
        {/* -------------------------stdent info----------------------- */}
        <Grid
          container
          spacing={2}
          py={0.5}
          display={props.id == "studentinfo" ? "" : "none"}
        >
          <Grid item xs={0.4} md={0.4}></Grid>
          <Grid item xs={3} md={1.4}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              sx={{ fontWeight: "bold" }}
              onClick={onSelectStudent}
            >
              STUDENT’S INFO
            </Button>
          </Grid>

          <Grid item xs={2.6} md={1}>
            <Button
              fullWidth
              size="small"
              onClick={studentClick}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              INFORMATION
            </Button>
          </Grid>
          <Grid item xs={3} md={1.5}>
            <Button
              fullWidth
              size="small"
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              onClick={studentClick}
            >
              ATTENDANCE
            </Button>
          </Grid>
          <Grid item xs={2.6} md={0.6}>
            <Button
              fullWidth
              size="small"
              onClick={studentClick}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              FEES
            </Button>
          </Grid>
          <Grid item xs={3} md={0.6}>
            <Button
              fullWidth
              size="small"
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
              onClick={studentClick}
            >
              EXAMS
            </Button>
          </Grid>
          <Grid item xs={3} md={1}> 
            <Button
              fullWidth
              size="small"
              onClick={studentClick}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              LIBRARY
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              fullWidth
              size="small"
              onClick={studentClick}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              SUPPLIES
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              fullWidth
              size="small"
              onClick={studentClick}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              DOCUMENTS
            </Button>
          </Grid>
          <Grid item xs={3} md={1.5}>
            <Button
              fullWidth
              size="small"
              onClick={studentClick}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              REMARKS
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              fullWidth
              size="small"
              onClick={studentClick}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              GOVT INFO
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              fullWidth
              size="small"
              onClick={studentClick}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              UPLOADS
            </Button>
          </Grid>
        </Grid>
        {/* ----------------------staff -------------------- */}
        {/* <Grid
          container
          py={0.5}
          spacing={2}
          display={props.id == "staff" ? "" : "none"}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Button
              size="small"
              variant="contained"
              color="warning"
              sx={{ fontWeight: "bold" }}
              onClick={onStaff}
            >
              STAFF
            </Button>
          </Grid>
          <Grid item xs={1.45}>
            <Button
              size="small"
              className={`${getButtonClass("staffAttendance")}`}
              onClick={onStaffAttendence}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              ATTANDANCE
            </Button>
          </Grid>
          <Grid item xs={1.45}>
            <Button
              size="small"
              className={`${getButtonClass("staffList")}`}
              onClick={onStaffList}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              LIST STAFF
            </Button>
          </Grid>
          <Grid item xs={1.45}>
            <Button
              size="small"
              className={`${getButtonClass("staffSalary")}`}
              onClick={onStaffSalary}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              SALARIES DETAIL
            </Button>
          </Grid>
          <Grid item xs={1.45}>
            <Button
              size="small"
              className={`${getButtonClass("paySalary")}`}
              onClick={onStaffPaySalary}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              PAY SALARIES
            </Button>
          </Grid>
          <Grid item xs={1.45}>
            <Button
              size="small"
              className={`${getButtonClass("bankDetails")}`}
              onClick={onBankDetails}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              DETAIL TO BANK
            </Button>
          </Grid>
          <Grid item xs={1.45}>
            <Button
              size="small"
              className={`${getButtonClass("addStaff")}`}
              onClick={onStaffAdd}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              ADD NEW
            </Button>
          </Grid>
        </Grid> */}
        {/* ---------------------Attendence------------------- */}
        {/* <Grid
          container
          spacing={2}
          py={0.5}
          display={props.id == "attendence" ? "" : "none"}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              sx={{ fontWeight: "bold" }}
            >
              Attendance
            </Button>
          </Grid>
          <Grid item xs={2.8}>
            <Button
              size="small"
              className={`${getButtonClass("dailyAttendance")}`}
              onClick={onAtendenceEntry}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              DAILY ATTENDANCE ENTRY
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={onAtendenceReortApprovel}
              className={`${getButtonClass("approveAttendance")}`}
              size="small"
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              APPROVE ATTENDANCE ENTRY
            </Button>
          </Grid>
          <Grid item xs={2.7}>
            <Button
              size="small"
              onClick={onAtendenceReort}
              className={`${getButtonClass("attendanceReport")}`}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              ATTENDANCE REPORT
            </Button>
          </Grid>
        </Grid> */}
        {/* ------------------------Fees----------------------- */}
        <Grid
          container
          spacing={1}
          py={0.5}
          display={props.id == "fees" ? "" : "none"}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              sx={{ fontWeight: "bold" }}
              onClick={onfees}
            >
              fees
            </Button>
          </Grid>

          <Grid item xs={1.5}>
            <Button
              // onClick={onFeeCollection}
              size="small"
              sx={{
                color: "white",
                backgroundColor: "black",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "black", color: "white" },
              }}
            >
              Fees collection
            </Button>
          </Grid>

          <Grid item xs={2.5}>
            <Button
              // onClick={onEditStudentsFeesTag}
              size="small"
              sx={{
                color: "black",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "black", color: "white" },
              }}
            >
              Edit students fees tag(in group)
            </Button>
          </Grid>

          <Grid item xs={2.5}>
            <Button
              fullWidth
              // onClick={onEditStudentsFeesTag1}
              size="small"
              sx={{
                color: "black",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "black", color: "white" },
              }}
            >
              Edit individual student's fees tag
            </Button>
          </Grid>

          <Grid item xs={2}>
            <Button
              fullWidth
              // onClick={onFeeReport}
              size="small"
              sx={{
                color: "black",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "black", color: "white" },
              }}
            >
              Fee reports
            </Button>
          </Grid>
        </Grid>
        {/* -----------------------reports------------------- */}
        {/* <Grid
          container
          spacing={2}
          py={0.5}
          display={props.id == "reports" ? "" : "none"}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              variant="contained"
              color="warning"
              size="small"
              sx={{ fontWeight: "bold" }}
            >
              REPORTS
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <Button
              size="small"
              className={`${getButtonClass("hallTicket")}`}
              onClick={onHallTicket}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              HALL TICKET
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              size="small"
              className={`${getButtonClass("allStaffInfo")}`}
              onClick={onStaffInformation}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              ALL INFORMATION STAFF
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              size="small"
              className={`${getButtonClass("allStudentInfo")}`}
              onClick={onStudentInformation}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              ALL INFORMATION STUDENTS
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <Button
              size="small"
              
             onClick={()=> (
              alert("coming soon")
             )}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              EXAM RESULT
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <Button
              size="small"
              className={`${getButtonClass("idCard")}`}
              onClick={onIdcards}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              ID CARDS
            </Button>
          </Grid>
        </Grid> */}
        {/* -----------------------after exam------------------- */}
        <Grid
          container
          spacing={2}
          py={0.5}
          display={props.id == "afterfinalexam" ? "" : "none"}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={1.5}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              sx={{ fontWeight: "bold" }}
            >
              AFTER FINAL EXAM
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              size="small"
              // className={`${getButtonClass("startNextYear")}`}
              onClick={onNextAcademicYear}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },

                fontWeight: "bold",
              }}
            >
              START NEXT ACADEMIC YEAR
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              size="small"
              className={`${getButtonClass("newAdmission")}`}
              onClick={onNewAddmisson}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              NEW ADMISSION FOR NEXT ACADEMIC YEAR{" "}
            </Button>
          </Grid>
          <Grid item xs={3.2}>
            <Button
              size="small"
              onClick={onfreeze}
              className={`${getButtonClass("freezeData")}`}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              FREEZE DATA AND MIGRATE FOR NEXT ACADEMIC YEAR{" "}
            </Button>
          </Grid>
        </Grid>
        {/* ---------------------setting------------------- */}
        <Grid
          container
          spacing={2}
          py={0.5}
          display={props.id == "settings" ? "" : "none"}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              sx={{ fontWeight: "bold" }}
            >
              SETTINGS
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              size="small"
              className={`${getButtonClass("setExamTimings")}`}
              onClick={onExamTime}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              SET EXAM TIMINGS
            </Button>
          </Grid>

          <Grid item xs={2}>
            <Button
              fullWidth
              size="small"
              className={`${getButtonClass("userManagement")}`}
              onClick={onUser}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              USER MANAGEMENT
            </Button>
          </Grid>

          {/* <Grid item xs={2}>
            <Button
              fullWidth
              size="small"
              onClick={onBackUp}
              className={`${getButtonClass("backUp")}`}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
              {" "}
              BACKUP
            </Button>
          </Grid> */}
          <Grid item xs={2.5}></Grid>
        </Grid>
        {/* ---------------------------class routine----------------- */}
        {/* <Grid
          container
          spacing={2}
          py={0.5}
          display={props.id == "routine" ? "" : "none"}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              onClick={onRoutine}
              sx={{ fontWeight: "bold" }}
            >
              ROUTINE
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              size="small"
              onClick={onClassRoutine}
              className={`${getButtonClass("classRoutine")}`}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
            
              CLASS ROUTINE
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              size="small"
              onClick={onTeacherRoutine}
              className={`${getButtonClass("teacherRoutine")}`}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                fontWeight: "bold",
              }}
            >
             
              TEACHER ROUTINE
            </Button>
          </Grid>
          <Grid item xs={4.5}></Grid>
        </Grid> */}
      </Box>

      <Modal
        open={open}
        // onClose={() => setSucess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <SucessModal
            text="Had You Selected The Student ? "
            name="Had You Selected The Student ? "
          />
        </div>
      </Modal>
    </div>
  );
};
export default LaunchpadTopBar;
