// import * as React from "react";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";
// import { Button, Typography } from "@mui/material";
// import { StaffService } from "../../../services/StaffService";
// import { SubjectService } from "../../../services/subjectService";

// const Item = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(1),
//   textAlign: "center",
//   border: "2px solid black",
// }));

// export default function BasicGrid() {
//   React.useEffect(() => {
//     onStaffLoad();
//     onSubjectLoad();
//   }, []);

//   const [staff, setStaff] = React.useState([]);
//   const onStaffLoad = async () => {
//     try {
//       let response = await StaffService.staffListAll();
//       // console.log(response.data.data.staffSubjectList);
//       setStaff(response.data.data);
//     } catch (error) {
//       console.log(error);
//       // alert("Failure");
//     }
//   };

//   const [subject, setSubject] = React.useState([]);

//   const onSubjectLoad = async () => {
//     console.log("hello subject");

//     try {
//       let response = await SubjectService.subjectListAll();
//       console.log(response.data.data);

//       setSubject(response.data.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <Grid container spacing={1}>
//         <Grid item xs={6}>
//           <Typography sx={{ fontWeight: "bold" }}>
//             PRESENT SELECTED SUBJECTS CANNOT BE  REMOVED
//           </Typography>
//           <Item>
//             <Grid container spacing={1}>
//               {subject.map((element) => (
//                 <Grid item xs={2}>
//                   <Button variant="outlined" fullWidth>
//                     {element.subjectName}
//                   </Button>
//                 </Grid>
//               ))}
//             </Grid>
//           </Item>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography sx={{ fontWeight: "bold" }}>
//             SELECT MORE SUBJECT TO BE ADDED
//           </Typography>
//           <Item>
//             <Grid container spacing={1}>
//               {subject.map((element) => (
//                 <Grid item xs={2}>
//                   <Button variant="outlined" fullWidth>
//                     {element.subjectName}
//                   </Button>
//                 </Grid>
//               ))}
//             </Grid>
//           </Item>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import { Button, Typography, IconButton } from "@mui/material";
import { StaffService } from "../../../services/StaffService";
import { SubjectService } from "../../../services/subjectService";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import AlertModal from "../../buildSchool/Dashboard/popup/alertPopUp";
import { C } from "../../util/C";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "2px solid black",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid green",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};


export default function BasicGrid(props) {
  React.useEffect(() => {
    onSubjectLoad();
    console.log(props.data);
  }, []);

  const [subject, setSubject] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);

  const onSubjectLoad = async () => {
    console.log("hello subject");
    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await SubjectService.subjectListAll(yearData.yearName);
      console.log(response.data.data);

      setSubject(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [subjectSelect, setSubjectSelect] = React.useState([]);
  const onSelectSub = (e) => {
    C.c(e.currentTarget.id);
    C.c(e.currentTarget.name);

    const subObj = {
      subjectID: e.currentTarget.id,
      subjectName: e.currentTarget.name,
    };

    staffSubjectList.forEach((element) => {
      if (element.subjectID == e.currentTarget.id) return false;
    });

    if (
      !subjectSelect.some(
        (subjectSelect) => subjectSelect.subjectID === e.currentTarget.id
      )
    ) {
      setSubjectSelect((subjectSelect) => [...subjectSelect, subObj]);
      C.c(subjectSelect);
    } else {
      // alert(`already selected ${e.currentTarget.name}`);
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2000);
    }
  };

  const onSubjectDel = (e) => {
    C.c(e.currentTarget.id);
    const found = subjectSelect.find(
      (element) => element.subjectID === e.currentTarget.id
    );

    if (found) {
      setSubjectSelect(
        subjectSelect.filter((item) => item.subjectID !== found.subjectID)
      );
      C.c(subjectSelect);
    }
  };

  const [staffSubjectList, setstaffSubjectList] = React.useState(
    props.data.staffSubjectList
  );

  const onSubjectSave = async () => {
    let staffObj = props.data;
    staffObj.staffSubjectList = subjectSelect;
    // console.log(staffObj);
    try {
      let response = await StaffService.createStaff(staffObj);
      // alert("Subjects updated successfully");
      C.c(response.data.data);
      setOpen(true);
      setTimeout(()=>{
        setOpen(false);
      },1000);
    } catch (error) {
      C.c(error.message);
    }
  };

  return (
    <Box sx={{ flexGrow: 1,border:"2px solid black" }}>
      <Grid container spacing={1} >
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
              backgroundColor: "lightblue",
              width: "100%",
            }}
          >
            PRESENT SELECTED SUBJECTS
          </Typography>
          <Item>
            <Grid container spacing={1}>
              {staffSubjectList.map((element) => (
                <Grid item xs={2}>
                  <Button variant="outlined" fullWidth  
                  sx={{background: "#37bc98",fontWeight:"bold",
                  "&:hover": {
                    background: "#2ea283",
                  },
                  color: "white",}}
                  >
                    {element.subjectName}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
              backgroundColor: "lightblue",
              width: "100%",
            }}
          >
            SELECTED SUBJECTS
          </Typography>
          <Item>
            <Grid container spacing={1}>
              {subjectSelect.map((element) => (
                <Grid item xs={2}>
                  <Button variant="outlined" fullWidth sx={{background: "#37bc98",
                      "&:hover": {
                        background: "#2ea283",
                      },
                      color: "white",}}>
                    {element.subjectName}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
              backgroundColor: "lightblue",
              width: "100%",
            }}
          >
            SELECT MORE SUBJECT TO BE ADDED
          </Typography>
          {/* <Item>
            <Grid container spacing={1}>
              {subject.map((element) => (
                <Grid item xs={4}>
               
                  <Button
                    variant="outlined"
                    sx={{
                      display:"flex",
                      fontWeight: "bold",
                      background: "#37bc98",
                      "&:hover": {
                        background: "#2ea283",
                      },
                      color: "white",
                    }}
                    size="small"
                  
                  >
                    {element.subjectName}
                    <IconButton
                      aria-label="delete"
                      id={element.subjectID}
                      name={element.subjectName}
                      onClick={onSelectSub}
                      size="small"
                      color="success"
                    >
                      <CheckCircleIcon fontSize="inherit"></CheckCircleIcon>
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      id={element.subjectID}
                      onClick={onSubjectDel}
                      size="small"
                      color="error"
                    >
                      <CancelIcon fontSize="inherit"></CancelIcon>
                    </IconButton>
                  </Button>
                 
                </Grid>
              ))}
            </Grid>
          </Item> */}

<Grid
                    container
                    spacing={2}
                    sx={{
                      width: "100%",
                      padding: "0 5px",
                      boxSizing: "border-box",
                    }}
                  >
                    {subject.map((element) => (
                      <Grid item xs={4}>
                        <Item
                          id={element.subjectID}
                          sx={{
                            border:"none",
                            height:20,
                            display: "flex",
                            justifyContent: "center",
                            background: "#37bc98",
                            "&:hover": {
                              background: "#2ea283",
                            },
                          }}
                        >
                          <IconButton
                            sx={{
                              color: "white",
                              fontSize: 14,
                              fontWeight:"bold"
                            }}
                            onClick={onSelectSub}
                            id={element.subjectID}
                            name={element.subjectName}
                          >
                            {element.subjectName.toUpperCase()}
                          </IconButton>
                          {/* <Item sx={{display:"flex",}}> */}
                          <IconButton
                            aria-label="delete"
                            id={element.subjectID}
                            onClick={onSubjectDel}
                            size="small"
                            color="error"
                            sx={{float:"right"}}
                          >
                            <CancelIcon fontSize="inherit"  ></CancelIcon>
                          </IconButton>
                          {/* </Item> */}
                        </Item>
                      </Grid>
                    ))}
                  </Grid>

        </Grid>
      </Grid>
      <Box margin={2}>
        <Button variant="contained" onClick={onSubjectSave}>
          Save
        </Button>
        <Modal
                open={open}
                // onClose={() => setSucess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div sx={style}>
                  <SucessModal
                    text="Staff Subjects Updated Successfully"
                    name="Staff Subjects Updated Successfully"
                  />
                </div>
              </Modal>

              <Modal
                open={alert}
                // onClose={() => setSucess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div sx={style}>
                  <AlertModal
                    text="Already Selected This Subject"
                    name="Already Selected This Subject"
                  />
                </div>
              </Modal>

      </Box>
    </Box>
  );
}
