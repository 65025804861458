import * as React from "react";
import {
  styled,
  Box,
  Paper,
  Grid,
  AlertTitle,
  Avatar,
  Button,
  FormHelperText,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { DesignationService } from "../../../services/DesignationService";
import { SubjectService } from "../../../services/subjectService";
import { DatePicker, esES, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { C } from "../../util/C";
import { ImageService } from "../../../services/ImageService";
import { StaffService } from "../../../services/StaffService";
import { useNavigate } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CancelIcon from "@mui/icons-material/Cancel";
import { UploadFileService } from "../../../services/FileUploadService";
import { ImageConverter } from "../../../programs/imgaeToBase";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0),
  textAlign: "center",
}));

export default function BasicGrid() {
  React.useEffect(() => {
    onDesignationload();
    onSubjectLoad();
  }, []);

  const handleOpen = () => setOpen(true);

  const [doj, setDoj] = React.useState(null);
  const [dob, setDob] = React.useState(null);

  const [designation, setDesignation] = React.useState([]);
  const [designationVal, setDesignationVal] = React.useState("");

  // const [addStaff, setAddStaff] = React.useState([]);

  const onDesignationload = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await DesignationService.designationListAll(data.yearName);
      console.log(response.data.data);
      setDesignation(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const [subject, setSubject] = React.useState([]);
  const onSubjectLoad = async () => {
    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    console.log("hello subject");
    try {
      let response = await SubjectService.subjectListAll(yearData.yearName);
      setSubject(response.data.data);
      C.c(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // const handleChange = (event) => {
  //   setDesignationVal(event.target.value);
  // };
  const [teaching, setTeaching] = React.useState("");

  const [subjectSelect, setSubjectSelect] = React.useState([]);
  const onSelectSub = (e) => {
    C.c(e.currentTarget.id);
    C.c(e.currentTarget.name);

    const subObj = {
      subjectID: e.currentTarget.id,
      subjectName: e.currentTarget.name,
    };

    if (
      !subjectSelect.some(
        (subjectSelect) => subjectSelect.subjectID === e.currentTarget.id
      )
    ) {
      setSubjectSelect((subjectSelect) => [...subjectSelect, subObj]);
      C.c(subjectSelect);
    } else {
      // alert(`already selected ${e.currentTarget.name}`);
      setAlert(true);
    }
  };

  const onSubjectDel = (e) => {
    C.c(e.currentTarget.id);
    const found = subjectSelect.find(
      (element) => element.subjectID === e.currentTarget.id
    );

    C.c(found);
    if (found) {
      setSubjectSelect(
        subjectSelect.filter((item) => item.subjectID !== found.subjectID)
      );
      C.c(subjectSelect);
    }
  };
  const handleAlertOpen = () => setAlert(true);
  const [openAlert, setAlert] = React.useState(false);
  // const [alertVal, setAlertVal] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState("");
  const [imageSrc, setImageSrc] = React.useState();
  const [staffName, setStaffName] = React.useState("");
  const [salary, setSalary] = React.useState("");
  const [nameErrorText, setNameErrorText] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");

  const [phoneNoErrorText, setPhoneNoErrorText] = React.useState("");

  const [salaryErrorText, setSalaryErrorText] = React.useState("");
  const [profession, setProfession] = React.useState("");
  const [professionSelected, setProfessionSelected] = React.useState(null);
  const [professionErrorText, setProfessionErrorText] = React.useState(false);
  const [qualification, setQualification] = React.useState("");
  const [qualificationErrorText, setQualificationErrorText] =
    React.useState("");
  const [bank, setBank] = React.useState("");
  const [account, setAccount] = React.useState("");
  const [ifsc, setIfsc] = React.useState("");
  const [branch, setBranch] = React.useState("");
  const [pan, setPan] = React.useState("");
  const [subjectSelectUI, setSubjectSelectUI] = React.useState(true);

  const style = {
    position: "absolute",
    top: "80%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 150,
    bgcolor: "background.paper",
    border: "2px solid yellow",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
    color: "darkblue",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // overflow:"scroll"
  };
  // const fileBrowseHandler = (event) => {
  // handleFileUpload fileBrowseHandler = (event) => {
  //   let value = URL.createObjectURL(event.currentTarget.files[0]);
  //   setImageSrc(event.currentTarget.files[0]);
  //   C.c(value);
  //   setImageUrl(value);
  //   C.c(imageSrc);
  // };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await ImageConverter.convertToBase64(file);
    // setPostImage({ ...postImage, myFile: base64 });
    console.log(base64);
    setImageUrl(base64);
  };
  const textInput = React.useRef("");
  const onlyCharaters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^zA-Z ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const onlyCharatersqual = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^zA-Z. ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const onlyNumbers = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9,. ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const onlyCharNum = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9A-Z ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    // const fileData = new FormData();
    // fileData.append("file", imageSrc);
    // C.c(fileData.get("file"));
    // let fileUrl;
    // try {
    //   let response = await UploadFileService.upLoadFile(fileData);
    //   fileUrl = response.data;
    //   C.c(response.data);
    // } catch (error) {
    //   C.c(error.message);
    // }
    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    console.log(yearData);

    // const found = designation.find(
    //   (element) => element.designationID == data.get("designation")
    // );
    let staffOBJ = {
      staffName: data.get("staffName"),
      designation: designationVal,
      // designationID: data.get("designation"),
      staffType: data.get("designationType"),
      staffDOB: dob.toLocaleDateString("en-GB"),
      staffQualification: data.get("qualification"),
      monthlySalary: data.get("salary"),
      staffMobileNo: [data.get("phoneNo")],
      stafDOJ: doj.toLocaleDateString("en-GB"),
      // staffClass:data.get("staffClass"),
      staffProfilePhoto: imageUrl,
      staffSubjectList: subjectSelect,
      // staffPan
      yearID: yearData.yearID,
      yearName: yearData.yearName,
    };
    console.log(staffOBJ);

    try {
      handleOpen();
      let response = await StaffService.createStaff(staffOBJ);
      C.c(response.data.data);
      let bankDetails = {
        accountNo: data.get("account_no"),
        bankName: data.get("bank"),
        branch: data.get("branch"),
        ifscDetails: data.get("ifsc_code"),
        panDetails: data.get("pan"),
        ssid: response.data.data.ssid,
        staffName: response.data.data.staffName,
        staffBankDetails: data.get("salary"),
        yearID: yearData.yearID,
        yearName: yearData.yearName,
      };
      BankSubmit(bankDetails);
    } catch (error) {
      C.c(error.message);
    }

    textInput.current.value = "";

    //VALIDATE STAFF NAME

    if (!staffName && staffName.length < 3) {
      setNameErrorText("*Enter valid name");
    } else {
      setNameErrorText("");
    }

    //VALIDATE PHONE NO
    if (!phoneNo && phoneNo.length !== 10) {
      setPhoneNoErrorText("Please enter phone number");
    } else {
      setPhoneNoErrorText("");
    }

    // VALIDATE STAFF SALARY
    if (!salary) {
      setSalaryErrorText("Please enter salary details");
    } else {
      setSalaryErrorText("");
    }

    //VALIDATE STAFF TYPE
    setProfessionErrorText(false);
    if (!professionSelected) {
      setProfessionErrorText(true);
    }

    //VALIDATE STAFF QUALIFICATION
    if (!qualification && qualification.length < 1) {
      setQualificationErrorText("*Enter qualification");
    } else {
      setQualificationErrorText("");
    }

    // let addStaff=data.get('addStaff');
    // if(addStaff.trim().length === 0){
    //   console.log(data.get('addStaff'));
    //   setAlertVal("Please enter a valid subject");
    //   handleAlertOpen();
    // }
    setStaffName("");
    setDesignationVal("");
    setTeaching("");
    setDob("");
    setQualification("");
    setSalary("");
    setPhoneNo("");
    setDoj("");
    setImageUrl("");
    setBank("");
    setAccount("");
    setIfsc("");
    setBranch("");
    setPan("");
    // subjectSelect([]);
    setSubjectSelect([]);
  };

  const BankSubmit = async (bank) => {
    try {
      let response = await StaffService.salaryDetails(bank);
      C.c(response.data.data);
    } catch (error) {
      C.c(error.message);
    }
    textInput.current.value = "";
  };
  //VALIDATE PHONE NO
  const validatephoneNo = (e) => {
    if (e.target.value.toString().length > 9) {
      e.preventDefault();
      return;
    }
  };

  let navigate = useNavigate();
  const onLogout = () => {
    console.log("onLogout");
    navigate("/staff_copy", { replace: true });
  };

  return (
    <div
      style={{
        backgroundImage: `url("bgcolor.png")`,
        backgroundSize: "cover",
      }}
    >
      {/* <LaunchpadTopBar id="staff" /> */}
      <Box component="form" onSubmit={handleSubmit} sx={{ flexGrow: 1 }}>
        <Grid container spacing={8}>
          <Grid item md={10} xs={8}>
            <Box sx={{ m: 1 ,marginLeft:3, marginTop:2}}>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: "center",
                  backgroundColor: "lightblue",
                  // my: 1,
                  fontWeight: "bold",
                }}
              >
                ADD NEW STAFF
              </Typography>
            </Box>

            <Box sx={{ m: 2 }}>
              <Grid container sx={{ marginLeft: 0 }} spacing={1}>
                <Grid item md={4} xs={6} sx={{}}>
                  {/* STAFF NAME */}
                  <TextField
                    size="large"
                    id="sraffName"
                    label="NAME"
                    style={{ color: "black" }}
                    fullWidth
                    required
                    variant="filled"
                    name="staffName"
                    value={staffName}
                    inputRef={textInput}
                    onInput={(e) => onlyCharaters(e)}
                    error={!!nameErrorText}
                    helperText={nameErrorText}
                    onChange={(e) => setStaffName(e.target.value.toUpperCase())}
                    type="string"
                  />
                </Grid>

                {/* DESIGNATION */}
                <Grid item md={4} xs={6} >
                  <FormControl fullWidth size="large">
                    <InputLabel id="designationID" style={{ color: "black" }}>
                      DESIGNATION
                    </InputLabel>
                    <Select
                      labelId="designationID"
                      id="designation"
                      value={designationVal}
                      label="Designation"
                      variant="filled"
                      name="designation"
                      required
                      size="large"
                      onChange={(e) => {
                        // const found = designation.find(
                        //   (element) => element.designationID == e.target.value
                        // );
                        // setDesignationVal(found.designation);

                        setDesignationVal(e.target.value.toUpperCase());
                      }}
                    >
                      {designation.map((element, i) => (
                        <MenuItem
                          key={element.designationID}
                          value={element.designation}
                        >
                          {element.designation}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* STAFF TYPE */}
                <Grid item md={4} xs={6}>
                  <FormControl fullWidth size="large">
                    <InputLabel style={{ color: "black"}}>
                      STAFF TYPE
                    </InputLabel>
                    <Select
                      labelId="designationTypeID"
                      id="designationType"
                      value={teaching}
                      label="Teaching"
                      variant="filled"
                      name="designationType"
                      onChange={(e) => {
                        setTeaching(e.target.value);
                        setProfessionSelected(e.target.value); //validate staffType
                        setProfession(e.target.value);
                        if (e.target.value == "NON-TEACHING") {
                          setSubjectSelectUI(false);
                        } else {
                          setSubjectSelectUI(true);
                        }
                      }}
                    >
                      <MenuItem value="TEACHING">TEACHING</MenuItem>
                      <MenuItem value="NON-TEACHING">NON-TEACHING</MenuItem>
                    </Select>
                    {professionErrorText && (
                      <FormHelperText sx={{ color: "red" }}>
                        *select profession!
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* STAFF DATE OF BIRTH */}
                <Grid item md={2.4} xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      format="dd/MM/yyyy"
                      label="DATE OF BIRTH"
                      value={dob}
                      onChange={(newDate) => {
                        setDob(newDate);
                      }}
                      sx={{ backgroundColor: "#eeeeee", width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          size="small"
                          name="DOB"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                {/* STAFF QUALIFICATION */}

                <Grid item md={2.4} xs={6}  >
                  <TextField
                    size="small"
                    variant="filled"
                    label="QUALIFICATION "
                    style={{ color: "black" }}
                    fullWidth
                    name="qualification"
                    sx={{textTransform:"uppercase"}}
                    value={qualification}
                    inputRef={textInput}
                    onInput={(e) => onlyCharatersqual(e)}
                    // InputProps={{
                    //   inputProps: {
                    //     minLength: 3,
                    //   },
                    //   style: { height: "55px",textTransform:"uppercase" },
                    // }}
                    inputProps={{style:{textTransform:"uppercase",height:"30px"}}}
                    error={!!qualificationErrorText}
                    helperText={qualificationErrorText}
                    onChange={(e) => setQualification(e.target.value.toUpperCase())}
                  />
                </Grid>
                {/* STAFF MONTHLY SALARY */}

                <Grid item md={2.4} xs={6}  >
                  <TextField
                    size="small"
                    label="MONTHLY SALARY"
                    name="salary"
                    value={salary}
                    error={!!salaryErrorText}
                    helperText={salaryErrorText}
                    onInput={(e)=> onlyNumbers(e)}
                    onChange={(e) => setSalary(e.target.value)}
                    fullWidth
                    variant="filled"
                    // type="number"
                    inputProps={{ style: { height: "30px" } }}
                  />
                </Grid>
                {/* STAFF PHONE NO */}
                <Grid item md={2.4} xs={6}>
                  <TextField
                    size="small"
                    name="phoneNo"
                    label="PHONE NO"
                    type="number"
                    maxLength="8"
                    value={phoneNo}
                    error={!!phoneNoErrorText}
                    helperText={phoneNoErrorText}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    onKeyPress={validatephoneNo}
                    fullWidth
                    variant="filled"
                    inputProps={{ style: { height: "30px" } }}
                  />
                </Grid>

                {/* STAFF DATE OF JOINING */}
                <Grid item md={2.4} xs={6}  >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      format="dd/MM/yyyy"
                      label="DATE OF JOINING"
                      value={doj}
                      onChange={(newDate) => {
                        setDoj(newDate);
                      }}
                      sx={{ backgroundColor: "#eeeeee", width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          size="small"
                          name="DOJ"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
            {/* <Box sx={{ m: 1 ,marginLeft:3, marginTop:2}}>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: "center",
                  backgroundColor: "lightblue",
                  // my: 1,
                  fontWeight: "bold",
                }}
              >
                {subjectSelectUI
                  ? "CLICK TO SELECT / DESELECT SUBJECTS"
                  : "ENTER THE BANK DETAILS"}
              </Typography>
            </Box> */}
          </Grid>
          <Grid item md={2} xs={4}>
            <Box
              sx={{
                // border: "2px solid black",
                // borderRadius: 5,
                mt: 1,
                // backgroundColor: "#f9fbe7",
                width: "162px",
                height: "166px",
              }}
            >
              <Box display={"flex"} justifyContent={"center"} >
                <Avatar
                  alt="A"
                  src={imageUrl}
                  sx={{
                    width: 150,
                    height: 160,
                    // margin: 1,
                    mt: 1,
                    // ml: 1,
                    borderRadius: 2,
                  }}
                  variant="square"
                />
                
              </Box>
              {/* ----------------------------------STAFF IMAGE--------------------------- */}
              <Box display={"flex"} justifyContent={"center"}>
                <input
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    accept="image/*"
                    name="studentImg"
                    // onChange={fileBrowseHandler}
                    onChange={handleFileUpload}
                  />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    sx={{
                      marginTop: 1,
                      borderRadius: 9,
                      fontWeight: 700,
                      // ml: "20%",
                      
                    }}
                  >
                    UPLOAD
                  </Button>
                </label>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box  sx={{ flexGrow: 1, mt: 4, ml: 2.5,marginRight: 2.5, textAlign: "center" }}>
              <Typography
             
                variant="subtitle1"
                sx={{
                  textAlign: "center",
                  backgroundColor: "lightblue",
                  // my: 1,
                  fontWeight: "bold",
                }}
              >
                {subjectSelectUI
                  ? "CLICK TO SELECT / DESELECT SUBJECTS"
                  : "ENTER THE BANK DETAILS"}
              </Typography>
        </Box>
        {subjectSelectUI ? (
          <Grid container spacing={2} sx={{width:"100%", padding:"0 5px", boxSizing:"border-box"}}>
            <Box sx={{ flexGrow: 1, mt: 4, ml: 4, textAlign: "center" }}>
              {/* <Typography sx={{ fontWeight: "bold" }}>
              CLICK TO SELECT SUBJECTS
            </Typography> */}
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sx={{
                    mt: 2,
                    border: "2px solid",
                    background:
                      " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
                  }}
                >
                  {/* <SubItem sx={{}}> */}
                  <Grid container
                   spacing={2}
                    sx={{
         
                      width: "100%",
                      padding: "0 5px",
                      boxSizing: "border-box",
                    }}>
                    {subjectSelect.map((element) => (
                      <Grid item xs={12} md={4}>
                      <Button
                      fullWidth
                        sx={{
                          
                          m: 1,
                          fontWeight: "bold",
                          background: "#37bc98",
                          "&:hover": {
                            background: "#2ea283",
                          },
                          color: "white",
                        }}
                        size="small"
                      >
                        {element.subjectName}
                      </Button>
                      </Grid>
                    ))}
                  {/* </SubItem> */}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    border: "2px solid ",
                    borderLeft:"none",
                    mt: 2,
                    backgroundImage: `url("bgcolor.png")`,
                    backgroundSize: "cover",
                    background:
                      " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
                  }}
                >
                  {/* <Box
                  sx={{
                    textAlign: "center",

                    overflowY: "scroll",
                    backgroundImage: `url("bgcolor.png")`,
                    backgroundSize: "cover",
                  }} */}
                  {/* > */}
                  {/* SUBJECTS LIST */}

                  <Grid
                    container
                    spacing={2}
                    sx={{
                      
                      width: "100%",
                      padding: "0 5px",
                      boxSizing: "border-box",
                    }}
                  >
                    {subject.map((element) => (
                      <Grid item xs={12} md={4}>
                        <Item
                          id={element.subjectID}
                          sx={{
                            // display: "flex",
                            width:"100%",
                            // marginTop:"5px",
                            // marginLeft:1,
                            // marginRight:1,
                            m: 1,
                            justifyContent: "center",
                            background: "#37bc98",
                            "&:hover": {
                              background: "#2ea283",
                            },
                          }}
                        >
                          <IconButton
                            sx={{
                              color: "white",
                              fontSize: 14,
                              fontWeight:"bold"
                            }}
                            onClick={onSelectSub}
                            id={element.subjectID}
                            name={element.subjectName}
                          >
                            {element.subjectName.toUpperCase()}
                          </IconButton>
                          {/* <Item sx={{display:"flex",}}> */}
                          <IconButton
                            aria-label="delete"
                            id={element.subjectID}
                            onClick={onSubjectDel}
                            size="small"
                            color="error"
                            sx={{float:"right"}}
                          >
                            <CancelIcon fontSize="inherit"  ></CancelIcon>
                          </IconButton>
                          {/* </Item> */}
                        </Item>
                      </Grid>
                    ))}
                  </Grid>
                  {/* </Box> */}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ) : (
          ""
        )}

        <Box sx={{ m: 2.5 }}>
          <Grid container spacing={1} backgroundColor="white" 
          sx={{
            paddingBottom:1,
            paddingLeft:1,
            paddingRight:1
          }}>
            {/* <Grid item xs={12}>
              <Item>
                <Grid container spacing={0}> */}
                  {/* <Grid item xs={2}>
                <TextField
                  id=" "
                  label="SALARY"
                  variant="filled"
                  name="salary"
                  readonly
                />
              </Grid> */}
                  {/* <Grid item xs={2}>
                    <TextField
                      id=" "
                      label="SALARY"
                      variant="filled"
                      name="salary"
                    />
                  </Grid> */}
                  
                  <Grid item md={2.4} xs={12}>
                    <TextField
                    fullWidth
                      id="bank"
                      label="BANK"
                      variant="filled"
                      value={bank}
                      name="bank"
                      inputProps={{
                        style: { textTransform: "uppercase" },
                      }}
                      onChange={(e) => {
                        onlyCharaters(e);
                        setBank(e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  <Grid item md={2.4} xs={6}>
                    <TextField
                    fullWidth
                      id="acc"
                      type="number"
                      label="ACCOUNT NO"
                      variant="filled"
                      name="account_no"
                      value={account}
                      onChange={(e) => {
                        setAccount(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item md={2.4}  xs={6}>
                    <TextField
                    fullWidth
                      id="ifsc"
                      label="IFSC CODE"
                      variant="filled"
                      value={ifsc}
                      name="ifsc_code"
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      onChange={(e) => {
                        onlyCharNum(e);
                        setIfsc(e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  <Grid item md={2.4}  xs={6}>
                    <TextField
                    fullWidth
                      id="branch"
                      label="BRANCH"
                      variant="filled"
                      value={branch}
                      name="branch"
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      onChange={(e) => {
                        onlyCharNum(e);
                        setBranch(e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  <Grid item md={2.4}  xs={6}>
                    <TextField
                    fullWidth
                      id="pan"
                      label="PAN"
                      variant="filled"
                      name="pan"
                      value={pan}
                      inputProps={{
                        maxLength: 10,
                        style: { textTransform: "uppercase" },
                      }}
                      onChange={(e) => {
                        onlyCharNum(e);
                        setPan(e.target.value.toUpperCase());
                      }}
                      // inputRef={textInput}
                    />
                  </Grid>
                </Grid>
              {/* </Item>
            </Grid>
          </Grid> */}
        </Box>

         <Box sx={{display:"flex", justifyContent:"space-evenly", mt:1}}>
                <Button
                  variant="contained"
                  type="submit"
                  color="success"
                  sx={{
                    borderRadius: "30px",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                  }}
                  // onClick={handleOpen}
                >
                  Add Staff
                </Button>
               
                <Button
            onClick={onLogout}
            variant="contained"
            sx={{
              backgroundColor: "#ca424f",
              width:"140px",
              color: "white",
              marginBottom:2,
              borderRadius: " 30px",
              fontWeight: "bold",
              fontSize: 15,
              "&:hover": { backgroundColor: "#ca424f" },
              
            }}
          >
            EXIT
          </Button>
                </Box>
           
            <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box
                  sx={{
                    justifyContent: "center",
                  }}
                ></Box>
                <Typography variant="h6" textAlign="center">
                  <b>NEW STAFF HAS BEEN CREATED SUCCESSFULLY !</b>
                </Typography>
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  OK
                </Button>
              </Box>
            </Modal>

            <Modal
              open={openAlert}
              // onClose={handleAlertClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box
                  sx={{
                    direction: "rtl",
                  }}
                >
                  <IconButton
                    color="error"
                    onClick={() => setAlert(false)}
                    aria-label="delete"
                    size="large"
                  >
                    <HighlightOffIcon fontSize="inherit" />
                  </IconButton>
                </Box>
                <Typography variant="h6" textAlign="center">
                  <b>Subject Already Exists</b>
                </Typography>
              </Box>
            </Modal>
      </Box>
    </div>
  );
}
