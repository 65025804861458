import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { Button, Checkbox, Stack, Typography } from "@mui/material";
import { AddStandarsService } from "../../../services/addStandardsService";
import { C } from "../../util/C";
import { StandardDivision } from "../../../services/StandardDIvision";
import { StudentService } from "../../../services/studentService";
import { StudentAttendenanceService } from "../../../services/studentAttendance";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",

  // color: theme.palette.text.secondary
}));
const Items = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  // color: theme.palette.text.secondary
}));
const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));
const ItemD = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#03dff9",
  "&:hover": {
    background: "#038da6",
    color: "white",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid red",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};

const columns = [
  // { field: "id", headerName: "ID", width: 90 },
  // { field: "id", headerName: "Role", width: 90 },
  {
    field: "id",
    headerName: "NO",
    width: 90,
    headerAlign: "center",
    headerClassName: "super-app-theme--header",
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
    align: "center",
    sortable: false,
  },
  {
    field: "name",
    headerName: "NAME",
    flex: 1,
    // editable: true,
    headerAlign: "center",
    align: "center",
    headerClassName: "super-app-theme--header",
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"NAME"}</strong>,
    sortable: false,
  },
  {
    field: "absent",
    headerName: "ABSENT",
    headerAlign: "center",
    align: "center",
    type: "boolean",
    flex: 1,
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"ABSENT"}</strong>,
    editable: true,
    headerClassName: "super-app-theme--header",
    sortable: false,
  },
  {
    field: "leave",
    headerName: "LEAVE",
    headerAlign: "center",
    align: "center",
    type: "boolean",
    flex: 1,
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"LEAVE"}</strong>,
    editable: true,
    headerClassName: "super-app-theme--header",
    sortable: false,
  },
];

export default function BasicGrid() {
  const [standard, setStandard] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [openSucess, setSucess] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(false);

  React.useEffect(() => {
    onstandardsLoad();
  }, []);

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      // C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      // C.c(error);
    }
  };

  const [Standardname, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [DivisionName, setDivisionName] = React.useState();
  const [Division, setDivision] = React.useState([]);
  const [studentData, setStudentData] = React.useState([]);
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const currentDate = new Date().toLocaleDateString(undefined, options);

  // const currentDate = new Date();
  // const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  // const formattedDate = currentDate.toLocaleDateString(undefined, options);

  async function onStandardSelect(e, index) {
    e.preventDefault();
    setRows("");
    setStandardName(e.target.textContent);
    setStandardID(e.target.id);
    C.c(e.currentTarget.value);

    let highlightStandardList = [...standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index][
      "isSelected"
    ]
      ? false
      : true;

    let stdId = e.currentTarget.id;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await StandardDivision.StandardDivisionWiseStandard(
        e.currentTarget.id,
        data.yearName,
        data.yearID
      );
      C.c(response.data.data);
      setDivision(response.data.data);
    } catch (error) {
      C.c(error.message);
    }
  }

  const [studentTotal, setStudentTotal] = React.useState(0);
  const [divisionID, setDivisionID] = React.useState();

  const [absent, setAbsent] = React.useState(null);
  const [leave, setLeave] = React.useState(null);

  async function onSelectDivision(e, index) {
    console.log(e.currentTarget.id);
    setDivisionID(e.currentTarget.id);

    let highlightDivision = [...Division];
    highlightDivision = highlightDivision.map(
      ({ isSelected, ...highlightDivision }) => highlightDivision
    );
    setDivision(highlightDivision);
    highlightDivision[index]["isSelected"] = highlightDivision[index][
      "isSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    let student = [];
    try {
      let response = await StudentService.approveddivisionstudentListAll(
        data.yearName,
        e.currentTarget.id,
        StandardID
      );
      console.clear();
      C.c(response.data.data);
      let sorted = response.data.data.sort((a,b)=> a.studentName.localeCompare(b.studentName));
      console.log(sorted);
      // student = response.data.data;
      let count = 0;
      sorted.forEach((rec, i) => {
        count = count + 1;
        student.push({
          id: i + 1,
          student_id: rec.studentID,
          name: rec.studentName,
          absent: false,
          leave: false,
        });
        // C(rec)
      });
      setStudentData(response.data.data);
      setRows(student);
      setStudentTotal(count);
      setAbsent(0);
      setLeave(0);
      C.c(rows);
    } catch (error) {
      C.c(error.message);
    }
  }

  // let [totalAbsent, setTotalAbsent] = React.useState();

  function onAttendence(rec, v) {
    // C.c(rec);
    // C.c(v);
    const found = rows.find((element) => element.id == rec.id);

    if (rec.field === "leave") {
      if (rec.value === true && rec.row.absent) {
        // alert("both are true");
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 3000);
        return;
      }
      found.leave = rec.value;
      if (rec.value === true) setLeave(leave + 1);
      if (rec.value === false) {
        if (leave === 0) {
          return;
        } else {
          setLeave(leave - 1);
        }
      }
    } else if (rec.field === "absent") {
      if (rec.value === true && rec.row.leave) {
        alert("both are true");
        return;
      }
      found.absent = rec.value;
      if (rec.value === true) setAbsent(absent + 1);
      if (rec.value === false) {
        if (absent === 0) {
          return;
        } else {
          setAbsent(absent - 1);
        }
      }
    }
    // C.c(rows);
  }

  const stundentAttendence = [];

  // const [open, setOpen] = React.useState(false);

  const onAttendenceApprove = async (e) => {
    setSucess(true);
    setTimeout(() => {
      setSucess(false);
    }, 2000);

    console.log("attendenceApprove");
    // C.c(rows);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    const currentDate = new Date();
    const iso8601Date = currentDate.toISOString().split("T")[0];
    const options = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);
    console.log(iso8601Date);

    // const formattedDate = currentDate.toLocaleDateString();
    // const formattedTime = currentDate.toLocaleTimeString();
    // console.log(formattedDate);
    // console.log(formattedTime);

    let studentAttendance = [];
    let status;

    rows.forEach((row) => {
      if (row.absent === true) {
        status = "absent";
      }
      if (row.leave === true) {
        status = "leave";
      }
      if (row.leave === false && row.absent === false) {
        status = "present";
      }

      const recData = studentData.find(
        (element) => element.studentID == row.student_id
      );
      // console.log(recData);

      studentAttendance.push({
        date: iso8601Date,
        dateTime: 0,
        divisionID: recData.divisionID,
        divisionName: recData.divisionName,
        monthName: month,
        standardID: recData.standardID,
        standardName: recData.standardName,
        studentAttendanceStatus: status,
        studentGender: recData.studentGender,
        studentID: row.student_id,
        studentName: row.name,
        studentUSN: recData.studentUSN,
        yearID: data.yearID,
        yearName: data.yearName,
      });
    });

    let boyAbsent = 0;
    let boyPresent = 0;
    let girlAbsent = 0;
    let girlPresent = 0;
    studentAttendance.forEach((rec) => {
      // console.log(rec);
      if (rec.studentGender == "BOY") {
        if (
          rec.studentAttendanceStatus == "absent" ||
          rec.studentAttendanceStatus == "leave"
        ) {
          boyAbsent = boyAbsent + 1;
        } else {
          boyPresent = boyPresent + 1;
        }
      }
      if (rec.studentGender == "GIRL") {
        if (
          rec.studentAttendanceStatus == "absent" ||
          rec.studentAttendanceStatus == "leave"
        ) {
          girlAbsent = girlAbsent + 1;
        } else {
          girlPresent = girlPresent + 1;
        }
      }
    });

    let studentObject = {
      absentBoys: boyAbsent,
      absentGirls: girlAbsent,
      date: iso8601Date,
      day: new Date().getDay(),
      dateTime: 0,
      divisionID: divisionID,
      divisionName: divisionID,
      monthName: month,
      presentBoys: boyPresent,
      presentGirls: girlPresent,
      standardID: StandardID,
      standardName: Standardname,
      status: "false",
      studentAttendance: studentAttendance,
      total: studentTotal,
      totalAbsent: absent,
      totalPresent: studentTotal - (absent + leave),
      yearID: data.yearID,
      yearName: data.yearName,
    };

    C.c(studentObject);
    let attendenceList;
    try {
      let response = await StudentAttendenanceService.getAbsentreport(
        iso8601Date,
        data.yearName
      );
      attendenceList = response.data.data;
      C.c(response.data.data);
    } catch (error) {
      C.c(error.message);
    }

    // new Attendence

    C.c(attendenceList);
    // C.c(attendenceList[0].standardID);
    // C.c(attendenceList[0].status);

    let attendenceStatus = false;

    if (attendenceList) {
      // console.log(StandardID);
      let found = attendenceList.find(
        (element) => element.standardID == StandardID
      );
      C.c(found);

      if (found) {
        if (found.standardID == StandardID) {
          if (found.status == "false") {
            C.c(found.studentAttendance);
            C.c(studentAttendance);
            studentAttendance.forEach((r) => {
              found.studentAttendance.forEach((v) => {
                if (r.studentUSN == v.studentUSN) {
                  r.studentAttendanceID = v.studentAttendanceID;
                }
              });
            });
            C.c(studentAttendance);

            C.c("update");
            attendenceStatus = true;
            let studentObjectupdate = {
              studentdayattendanceID: found.studentdayattendanceID,
              absentBoys: boyAbsent,
              absentGirls: girlAbsent,
              date: iso8601Date,
              day: new Date().getDay(),
              dateTime: 0,
              divisionID: divisionID,
              divisionName: divisionID,
              monthName: month,
              presentBoys: boyPresent,
              presentGirls: girlPresent,
              standardID: StandardID,
              standardName: Standardname,
              status: "false",
              studentAttendance: studentAttendance,
              total: studentTotal,
              totalAbsent: absent,
              totalPresent: studentTotal - (absent + leave),
              yearID: data.yearID,
              yearName: data.yearName,
            };
            console.log(studentObjectupdate);
            try {
              let response = await StudentAttendenanceService.createAttendance(
                studentObjectupdate
              );
              setUpdate(true);
              setTimeout(() => {
                setUpdate(false);
              }, 2000);
              // alert("Attendence successfully Update");
            } catch (error) {
              C.c(error.message);
            }
          }
        }
      } else {
        // new  student attendence
        // attendenceStatus = false;
        C.c("new");

        try {
          let response = await StudentAttendenanceService.createAttendance(
            studentObject
          );
          // setOpen(true);
          // setTimeout(() => {
          //   setOpen(false);
          // }, 2000);
          // alert("Attendence successfully Saved");
          return;
        } catch (error) {
          C.c(error.message);
        }
      }
    }
  };

  let navigate = useNavigate();
  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };

  return (
    <div>
      {/* <LaunchpadTopBar id="attendence" /> */}

      <Box sx={{ width: "100%", padding: "0 10px", boxSizing: "border-box" }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            backgroundColor: "lightblue",
            mt: 1,
            fontWeight: "bold",
          }}
        >
          SELECT STANDARD AND DIVISION TO MARK ATTENDANCE FOR TODAY - {currentDate}
        </Typography>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          padding: "0 10px",
          boxSizing: "border-box",
          mt: 1,
        }}
      >
        <Grid container spacing={1}>
          <Grid item md={8} xs={8}>
            <Item>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {standard.map((element, index) => (
                  <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      name={element.standardName}
                      onClick={(e) => {
                        onStandardSelect(e, index);
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </Item>
          </Grid>
          <Grid item md={4} xs={4}>
            <Item>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Division.map((element, index) => (
                  <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                    <ItemD
                      sx={{
                        fontWeight: "bold",
                      }}
                      onClick={(e) => {
                        onSelectDivision(e, index);
                      }}
                      className={element.isSelected ? "selected" : ""}
                      id={element.divisionName}
                    >
                      {" "}
                      {element.divisionName}
                    </ItemD>
                  </Grid>
                ))}
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>
      {/* <Grid container spacing={0}>
        <Grid Items xs={12}>
          <Items> */}
      <Box
        sx={{
          height: 410,
          mt: 1,
          width: "100%",
          padding: "0 5px",
          boxSizing: "border-box",
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
            //  color:'white',
            fontWeight: "bold",
            fontSize: "15px",
            border: "1px solid white",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          onCellEditCommit={onAttendence}
          disableColumnMenu
          hideFooter={true}
        />
      </Box>
      {/* </Items>
        </Grid>
      </Grid> */}

      {/* <Stack direction="row" mx="32%">
        <Typography
          sx={{
            padding: "5px",
            width: "110px",
            backgroundColor: "red",
            color: "white",
            textAlign:"center",
            borderRadius:"10px",
            border: "2px solid black",
            borderRight: "none",
          }}
        >
          ABSENT :  {absent}
        </Typography>
        
        <Typography
          style={{
            width: "110px",
            padding: "5px",
            backgroundColor: "lightGreen",
            color: "black",
            border: "2px solid black",
            borderRight: "none",
          }}
        >
          PRESENT :  {studentTotal - (absent + leave)}
        </Typography>
        
        <Typography
          style={{
            color: "white",
            backgroundColor:"lightblue",
            borderRadius:"10px",
            padding: "5px",
            border: "2px solid black",
            // borderRight: "none",
          }}
        >
          TOTAL :   {studentTotal}
        </Typography>
        
      </Stack> */}

      {/* <Grid container>
        <Grid item xs={4} sx={{ alignItems: "center" }}>
          <Item
            sx={{ display: "flex", justifyContent: "center", border: "none" }}
          >
            <Typography
              variant="h6"
              sx={{
                border: "2px solid #ff3131",
                width: "180px",
                borderRadius: "5px",
                backgroundColor: "#ff3131",
                color: "white",
              }}
            >
              ABSENT : {absent}
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item
            sx={{ display: "flex", justifyContent: "center", border: "none" }}
          >
            <Typography
              variant="h6"
              sx={{
                border: "2px solid green",
                width: "180px",
                borderRadius: "5px",
                backgroundColor: "green",
                color: "white",
              }}
            >
              PRESENT : {studentTotal - (absent + leave)}
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item
            sx={{ display: "flex", justifyContent: "center", border: "none" }}
          >
            <Typography
              variant="h6"
              sx={{
                border: "2px solid #39335e",
                width: "180px",
                borderRadius: "5px",
                backgroundColor: "#39335e",
                color: "white",
              }}
            >
              TOTAL : {studentTotal}
            </Typography>
          </Item>
        </Grid>
      </Grid> */}

      <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 1 }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            border: "2px solid #ff3131",
            width: "180px",
            borderRadius: "5px",
            backgroundColor: "#ff3131",
            color: "white",
          }}
        >
          ABSENT : {absent !==null ? absent: "0"}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            border: "2px solid green",
            width: "180px",
            borderRadius: "5px",
            backgroundColor: "green",
            color: "white",
          }}
        >
          PRESENT : {studentTotal - (absent + leave)}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            border: "2px solid #39335e",
            width: "180px",
            borderRadius: "5px",
            backgroundColor: "#39335e",
            color: "white",
          }}
        >
          TOTAL : {studentTotal}
        </Typography>
      </Box>

      <Box sx={{  mt: 3,display:"flex",justifyContent:"space-evenly", paddingBottom:"25px" }}>
        <Button variant="contained" onClick={onAttendenceApprove}>
          Submit for approval and close
        </Button>

        <Button
                  variant="contained"
                  onClick={onLaunchpad}
                  sx={{
                    backgroundColor: "#ca424f",
                    fontSize: 15,
                    fontWeight: "bold",
                    borderRadius: "30px",
                    width: "120px",
                    ml: 5,
                    "&:hover": { backgroundColor: "#ca424f" },
                  }}
                >
                  exit
                </Button>

        <Modal
          open={openSucess}
          // onClose={() => setSucess(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div sx={style}>
            <SucessModal
              text="Attendance Saved Successfully"
              name="Attendance Saved Successfully"
            />
          </div>
        </Modal>

        <Modal
          open={open}
          // onClose={() => setSucess(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div sx={style}>
            <SucessModal
              text="Both Absent and Leave are true"
              name="Both Absent and Leave are true"
            />
          </div>
        </Modal>

        <Modal
          open={update}
          // onClose={() => setSucess(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div sx={style}>
            <SucessModal
              text="Attendance Updated Successfully "
              name="Attendance Updated Successfully "
            />
          </div>
        </Modal>
      </Box>
    </div>
  );
}
