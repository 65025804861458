import * as React from "react";
import { useRef } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { TimeTableService } from "../../../services/TimeTableService";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../Dashboard/popup/deletePopUp";
import AlertModal from "../Dashboard/popup/alertPopUp";
import SucessModal from "../Dashboard/popup/sucessPopUp";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Modal,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  FormGroup,
} from "@mui/material";
import Topbar from "../../MainTopbar/Topbar";
import { C } from "../../util/C";
import Card from "./Card";
import Borad from "./borad";
import { AddStandarsService } from "../../../services/addStandardsService";
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";
import { max } from "date-fns";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid",

  // width: 80,
  // color: theme.palette.text.secondary
}));

const TimeItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  minWidth: 160,
  height: 50,
  border: "1px solid",
  // color: theme.palette.text.secondary
}));

const Ditem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: 80,
  border: "2px solid",
  // overflowX: "scroll",
  // overflowY: "hidden",

  // color: theme.palette.text.secondary
}));

const CheckBoxItem = styled(Paper)(({ theme }) => ({
  // padding: "70 40",
  padding: theme.spacing(1),
  width: 150,
  color: "blue",
  // padding: 5,
  textAlign: "center",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid red",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "20px",
};
export default function BasicGrid() {
  const [Loop, setLoop] = React.useState([]);
  const [Loop1, setLoop1] = React.useState([]);

  const [classes, setClass] = React.useState(0);
  const [classes1, setClass1] = React.useState(0);
  const [breaks, setBreak] = React.useState(0);
  const [breaks1, setBreak1] = React.useState(0);

  // const [session, setsession] = React.useState(true);
  const [session, setsession] = React.useState("none");

  const [totalNum, setTotalNum] = React.useState();

  const [start, setStart] = React.useState();
  const [startH, setStartH] = React.useState();
  const [startM, setStartM] = React.useState();

  const [start1, setStart1] = React.useState();
  const [start1H, setStart1H] = React.useState();
  const [start1M, setStart1M] = React.useState();

  const [end, setEnd] = React.useState();
  const [endH, setEndH] = React.useState();
  const [end1H, setEnd1H] = React.useState();
  const [endM, setEndM] = React.useState();

  const [checkBoxOne, setCheckBoxOne] = React.useState([]);
  const [checkBoxTwo, setCheckBoxTwo] = React.useState([]);

  let navigate = useNavigate();

  // * =======================window Pop=====================================
  // *delete
  const [openDelete, setDelete] = React.useState(false);
  const handleDeleteOpen = () => setDelete(true);
  const handleDeleteClose = () => setDelete(false);

  // *alert
  const [openAlert, setAlert] = React.useState(false);
  const [alertVal, setAlertVal] = React.useState("");
  const handleAlertOpen = () => setAlert(true);
  const handleAlertClose = () => setAlert(false);

  // *sucess
  const [openSucess, setSucess] = React.useState(false);
  const handleSucessOpen = () => setSucess(true);
  const handleSucessClose = () => setSucess(false);

  // * =======================window Popup end=====================================

  // const checkboxRef = useRef();

  const textInput = React.useRef("");
  const onlyCharaters = (e, callback) => {
    const value = e.target.value ? e.target.value.replace(/[^0-9]+/gi, "") : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const onNextPage = async () => {
    console.log("next page");
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: fourId,
        buildSchoolName: "timetable",
        buildSchoolStatus: "true",
        buildSchoolStep: "four",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      alert("Error creating buildSchool " + error);
    }
    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: fiveId,
        buildSchoolName: "feestructure",
        buildSchoolStatus: "false",
        buildSchoolStep: "five",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      alert("Error creating buildSchool " + error);
    }
    navigate("/dashborad", { replace: true });
    // navigate("/timetable1", { replace: true });
  };

  const choose = [
    { label: "C", id: 1 },
    { label: "B", id: 2 },
  ];

  React.useEffect(() => {
    // console.log(breaks);
    createLoop();
  }, [breaks, classes]);
  React.useEffect(() => {
    // console.log(breaks);
    createLoop1();
  }, [breaks1, classes1]);

  const onClasses = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    setClass(e.target.value);
  };
  const onBreaks = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    setBreak(e.target.value);
  };
  const onClasses1 = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    setClass1(e.target.value);
  };
  const onBreaks1 = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    setBreak1(e.target.value);
  };

  const createLoop = () => {
    let addnumber = parseInt(classes) + parseInt(breaks);
    console.log(addnumber);

    let arr = [];
    for (let i = 0; i < addnumber; i++) {
      arr[i] = i;
    }
    console.log(arr);
    setLoop(arr);
    setTotalNum(addnumber);
    // console.log(Loop.id);
  };
  const createLoop1 = () => {
    let addnumber = parseInt(classes1) + parseInt(breaks1);
    console.log(addnumber);

    let arr = [];
    for (let i = 0; i < addnumber; i++) {
      arr[i] = i;
    }
    setLoop1(arr);
  };

  const [alignment, setAlignment] = React.useState("1");

  const handleSessionChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const onSessionOne = () => {
    console.log(session);
    setsession(true);
  };
  const onSessionSecond = () => {
    console.log(session);
    setsession(false);
  };

  const handleEndChange = (e) => {
    console.log(e);

    var end = document.getElementById("end" + e).value;

    var update = e + 1;
    // if (Loop.length + 1 == "end" + e) {
    // } else {
    // }
    document.getElementById("str" + update).value = end;
    // console.log("hhhh");
  };
  const handleEndChange1 = (e) => {
    var end = document.getElementById("end1" + e).value;
    var update = e + 1;

    document.getElementById("str1" + update).value = end;
  };

  const onStandardLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    console.log("hello standard");

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      //console.log(response.data.data);

      onStdLoop(response.data.data);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };
  const [stdLoop, onStdLoop] = React.useState([]);

  const [Monday, setMonday] = React.useState(null);

  const onMonday = (e) => {
    if (e.currentTarget.checked == true) {
      setMonday("Monday");
    } else if (e.currentTarget.checked == false) {
      setMonday("");
    }
  };
  const [Tuseday, setTuseday] = React.useState(null);

  const onTuseday = (e) => {
    if (e.currentTarget.checked == true) {
      setTuseday("Tuseday");
    } else if (e.currentTarget.checked == false) {
      setTuseday("");
    }
  };
  const [Wednesday, setWednesday] = React.useState(null);

  const onWednesday = (e) => {
    if (e.currentTarget.checked == true) {
      setWednesday("Wednesday");
    } else if (e.currentTarget.checked == false) {
      setWednesday("");
    }
  };
  const [Thursday, setThursday] = React.useState(null);

  const onThursday = (e) => {
    if (e.currentTarget.checked == true) {
      setThursday("Thursday");
    } else if (e.currentTarget.checked == false) {
      setThursday("");
    }
  };
  const [Friday, setFriday] = React.useState(null);

  const onFriday = (e) => {
    if (e.currentTarget.checked == true) {
      setFriday("Friday");
    } else if (e.currentTarget.checked == false) {
      setFriday("");
    }
  };
  const [Saturday, setSaturday] = React.useState(null);

  const onSaturday = (e) => {
    if (e.currentTarget.checked == true) {
      setSaturday("Saturday");
    } else if (e.currentTarget.checked == false) {
      setSaturday("");
    }
  };
  const [Sunday, setSunday] = React.useState(null);

  const onSunday = (e) => {
    if (e.currentTarget.checked == true) {
      setSunday("Sunday");
    } else if (e.currentTarget.checked == false) {
      setSunday("");
    }
  };

  // React.useEffect(() => {
  //   onRadioChange();
  // }, []);

  let [radio, setRadio] = React.useState(null);

  const onRadioChange = (e) => {
    C.c(e.currentTarget.value);
    setRadio(e.currentTarget.value);
  };

  const [status, setStatus] = React.useState();

  const onLoadBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.buildSchoolStatusListAll(
        data.yearName
      );
      // console.log(response.data.data[0]);
      setStatus(response.data.data[3].buildSchoolStatus);
      setFourId(response.data.data[3].buildSchoolID);
      setFiveId(response.data.data[4].buildSchoolID);
    } catch (error) {
      console.error(error);
    }
  };

  const [four, setFour] = React.useState();
  const [fourId, setFourId] = React.useState();
  const [five, setFive] = React.useState();
  const [fiveId, setFiveId] = React.useState();

  const onSaveTimeTable = async () => {
    console.log("hello");

    var schoolWorkingDays = [];

    if (document.getElementById("mondayID").value) {
      //console.log(document.getElementById("mondayID").value);
      schoolWorkingDays.push(document.getElementById("mondayID").value);
    }

    if (document.getElementById("tuesdayID").value) {
      //console.log(document.getElementById("mondayID").value);
      schoolWorkingDays.push(document.getElementById("tuesdayID").value);
    }

    if (document.getElementById("wednesdayID").value) {
      //console.log(document.getElementById("mondayID").value);
      schoolWorkingDays.push(document.getElementById("wednesdayID").value);
    }

    if (document.getElementById("thursdayID").value) {
      //console.log(document.getElementById("mondayID").value);
      schoolWorkingDays.push(document.getElementById("thursdayID").value);
    }

    if (document.getElementById("fridayID").value) {
      //console.log(document.getElementById("mondayID").value);
      schoolWorkingDays.push(document.getElementById("fridayID").value);
    }

    if (document.getElementById("saturdayID").value) {
      //console.log(document.getElementById("mondayID").value);
      schoolWorkingDays.push(document.getElementById("saturdayID").value);
    }

    if (document.getElementById("sundayID").value) {
      //console.log(document.getElementById("mondayID").value);
      schoolWorkingDays.push(document.getElementById("sundayID").value);
    }

    // if (document.getElementById("standardsession" + element).value) {
    let standard_array = [];
    for (let i = 0; i < stdLoop.length; i++) {
      // C.c("standard" + i);
      C.c(stdLoop[i]);
      standard_array.push(stdLoop[i].standardName);
      // console.log(document.getElementById("standardsession" + i).value);
    }
    // schoolWorkingDays.push(document.getElementById("mondayID").value);
    // }
    var timeTableList = [];

    var timeTableMaster;

    let addnumber = parseInt(classes) + parseInt(breaks);
    // console.log(addnumber);
    for (let i = 0; i <= addnumber - 1; i++) {
      // console.log(document.getElementById("P"+i).value);
      //console.log(document.getElementById("str"+i).value);
      //console.log(document.getElementById("end"+i).value);

      var e = document.getElementById("periodTypeID" + i).value;

      console.log(e);
      //var strUser = e.options[e.selectedIndex].text;

      var timeTableObj = {
        startTime: document.getElementById("str" + i).value,
        endTime: document.getElementById("end" + i).value,
        title: document.getElementById("P" + i).value,
        typeOfPeriod: e,
      };

      timeTableList.push(timeTableObj);
    }

    let data = JSON.parse(localStorage.getItem("academicYear"));

    timeTableMaster = {
      startTime: document.getElementById("startTimeID").value,
      endTime: document.getElementById("endTimeID").value,
      classes: document.getElementById("classesID").value,
      breaks: document.getElementById("breakID").value,
      numberOfSessions: document.getElementById("breakID").value,
      schoolWorkingDays: schoolWorkingDays,
      schoolPeriodTimings: timeTableList,
      standards: standard_array,
      yearID: data.yearID,
      yearName: data.yearName,
    };
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");

    console.log(timeTableMaster);

    try {
      let response = await TimeTableService.createSchoolTimeTableMaster(
        timeTableMaster
      );
      // navigate("/dashborad", { replace: true });
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }

    console.log("#########################################");
    setAlertVal("Time Table Has been Created successfully !");
    handleAlertOpen();

    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: fourId,
        buildSchoolName: "timetable",
        buildSchoolStatus: "true",
        buildSchoolStep: "four",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      setAlertVal("Error creating buildSchool " + error);
      handleAlertOpen();
    }
    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: fiveId,
        buildSchoolName: "feestructure",
        buildSchoolStatus: "false",
        buildSchoolStep: "five",
        buildSchoolYear: data.yearName,
        yearName: data.yearName,
      });
    } catch (error) {
      setAlertVal("Error creating buildSchool " + error);
      handleAlertOpen();
    }
    setTimeout(() => {
      handleAlertClose();
    }, 2000);

    // Navigate("/dashborad", { replace: true });
  };
  React.useEffect(() => {
    onStandardLoad();
    onLoadBuildSchool();
  }, []);

  // const onSubjectLoad = async () => {
  //   console.log("hello subject");

  //   try {
  //     let response = await SubjectService.subjectListAll();
  //     //console.log(response.data.data);

  //     onStdLoop(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //     // alert("Failure");
  //   }
  // };

  // const startTime = (e) => {
  //   debugger;
  //   console.log(e.target.value);
  //   setStart(e.target.value);
  // };

  return (
    <div>
      {/* <Box component="form"> */}
      <Topbar text={"Creating Time Table for School"} />
      <Box display={status == "true" ? "none" : "block"}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item xs={10}>
              <Box
                sx={{
                  padding: 1,
                  textAlign: "center",
                }}
              >
                <Typography>
                  Kindly let us know your school runs in one session or two
                  sessions Please inform us about the two sessions days timings
                  including
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box
                sx={{
                  padding: 1,
                  textAlign: "center",
                }}
              >
                <ToggleButtonGroup
                  // color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleSessionChange}
                >
                  <ToggleButton
                    onClick={onSessionOne}
                    value="1"
                    size="small"
                    sx={{ width: 80, backgroundColor: "blue", color: "white" }}
                  >
                    1
                  </ToggleButton>

                  <ToggleButton
                    color="success"
                    onClick={onSessionSecond}
                    value="2"
                    size="small"
                    sx={{ width: 80, backgroundColor: "green", color: "white" }}
                    disabled={true}
                  >
                    2
                  </ToggleButton>
                </ToggleButtonGroup>
                {/* <Stack spacing={2} direction="row">
                <Button size="small" variant="contained" onClick={onSessionOne}>
                  1
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={onSessionSecond}
                >
                  2
                </Button>
              </Stack> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            sx={{
              border: "2px solid",
            }}
            container
            spacing={0}
          >
            <Grid item xs={12}>
              {/* <Item> */}
              <Box
                sx={{
                  padding: 1,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="blue"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Please select the days your school runs on
                </Typography>

                <Stack
                  sx={{
                    textAlign: "center",
                    display: "inline-flex",
                  }}
                  direction="row"
                  spacing={0}
                >
                  <CheckBoxItem>
                    <Typography>Monday</Typography>
                    <Checkbox
                      name="Monday"
                      id="mondayID"
                      // checked={monday}
                      value={Monday}
                      onChange={onMonday}
                    />
                  </CheckBoxItem>
                  <CheckBoxItem>
                    <Typography>Tuseday</Typography>
                    <Checkbox
                      name="Tuseday"
                      id="tuesdayID"
                      value={Tuseday}
                      onChange={onTuseday}
                    />
                  </CheckBoxItem>
                  <CheckBoxItem>
                    <Typography> Wednesday</Typography>
                    <Checkbox
                      name="Wednesday"
                      id="wednesdayID"
                      value={Wednesday}
                      onChange={onWednesday}
                    />
                  </CheckBoxItem>
                  <CheckBoxItem>
                    <Typography>Thursday</Typography>
                    <Checkbox
                      name="Thursday"
                      id="thursdayID"
                      value={Thursday}
                      onChange={onThursday}
                    />
                  </CheckBoxItem>
                  <CheckBoxItem>
                    <Typography>Friday</Typography>
                    <Checkbox
                      name="Friday"
                      id="fridayID"
                      value={Friday}
                      onChange={onFriday}
                    />
                  </CheckBoxItem>
                  <CheckBoxItem>
                    <Typography>Saturday</Typography>
                    <Checkbox
                      name="Saturday"
                      id="saturdayID"
                      value={Saturday}
                      onChange={onSaturday}
                    />
                  </CheckBoxItem>
                  <CheckBoxItem>
                    <Typography>Sunday</Typography>
                    <Checkbox
                      name="Sunday"
                      id="sundayID"
                      value={Sunday}
                      onChange={onSunday}
                    />
                  </CheckBoxItem>
                </Stack>
              </Box>
              {/* </Item> */}
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid variant="div" item xs={12}>
              <Item sx={{ overflowX: "scroll", overflowY: "hidden" }}>
                <Typography
                  color="blue"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Let us know the First Session's timings
                </Typography>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                  spacing={0}
                >
                  <Item>
                    <Typography color="blue" sx={{ mb: 1 }}>
                      Start Time
                    </Typography>
                    <Stack direction="row" spacing={0}>
                      <TextField
                        id="startTimeID"
                        // label="Hours"
                        variant="outlined"
                        size="small"
                        value={startH}
                        type="time"
                        onChange={(e) => setStartH(e.target.value)}
                        sx={{
                          width: "200px",
                        }}
                      />
                    </Stack>
                  </Item>
                  <Item>
                    <Typography color="blue" sx={{ mb: 1 }}>
                      End Time
                    </Typography>
                    <Stack direction="row" spacing={0}>
                      <TextField
                        id="endTimeID"
                        // label="Hours"
                        variant="outlined"
                        size="small"
                        type="time"
                        value={endH}
                        onChange={(e) => setEndH(e.target.value)}
                        sx={{
                          width: "200px",
                        }}
                      />
                      {/* <TextField
                      id="outlined-basic"
                      label="Minute"
                      variant="outlined"
                      size="small"
                      value={endM}
                      type="time"
                      onChange={(e) => setEndM(e.target.value)}
                      sx={{
                        width: "200px",
                      }}
                    /> */}
                    </Stack>
                  </Item>
                  <Item>
                    <Typography color="blue" sx={{ mb: 1 }}>
                      Classes
                    </Typography>
                    <TextField
                      id="classesID"
                      label="Enter"
                      variant="outlined"
                      size="small"
                      sx={{
                        width: "150px",
                      }}
                      inputRef={textInput}
                      onInput={(e) => onlyCharaters(e)}
                      InputProps={{
                        inputProps: {
                          max: 9,
                          maxLength: 1,
                        },
                      }}
                      onChange={onClasses}
                    />
                  </Item>
                  <Item>
                    <Typography color="blue" sx={{ mb: 1 }}>
                      Breaks
                    </Typography>
                    <TextField
                      id="breakID"
                      label="Enter"
                      variant="outlined"
                      size="small"
                      inputRef={textInput}
                      InputProps={{
                        inputProps: {
                          // min: 1,
                          max: 9,
                          maxLength: 1,
                        },
                      }}
                      onInput={(e) => onlyCharaters(e)}
                      sx={{
                        width: "150px",
                      }}
                      onChange={onBreaks}
                    />
                  </Item>
                </Stack>
                <Box sx={{ mt: 2, overflow: "scroll" }}>
                  <Stack
                    direction="row"
                    // divider={<Divider orientation="vertical" flexItem />}
                    spacing={0}
                  >
                    <TimeItem sx={{ height: 25 }}>
                      <Typography
                        sx={{
                          width: 60,
                          height: 20,
                        }}
                      >
                        Title
                      </Typography>
                    </TimeItem>
                    {Loop.map((i) => (
                      <TimeItem key={i} sx={{ height: 25 }}>
                        <Typography
                          id={"P" + i}
                          // defaultValue={"P" + i}
                          // size="small"
                          sx={{
                            width: 60,
                            height: 20,
                          }}
                        >
                          {"P" + i}
                        </Typography>
                      </TimeItem>
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    // divider={<Divider orientation="vertical" flexItem />}
                    spacing={0}
                  >
                    <TimeItem>
                      <Typography
                        sx={{
                          width: 60,
                          height: 20,
                        }}
                      >
                        Period Type
                      </Typography>
                    </TimeItem>
                    {Loop.map((i) => (
                      <TimeItem key={i}>
                        <Autocomplete
                          // disablePortal
                          id={"periodTypeID" + i}
                          options={choose}
                          disableClearable="true"
                          disablePortal={true}
                          sx={{ width: 110, height: 20 }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                        />
                      </TimeItem>
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    // divider={<Divider orientation="vertical" flexItem />}
                    spacing={0}
                  >
                    <TimeItem>
                      <Typography
                        sx={{
                          width: 60,
                          height: 20,
                        }}
                      >
                        START
                      </Typography>
                    </TimeItem>
                    {Loop.map((i) => (
                      <TimeItem key={i}>
                        <TextField
                          // label="Size"
                          id={"str" + i}
                          // value={i == 0 ? startH + ":" + startM : ""}
                          value={i == 0 ? startH : ""}
                          size="small"
                          type="time"
                          // readOnly
                          disabled
                          sx={{
                            width: 140,
                            height: 20,
                          }}
                        />
                      </TimeItem>
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    // divider={<Divider orientation="vertical" flexItem />}
                    spacing={0}
                  >
                    <TimeItem>
                      <Typography
                        sx={{
                          width: 60,
                          height: 20,
                        }}
                      >
                        END
                      </Typography>
                    </TimeItem>
                    {/* <TimeItem>END</TimeItem>
                  <TimeItem>END</TimeItem> */}
                    {Loop.map((i) => (
                      <TimeItem key={i}>
                        <TextField
                          // label="Size"
                          id={"end" + i}
                          // value={i == Loop.length - 1 ? endH : ""}
                          // defaultValue={"P" + i}
                          onChange={(e) => handleEndChange(i)}
                          size="small"
                          type="time"
                          sx={{
                            width: 140,
                            height: 20,
                          }}
                        />
                      </TimeItem>
                    ))}
                  </Stack>
                </Box>
                <Box sx={{ m: 2 }} hidden={session}>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={stdLoop}
                    getOptionLabel={(option) => option.standardName}
                    // defaultValue={session == true ? [stdLoop[2]] : []}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Standard"
                        // placeholder=""
                      />
                    )}
                  />
                </Box>
              </Item>
            </Grid>
            <Grid variant="div" item xs={12} hidden={session}>
              <Item sx={{ overflowX: "scroll", overflowY: "hidden" }}>
                <Typography
                  color="green"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Let us know the Second Session's timings
                </Typography>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                  spacing={0}
                >
                  <Item>
                    <Typography color="green" sx={{ mb: 1 }}>
                      Start Time
                    </Typography>
                    <Stack direction="row" spacing={0}>
                      <TextField
                        id="outlined-basic"
                        // label="Hours"
                        variant="outlined"
                        size="small"
                        value={start1H}
                        type="time"
                        onChange={(e) => setStart1H(e.target.value)}
                        sx={{
                          width: "200px",
                        }}
                      />
                    </Stack>
                  </Item>
                  <Item>
                    <Typography color="green" sx={{ mb: 1 }}>
                      End Time
                    </Typography>
                    <Stack direction="row" spacing={0}>
                      <TextField
                        id="outlined-basic"
                        // label="Hours"
                        variant="outlined"
                        size="small"
                        type="time"
                        // disabled={session}
                        value={end1H}
                        onChange={(e) => setEnd1H(e.target.value)}
                        sx={{
                          width: "200px",
                        }}
                      />
                      {/* <TextField
                      id="outlined-basic"
                      label="Minute"
                      variant="outlined"
                      size="small"
                      disabled={session}
                      sx={{
                        width: "100px",
                      }}
                    /> */}
                    </Stack>
                  </Item>
                  <Item>
                    <Typography color="green" sx={{ mb: 1 }}>
                      Classes
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      label="Enter"
                      variant="outlined"
                      size="small"
                      onChange={onClasses1}
                      inputRef={textInput}
                      InputProps={{
                        inputProps: {
                          // min: 1,
                          max: 9,
                          maxLength: 1,
                        },
                      }}
                      onInput={(e) => onlyCharaters(e)}
                      sx={{
                        width: "150px",
                      }}
                    />
                  </Item>
                  <Item>
                    <Typography color="green" sx={{ mb: 1 }}>
                      Breaks
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      label="Enter"
                      variant="outlined"
                      size="small"
                      inputRef={textInput}
                      onInput={(e) => onlyCharaters(e)}
                      InputProps={{
                        inputProps: {
                          // min: 1,
                          max: 9,
                          maxLength: 1,
                        },
                      }}
                      onChange={onBreaks1}
                      sx={{
                        width: "150px",
                      }}
                    />
                  </Item>
                </Stack>
                <Box sx={{ mt: 2, overflow: "scroll" }}>
                  <Stack
                    direction="row"
                    // divider={<Divider orientation="vertical" flexItem />}
                    spacing={0}
                  >
                    <TimeItem sx={{ height: 25 }}>
                      <Typography
                        sx={{
                          width: 60,
                          height: 20,
                        }}
                      >
                        Title
                      </Typography>
                    </TimeItem>
                    {Loop1.map((i) => (
                      <TimeItem key={i} sx={{ height: 25 }}>
                        <Typography
                          // label="Size"
                          id="outlined-size-small"
                          // defaultValue={"P" + i}
                          // size="small"
                          sx={{
                            width: 60,
                            height: 20,
                          }}
                        >
                          {"P" + i}
                        </Typography>
                      </TimeItem>
                    ))}
                  </Stack>
                  <Stack
                    direction="row  "
                    // divider={<Divider orientation="vertical" flexItem />}
                    spacing={0}
                  >
                    <TimeItem>
                      <Typography
                        sx={{
                          width: 60,
                          height: 20,
                        }}
                      >
                        Period Type
                      </Typography>
                    </TimeItem>
                    {Loop1.map((i) => (
                      <TimeItem key={i}>
                        <Autocomplete
                          // disablePortal
                          id="outlined-size-small"
                          options={choose}
                          disableClearable="true"
                          disablePortal={true}
                          sx={{ width: 140, height: 20 }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                        />
                      </TimeItem>
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    // divider={<Divider orientation="vertical" flexItem />}
                    spacing={0}
                  >
                    <TimeItem>
                      <Typography
                        sx={{
                          width: 60,
                          height: 20,
                        }}
                      >
                        START
                      </Typography>
                    </TimeItem>
                    {Loop1.map((i) => (
                      <TimeItem key={i}>
                        <TextField
                          // label="Size"
                          value={i == 0 ? start1H : ""}
                          id={"str1" + i}
                          // defaultValue={"P" + i}
                          size="small"
                          type="time"
                          disabled
                          sx={{
                            width: 140,
                            height: 20,
                          }}
                        />
                      </TimeItem>
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    // divider={<Divider orientation="vertical" flexItem />}
                    spacing={0}
                  >
                    <TimeItem>
                      <Typography
                        sx={{
                          width: 60,
                          height: 20,
                        }}
                      >
                        END
                      </Typography>
                    </TimeItem>
                    {Loop1.map((i) => (
                      <TimeItem key={i}>
                        <TextField
                          // label="Size"
                          id={"end1" + i}
                          // value={i == Loop1.length - 1 ? end1H : ""}
                          onChange={(e) => handleEndChange1(i)}
                          // defaultValue={"P" + i}
                          size="small"
                          type="time"
                          sx={{
                            width: 140,
                            height: 20,
                          }}
                        />
                      </TimeItem>
                    ))}
                  </Stack>
                </Box>
              </Item>
            </Grid>
          </Grid>
          <Box sx={{ m: 2 }} hidden={session}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={stdLoop}
              getOptionLabel={(option) => option.standardName}
              // defaultValue={session == true ? [stdLoop[2]] : []}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Standard"
                  // placeholder=""
                />
              )}
            />
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={8}>
              <Typography color="error" fontWeight={1000} mx={2}>
                Please select the "CLASS" and "BREAKS" as mentioned and Enter
                the "END TIME" as mentioned
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
                sx={{ m: 2 }}
              >
                <Button
                  type="submit"
                  onClick={onSaveTimeTable}
                  variant="contained"
                  disabled={status == "true" ? true : false}
                  sx={{
                    borderRadius: "25px",
                  }}
                >
                  Save TimeTable
                </Button>
                <Button
                  variant="contained"
                  onClick={onNextPage}
                  disabled={status == "true" ? true : false}
                  sx={{
                    borderRadius: "25px",
                  }}
                >
                  Freeze and Review
                </Button>
                {/* <Button variant="outlined" onClick={onNextPage}>
                SKIP Creating Time for now
              </Button> */}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box display={status == "true" ? "block" : "none"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 490,
          }}
        >
          <h1>"TimeTable 📅 has been Freezed❗"</h1>
        </Box>
      </Box>
      <Modal
        open={openSucess}
        onClose={handleSucessClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <SucessModal />
        </div>
      </Modal>
      <Modal
        open={openAlert}
        onClose={handleAlertClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <AlertModal text={alertVal} />
        </div>
      </Modal>
      <Modal
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <DeleteModal />
        </div>
      </Modal>
      {/* </Box> */}
    </div>
  );
}
