import "./App.css";
import React from "react";
import Login from "./components/login/login";
import RegisterSchool from "./components/buildSchool/Dashboard/schoolRegistration";
import Welcome from "./components/welcome/welcome";
import DashBoard from "./components/buildSchool/Dashboard/dashBorad";
// import { Route } from '@mui/icons-material';
import { Routes, Route } from "react-router-dom";
import Designation from "./components/buildSchool/Dashboard/designation";
import CreateTimeTable from "./components/buildSchool/Dashboard/createTimeTable";
import TimeTable from "./components/buildSchool/Dashboard/timeTable";
import TimeTable1 from "./components/buildSchool/Dashboard/TimeTable1";
// import TimeTable2 from "./components/buildSchool/Dashboard/TimeTable";
import FeesStructure from "./components/buildSchool/Dashboard/feesStructure";
import FeesEntry from "./components/buildSchool/Dashboard/feesEntry";
import FeesReview from "./components/buildSchool/Dashboard/feesReview";
import Language from "./components/buildSchool/Dashboard/language";
import AddStandard from "./components/buildSchool/Dashboard/addStandards";
import Standard from "./components/buildSchool/Dashboard/standards";
import Exam from "./components/buildSchool/Dashboard/exam";
import Exams from "./components/buildSchool/Dashboard/examsadding";
import LaunchPadLogin from "./components/login/launchpadLogin";
import LaunchPad from "./components/launchPad/MainLaunchPad";
import PageNotFound from "./components/login/pageNotFound";

import StudentClick from "./components/launchPad/studentInfo/studentClick";
import StudentSearch from "./components/launchPad/studentInfo/studentSearch";
import StudentDocument from "./components/launchPad/studentInfo/studentDocument";
import StudentSupplies from "./components/launchPad/studentInfo/studentSupplies";
import StudentInfo from "./components/launchPad/studentInfo/studentInfo";
import StudentUpload from "./components/launchPad/studentInfo/studentUpload";
import StudentGovt from "./components/launchPad/studentInfo/studentGovtInfo";
import StudentRemarks from "./components/launchPad/studentInfo/studentRemarks";
import StudentLibrary from "./components/launchPad/studentInfo/studentLibrary";
import StudentFeeRecipt from "./components/launchPad/studentInfo/studentFeeRecipt";
import StudentExam from "./components/launchPad/studentInfo/studentExam";
import StudentAttendance from "./components/launchPad/studentInfo/studentAttendence";

import DataEntry from "./components/launchPad/dataEntry/dataEntryMain";
import Click from "./components/launchPad/dataEntry/click";
import AddStudent from "./components/launchPad/dataEntry/addStudent";
import ListStudent from "./components/launchPad/dataEntry/assignDivision";
import ExamMarksForm from "./components/launchPad/dataEntry/examMarksEntryForm";
import ExamMarks from "./components/launchPad/dataEntry/examMarksEntry";
import BlankForm from "./components/launchPad/dataEntry/blankForms";
import Events from "./components/launchPad/dataEntry/events";

import ClassWise from "./components/launchPad/Fees/ClassWise";
import EditStudentsFeesTag from "./components/launchPad/Fees/EditStudentsFeesTag";
import EditStudentsFeesTag1 from "./components/launchPad/Fees/EditStudentsFeesTag1";
import EditStudentsFeesTag2 from "./components/launchPad/Fees/EditStudentsFeesTag2";
import Fees from "./components/launchPad/Fees/Fees";
// import FeesCollection from "./components/launchPad/Fees/FeesCollectionDemo";
import FeesCollection from "./components/launchPad/Fees/FeesCollection";
import Remainder from "./components/launchPad/Fees/Remainder";
import Summary from "./components/launchPad/Fees/Summary";
import Collection from "./components/launchPad/Fees/Collection";
import FeeReport from "./components/launchPad/Fees/FeesReport";
import FeeClick from "./components/launchPad/Fees/feeClick";

import BoooksEntry from "./components/launchPad/library/booksEntry";
import BooksList from "./components/launchPad/library/bookList";
import ListBooksTypeWise from "./components/launchPad/library/listBooksTypeWise";
import SearchBook from "./components/launchPad/library/searchAbook";
import BorrowBook from "./components/launchPad/library/bookBorrow";
import Book from "./components/launchPad/library/book";
import LibraryClick from "./components/launchPad/library/libraryClick";
// *******************************STAFF DETAILS*******************************
import StaffClick from "./components/launchPad/staffEntry/staffClick";
import Staff from "./components/launchPad/staffEntry/staff";
import Staff_copy from "./components/launchPad/staffEntry/staff_copy";
import AddNewStaff from "./components/launchPad/staffEntry/addStaff";
import AddStaffAttend from "./components/launchPad/staffEntry/staffAttendence";
import AddStaffDetails from "./components/launchPad/staffEntry/staffDetails";
import StaffSubject from "./components/launchPad/staffEntry/StaffSubject";
import Salarydetails from "./components/launchPad/staffEntry/salaryDetails";
import Salarypay from "./components/launchPad/staffEntry/paySalaries";
import BankDetail from "./components/launchPad/staffEntry/bankDetail";
import StaffList from "./components/launchPad/staffEntry/staffList";

// ******************************************** hallticket ****************************************************
import ReportClick from "./components/launchPad/Reports/reportClick";
import HallTicket from "./components/launchPad/Reports/hallTicket";
import StaffInformation from "./components/launchPad/Reports/allStaffInformation";
import StudentInformation from "./components/launchPad/Reports/allStudentInformation";
import ExamResults from "./components/launchPad/Reports/examResults";
import IdCard from "./components/launchPad/Reports/idCard";
import IndividualStudent from "./components/launchPad/Reports/individualStudentInfo";

// ********************************************Attendence Entry****************************************************
import AttendanceClick from "./components/launchPad/attendence/attendanceClick";
import AttendenceEntry from "./components/launchPad/attendence/attendenceEntry";
import AttendenceApproval from "./components/launchPad/attendence/attendenceApproval";
import AttendenceReport from "./components/launchPad/attendence/attendenceReport";

// ******************************************** RND ****************************************************
import AttendenceDemo from "../src/programs/attendence";
// import Timetablecreact from "./components/buildSchool/tempfile/timetablecreate";
import RndTimeTable from "./components/buildSchool/tempfile/TimeTableTemp";
// import Fees from "./components/buildSchool/tempfile/fees";
// import FeesE from "./components/buildSchool/tempfile/feesE";
import Fileupload from "./programs/fileupload";
// import Fileupload from "./programs/fileuploadcopy";
import StaffEdit from "./components/launchPad/staffEntry/staffEdit";
// ********************************************AFTER FINAL EXAM****************************************************
import NewAdmission from "./components/launchPad/AfterFinalExam/NewAdmission";
import After from './components/launchPad/AfterFinalExam/after';
import FreezeData from "./components/launchPad/AfterFinalExam/FreezeData";
import AcceptRequestForTc from "./components/launchPad/AfterFinalExam/AcceptRequestForTc";
import Congratulation from "./components/launchPad/AfterFinalExam/Congratulation";
import GrantAndReject from "./components/launchPad/AfterFinalExam/GrantAndReject";
import FreezePopUp from "./components/launchPad/AfterFinalExam/FreezePopUp";
import StartAcademicYear from "./components/launchPad/AfterFinalExam/startNextAcademicYear1";
import New from "./components/launchPad/AfterFinalExam/NewAdmit";
// import AfterExamStandards from "./components/launchPad/AfterFinalExam/AfterExamStandards";
// import AfterExamChangeSubjects from "./components/launchPad/AfterFinalExam/AfterExamChangeSubjects";
// import AfterExamFees from "./components/launchPad/AfterFinalExam/AfterExamFees";

import ClassClick from "./components/launchPad/Classroutine/classClick";

import SetExamTimings from "./components/launchPad/Settings/SetExamTimings";
import IDCards from "./components/launchPad/Settings/IDCards";
import BackUp from "./components/launchPad/Settings/BackUp";
import RightsManagement from "./components/launchPad/Settings/RightsManagement";
import UserEntry from "./components/launchPad/Settings/UserEntry";
import SettingClick from "./components/launchPad/Settings/settingClick";
import Event from "./components/launchPad/events/event";
import AddEvents from "./components/launchPad/events/addEvent";
import ListEvents from "./components/launchPad/events/eventList";
import LayoutEvents from "./components/launchPad/events/eventLayout";
// import Redux from "../src/programs/redux/redux";
// import Redux1 from "../src/programs/redux/redux2";
import AcadmicYear from "./components/migration/AcadmicYear";

function App() {
  return (
    <React.Fragment>
      <div
        style={{
          // backgroundImage: `url("bgColor11.png")`,
          backgroundImage: `url("/background.jpeg")`,
          backgroundSize: "cover",
        }}
      >
        {/* <ResponsiveAppBar /> */}
        <Routes>
          {/* <Route path={'/'} element={<Navigate to={'/Login'}/>}/> */}
          <Route path={"/"} element={<LaunchPadLogin />} />
          <Route path={"/login"} element={<Login />} />
          <Route path={"/registerschool"} element={<RegisterSchool />} />
          <Route path={"/welcome"} element={<Welcome />} />
          <Route path={"/dashborad"} element={<DashBoard />}></Route>
          <Route path={"/addstandards"} element={<AddStandard />} />
          <Route path={"/standards"} element={<Standard />} />
          <Route path={"/language"} element={<Language />} />
          <Route path={"/designationandrole"} element={<Designation />} />
          <Route path={"/timetable"} element={<CreateTimeTable />} />

          {/* <Route path={"/createtimetable"} element={<CreateTimeTable />} /> */}
          <Route path={"/timetable1"} element={<CreateTimeTable />} />
          {/* <Route path={"/timetable2"} element={<TimeTable2 />} /> */}
          <Route path={"/feesStructure"} element={<FeesStructure />} />
          <Route path={"/feesEntry"} element={<FeesEntry />} />
          <Route path={"/feesReview"} element={<FeesReview />} />
          <Route path={"/exammarkssetup"} element={<Exam />} />
          <Route path={"/examadding"} element={<Exams />} />
          {/* "================launchPad===========================" */}
          <Route path={"/launchpadlogin"} element={<LaunchPadLogin />} />
          <Route path={"/launchpad"} element={<LaunchPad />} />

          {/* "================student route===========================" */}
          <Route path={"/click"} element={<Click />} />
          <Route path={"/dataentry"} element={<DataEntry />} />
          <Route path={"/addStudent"} element={<AddStudent />} />
          <Route path={"/students-list"} element={<ListStudent />} />
          <Route path={"/examMarksEntryForm"} element={<ExamMarksForm />} />
          <Route path={"/examMarksEntry"} element={<ExamMarks />} />
          <Route path={"/studentBlankForm"} element={<BlankForm />} />
          <Route path={"/events"} element={<LayoutEvents />} />

          {/* "================student info===========================" */}
          <Route path={"/studentClick"} element={<StudentClick />} />
          <Route path={"/studentsearch"} element={<StudentSearch />} />
          <Route path={"/studentDocument"} element={<StudentDocument />} />
          <Route path={"/studentSupplies"} element={<StudentSupplies />} />
          <Route path={"/studentInfo"} element={<StudentInfo />} />
          <Route path={"/studentGovernmentRecords"} element={<StudentGovt />} />
          <Route path={"/studentRemarks"} element={<StudentRemarks />} />
          <Route path={"/studentInfoLibrary"} element={<StudentLibrary />} />
          <Route path={"/studentFeeRecipt"} element={<StudentFeeRecipt />} />
          <Route path={"/studentExam"} element={<StudentExam />} />
          <Route path={"/studentUpload"} element={<StudentUpload />} />
          <Route path={"/studentAttendance"} element={<StudentAttendance />} />

          {/* "================library route===========================" */}
          <Route path={"/libraryClick"} element={<LibraryClick />} />
          <Route path={"/booksentry"} element={<BoooksEntry />} />
          <Route path={"/booksList"} element={<BooksList />} />
          <Route path={"/listBookTypeWise"} element={<ListBooksTypeWise />} />
          <Route path={"/searchBook"} element={<SearchBook />} />
          <Route path={"/borrowBook"} element={<BorrowBook />} />
          <Route path={"/book"} element={<Book />} />

          {/* "================ Attendance===========================" */}
          <Route path={"/attendance"} element={<AttendanceClick />} />
          <Route path={"/attendenceEntry"} element={<AttendenceEntry />} />
          <Route
            path={"/attendenceApproval"}
            element={<AttendenceApproval />}
          />
          <Route path={"/attendenceReport"} element={<AttendenceReport />} />
          {/* <Route path={"/absentReport"} element={<AbsentReport />} /> */}
          {/* <Route path={"/combinedReport"} element={<CombinedReport />} /> */}
          {/* <Route path={"/classWise"} element={<Classwise />} /> */}

          {/*"========================Fees==============================*/}
          <Route path={"/ClassWise"} element={<ClassWise />} />
          <Route path={"/Collection"} element={<Collection />} />
          <Route
            path={"/EditStudentsFeesTag"}
            element={<EditStudentsFeesTag />}
          />
          <Route
            path={"/EditStudentsFeesTag1"}
            element={<EditStudentsFeesTag1 />}
          />
          <Route
            path={"/EditStudentsFeesTag2"}
            element={<EditStudentsFeesTag2 />}
          />
          <Route path={"/Fees"} element={<Fees />} />
          <Route path={"/FeesCollection"} element={<FeesCollection />} />
          {/* <Route path={"/Remainder"} element={<Remainder />} />
          <Route path={"/Summary"} element={<Summary />} /> */}
          <Route path={"/FeeReport"} element={<FeeReport />} />
          <Route path={"/FeeClick"} element={<FeeClick />} />

          {/* "================Reports route===========================" */}
          <Route path={"/reportClick"} element={<ReportClick />} />
          <Route path={"/hallTicket"} element={<HallTicket />} />
          <Route path={"/staffReport"} element={<StaffInformation />} />
          <Route path={"/studentReport"} element={<StudentInformation />} />
          <Route path={"/studentExamResult"} element={<ExamResults />} />
          <Route path={"/studentIdCards"} element={<IdCard />} />
          <Route
            path={"/individualStudentInfo"}
            element={<IndividualStudent />}
          />

          {/* "================staff route===========================" */}
          <Route path={"/staffClick"} element={<StaffClick />} />
          <Route path={"/addstaff"} element={<AddNewStaff />} />
          <Route path={"/staff"} element={<Staff />} />
          <Route path={"/staff_copy"} element={<Staff_copy />} />
          <Route path={"/staffattendance"} element={<AddStaffAttend />} />
          <Route path={"/staffdetails"} element={<AddStaffDetails />} />
          <Route path={"/staffsubject"} element={<StaffSubject />} />
          <Route path={"/staff-edit"} element={<StaffEdit />} />
          <Route path={"/staff-salaryDetails"} element={<Salarydetails />} />
          <Route path={"/staff-salaryPay"} element={<Salarypay />} />
          <Route path={"/staffBankDetail"} element={<BankDetail />} />
          <Route path={"/staffList"} element={<StaffList />} />

          {/* "================temp route===========================" */}
          <Route path={"/RndTimeTable"} element={<RndTimeTable />} />
          {/* <Route path={"/fees"} element={<Fees />} /> */}
          {/* <Route path={"/feesE"} element={<FeesE />} /> */}
          {/* <Route path={"/createtimetable"} element={<Timetablecreact />} /> */}
          {/* <Route path={"/upload"} element={<Fileupload />} /> */}
          <Route path={"/upload"} element={<Fileupload />} />
          <Route path={"/attendenceDemo"} element={<AttendenceDemo />} />
          {/* <Route path={"/redux"} element={<Redux />} /> */}
          {/* <Route path={"/redux2"} element={<Redux1 />} /> */}

          {/* "================After class routine===========================" */}
          <Route path={"/classClick"} element={<ClassClick />} />

          {/* "================After final exam===========================" */}
          <Route path={"/newadmission"} element={<NewAdmission />} />
          <Route path={"/freezedata"} element={<FreezeData />} />
          <Route
            path={"/acceptrequestfortc"}
            element={<AcceptRequestForTc />}
          />
          <Route path={"/congratulation"} element={<Congratulation />} />
          <Route path={"/grantandreject"} element={<GrantAndReject />} />
          <Route path={"/freezepopup"} element={<FreezePopUp />} />
          <Route path={"/startacademicyear1"} element={<StartAcademicYear />} />
          <Route path={"/after"} element={<After/>} />
          <Route path={"/new"} element={<New/>} />
          {/* <Route
            path={"/afterexamstandards"}
            element={<AfterExamStandards />}
          />
          <Route
            path={"/afterexamchangesubjects"}
            element={<AfterExamChangeSubjects />}
          />
          <Route path={"/afterexamfees"} element={<AfterExamFees />} /> */}

          {/* "====================Events=======================" */}
          <Route path={"/event"} element={<Event />} />
          <Route path={"/addevent"} element={<AddEvents />} />
          <Route path={"/listevent"} element={<ListEvents />} />
          {/* "================Settings===========================" */}
          <Route path={"/settingClick"} element={<SettingClick />} />
          <Route path={"/setexamtimings"} element={<SetExamTimings />} />
          <Route path={"/backup"} element={<BackUp />} />
          <Route path={"/rightsmanagement"} element={<RightsManagement />} />
          <Route path={"/idCards"} element={<IDCards />} />
          <Route path={"/userEntry"} element={<UserEntry />} />

          {/* "================migration===========================" */}
          <Route path={"/create-year"} element={<AcadmicYear />} />

          {/* "================404 page keep it in last===========================" */}

          <Route path={"*"} element={<PageNotFound />} />
        </Routes>
      </div>
    </React.Fragment>
  );
}

export default App;
