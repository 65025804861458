import { Button, Stack, Typography, gridClasses } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { LibraryService } from "../../../services/libraryService";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import jsPDF from "jspdf";
import { autoTable } from "jspdf-autotable";
import { useNavigate } from "react-router-dom";
// import ms1 from "./ms1.jpg";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";

export default function Books() {
  React.useEffect(() => {
    onBookLoad();
  }, []);
  const [columns, setColumns] = React.useState([
    {
      field: "NO",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      headerName: "NO",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "BOOK_NUMBER",
      headerName: "BOOK NUMBER",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"BOOK NUMBER"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "BOOK_TITLE",
      headerName: "BOOK TITLE",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"BOOK TITLE"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "BOOK_TYPE",
      headerName: "TYPE",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"TYPE"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "AUTHOR_PUBLICATION",
      headerName: "AUTHORPUBLICATION",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"AUTHOR/PUBLICATION"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "CONDITION",
      headerName: "CONDITION ",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"CONDITION"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
  ]);
  const [rows, setRows] = React.useState([]);
  const [book, setBook] = React.useState([]);
  const [Excel, setExcel] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const onBookLoad = async () => {
    setLoading(true);
    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await LibraryService.listAll(yearData.yearName);
      console.log(response.data.data);

      // setBook(response.data.data);
      let book_array = [];
      response.data.data.forEach(function (rec, i) {
        console.log(rec);
        book_array.push({
          id: rec.libraryID,
          NO: i + 1,
          BOOK_NUMBER: rec.bookNumber.toUpperCase(),
          BOOK_TITLE: rec.bookTitle,
          BOOK_TYPE: rec.bookType,
          AUTHOR_PUBLICATION: rec.bookAuthorPublication,
          CONDITION: rec.bookCondition,
          // libraryID: rec.id,
        });
      });
      setRows(book_array);
      console.log(rows);
      setLoading(false);

      let bookExecl = [];
      response.data.data.forEach(function (rec, i) {
        console.log(rec);
        bookExecl.push({
          NO: i + 1,
          BOOK_NUMBER: rec.bookNumber.toUpperCase(),
          BOOK_TITLE: rec.bookTitle,
          BOOK_TYPE: rec.bookType,
          AUTHOR_PUBLICATION: rec.bookAuthorPublication,
          CONDITION: rec.bookCondition,
          // libraryID: rec.id,
        });
      });
      setExcel(bookExecl);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  let navigate = useNavigate();
  const onLaunchPad = () => {
    console.log("launchpad");
    navigate("/launchpad", { replace: true });
  };
  const [academicYear, setacademicYear] = React.useState(
    JSON.parse(localStorage.getItem("academicYear"))
  );
  const [schoolName, setschoolName] = React.useState(
    JSON.parse(localStorage.getItem("schoolDetails"))
  );

  const generatePDF = () => {
    const doc = new jsPDF("p", "pt", "a4");
    const sch = schoolName.schoolNameOnDocs.toUpperCase();
    const acad = academicYear.yearName;
    // doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    // doc.text(`${sch}`, 150, 35);
    var fontSize = 18;
    var text = `${sch}`;
    var pageWidth = doc.internal.pageSize.getWidth();
    var textWidth =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    var centerX = (pageWidth - textWidth) / 2;
    doc.text(text, centerX, 35);

    doc.setFontSize(10);
    doc.text(` ${acad}`, 500, 35);
    doc.setFontSize(15);
    doc.text("LIBRARY BOOKS", 240, 75);
    // doc.addImage(ms1,'JPG',0,0, doc.internal.pageSize.width, doc.internal.pageSize.height)
    const img = `${schoolName.schoolLogoUrl}`;
    doc.addImage(img, "JPEG", 20, 10, 80, 70);

    const tableData = rows.map((row) => [
      row.NO,
      row.BOOK_NUMBER,
      row.BOOK_TITLE,
      row.BOOK_TYPE,
      row.AUTHOR_PUBLICATION,
      row.CONDITION,
    ]);

    doc.autoTable({
      tableLineColor: [0,0,0],
      startY: 90,
      head: [
        [
          "NO.",
          "BOOK NUMBER",
          "BOOK TITLE",
          "TYPE",
          "AUTHOR/PUBLICATION",
          "CONDITION",
        ],
      ],
      body: tableData,
      tableLineWidth: 0.95,
      theme: "grid",
      headStyles: {
        valign: "middle",
        backgroundColor: "lightgray",
        halign: "center",
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
        lineColor: [0,0,0],
      },
    });
    doc.setFontSize(10);
    // doc.text("Copyright: Technotharanga Solutions Pvt.ltd", 190, 800);
    doc.save("Library-List all books.pdf");
  };

  const fileType = "xlsx";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(Excel);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, "Library-List all books" + ".xlsx");
  };

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="library" /> */}
      {loading ? (
        <div style={{padding:"180px 0px", display:"flex", justifyContent:"center",}}>
        <div className = "spinner-container">
        <div className = "loading-spinner">
        </div>
      </div>
      </div>
      ): (
      <Box>
      <Box sx={{ width: "100%", padding: "0 10px", boxSizing: "border-box" }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            mt: 2,
            fontWeight: "bold",
            backgroundColor: "lightblue",
          }}
        >
          LIBRARY BOOKS
        </Typography>
      </Box>
      <Box
        sx={{
          height: 500,
          mt: 2,
          width: "100%",
          padding: "0 10px",
          boxSizing: "border-box",
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
            //  color:'white',
            fontWeight: "bold",
            fontSize: "15px",
            border: "1px solid white",
          },
          overflowX:"auto",
        }}
      >
        <DataGrid
          showColumnRightBorder={true}
          showCellRightBorder={true}
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[10]}
          sx={{minWidth:1000}}
          disableColumnMenu
          disableSelectionOnClick
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent:"space-evenly",mt:2, paddingBottom:"25px" }}
      >
        <Button
          variant="contained"
          color="error"
          sx={{
            color: "white",
            borderRadius: "30px",
            fontSize: 15,
            width: 120,
            fontWeight: "bold",
          }}
          onClick={generatePDF}
        >
          PDF
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={exportToCSV}
          sx={{
            color: "white",
            borderRadius: "30px",
            fontSize: 15,
            width: 120,
            fontWeight: "bold",
          }}
        >
          EXCEL
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#ca424f",
            borderRadius: "30px",
            fontSize: 15,
            fontWeight: "bold",
            width: 120,
            "&:hover": {
              backgroundColor: "#ca424f",
            },
          }}
          onClick={onLaunchPad}
        >
          EXIT
        </Button>
      </Box>
      </Box>
      )}
    </React.Fragment>
  );
}
