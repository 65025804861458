import {
  Avatar,
  Button,
  gridClasses,
  Paper,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { C } from "../../util/C";
import styled from "@emotion/styled";
import ButtonGroup from "@mui/material/ButtonGroup";
import EditIcon from "@mui/icons-material/Edit";
import { StackedBarChartOutlined } from "@mui/icons-material";
import { StudentInfoService } from "../../../services/StudentInfo/StudentList";
import { StudentDcoumentService } from "../../../services/StudentDocument";
import { UploadFileService } from "../../../services/FileUploadService";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";


const FormItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: 180,
  // height: 80
  // color: theme.palette.text.secondary,
}));

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "center",
  // fontWeight:"bold"
  // color: theme.palette.text.secondary
}));
export default function StudentDocument() {
  const [imageUrl, setImageUrl] = React.useState("");
  const [imageSrc, setImageSrc] = React.useState();
  const [excel, setExcel] = React.useState([]);

  const [studentInfo, updateStudentInfo] = React.useState({});
  React.useEffect(() => {
    studentDetailsLoad();
  }, []);

  let navigate = useNavigate();
  const onLaunchPad = ()=>{
    navigate("/launchpad", {replace: true});
  }

  const studentDetailsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let studentUSN = localStorage.getItem("studentUSN");
    try {
      let response = await StudentInfoService.studentInfoShowStudentDetails(
        studentUSN,
        data.yearName
      );
      console.log(response.data.data);
      updateStudentInfo(response.data.data);
      DocumentLoad(response.data.data.studentUSN);
    } catch (error) {
      console.log(error);
    }
  };

  const DocumentLoad = async (usid) => {
    let suppliesDocs = [];
    let suppliesDocsData = [];
    try {
      let response = await StudentDcoumentService.getStudentDocument(usid);
      response.data.data.forEach((element) => {
        if (element.documentType == "document") {
          suppliesDocs.push(element);
        }
      });
      console.log(suppliesDocs);
      
    } catch (error) {
      console.log(error);
    }

    suppliesDocs.forEach(async (rec, i) => {
      // try {
      //   let respImg = await UploadFileService.downloadFile(rec.documentImgURL);
      //   console.log(respImg);
      suppliesDocsData.push({
        id: i+1,
        date: rec.date,
        description: rec.description,
        image: rec.documentImgURL,
      });
      // } catch (error) {
      //   console.log(error);
      // }
    });

    let stud=[];
    suppliesDocs.forEach((res,i)=>{
      stud.push({
        NO : i+1,
        DATE: res.date,
        DESCRIPTION: res.description
      });
    });
    setExcel(stud);

    setTimeout(() => {
      setRows(suppliesDocsData);
    }, 1000);
  };

  const fileBrowseHandler = (event) => {
    let value = URL.createObjectURL(event.currentTarget.files[0]);
    setImageSrc(event.currentTarget.files[0]);
    C.c(value);
    // C.c(filevalue);
    setImageUrl(value);
    // setImageSrc(filevalue);
    C.c(imageSrc);
  };
  const [columns, setColumns] = React.useState([
    {
      field: "id",
      headerName: "NO",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO."}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "date",
      headerName: "DATE",
      flex: 1,
      type: "date",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"DATE"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "description",
      headerName: "DESCRIPTION OF DOCUMENT",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"DESCRIPTION OF DOCUMENT"}</strong>
      ),
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p style={{ textTransform: "uppercase" }}>{params.value}</p>
      ),
    },
    {
      field: "image",
      headerName: "IMAGE",
      flex: 1,
      height: "20%",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"IMAGE"}</strong>,
      headerClassName: "super-app-theme--header",
      renderCell: (rec) => {
        return (
          <Box>
            <Avatar
              variant="square"
              alt="book image"
              src={rec.value}
              sx={{ width: 56, height: 56 }}
            />
            {/* <img
              style={
                height= "20px",
                width= "20px",
              }
              src={rec.value}
            /> */}
          </Box>
        );
      },
    },
    // {
    //   field: "edit",
    //   headerName: "Edit",
    //   sortable: false,
    //   flex: 1,
    //   disableClickEventBubbling: true,
    //   align: "center",
    //   renderHeader: () => <strong style={{ fontSize: 15 }}>{"Edit"}</strong>,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: () => {
    //     return (
    //       <Button variant="contained" color="primary" startIcon={<EditIcon />}>
    //         Edit
    //       </Button>
    //     );
    //   },
    // },
  ]);

  const [rows, setRows] = React.useState([]);
  React.useEffect(() => {}, []);

  const onAddRoW = (e) => {
    console.log(rows.length);

    let new_row = {
      id: rows.length + 1,
      id: "",
      date: " ",
      description: " ",
      image: "0",
    };

    setRows((rows) => [...rows, new_row]);
    console.log(rows);
  };
  

  const school = JSON.parse(localStorage.getItem("schoolDetails"));
  const academic = JSON.parse(localStorage.getItem("academicYear"));

 
const genaratePDF = () => {
  const doc = new jsPDF("landscape");
 
  const sch = school.schoolNameOnDocs.toUpperCase();
  const acad = academic.yearName;
  const img = school.schoolLogoUrl;
  doc.setFont("helvetica", "bold");
  var fontSize = 18;
  var text = `${sch}`;
  var pageWidth = doc.internal.pageSize.getWidth();
  var textWidth =
    (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
  var centerX = (pageWidth - textWidth) / 2;
  doc.text(text, centerX, 15);

  doc.addImage(img, "JPEG", 15,5,27,27);
  doc.setFontSize(10);
  doc.text(` ${acad}`, 250, 15);

  

    doc.setFontSize(15);
    doc.setFont("helvetica", "bold");
    doc.text("STUDENT DOCUMENTS", 90, 30);

    doc.setFontSize(14);
    doc.text(studentInfo.studentName+"--"+studentInfo.studentUSN, 110,48);
    doc.addImage(studentInfo.studentProfileUrl, 'JPEG', 220,25,30,36);
 
    doc.autoTable({
      startY:60,
      head:[["STD-DIV : "+studentInfo.standardName+"-"+studentInfo.divisionName, 
   "MED :"+ studentInfo.studentMedicalAttentionDetail,
  "BG :"+  studentInfo.studentBloodGroup,
  "DOB :" + studentInfo.studentDOB, "AGE :"+ studentInfo.studentAge
    ]],
    headStyles:{
      fillColor : "white",
      textColor: 0,
      fontSize:12
    },
    theme:"grid",
    });

 
    const tableData = rows.map((row)=>[
row.id, row.date,row.description
    ]);


    doc.autoTable({
      startY:80,
      tableLineColor: [0,0,0],
      head:[["NO","DATE","DESCRIPTION OF DOCUMENT"]],
    body: tableData,
    headStyles: {
      valign: "middle",
      halign: "center",
    },
    bodyStyles: {
      valign: "middle",
      halign: "center",
      textTransform:"uppercase",
      lineColor: [0,0,0],
    },
    tableLineWidth: 0.4,
    theme:"grid",
    });
 doc.save(studentInfo.standardName+"-"+studentInfo.divisionName+"--"+studentInfo.studentUSN+" Documents.pdf");  
}

const fileType = "csv";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(excel);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, studentInfo.standardName+"-"+studentInfo.divisionName+"--"+studentInfo.studentUSN+" Documents.csv");
  };

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="studentinfo" /> */}

      <Box sx={{ width: "100%", paddingLeft: "30px", boxSizing: "border-box", mt: 2}}>
          <Grid container>
            <Grid item md={10} xs={8}>
              <Box
                display={"flex"}
                alignContent={"center"}
                justifyContent={"center"}


              >
                <Stack direction="column" sx={{ width: "100%",  boxSizing: "border-box", mt: 2 }}>
                  <Item >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      STUDENT NAME
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      {studentInfo ? studentInfo.studentName : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
              <Box
                sx={{ width: "100%", boxSizing: "border-box", my: "1%" }}
              >
                <Grid  container spacing={{ xs: 1, sm: 1, md: 2 }} sx={{ width: "100%", boxSizing: "border-box", mt: 2 }}>
                  <Grid item md={1} xs={3} >
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        USN
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentUSN : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        STANDARD | DIVISION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.standardName +
                          " | " +
                          studentInfo.divisionID
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={1} xs={3}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        CLASS TR
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {"--"}
                        {/* {studentInfo ? studentInfo.classteacher : "--"} */}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        MEDICAL ATTENTION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.studentMedicalAttentionDetail
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        BLOOD GROUP
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentBloodGroup : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        DOB
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentDOB : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  {/* <Typography
              variant="body1"
              width="250px"
              sx={{ textAlign: "center" }}
            >
              {studentInfo.studentAge + " Age"}
            </Typography> */}
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item>
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        AGE
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentAge : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
              {/* <Box
              sx={{
                height: 400,
                width: "100%",
                // ml: "2%",
                "& .super-app-theme--header": {
                  backgroundColor: "#c0c0c0",
                  //  color:'white',
                  fontWeight: "bold",
                  fontSize: "15px",
                  border: "1px solid white",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
              />
            </Box> */}
            </Grid>
            <Grid item md={2} xs={4}>
              <Box display={"flex"} justifyContent={"center"}  sx={{mt:"2%"}}>
                <Avatar
                  alt="Remy Sharp"
                  src={studentInfo ? studentInfo.studentProfileUrl : ""}
                  //   id={element.staffID}
                  variant="square"
                  sx={{
                    width: 150,
                    height: 190,
                    mx: 2,
                    // mt: 1,
                    border: "3px solid skyblue",
                    borderRadius: "5%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

      <Grid container>
          <Grid item md={11.35} xs={11.5}>
          <Box
              sx={{
                height: 500,
                width: "100%",
                ml: "2%",
                "& .super-app-theme--header": {
                  backgroundColor: "#c0c0c0",
                  //  color:'white',
                  fontWeight: "bold",
                  fontSize: "15px",
                  border: "1px solid white",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                getRowHeight={() => "auto"}
                sx={{
                  [`& .${gridClasses.cell}`]: {
                    py: 3,
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>

      <Box sx={{display:"flex", justifyContent:"space-evenly", mt:2}}>
      <Button
                variant="contained"
                size="large"
                color="error"
                onClick={genaratePDF}
                sx={{
                  borderRadius: "30px",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                PDF
              </Button>
              <Button
                variant="contained"
                size="large"
                color="success"
                onClick={exportToCSV}
                sx={{
                  borderRadius: "30px",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                excel
              </Button>
     
        <Button
                variant="contained"
                size="large"
                onClick={()=>{window.print()}}
                sx={{
                  borderRadius: "30px",
                  backgroundColor:"black",
                  color:"white",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                  "&:hover":{backgroundColor:"black",color:"white"}
                }}
              >
                print
              </Button>

              <Button
                variant="contained"
                size="large"
                onClick={onLaunchPad}
                sx={{
                  borderRadius: "30px",
                  backgroundColor:"#ca424f",
                  color:"white",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                  "&:hover":{backgroundColor:"#ca424f",color:"white"}
                }}
              >
                exit
              </Button>
      </Box>
    </React.Fragment>
  );
}
