import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom"; //for browser back button navigation handling
import { UploadFileService } from "../../services/FileUploadService";
import { SchoolMaster } from "../../services/SchoolMaster";
// import RealTimeData from "../util/RealTimeDate";

const ResponsiveAppBar = (props) => {
  let Navigate = useNavigate();
  const logout = () => {
    Navigate("/");
  };

  const [schoolName, setschoolName] = React.useState("");
  const [schoolImgID, setschoolImgID] = React.useState("");
  const [schoolImg, setschoolImg] = React.useState("");
  const [academicYear, setacademicYear] = React.useState("");

  const [date, setDate] = React.useState("");

  React.useEffect(() => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date();
    let year = date.getFullYear();
    let month = months[date.getMonth()];
    let day = date.getDate();

    setDate(`${day} ${month} ${year}`);

    const schoolData = JSON.parse(localStorage.getItem("schoolDetails"));
    const academicData = JSON.parse(localStorage.getItem("academicYear"));
    setschoolName(
      schoolData ? (
        <b>{schoolData.schoolNameOnDocs}</b>
      ) : (
        "NEW INTERNATIONAL SCHOOL"
      )
    );
    setacademicYear(academicData.yearName);
    setschoolImgID(schoolData.schoolLogoUrl);
    console.log(academicData);
    onschoolLoad();
  }, []);

  const onschoolLoad = async () => {
    let imageUrl;
    try {
      let response = await SchoolMaster.schoolList();
      setschoolImg(response.data.data[0].schoolLogoUrl);
      console.log(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
    // try {
    //   console.log(schoolImgID);
    //   let response = await UploadFileService.downloadFile(imageUrl);
    //   setschoolImg(response.request.responseURL);
    //   console.log(response.request.responseURL);
    // } catch (error) {
    //   console.log(error.message);
    // }
  };

  const onbuildSchool = () => {
    console.log("onBuildSchool");
    Navigate("/dashborad", { replace: true });
  };

  return (
    <div>
      <AppBar position="static" color="transparent">
        <Container maxWidth="xl">
          <Toolbar sx={{ height: 140 }} disableGutters>
            <Box marginTop={2} 
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              // border={"2px solid black"}
            >
              {/* <Tooltip title="logo"> */}
              <Avatar
                variant="square"
                sx={{
                  width: 90,
                  height: 90,
                }}
                alt="w"
                src={schoolImg ? schoolImg : ""}
              />
              {/* </Tooltip> */}
              <Typography
                variant="subtitle1"
                noWrap
                component="div"
                fontWeight="bold"
                fontSize={{md:"17px",xs:"13px"}}
                sx={{ flexGrow: 1 }}
              >
                Academic Year : {academicYear ? academicYear : "invalid year"}
              </Typography>
            </Box>
            <Box marginTop={2}
              sx={{
                flexGrow: 1,
                display: {
                  xs: "flex",
                  // justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  // paddingRight:"6%"

                },
                // width: "100%",
                
                // boxSizing: "border-box",
              }}
              paddingRight= {{xs:"", md:"6%"}}
              
            >
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ textTransform: "uppercase" }} fontSize={{xs:"16px", md:"21px"}}
              >
                {schoolName}
                {/* NEW INTERNATIONAL SCHOOL */}
              </Typography>
              {/* <Tooltip sx={{}} title="Open settings"> */}
              {/* <IconButton sx={{ p: 0 }}> */}
                <Avatar
                  alt="Remy Sharp"
                  src="ms2.jpg"
                  variant="square"
                  sx={{
                    width: 145,
                    height: 80,
                  }}
                />
              {/* </IconButton> */}
              {/* </Tooltip> */}
            </Box>

            <Box marginTop={2}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              {/* <Tooltip title="bui"> */}
              <Avatar
                alt="Remy Sharp"
                src="logo2.png"
                variant="square"
                sx={{
                  width: 90,
                  height: 90,
                }}
              />
              {/* </Tooltip> */}
              <Typography
                variant="subtitle1"
                noWrap
                component="div"
                fontWeight="bold"
                fontSize={{md:"17px",xs:"13px"}}
                sx={{ flexGrow: 1 }}
              >
                Myschool BooK
              </Typography>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* <Box
        // sx={{
        //   m: "0px 8px 0 8px",
        // }}
      > */}
        <Box
          sx={{
            flexGrow: 1,
            border: "2px solid black",
            // width: "100%",
            // padding: "0 25px",
            // boxSizing: "border-box",
            background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
          }}
        >
          <Grid container spacing={2}  py={0.5}>
            <Grid
              item
              xs={2}
              md={2}
              sx={{
                // paddingLeft: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                
                // background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
              }}
              // marginLeft={{xs:"20px", md:"0"}}
            >
              <Typography
                fontSize="15px"
                fontWeight="bold"
                variant="h4"
                paddingLeft={{md:"", xs:4}}
                // sx={{ marginLeft: "5px" }}
              >
                Administrator
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              md={8}
             
              // md={4}
              sx={{
                textAlign: "center",
                // background:
                //   " linear-gradient(180deg,transparent, transparent,#c0c0c059, #bbbebe)",
              }}
            >
              <IconButton  onClick={onbuildSchool}>
                <Typography
                  marginLeft={{xs:"80px", md:"0"}}
                  sx={{
                    fontWeight: "bold",
                    mt: 1,
                    
                  }}
                >
                  <label style={{ color: "black" }}>Build School</label>
                </Typography>
              </IconButton>
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Button
                  variant="text"
                  
                  // color="red"
                  sx={{
                    color: "red",
                    fontWeight:"bold",
                  }}
                  onClick={logout}
                >
                  LOGOUT
                </Button>
                <Typography fontSize="10px" fontWeight="bold" variant="subtitle2" paddingLeft={1}>
                  {date}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      {/* </Box> */}
      <Box
        sx={{
          m: 1,
          flexGrow: 1,
          backgroundColor: "rgb(165, 250, 165)",
          border: "2px solid #a6ffbe",
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{props.text}</Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default ResponsiveAppBar;
