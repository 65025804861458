import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class TimeTable {
  // static serverURL = "http://143.110.188.253:1919/schoolTimetable/";
  static service = "schoolTimetable/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static StandardTimeTable(standardID, standard, divisionID, year) {
    var dataURL =
      this.serverURL +
      "getStandaredTimetable?division=" +
      divisionID +
      "&divisionID=" +
      divisionID +
      "&standard=" +
      standard +
      "&standardID=" +
      standardID +
      "&yearName=" +
      year;

    return axios.get(dataURL);
  }
  static StaffStandardTimeTable(id, name, year) {
    var dataURL =
      this.serverURL +
      "getStaffTimetable?staffID=" +
      id +
      "&staffName=" +
      name +
      "&yearName=" +
      year;

    return axios.get(dataURL);
  }
}
