import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TopBar from '../../MainTopbar/Appbar';
import AttendanceEntry from './attendenceEntry';
import AttendanceApprove from './attendenceApproval';
import AttendanceReport from './attendenceReport';
import { useNavigate } from 'react-router-dom';

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height:"100%", width:"100%" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs() {

  React.useEffect(()=>{
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, null, window.location.href);
    };
  },[])
  const [value, setValue] = React.useState(0);
//   const [highlight, setHighlight] = React.useState(false);

  const Click = (newValue) => {
    setValue(newValue);
    // setHighlight(newValue);
    console.log(value);
    // console.log(highlight);
  };

  return (
    <div>
      <TopBar/>
         <Box sx={{border:"2px solid black",
         background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
        }}>
            <Grid
          container
          spacing={2}
          py={0.5}
         
        >
          <Grid item xs={0.5}></Grid>
          <Grid item md={1.5} sm={2.5} xs={5.5}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              sx={{ fontWeight: "bold" }}
            >
              Attendance
            </Button>
          </Grid>
          <Grid item md={1.9} sm={3} xs={6}>
            <Button
              size="small"
              onClick={()=>Click(0)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 0 ? 'black' : '' ,
                color: value == 0 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
              DAILY ATTENDANCE ENTRY
            </Button>
          </Grid>
          <Grid item md={2.5} sm={3} xs={6}>
            <Button
                onClick={()=>Click(1)}
              size="small"
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 1 ? 'black' : '' ,
                color: value == 1 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
              APPROVE THE ATTENDANCE ENTRY
            </Button>
          </Grid>
          <Grid item md={2} sm={3} xs={6}>
            <Button
              size="small"
              onClick={() => Click(2)}
              sx={{
                color: "black",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
                backgroundColor: value == 2 ? 'black' : '' ,
                color: value == 2 ? 'white' : 'black' ,
                fontWeight: "bold",
              }}
            >
              ATTENDANCE REPORT
            </Button>
          </Grid>
        </Grid>
</Box>

      <CustomTabPanel value={value} index={0}>
      <AttendanceEntry/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
      <AttendanceApprove/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
    <AttendanceReport/>
      </CustomTabPanel>
     
      </div>
  );
}
