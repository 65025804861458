import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { C } from "../../util/C";
import { StaffService } from "../../../services/StaffService";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function BasicGrid(props) {
  const [monthlySalary, setMonthlySalary] = React.useState([]);
  const [bankDetails, setBankDetails] = React.useState();
  React.useEffect(() => {
    onStaffBankLoad();
    onStaffSalaryLoad();
  }, []);
  const onStaffBankLoad = async () => {
    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await StaffService.getStaffBankDetails(
        props.data.ssid,
        yearData.yearName
      );
      setBankDetails(response.data.data.bankDetails);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const [total, setTotal] = React.useState(0);
  const onStaffSalaryLoad = async () => {
    let monthtotal = 0;
    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await StaffService.staffSalaryDetailsMonths(
        props.data.ssid,
        yearData.yearName
      );
      setMonthlySalary(response.data.data);
      console.log(response.data.data);
      if (response.data.data) {
        response.data.data.forEach((element) => {
          if (element.totalPay != null) {
            monthtotal = monthtotal + parseInt(element.totalPay);
          }
        });
      }
      setTotal(monthtotal);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box component="form" sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Box sx={{ m: 0, mt: 0, ml: 0 }}>
                  {/* <Stack direction="row" sx={{ mb: 5 }}>
                    {monthlySalary.map((rec) => (
                      <Stack direction="column" sx={{ marginX: 1 }}>
                        <Item sx={{ width: "150px" }}>
                          <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                              fontWeight: "bold",
                              fontSize: "18px",
                              backgroundColor: "lightgray",
                            }}
                          >
                            {rec.month}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ textAlign: "center" }}
                          >
                            {rec.totalPay}
                          </Typography>
                        </Item>
                      </Stack>
                    ))}
                  </Stack> */}
                  <Grid container spacing={0}>
                    {monthlySalary.map((rec) => (
                      <Grid item xs={1}>
                        <Stack direction="column" sx={{ marginX: 0.5 }}>
                          <Item sx={{}}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              sx={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                backgroundColor: "lightgray",
                              }}
                            >
                              {rec.month}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              sx={{ textAlign: "center",fontWeight:500 }}
                            >
                              {/* {rec.totalPay} */}
                              {rec.totalPay !== null ? rec.totalPay : "--"}
                            </Typography>
                          </Item>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ m: 0, mt: 0, ml: 0 }}>
                  <Stack direction="row" sx={{ mb: 5 }}>
                    <Stack direction="column" sx={{ marginX: 1 }}>
                      <Item sx={{ width: "1400px" }}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            backgroundColor: "lightgray",
                          }}
                        >
                          TOTAL
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ textAlign: "center" }}
                        >
                          {total}
                        </Typography>
                      </Item>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {bankDetails ? (
          <Grid item xs={12}>
            <Box sx={{ m: 2, mt: 5, ml: 7 }}>
              <Stack direction="row" sx={{ mb: 5 }}>
                <Stack direction="column" sx={{ marginX: 1 }}>
                  {/* STAFF DESIGNATION */}
                  <Item sx={{ width: "200px" }}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      BANK NAME
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ textAlign: "center", textTransform: "uppercase" }}
                    >
                      {bankDetails.bankName ? bankDetails.bankName : "--"}
                    </Typography>
                  </Item>
                </Stack>
                <Stack direction="column" sx={{ marginX: 1 }}>
                  {/* STAFF DESIGNATION */}
                  <Item sx={{ width: "200px" }}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      ACCOUNT NO.
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ textAlign: "center" }}
                    >
                      {bankDetails.accountNo ? bankDetails.accountNo : "--"}
                    </Typography>
                  </Item>
                </Stack>
                <Stack direction="column" sx={{ marginX: 1 }}>
                  {/* STAFF DESIGNATION */}
                  <Item sx={{ width: "200px" }}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      IFSC
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ textAlign: "center", textTransform: "uppercase" }}
                    >
                      {bankDetails.ifscDetails ? bankDetails.ifscDetails : "--"}
                    </Typography>
                  </Item>
                </Stack>
                <Stack direction="column" sx={{ marginX: 1 }}>
                  {/* STAFF DESIGNATION */}
                  <Item sx={{ width: "200px" }}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      BRANCH
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ textAlign: "center", textTransform: "uppercase" }}
                    >
                      {bankDetails.branch ? bankDetails.branch : "--"}
                    </Typography>
                  </Item>
                </Stack>
                <Stack direction="column" sx={{ marginX: 1 }}>
                  {/* STAFF DESIGNATION */}
                  <Item sx={{ width: "200px" }}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      PAN
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ textAlign: "center", textTransform: "uppercase" }}
                    >
                      {bankDetails.panDetails ? bankDetails.panDetails : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <h4>Bank Detail not Entered</h4>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
