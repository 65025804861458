import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";
import { AirportShuttle } from "@mui/icons-material";

export class Report {
  static service = "report/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static getStaffReport() {
    var dataURL = this.serverURL + "staffReport";
    return axios.get(dataURL);
  }

  static getStudentReport(d, s, y) {
    var dataURL =
      this.serverURL +
      "studentReport?approveStatus=true&division=" +
      d +
      "&standard=" +
      s +
      "&yearName=" +
      y;
    return axios.get(dataURL);
  }

  static getHallTicketData(standard, testTitleName) {
    var dataURL =
      this.serverURL +
      "hallTicketData?standard=" +
      standard +
      "&testTitleName=" +
      testTitleName;
    // http://localhost:1919/report/hallTicketData?standard=Pre%20KG&testTitleName=Test%20I
    return axios.get(dataURL);
  }

  static examResult (division,standard,testTitleName,usid,yearName) {
    var dataURL =
    this.serverURL +
   "examResult?division=" +
    division +
    "&standard=" + 
    standard +
    "&testTitleName=" + 
    testTitleName +
    "&usid=" +
   usid +
    "&yearName=" +
     yearName;

     return axios.get(dataURL);
  }

  static examResultAllStudents(division,standard,testTitleName,yearName) {
    var dataURL =
      this.serverURL +
      "examResultAllStudents?division=" +
      division +
      "&standard="+
      standard+
      "&testTitleName=" +
      testTitleName+
      "&yearName="+yearName;
      http://localhost:1919/report/examResultAllStudents?division=A&standard=LOWER%20KG&testTitleName=final&yearName=2024-2025
    return axios.get(dataURL);
  }
}
