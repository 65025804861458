import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class StudentAttendenanceService {
  static service = "studentattendance/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createAttendance(data) {
    var dataURL = this.serverURL + "create";
    return axios.post(dataURL, data);
  }
  static mouthAbentReport(m, y) {
    var dataURL =
      this.serverURL + "getmonthabsentreport?monthName=" + m + "&yearName=" + y;
    return axios.get(dataURL);
  }
  static thatMouthAbentReport(m, y) {
    var dataURL =
      this.serverURL + "getmonthabsentreport?monthName=" + m + "&yearName=" + y;
    return axios.get(dataURL);
  }
  static dateAbentReport(m, y) {
    var dataURL =
      this.serverURL + "getdateabsentreport?date=" + m + "&yearName=" + y;
    // getdateabsentreport?date=5%2F18%2F2023&yearName=2077
    return axios.get(dataURL);
  }
  static toFromAbentReport(s, e, y) {
    var dataURL =
      this.serverURL +
      "GetDateRangeAbsentReport?from=" +
      s +
      "&to=" +
      e +
      "&yearName=" +
      y;
    // getdateabsentreport?date=5%2F18%2F2023&yearName=2077
    return axios.get(dataURL);
  }
  static classAbentReport(date, s, d, y) {
    var dataURL =
      this.serverURL +
      "getstudentattendance?date=" +
      date +
      "&divisionID=" +
      d +
      "&standardID=" +
      s +
      "&yearName=" +
      y;
    // getstudentattendance?date=1&divisionID=1&standardID=1&yearName=1
    return axios.get(dataURL);
  }
  static getmonthStandaredabsentreport(m, s, y) {
    var dataURL =
      this.serverURL +
      "getmonthStandaredabsentreport?monthName=" +
      m +
      "&standardName=" +
      s +
      "&yearName=" +
      y;
    // getmonthStandaredabsentreport?monthName=AS&standardName=a&yearName=a
    // getstudentattendance?date=1&divisionID=1&standardID=1&yearName=1
    return axios.get(dataURL);
  }
  static getmonthstandaredabsentreportmonth(m, y) {
    var dataURL =
      this.serverURL +
      "getmonthstandaredabsentreport?monthName=" +
      m +
      "&yearName=" +
      y;
    //localhost:1919/studentattendance/getmonthstandaredabsentreport?monthName=May&yearName=2077
    return axios.get(dataURL);
  }
  static getdatestandaredabsentreportdate(d, y) {
    var dataURL =
      this.serverURL +
      "getDatestandaredabsentreport?date=" +
      d +
      "&yearName=" +
      y;
    //localhost:1919/studentattendance/getmonthstandaredabsentreport?monthName=May&yearName=2077
    //localhost:1919/studentattendance/getDatestandaredabsentreport?date=6%2F12%2F2023&yearName=2077
    return axios.get(dataURL);
  }

  static getAbsentreport(d, y) {
    console.log(d, y);
    var dataURL =
      this.serverURL + "getdateabsentreport?date=" + d + "&yearName=" + y;
    return axios.get(dataURL);
    //localhost:1919/studentattendance/getabsentreport?date=10%2F28%2F2023&monthName=October&yearName=2023-2024
  }
  static getAbsentClassDivreport(d, div, s, y) {
    var dataURL =
      this.serverURL +
      "getdateabsentreport?date=" +
      d +
      "&divisionID=" +
      div +
      "&standardID=" +
      s +
      "&yearName=" +
      y;
    return axios.get(dataURL);
    // getstudentattendance?date=10%2F29%2F2023&divisionID=A&standardID=8e6bb441-72d7-4c2e-a641-8707e0c1e8e5&yearName=2023-2024
  }
  static getStudentAttandanceAllMonthInfo(usn, y) {
    var dataURL =
      this.serverURL +
      "getStudentAttandanceAllMonthInfo?studentUSN=" +
      usn +
      "&yearName=" +
      y;
    return axios.get(dataURL);
    //studentattendance/getStudentAttandanceAllMonthInfo?studentUSN=0021&yearName=0021
  }
  static getStudentAttandanceMonthInfo(m, usn, y) {
    var dataURL =
      this.serverURL +
      "getStudentAttandanceMonthInfo?monthName=" +
      m +
      "&studentUSN=" +
      usn +
      "&yearName=" +
      y;
    return axios.get(dataURL);
    // studentattendance/getStudentAttandanceMonthInfo?monthName=mm&studentUSN=mm&yearName=mm
  }

  static getClassDivreport(d, div, s, y) {
    var dataURL =
      this.serverURL +
      "getstudentattendance?date=" +
      d +
      "&divisionID=" +
      div +
      "&standardID=" +
      s +
      "&yearName=" +
      y;
    return axios.get(dataURL);
  }
  static getClassDivreportMonth(d, m, s, y) {
    var dataURL =
      this.serverURL +
      "getStudentMonthWiseReport?divisionID=" +
      d +
      "&monthName=" +
      m +
      "&standaredId=" +
      s +
      "&yearName=" +
      y;
    return axios.get(dataURL);
  }
  static getTotalReport(d, m, y) {
    var dataURL =
      this.serverURL +
      "getabsentreport?date=" +
      d +
      "&monthName=" +
      m +
      "&yearName=" +
      y;
    return axios.get(dataURL);
  }
}
