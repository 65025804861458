import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Avatar,
  Button,
  Checkbox,
  Grid,
  Paper,
  Stack,
  ToggleButton,
  Typography,
} from "@mui/material";
import Topbar from "../../MainTopbar/Topbar";
import { Box } from "@mui/system";
import { StaffService } from "../../../services/StaffService";
import { StandardDivision } from "../../../services/StandardDIvision";
import { AddStandarsStrengthService } from "../../../services/standardStrength";
import { StandardSubjectService } from "../../../services/StandardSubject";
import { TimeTableService } from "../../../services/TimeTableService";
import { TimeTable } from "../../../services/TimeTable";
import { C } from "../../util/C";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function DataTable() {
  const [staff, staffList] = React.useState([]);
  const [staffSelected, setStaffsSelected] = React.useState(false);

  React.useEffect(() => {
    onStaffLoad();
    onStandardLoad();
  }, []);

  const [standardListAll, updateStandard] = React.useState([]);

  const onStandardLoad = async () => {
    try {
      let response = await AddStandarsStrengthService.standardStrengthListAll();
      //console.log(response.data.data);
      updateStandard(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onStaffLoad = async () => {
    try {
      let response = await StaffService.staffListAll();
      // console.log(response.data.data);
      staffList(response.data.data);
      // console.log(staff);
    } catch (error) {
      console.log(error);
    }
  };

  const [staffName, setstaff] = React.useState();
  const onStaffSelection = (e) => {
    console.log(e.currentTarget.value);
    setstaff(e.currentTarget.value);
    console.log(e.currentTarget.id);
  };

  const [standardID, setstandardID] = React.useState();
  const [standardName, setstandardName] = React.useState();

  const onStandardSelect = (e) => {
    // C.c(e.currentTarget.id);
    // C.c(e.currentTarget.name);
    setstandardID(e.currentTarget.id);
    setstandardName(e.currentTarget.name);
    onStandardSubjectLoad(e.currentTarget.id);
    onStandardDivisionLoad(e.currentTarget.id);
  };

  const [standardDivisionID, setstandardDivisionID] = React.useState();
  // const [standardDivisionName, setstandardDivisionName] = React.useState();

  const onDivisionSelect = (e) => {
    console.log(e.currentTarget.id);
    setstandardDivisionID(e.currentTarget.id);
    // setstandardDivisionName(e.currentTarget.name);
    timeTable();
  };

  const [standardSubjectID, setstandardsubjectID] = React.useState();
  const [standardsubjectName, setstandardsubjectName] = React.useState();

  const onSubjectSelect = (e) => {
    console.log(e.currentTarget.id);
    console.log(e.currentTarget.name);
    setstandardsubjectID(e.currentTarget.id);
    setstandardsubjectName(e.currentTarget.name);
  };

  const [standardSubjectListAll, updateStandardSubject] = React.useState([]);

  const onStandardSubjectLoad = async (standardID) => {
    // console.log("hello standardsubject");
    // console.log("=====================" + standardID);

    try {
      let response =
        await StandardSubjectService.findSubjectListByStandardIDYearID(
          "75c2fd74-6b90-4784-bc28-646e4412a739",
          standardID
        );
      console.log(response.data.data);

      updateStandardSubject(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [standardDivisionListAll, updateStandardDivision] = React.useState([]);

  const onStandardDivisionLoad = async (standardID) => {
    // console.log("hello onStandardDivisionLoad");
    // console.log("=====================" + standardID);
    try {
      let response = await StandardDivision.StandardDivisionWiseStandard(
        "75c2fd74-6b90-4784-bc28-646e4412a739",
        standardID
      );
      // console.log(response.data.data);
      updateStandardDivision(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [rows, setRows] = React.useState([]);

  const [columns, setColumns] = React.useState([]);

  const [timeTableMaster, setTimeTableMaster] = React.useState([]);

  const timeTable = async () => {
    console.log(standardDivisionID);
    console.log(standardName);

    let daysList = [];
    let typeList = [];
    try {
      // let response = await TimeTableService.StandardTimeTable(
      let response = await TimeTable.StandardTimeTable(
        standardID,
        standardName,
        standardDivisionID
      );
      daysList = response.data.data.daysList;
      typeList = response.data.data.titleList;
      setTimeTableMaster(response.data.data.timeTableMasterList);
    } catch (error) {
      console.log(error);
    }

    daysList.unshift("No of day");
    C.c(daysList);

    let daysColumn = [];
    for (let i = 0; i < daysList.length; i++) {
      // console.log(daysList[i]);
      daysColumn.push({
        field: daysList[i],
        headerName: daysList[i],
        // editable: "true",
        width: 200,
        ClassName: daysList[i],
      });
      console.log(daysColumn);
    }
    setColumns(daysColumn);

    let rowType = [];
    for (let i = 0; i < typeList.length; i++) {
      rowType.push({
        id: i,
        No_of_day: i,
      });
      console.log(rowType);
    }
    setRows(rowType);
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * adding the data to the datagrid * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  const oncellselect = (cell, e) => {
    let day = cell.field;
    C.c(cell.field);
    C.c(cell.row.id);
    C.c(timeTableMaster);

    let mondayMasterTimeTable = [];
    if (timeTableMaster.monday) {
      mondayMasterTimeTable = timeTableMaster.monday;
      // C.c(mondayMasterTimeTable);
    }
    let tuesdayMasterTimeTable = [];
    if (timeTableMaster.tuesday) {
      tuesdayMasterTimeTable = timeTableMaster.tuesday;
      // C.c(tuesdayMasterTimeTable);
    }
    let wednesdayMasterTimeTable = [];
    if (timeTableMaster.wednesday) {
      wednesdayMasterTimeTable = timeTableMaster.wednesday;
      // C.c(wednesdayMasterTimeTable);
    }
    let thursdayMasterTimeTable = [];
    if (timeTableMaster.thursday) {
      thursdayMasterTimeTable = timeTableMaster.thursday;
      // C.c(thursdayMasterTimeTable);
    }
    let fridayMasterTimeTable = [];
    if (timeTableMaster.friday) {
      fridayMasterTimeTable = timeTableMaster.friday;
      // C.c(fridayMasterTimeTable);
    }
    let saturdayMasterTimeTable = [];
    if (timeTableMaster.saturday) {
      saturdayMasterTimeTable = timeTableMaster.saturday;
      // C.c(saturdayMasterTimeTable);
    }
    let sundayMasterTimeTable = [];
    if (timeTableMaster.sunday) {
      sundayMasterTimeTable = timeTableMaster.sunday;
      // C.c(sundayMasterTimeTable);
    }

    let row_id = cell.row.id + 1;

    if (cell.field == "monday") {
      mondayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].monday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "tuesday") {
      // C.c(tuesdayMasterTimeTable);
      tuesdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].tuesday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "wednesday") {
      wednesdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].wednesday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "thursday") {
      thursdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].thursday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "friday") {
      fridayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].friday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "saturday") {
      saturdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].saturday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "sunday") {
      sundayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].sunday =
            standardName +
            " " +
            standardDivisionID +
            " " +
            standardsubjectName +
            " " +
            staffName;
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
  };

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * deleting the data to the datagrid * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  const onCellDelete = (cell, e) => {
    C.c(cell.field);
    C.c(cell.row.id);
    C.c(timeTableMaster);

    let mondayMasterTimeTable = [];
    if (timeTableMaster.monday) {
      mondayMasterTimeTable = timeTableMaster.monday;
      // C.c(mondayMasterTimeTable);
    }
    let tuesdayMasterTimeTable = [];
    if (timeTableMaster.tuesday) {
      tuesdayMasterTimeTable = timeTableMaster.tuesday;
      // C.c(tuesdayMasterTimeTable);
    }
    let wednesdayMasterTimeTable = [];
    if (timeTableMaster.wednesday) {
      wednesdayMasterTimeTable = timeTableMaster.wednesday;
      // C.c(wednesdayMasterTimeTable);
    }
    let thursdayMasterTimeTable = [];
    if (timeTableMaster.thursday) {
      thursdayMasterTimeTable = timeTableMaster.thursday;
      // C.c(thursdayMasterTimeTable);
    }
    let fridayMasterTimeTable = [];
    if (timeTableMaster.friday) {
      fridayMasterTimeTable = timeTableMaster.friday;
      // C.c(fridayMasterTimeTable);
    }
    let saturdayMasterTimeTable = [];
    if (timeTableMaster.saturday) {
      saturdayMasterTimeTable = timeTableMaster.saturday;
      // C.c(saturdayMasterTimeTable);
    }
    let sundayMasterTimeTable = [];
    if (timeTableMaster.sunday) {
      sundayMasterTimeTable = timeTableMaster.sunday;
      // C.c(sundayMasterTimeTable);
    }

    let row_id = cell.row.id + 1;

    if (cell.field == "monday") {
      mondayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].monday = " ";
          alert("Removed");
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "tuesday") {
      // C.c(tuesdayMasterTimeTable);
      tuesdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].tuesday = "click";
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "wednesday") {
      wednesdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].wednesday = "click";
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "thursday") {
      thursdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].thursday = "click";
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "friday") {
      fridayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].friday = "click";
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "saturday") {
      saturdayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].saturday = "click";
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
    if (cell.field == "sunday") {
      sundayMasterTimeTable.find((rec) => {
        // C.c("mmmmm");
        if (
          rec.title == row_id &&
          rec.standardName == standardName &&
          rec.divisionName == standardDivisionID
        ) {
          C.c(rec);

          C.c(rows);
          rows[cell.row.id].sunday = "click";
          // debugger;
        } else {
          C.c("Please select the correct data");
        }
      });
    }
  };

  return (
    <div>
      <Topbar
        text={"Creating Teachers Time Table has two steps (Step 1 & Step 2)"}
      />
      <Box sx={{ m: 2, textAlign: "center" }}>
        <Typography sx={{ fontWeight: "bold", color: "blue" }}>
          {" "}
          Select the Staff
        </Typography>
        <Grid container spacing={1}>
          {staff.map((element) => (
            <Grid item xs={1}>
              <Item>
                <Box sx={{ width: 80, height: 80 }}>
                  <Avatar
                    variant="square"
                    sx={{ mx: 4, my: 1 }}
                    alt={element.staffName}
                    src="..."
                  />
                  {/* <Typography>{element.staffName}</Typography> */}
                  <ToggleButton
                    value={element.staffName}
                    id={element.staffID}
                    selected={staffSelected}
                    onChange={onStaffSelection}
                    size="small"
                    sx={{ height: 20 }}
                  >
                    {element.staffName}
                  </ToggleButton>
                </Box>
              </Item>
            </Grid>
          ))}
        </Grid>
        {/* <Stack direction="row" spacing={1}>
          {staff.map((element) => (
            <Box sx={{ border: "1px solid", width: 80, height: 80 }}>
              <Avatar
                variant="square"
                sx={{ mx: 2, my: 1 }}
                alt={element.staffName}
                src="..."
              />
              <ToggleButton
                value={element.staffName}
                id={element.staffID}
                selected={staffSelected}
                onChange={onStaffSelection}
                size="small"
                sx={{ height: 20 }}
              >
                {element.staffName}
              </ToggleButton>
            </Box>
          ))}
        </Stack> */}
      </Box>
      <Box sx={{ m: 2, textAlign: "center" }}>
        <Typography sx={{ fontWeight: "bold", color: "blue" }}>
          Select The Standard
        </Typography>
        <Grid container spacing={1}>
          {standardListAll.map((element) => (
            <Grid item xs={1}>
              {/* <Item> */}
              <Button
                variant="outlined"
                id={element.standardID}
                name={element.standardName}
                onClick={onStandardSelect}
              >
                {element.standardName}
              </Button>
              {/* </Item> */}
            </Grid>
          ))}
        </Grid>
        {/* <Stack direction="row" spacing={1}>
          {standardListAll.map((element) => (
            <Button
              id={element.standardID}
              name={element.standardName}
              onClick={onStandardSelect}
            >
              {element.standardName}
            </Button>
          ))}
        </Stack> */}
      </Box>
      <Box sx={{ m: 2, textAlign: "center" }}>
        <Typography sx={{ fontWeight: "bold", color: "blue" }}>
          Select The Subject
        </Typography>
        <Grid container spacing={1}>
          {standardSubjectListAll.map((element) => (
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="outlined"
                id={element.subjectID}
                name={element.subjectName}
                onClick={onSubjectSelect}
              >
                {element.subjectName}
              </Button>
            </Grid>
          ))}
        </Grid>
        {/* <Stack direction="row" spacing={1}>
          {standardSubjectListAll.map((element) => (
            <Button
              id={element.subjectID}
              name={element.subjectName}
              onClick={onSubjectSelect}
            >
              {element.subjectName}
            </Button>
          ))}
        </Stack> */}
      </Box>
      <Box sx={{ m: 2, textAlign: "center" }}>
        <Typography sx={{ fontWeight: "bold", color: "blue" }}>
          Select The Division
        </Typography>
        <Grid container spacing={1}>
          {standardDivisionListAll.map((element) => (
            <Grid item xs={1}>
              <Button
                fullWidth
                variant="outlined"
                id={element.divisionName}
                onClick={onDivisionSelect}
              >
                {element.divisionName}
              </Button>
            </Grid>
          ))}
        </Grid>
        {/* <Stack direction="row" spacing={1}>
          {standardDivisionListAll.map((element) => (
            <Button id={element.divisionName} onClick={onDivisionSelect}>
              {element.divisionName}
            </Button>
          ))}
        </Stack> */}
      </Box>

      <Box
        style={{
          m: 2,
          height: 700,
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold", color: "blue" }}>
          TimeTable
        </Typography>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={12}
          rowsPerPageOptions={[12]}
          // onRowDoubleClick={setTimeTable}
          onCellClick={oncellselect}
          onCellDoubleClick={onCellDelete}
          // onCellDoubleClick
          // getCellClassName
          // onColumnHeaderClick={setTimeTable1}
        />
      </Box>
    </div>
  );
}
