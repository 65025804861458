import React from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

function BackUp() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <div>
      <LaunchpadTopBar id="settings" />
      <Grid container
        sx={{}}
      >
        <Box
          sx={{

            p: 2,
            mt: 5,
            ml: 30,
            mr: 30,
            width: "80%",
            height: 400,
            //   bgcolor: 'background.paper',
            //   borderRadius: 1,
            border: "2px solid black",
            textAlign: "center",
            color: "steelblue",
          }}
        >
         <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          backgroundColor: "lightblue",
          my: 1,
          fontWeight: "bold",
          color: "black", 
        }}
      >
            BACKUP ALL THE INFORMATION

          </Typography>

        </Box>


      </Grid>

    </div>
  )
}

export default BackUp   