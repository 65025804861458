import * as React from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { StudentInfoService } from "../../../services/StudentInfo/StudentList";
import { Box, Grid, Stack, Paper, Typography, Avatar, Button } from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { StudentAttendenanceService } from "../../../services/studentAttendance";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "center",
  // fontWeight:"bold"
  // color: theme.palette.text.secondary
}));

export default function StudentAttendence() {
  const [imageSrc, setImageSrc] = React.useState();
  const [total, setTotal] = React.useState();
  const [present, setPresent] = React.useState();
  const [absent, setAbsent] = React.useState();
  const [studentInfo, updateStudentInfo] = React.useState({});
  
  React.useEffect(() => {
    studentDetailsLoad();
  }, []);


  let navigate = useNavigate();
  const onLaunchPad = ()=>{
    navigate("/launchpad", {replace: true});
  }

  const studentDetailsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let studentUSN = localStorage.getItem("studentUSN");
    try {
      let response = await StudentInfoService.studentInfoShowStudentDetails(
        studentUSN,
        data.yearName
      );
      console.log(response.data.data);
      updateStudentInfo(response.data.data);
      AllMonthAttendenceData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [allMonthstudentInfo, setAllMonthStudentInfo] = React.useState([]);
  const [monthstudentInfo, setMonthStudentInfo] = React.useState([]);
  const [allMonth, setAllMonth] = React.useState([]);
  const [monthName, setMonth]= React.useState();

  
  console.log(monthName);

  const AllMonthAttendenceData = async (student) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    // const currentDate = new Date();
    // const iso8601Date = currentDate.toISOString().split("T")[0];
    // const options = { month: "long" };
    // const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);

// const month = monthName;    // console.log(iso8601Date);
       
    try {
      let response =
        await StudentAttendenanceService.getStudentAttandanceAllMonthInfo(
          student.studentUSN,
          data.yearName
        );
      console.log(response.data.data.attandanceMonthReports);
      setAllMonthStudentInfo(response.data.data.attandanceMonthReports);

      // console.clear();
      var mon = 0;
      for (let i = 0; i < response.data.data.attandanceMonthReports.length; i++) {
        let max = response.data.data.attandanceMonthReports[i].numberOfDays; 
        mon +=max;
      }
      console.log(mon);
      setTotal(mon);

      var pre = 0;
      for (let i = 0; i < response.data.data.attandanceMonthReports.length; i++) {
        let prst = response.data.data.attandanceMonthReports[i].numberOfDaysPresent; 
        pre +=prst;
      }
      console.log(pre);
      setPresent(pre);

      var abs = 0;
      for (let i = 0; i < response.data.data.attandanceMonthReports.length; i++) {
        let abst = response.data.data.attandanceMonthReports[i].numberOfDaysAbsent;  
        abs +=abst;
      }
      console.log(abs);
      setAbsent(abs);

      
      // console.log(total);
      // console.log(present);
      // console.log(absent);


    } catch (error) {
      console.log(error);
    }
    // try {
    //   let response =
    //     await StudentAttendenanceService.getStudentAttandanceMonthInfo(
    //       monthName,
    //       student.studentUSN,
    //       data.yearName
    //     );
    //     console.clear();
    //     console.log(monthName);
    //   console.log(response.data.data);
    //   setMonthStudentInfo(response.data.data);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const [highlight, setHighlight] = React.useState();
  const onSelectMonth = async(e)=> {
    // console.clear();
    console.log(e.target.value);
    setHighlight(e.target.value);
    setMonth(e.target.value.toUpperCase());
    let data = JSON.parse(localStorage.getItem("academicYear"));
    // AllMonthAttendenceData();
    try {
      let response =
        await StudentAttendenanceService.getStudentAttandanceMonthInfo(
          // monthName,
          e.target.value,
          studentInfo.studentUSN,
          data.yearName
        );
      console.log(response.data.data);
      setMonthStudentInfo(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const school = JSON.parse(localStorage.getItem("schoolDetails"));
  const academic = JSON.parse(localStorage.getItem("academicYear"));

 
const genaratePDF = () => {
  const doc = new jsPDF("landscape");
 
  const sch = school.schoolNameOnDocs.toUpperCase();
  const acad = academic.yearName;
  const img = school.schoolLogoUrl;
  doc.setFont("helvetica", "bold");
  var fontSize = 18;
  var text = `${sch}`;
  var pageWidth = doc.internal.pageSize.getWidth();
  var textWidth =
    (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
  var centerX = (pageWidth - textWidth) / 2;
  doc.text(text, centerX, 15);

  doc.addImage(img, "JPEG", 15,5,27,27);
  doc.setFontSize(10);
  doc.text(` ${acad}`, 250, 15);

  

    doc.setFontSize(15);
    doc.setFont("helvetica", "bold");
    doc.text("INDIVIDUAL STUDENT LIBRARY REPORT", 90, 30);

    doc.setFontSize(14);
    doc.text(studentInfo.studentName+"--"+studentInfo.studentUSN, 110,48);
    doc.addImage(studentInfo.studentProfileUrl, 'JPEG', 220,25,30,36);
 
    doc.autoTable({
      startY:60,
      head:[["STD-DIV : "+studentInfo.standardName+"-"+studentInfo.divisionName, 
   "MED :"+ studentInfo.studentMedicalAttentionDetail,
  "BG :"+  studentInfo.studentBloodGroup,
  "DOB :" + studentInfo.studentDOB, "AGE :"+ studentInfo.studentAge
    ]],
    headStyles:{
      fillColor : "white",
      textColor: 0,
      fontSize:12
    },
    theme:"grid",
    });
    
doc.setFontSize(11);
    doc.text("MAY",10,85);
    doc.text("JUNE",24,85);
    doc.text("JULY",40,85);
    doc.text("AUGUST",55,85);
    doc.text("SEPTEMBER",75,85);
    doc.text("OCTOBER",103,85);
    doc.text("NOVEMBER",128,85);
    doc.text("DECEMBER",158,85);
    doc.text("JANUARY",185,85);
    doc.text("FEBRUARY",208,85);
    doc.text("MARCH",235,85);
    doc.text("APRIL",253,85);
  

    let arr = allMonthstudentInfo.map(obj => ({
      ...obj,
      numberOfDays: String(obj.numberOfDays),
      numberOfDaysAbsent: String(obj.numberOfDaysAbsent),
      numberOfDaysPresent: String(obj.numberOfDaysPresent),
    }));
    console.log(arr);

doc.setLineWidth(0.5);
doc.rect(7,80,260,20);
doc.text(arr[0].numberOfDays, 13,90);
doc.text(arr[1].numberOfDays,29,90);
doc.text(arr[2].numberOfDays,43.5,90);
doc.text(arr[3].numberOfDays,62,90);
doc.text(arr[4].numberOfDays,85,90);
doc.text(arr[5].numberOfDays,112,90);
doc.text(arr[6].numberOfDays,137,90);
doc.text(arr[7].numberOfDays,169,90);
doc.text(arr[8].numberOfDays,194,90);
doc.text(arr[9].numberOfDays,216,90);
doc.text(arr[10].numberOfDays,240,90);
doc.text(arr[11].numberOfDays,257,90);

doc.text(arr[0].numberOfDaysPresent+" | ",10,95);
doc.text(arr[1].numberOfDaysPresent+" | ",26,95);
doc.text(arr[2].numberOfDaysPresent+" | ",41,95);
doc.text(arr[3].numberOfDaysPresent+" | ",60,95);
doc.text(arr[4].numberOfDaysPresent+" | ",83,95);
doc.text(arr[5].numberOfDaysPresent+" | ",110,95);
doc.text(arr[6].numberOfDaysPresent+" | ",135,95);
doc.text(arr[7].numberOfDaysPresent+" | ",167,95);
doc.text(arr[8].numberOfDaysPresent+" | ",192,95);
doc.text(arr[9].numberOfDaysPresent+" | ",213,95);
doc.text(arr[10].numberOfDaysPresent+" | ",237,95);
doc.text(arr[11].numberOfDaysPresent+" | ",255,95);

doc.setTextColor(255,0,0);
doc.text(arr[0].numberOfDaysAbsent,16.5,95);
doc.text(arr[1].numberOfDaysAbsent,32.5,95);
doc.text(arr[2].numberOfDaysAbsent,48,95);
doc.text(arr[3].numberOfDaysAbsent,66.5,95);
doc.text(arr[4].numberOfDaysAbsent,89.5,95);
doc.text(arr[5].numberOfDaysAbsent,116.5,95);
doc.text(arr[6].numberOfDaysAbsent,141.5,95);
doc.text(arr[7].numberOfDaysAbsent,173.5,95);
doc.text(arr[8].numberOfDaysAbsent,198.5,95);
doc.text(arr[9].numberOfDaysAbsent,219.5,95);
doc.text(arr[10].numberOfDaysAbsent,243.5,95);
doc.text(arr[11].numberOfDaysAbsent,261.5,95);

doc.setTextColor(0,0,0);
doc.text("1",16,125);
doc.text("2",42,125);
doc.text("3",68,125);
doc.text("4",94,125);
doc.text("5",120,125);
doc.text("6",146,125);
doc.text("7",172,125);
doc.text("8",198,125);
doc.text("9",224,125);
doc.text("10",250,125);
doc.text("11",276,125);
doc.text("12",16,142);
doc.text("13",42,142);
doc.text("14",68,142);
doc.text("15",94,142);
doc.text("16",120,142);
doc.text("17",146,142);
doc.text("18",172,142);
doc.text("19",198,142);
doc.text("20",224,142);
doc.text("21",250,142);
doc.text("22",276,142);
doc.text("23",16,161);
doc.text("24",42,161);
doc.text("25",68,161);
doc.text("26",94,161);
doc.text("27",120,161);
doc.text("28",146,161);
doc.text("29",172,161);
doc.text("30",198,161);
doc.text("31",224,161);


monthstudentInfo.s0 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s0 == null ? "- - - - - -" : monthstudentInfo.s0.toUpperCase(),11,131);

monthstudentInfo.s1 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s1 == null ? "- - - - - -" : monthstudentInfo.s1.toUpperCase(),36,131);

monthstudentInfo.s2 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s2 == null ? "- - - - - -" : monthstudentInfo.s2.toUpperCase(),62,131);

monthstudentInfo.s3 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s3 == null ? "- - - - - -" : monthstudentInfo.s3.toUpperCase(),88,131);

monthstudentInfo.s4 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s4 == null ? "- - - - - -" : monthstudentInfo.s4.toUpperCase(),114,131);

monthstudentInfo.s5 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s5 == null ? "- - - - - -" : monthstudentInfo.s5.toUpperCase(),140,131);

monthstudentInfo.s6 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s6 == null ? "- - - - - -" : monthstudentInfo.s6.toUpperCase(),166,131);

monthstudentInfo.s7 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s7 == null ? "- - - - - -" : monthstudentInfo.s7.toUpperCase(),192,131);

monthstudentInfo.s8 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s8 == null ? "- - - - - -" : monthstudentInfo.s8.toUpperCase(),218,131);

monthstudentInfo.s9 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s9 == null ? "- - - - - -" : monthstudentInfo.s9.toUpperCase(),244,131);

monthstudentInfo.s10 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s10 == null ? "- - - - - -" : monthstudentInfo.s10.toUpperCase(),270,131);

monthstudentInfo.s11 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s11 == null ? "- - - - - -" : monthstudentInfo.s11.toUpperCase(),11,148);

monthstudentInfo.s12 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s12 == null ? "- - - - - -" : monthstudentInfo.s12.toUpperCase(),36,148);

monthstudentInfo.s13 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s13 == null ? "- - - - - -" : monthstudentInfo.s13.toUpperCase(),62,148);

monthstudentInfo.s14 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s14 == null ? "- - - - - -" : monthstudentInfo.s14.toUpperCase(),88,148);

monthstudentInfo.s15 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s15 == null ? "- - - - - -" : monthstudentInfo.s15.toUpperCase(),114,148);

monthstudentInfo.s16 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s16 == null ? "- - - - - -" : monthstudentInfo.s16.toUpperCase(),140,148);

monthstudentInfo.s17 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s17 == null ? "- - - - - -" : monthstudentInfo.s17.toUpperCase(),166,148);

monthstudentInfo.s18 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s18 == null ? "- - - - - -" : monthstudentInfo.s18.toUpperCase(),192,148);

monthstudentInfo.s19 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s19 == null ? "- - - - - -" : monthstudentInfo.s19.toUpperCase(),218,148);

monthstudentInfo.s20 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s20 == null ? "- - - - - -" : monthstudentInfo.s20.toUpperCase(),244,148);

monthstudentInfo.s21 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s21 == null ? "- - - - - -" : monthstudentInfo.s21.toUpperCase(),270,148);

monthstudentInfo.s22 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s22 == null ? "- - - - - -" : monthstudentInfo.s22.toUpperCase(),11,167);

monthstudentInfo.s23 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s23 == null ? "- - - - - -" : monthstudentInfo.s23.toUpperCase(),36,167);

monthstudentInfo.s24 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s24 == null ? "- - - - - -" : monthstudentInfo.s24.toUpperCase(),62,167);

monthstudentInfo.s25 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s25 == null ? "- - - - - -" : monthstudentInfo.s25.toUpperCase(),88,167);

monthstudentInfo.s26 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s26 == null ? "- - - - - -" : monthstudentInfo.s26.toUpperCase(),114,167);

monthstudentInfo.s27 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s27 == null ? "- - - - - -" : monthstudentInfo.s27.toUpperCase(),140,167);

monthstudentInfo.s28 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s28 == null ? "- - - - - -" : monthstudentInfo.s28.toUpperCase(),166,167);

monthstudentInfo.s29 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s29 == null ? "- - - - - -" : monthstudentInfo.s29.toUpperCase(),192,167);

monthstudentInfo.s30 == "absent" ? doc.setTextColor(255,0,0) : doc.setTextColor(0,0,0);
doc.text(monthstudentInfo.s30 == null ? "- - - - - -" : monthstudentInfo.s30.toUpperCase(),218,167);

doc.setLineWidth(0.5);
doc.line(66,111.5,230,111.5);
doc.setTextColor(0,0,0);
doc.setFont("Times new roman","bold");
doc.setFontSize(15);
doc.text("MONTHLY ATTENDANCE REPORT FOR "+monthName+" MONTH",70,110);

 doc.save(studentInfo.standardName+"-"+studentInfo.divisionName+"--"+studentInfo.studentUSN+"Attendance Report.pdf");  
 
}

  return (
    <React.Fragment>
      <Box>
        {/* <LaunchpadTopBar id="studentinfo" /> */}

        <Box sx={{ width: "100%", paddingLeft: "30px", boxSizing: "border-box", mt: 2}}>
          <Grid container>
            <Grid item md={10} xs={8}>
              <Box
                display={"flex"}
                alignContent={"center"}
                justifyContent={"center"}


              >
                <Stack direction="column" sx={{ width: "100%",  boxSizing: "border-box", mt: 2 }}>
                  <Item >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      STUDENT NAME
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      {studentInfo ? studentInfo.studentName : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
              <Box
                sx={{ width: "100%", boxSizing: "border-box", my: "1%" }}
              >
                <Grid  container spacing={{ xs: 1, sm: 1, md: 2 }} sx={{ width: "100%", boxSizing: "border-box", mt: 2 }}>
                  <Grid item md={1} xs={3} >
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        USN
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentUSN : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        STANDARD | DIVISION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.standardName +
                          " | " +
                          studentInfo.divisionID
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={1} xs={3}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        CLASS TR
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {"--"}
                        {/* {studentInfo ? studentInfo.classteacher : "--"} */}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        MEDICAL ATTENTION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.studentMedicalAttentionDetail
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        BLOOD GROUP
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentBloodGroup : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        DOB
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentDOB : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  {/* <Typography
              variant="body1"
              width="250px"
              sx={{ textAlign: "center" }}
            >
              {studentInfo.studentAge + " Age"}
            </Typography> */}
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item>
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        AGE
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentAge : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={2} xs={4}>
              <Box display={"flex"} justifyContent={"center"}  sx={{mt:"2%"}}>
                <Avatar
                  alt="Remy Sharp"
                  src={studentInfo ? studentInfo.studentProfileUrl : ""}
                  //   id={element.staffID}
                  variant="square"
                  sx={{
                    width: 150,
                    height: 190,
                    mx: 2,
                    // mt: 1,
                    border: "3px solid skyblue",
                    borderRadius: "5%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* <Box sx={{ width: "100%",paddingLeft:"20px", boxSizing: "border-box", mt: 2 , border:"2px solid brown"}}> */}
        {/* <Grid container border={"2px solid red"}> */}
        {/* <Grid item sx={{border:"2px solid green"}} > */}
        <Box
          sx={{ width: "100%", paddingLeft:"30px", boxSizing: "border-box", mt: 2}}
          display={"flex"}
          alignContent={"center"}
          justifyContent={"center"}
          

        >
          <Grid container>
            <Grid container md={10.4} xs={12}  >
              {allMonthstudentInfo.map((r) => (
                <Grid item  xs={2.7} md={0.92} sx={{width: "100%", boxSizing: "border-box", mt: 2, marginX:0.5 }}>
                  <Item>
                    <Button
                      variant="contained"
                      value={r.month}
                      gutterBottom
                      sx={{
                        width: "100px",
                        borderRadius: "25px",
                        color: highlight === r.month ? "white" : "black",
                        backgroundColor: highlight === r.month ? "primary" : "lightblue",
                        fontWeight: "bold",

                      }}
                      onClick={onSelectMonth}
                    >
                      {r.month}
                    </Button>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                        borderBottom: "2px solid black",
                      }}
                    >
                      {r.numberOfDays}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {r.numberOfDaysPresent} | <span style={{ color: "red" }}>{r.numberOfDaysAbsent} </span>
                    </Typography>
                  </Item>
                </Grid>
              ))}


            </Grid>
            <Grid item xs={12} md={1.23}  sx={{ mt: 2}}>
              <Item sx={{width:"95%" }}  >
                      <Typography
                        variant="h6"
                        // width={{md:"150px", xs:"675px"}}
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        TOTAL FOR YEAR
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          borderBottom: "2px solid black",
                        }}
                      >
                        {total}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {present} |<span style={{ color: "red" }}> {absent} </span>
                      </Typography>
              </Item>
            </Grid>

          </Grid>

          {/* <Item sx={{ width: "100px" }}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        TOTAL FOR YEAR
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          borderBottom: "2px solid black",
                        }}
                      >
                        {total}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {present} | {absent}
                      </Typography>
                    </Item> */}

        </Box>
        {/* </Grid> */}
        {/* </Grid> */}
        {/* </Box> */}
        {monthstudentInfo.length == 0 ? (
          <div></div>
        ) : (
          <div>
            <Box sx={{ display: "flex", justifyContent: "center", backgroundColor:"#add8e6" , mt:1 }} marginLeft={{md:"35px",xs:"35px"}} marginRight={{md:"57px",xs:"25px"}} >
              <Typography variant="h6" fontWeight="bold">MONTHLY ATTENDANCE REPORT FOR {monthName} MONTH</Typography>
            </Box>
            <Grid container  sx={{ mt: 2, paddingLeft:"25px"}}>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        1
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s0 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s0 != null
                          ? monthstudentInfo.s0.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        2
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s1 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s1 != null
                          ? monthstudentInfo.s1.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        3
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s2 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s2 != null
                          ? monthstudentInfo.s2.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        4
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s3 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s3 != null
                          ? monthstudentInfo.s3.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        5
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s4 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s4 != null
                          ? monthstudentInfo.s4.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        6
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s5 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s5 != null
                          ? monthstudentInfo.s5.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        7
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s6 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s6 != null
                          ? monthstudentInfo.s6.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        8
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s7 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s7 != null
                          ? monthstudentInfo.s7.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        9
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s8 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s8 != null
                          ? monthstudentInfo.s8.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        10
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s9 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s9 != null
                          ? monthstudentInfo.s9.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        11
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s10 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s10 != null
                          ? monthstudentInfo.s10.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        12
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s11 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s11 != null
                          ? monthstudentInfo.s11.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        13
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s12 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s12 != null
                          ? monthstudentInfo.s12.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        14
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s13 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s13 != null
                          ? monthstudentInfo.s13.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        15
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s14 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s14 != null
                          ? monthstudentInfo.s14.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        16
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s15 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s15 != null
                          ? monthstudentInfo.s15.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        17
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s16 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s16 != null
                          ? monthstudentInfo.s16.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        18
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s17 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s17 != null
                          ? monthstudentInfo.s17.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        19
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s18 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s18 != null
                          ? monthstudentInfo.s18.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        20
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s19 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s19 != null
                          ? monthstudentInfo.s19.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        21
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s20 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s20 != null
                          ? monthstudentInfo.s20.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        22
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s21 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s21 != null
                          ? monthstudentInfo.s21.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        23
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s22 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s22 != null
                          ? monthstudentInfo.s22.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        24
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s23 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s23 != null
                          ? monthstudentInfo.s23.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        25
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s24 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s24 != null
                          ? monthstudentInfo.s24.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        26
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s25 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s25 != null
                          ? monthstudentInfo.s25.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        27
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s26 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s26 != null
                          ? monthstudentInfo.s26.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        28
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s27 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s27 != null
                          ? monthstudentInfo.s27.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        29
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s28 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s28 != null
                          ? monthstudentInfo.s28.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        30
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s29 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s29 != null
                          ? monthstudentInfo.s29.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={1.7}>
                <Box
                  sx={{
                    width: "80%",
                    marginLeft: "2%",
                    my: "2%",
                    // border: "3px solid black",
                  }}
                >
                  <Stack direction="column" sx={{ marginX: 0.5 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: "550",
                          fontSize: "17px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        31
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: monthstudentInfo.s30 == "absent" ? "red" : "black"
                        }}
                      >
                        {monthstudentInfo.s30 != null
                          ? monthstudentInfo.s30.toUpperCase()
                          : "--"}
                      </Typography>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </div>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 2 }}>
          <Button
            variant="contained"
            size="large"
            color="error"
            onClick={genaratePDF}
            sx={{
              borderRadius: "30px",
              width: "140px",
              marginBottom: 2,
              fontSize: 15,
              fontWeight: "bold",
            }}
          >
            PDF
          </Button>
          {/* <Button
                variant="contained"
                size="large"
                color="success"
                // onClick={exportToCSV}
                sx={{
                  borderRadius: "30px",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                excel
              </Button> */}
     
        <Button
                variant="contained"
                size="large"
                onClick={()=>{window.print()}}
                sx={{
                  borderRadius: "30px",
                  backgroundColor:"black",
                  color:"white",
                  width: "140px",
                  marginBottom:2,
                  fontSize: 15,
                  fontWeight: "bold",
                  "&:hover":{backgroundColor:"black",color:"white"}
                }}
              >
                print
              </Button>

          <Button
            variant="contained"
            size="large"
            onClick={onLaunchPad}
            sx={{
              borderRadius: "30px",
              backgroundColor: "#ca424f",
              color: "white",
              width: "140px",
              marginBottom: 2,
              fontSize: 15,
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#ca424f", color: "white" }
            }}
          >
            exit
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}
