import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class StandardSubjectService {
  // static serverURL = "http://143.110.188.253:1919/standardsubject/";
  static service = "standardsubject/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static standardsubject(contact) {
    var dataURL = this.serverURL + "create";

    // console.log("&&&&&&&@@@@@@&&&&&&&&&&&");
    // console.log(contact);

    return axios.post(dataURL, contact);
  }

  static findSubjectListByStandardIDYearID(yearID, standardID) {
    var dataURL =
      this.serverURL +
      "year/standard/list?standardID=" +
      standardID +
      "&yearID=" +
      yearID;

    return axios.get(dataURL);
  }
}
