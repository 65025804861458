import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Avatar, Stack, Typography } from "@mui/material";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { StaffService } from "../../../services/StaffService";
import { useNavigate } from "react-router-dom";
import { Button, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import { C } from "../../util/C";
import { ImageService } from "../../../services/ImageService";
import { AlertTitle, IconButton, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker, esES, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import StaffSubject from "../staffEntry/StaffSubject";
import StaffDocument from "../staffEntry/staffDocument";
import StaffPersonalDetail from "../staffEntry/staffDetails";
import StaffRemarkRecognition from "../staffEntry/staffRemarkRecognition";
import StaffFreeze from "../staffEntry/staffFreeze";
import StaffSalary from "../staffEntry/staffSalaryDetails";
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Fade from "@mui/material/Fade";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
}));
const FormItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  height: 180,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicGrid() {
  const navigate = useNavigate();
  React.useEffect(() => {
    onStaffLoad();
  }, []);  

  const [staff, setStaff] = React.useState([]);
  const onStaffLoad = async () => {
    try {
      let response = await StaffService.staffListAll();
      console.log(response.data.data);
      setStaff(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [value, setValue] = React.useState(0);
  const [dob, setDob] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleData = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    console.log(data.get("class"));
  };

  return (
    <React.Fragment>
      <div>
        <LaunchpadTopBar id="staff" />
        {/* ---LIST OF STAFF--- */}
        <Box>
          <Typography variant="h6" sx={{ textAlign: 'center',backgroundColor:'lightblue',borderRadius:"30px",mt:2}}>
            CLICK ON THE IMAGE BELOW TO GET INDIVIDUAL INFORMATION
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              {staff.map((element) => (
                <Grid item xs={2}>
                  <Item>
                    <PopupState variant="popper" popupId="demo-popup-popper">
                      {(popupState) => (
                        <div>
                          <Box
                            sx={{
                              display: "flex",
                              alignContent: "center",
                            }}
                          >
                            <Button {...bindToggle(popupState)}>
                              <Avatar
                                alt="Remy Sharp"
                                src={element.staffProfilePhoto}
                                variant="square"
                                // onClick={onStaff}
                                sx={{
                                  width: 150,
                                  height: 150,
                                  mx: 5,
                                }}
                              />
                            </Button>
                          </Box>
                          <Typography>{element.staffName}</Typography>
                          <Popper {...bindPopper(popupState)} transition>
                            {({ TransitionProps }) => (
                              <Fade {...TransitionProps} timeout={350}>
                                <Paper>
                                  <div>
                                    <Box
                                      component="form"
                                      onSubmit={handleData()}
                                      sx={{ flexGrow: 1 }}
                                    >
                                      <Grid container spacing={2}>
                                        <Grid item xs={10}>
                                          <Box sx={{ m: 2 }}>
                                            {/* STAFF NAME */}
                                            <Typography
                                              variant="h6"
                                              heading
                                              color="primary"
                                              fontWeight="bold"
                                              sx={{ textAlign: "center" }}
                                            >
                                              Staff Name
                                            </Typography>
                                          </Box>

                                          <Box sx={{ m: 2 }}>
                                            <Stack
                                              direction="row"
                                              sx={{ mb: 5 }}
                                            >
                                              {/* STAFF CLASS */}
                                              <TextField
                                                size="small"
                                                label="CLASS"
                                                fullWidth                                                
                                                variant="filled"
                                                name="class"
                                              />
                                              <FormControl
                                                fullWidth
                                                size="small"
                                              >
                                                {/* STAFF DESIGNATION */}
                                                <InputLabel>
                                                  Designation
                                                </InputLabel>
                                                <Select
                                                  labelId="designationID"
                                                  id="designation"
                                                  label="Designation"
                                                  variant="filled"
                                                  name="designation"
                                                  // onChange={handleChange}
                                                ></Select>
                                              </FormControl>
                                               
                                               {/* STAFF QUALIFICATION */}
                                              <TextField
                                                size="small"
                                                label="QALIFICATION "
                                                fullWidth                                                
                                                variant="filled"
                                                name="qualification "
                                              />

                                              {/* STAFF DOB */}
                                              <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                              >
                                                <DatePicker
                                                  label="DATE OF BIRTH"
                                                  value={dob}
                                                  onChange={(newDate) => {
                                                    setDob(newDate);
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      variant="filled"
                                                      size="small"
                                                      name="DOB"
                                                      fullWidth
                                                    />
                                                  )}
                                                />
                                              </LocalizationProvider>
                                              

                                              <FormControl
                                                variant="filled"
                                                size="small"
                                                fullWidth
                                              >
                                                {/* STAFF GENDER */}
                                                <InputLabel id="gender">
                                                  Gender
                                                </InputLabel>
                                                <Select                                                
                                                >
                                                  <MenuItem value={"Male"}>
                                                    {" "}
                                                    Male
                                                  </MenuItem>
                                                  <MenuItem value={"Female"}>
                                                    {" "}
                                                    Female
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
 
                                            {/* STAFF BLOOD GROUP */}
                                              <TextField
                                                size="small"
                                                id="sraffName"
                                                label="BLOOD GROUP"
                                                fullWidth                                                
                                                variant="filled"
                                                name="BLOOD GROUP"
                                              />

                                              {/* STAFF PHONE NO */}
                                              <TextField
                                                size="small"
                                                label="PHONE NO"
                                                fullWidth
                                                variant="filled"
                                                name="PHONE NO"
                                                InputProps={{
                                                  inputProps: {
                                                    inputMode: "numeric",
                                                    maxLength: 10,
                                                  },
                                                }}
                                              />                                             
                                            </Stack>

                                            {/* STAFF MOTHER'S NAME */}
                                            <Stack direction="row">
                                              <TextField
                                                size="small"
                                                label="MOTHER’S NAME "
                                                fullWidth                                                
                                                variant="filled"
                                                name="MOTHER’S NAME"
                                              />

                                              {/* STAFF FATHER’S NAME */}
                                              <TextField
                                                size="small"
                                                fullWidth
                                                label="FATHER’S NAME"                                            
                                                variant="filled"
                                                name="FATHER’S NAME"
                                              />

                                              {/* STAFF MARITALS */}
                                              <TextField
                                                size="small"
                                                label="MARITALS"
                                                fullWidth                                                
                                                variant="filled"
                                                name="MARITALS"
                                              />

                                              {/* STAFF ADDRESS */}
                                              <TextField
                                                size="small"
                                                label="ADDRESS"
                                                fullWidth                                                
                                                variant="filled"
                                                name="address"
                                              />
                                            </Stack>
                                            {/* END BUTTON */}
                                            <Button type="submit"> save</Button>
                                          </Box>
                                        </Grid>

                                        {/* STAFF IMAGE */}
                                        <Grid item xs={2}>
                                          <FormItem>
                                            <Avatar
                                              alt="A"
                                              sx={{
                                                width: 100,
                                                height: 100,
                                                margin: 8,
                                              }}
                                            />

                                            <input
                                              style={{ display: "none" }}
                                              id="contained-button-file"
                                              type="file"
                                              accept="image/*"
                                              name="studentImg"
                                              //  onChange={fileBrowseHandler}
                                            />

                                            <label htmlFor="contained-button-file">
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                component="span"
                                              >
                                                Upload Image
                                              </Button>
                                            </label>
                                          </FormItem>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box sx={{ width: "100%", mt: 3 }}>
                                      <Box
                                        sx={{
                                          borderBottom: 1,
                                          borderColor: "divider",
                                        }}
                                      >
                                        <Tabs
                                          value={value}
                                          onChange={handleChange}
                                          aria-label="basic tabs example"
                                        >
                                          <Tab
                                            label="ATTANDANCE"
                                            {...a11yProps(0)}
                                          />
                                          <Tab
                                            label="CLASS ROUTINE"
                                            {...a11yProps(1)}
                                          />
                                          <Tab
                                            label="SUBJECTS"
                                            {...a11yProps(2)}
                                          />
                                          <Tab
                                            label="DOCUMENTS"
                                            {...a11yProps(3)}
                                          />
                                          <Tab
                                            label="SALARIES DETAIL"
                                            {...a11yProps(4)}
                                          />
                                          <Tab
                                            label="PERSONAL INFORMATION "
                                            {...a11yProps(5)}
                                          />
                                          <Tab
                                            label=" REMARKS AND RECOGNISITION "
                                            {...a11yProps(6)}
                                          />
                                          <Tab
                                            label="FREEZE"
                                            {...a11yProps(7)}
                                          />
                                        </Tabs>
                                      </Box>
                                      
                                      <TabPanel value={value} index={0}>
                                        ATTANDANCE
                                      </TabPanel>
                                      <TabPanel value={value} index={1}>
                                        CLASS ROUTINE
                                      </TabPanel>
                                      <TabPanel value={value} index={2}>
                                        <StaffSubject />
                                      </TabPanel>
                                      <TabPanel value={value} index={3}>
                                        <StaffDocument />
                                      </TabPanel>
                                      <TabPanel value={value} index={4}>
                                        <StaffSalary />
                                      </TabPanel>
                                      <TabPanel value={value} index={5}>
                                        <StaffPersonalDetail />
                                      </TabPanel>
                                      <TabPanel value={value} index={6}>
                                        <StaffRemarkRecognition />
                                      </TabPanel>
                                      <TabPanel value={value} index={7}>
                                        <StaffFreeze />
                                      </TabPanel>
                                    </Box>
                                  </div>
                                </Paper>
                              </Fade>
                            )}
                          </Popper>
                        </div>
                      )}
                    </PopupState>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </div>
    </React.Fragment>
  );
}

