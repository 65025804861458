// import * as React from "react";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";

// const Item = styled(Paper)(({ theme }) => ({  
//   padding: theme.spacing(1),
//   textAlign: "center",
//   border: "2px solid black",
// }));

// export default function BasicGrid() {
//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <Grid container spacing={0}>
//         <Grid item xs={2}>
//           <Item>No.</Item>
//         </Grid>
//         <Grid item xs={4}>
//           <Item>DESCRIPTION OF DOCUMENT</Item>
//         </Grid>
//         <Grid item xs={3}>
//           <Item>IMAGE</Item>
//         </Grid>
//         <Grid item xs={3}>
//           <Item>EDIT / UPDATE</Item>
//         </Grid>
//         <Grid item xs={2}>
//           <Item>1</Item>
//         </Grid>
//         <Grid item xs={4}>
//           <Item>QUALIFICATION CERTIFICATES</Item>
//         </Grid>
//         <Grid item xs={3}>
//           <Item>avator</Item>
//         </Grid>
//         <Grid item xs={3}>
//           <Item>EDIT / UPDATE</Item>
//         </Grid>
//         <Grid item xs={2}>
//           <Item>2</Item>
//         </Grid>
//         <Grid item xs={4}>
//           <Item>RESUME / APPLICATION</Item>
//         </Grid>
//         <Grid item xs={3}>
//           <Item>avator</Item>
//         </Grid>
//         <Grid item xs={3}>
//           <Item>EDIT / UPDATE</Item>
//         </Grid>
//         <Grid item xs={2}>
//           <Item>3</Item>
//         </Grid>
//         <Grid item xs={4}>
//           <Item>APPOINTMENT LETTER</Item>
//         </Grid>
//         <Grid item xs={3}>
//           <Item>avator</Item>
//         </Grid>
//         <Grid item xs={3}>
//           <Item>upload</Item>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }

import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Button, ButtonGroup, Grid } from "@mui/material";
import { StaffService } from "../../../services/StaffService";
import { C } from "../../util/C";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { autoTable } from "jspdf-autotable";


export default function DataGridDemo(props) {
  const [columns, setColumns] = React.useState([
    {
      field: "id",
      headerName: "NO",
      flex: 0.5,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"} </strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "date",
      headerName: "DATE",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"DATE"} </strong>,
      headerClassName: "super-app-theme--header",
    },
    
    {
      field: "detail",
      headerName: "DETAIL",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"DETAIL"} </strong>,
      headerClassName: "super-app-theme--header",
    },
    
    {
      field: "remark",
      headerName: "REMARK",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"REMARK"} </strong>,
      headerClassName: "super-app-theme--header",
    },
    
  ]);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    console.log(props.data)
    onRemarksLoad();
  }, []);

 const onRemarksLoad = async() => {
  let ssid = props.data.ssid
  let staffRemark = [];
  try{
    let response = await StaffService.staffRemarkList(ssid);
    console.log(response.data.data);
   response.data.data.forEach((rec,i)=> {
    staffRemark.push({
      id: i+1,
      date: rec.remarkDate,
      detail : rec.remarkDetail,
      remark : rec.remark,
    });
    console.log(staffRemark);
    setRows(staffRemark);

   })
  }catch(error){
    console.log(error);
  }
 }

 const [academicYear, setacademicYear] = React.useState(
  JSON.parse(localStorage.getItem("academicYear"))
);
const [schoolName, setschoolName] = React.useState(
  JSON.parse(localStorage.getItem("schoolDetails"))
);

const generatePDF = () => {
  const doc = new jsPDF("p", "pt", "a4");
  const sch = schoolName.schoolNameOnDocs.toUpperCase();
  const acad = academicYear.yearName;
  const img = schoolName.schoolLogoUrl;
  doc.setFont("helvetica", "bold");
  var fontSize = 18;
  var text = `${sch}`;
  var pageWidth = doc.internal.pageSize.getWidth();
  var textWidth =
    (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
  var centerX = (pageWidth - textWidth) / 2;
  doc.text(text, centerX, 35);

  doc.addImage(img, "JPEG", 20, 10, 80, 70);
  doc.setFontSize(10);
  doc.text(` ${acad}`, 500, 35);
  doc.setFontSize(15);
  doc.setFont("helvetica", "bold");
  doc.text("REMARKS AND RECOGNISITION", 150, 75);

  doc.setFontSize(14);
  doc.text(props.data.staffName + "--"+props.data.ssid, 210,120);
  const imag = props.data.staffProfilePhoto;
  doc.addImage(imag,"JPEG",420,80,80,85 );

  const tableData = rows.map((row) => [
    row.id,
    row.date,
    row.detail,
    row.remark,
  ]);

  doc.autoTable({
    startY: 175,
    tableLineWidth: 0.45,
    head: [
      ["NO", "DATE", "DETAILS", "REMARK"],
    ],
    body: tableData,
    headStyles: {
      valign: "middle",
      halign: "center",
      lineColor: [0,0,0],
    },
    bodyStyles: {
      valign: "middle",
      halign: "center",
      lineColor: [0,0,0],
    },
    theme: "grid",
  });
  doc.setFontSize(10);
  // doc.text("Copyright: Technotharanga Solutions Pvt.ltd", 190, 800);
  doc.save("Remarks and Recognisition"+"-"+props.data.ssid+" "+ ".pdf");
};

const fileType = "csv";
const exportToCSV = () => {
  const visible = XLSX.utils.json_to_sheet(rows);
  const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  filesaver.saveAs(data, "Staff Remarks and Recognisition" + ".csv");
};

  // const onAddRoW = (e) => {
  //   console.log(rows.length);
  //   let new_row = {
  //     id: rows.length + 1,
  //     lastName: "",
  //     firstName: "",
  //     age: 0,
  //   };
  //   setRows((rows) => [...rows, new_row]);
  //   console.log(rows);
  // };

  return (
    <>
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        disableColumnMenu
        rowsPerPageOptions={[5]}
        sx={{
          width: "100%",
              "& .super-app-theme--header": {
                backgroundColor: "#c0c0c0",
                // color:'white',
                fontWeight: "bold",
                fontSize: "15px",
                border: "1px solid white",
              },
        }}
      />
      
     
    </Box>
    <Box sx={{ display: "flex", justifyContent: "space-around", mt:2 }}>
    <Button
             variant="contained"
             size="large"
             color="error"
             onClick={generatePDF}
             sx={{
               borderRadius: "30px",
               width: "140px",
               marginBottom:2,
               fontSize: 15,
               fontWeight: "bold",
             }}
           >
             PDF
           </Button>
           <Button
             variant="contained"
             size="large"
             color="success"
             onClick={exportToCSV}
             sx={{
               borderRadius: "30px",
               width: "140px",
               marginBottom:2,
               fontSize: 15,
               fontWeight: "bold",
             }}
           >
             excel
           </Button>
  
     <Button
             variant="contained"
             size="large"
             onClick={()=>{window.print()}}
             sx={{
               borderRadius: "30px",
               backgroundColor:"black",
               color:"white",
               width: "140px",
               marginBottom:2,
               fontSize: 15,
               fontWeight: "bold",
               "&:hover":{backgroundColor:"black",color:"white"}
             }}
           >
             print
           </Button>
   
     </Box>
     </>
  );
}

