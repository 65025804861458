import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class DesignationService {
  // static serverURL = "http://143.110.188.253:1919/designation/";
  static service = "designation/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createDesignation(contact) {
    var dataURL = this.serverURL + "create";

    return axios.post(dataURL, contact);
  }

  // static deleteDesignation(contact) {
  //   var dataURL = this.serverURL + "create";

  //   return axios.post(dataURL, contact);
  // }

  static deleteDesignation(contact) {
    var dataURL = this.serverURL + "delete?designationID=" + contact;

    return axios.delete(dataURL);
  }

  static designationListAll(yearName) {
    console.log(this.serverURL);
    var dataURL = this.serverURL + "listall?yearName=" + yearName;
    console.log(dataURL);

    return axios.get(dataURL);
  }
}
