import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { Box, Button, Grid, Tab, Tabs } from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { C } from "../../util/C";
import { ImageService } from "../../../services/ImageService";
import {
  AlertTitle,
  Avatar,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker, esES, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import StaffSubject from "../staffEntry/StaffSubject";
import StaffDocument from "../staffEntry/staffDocument";
import StaffPersonalDetail from "./staffDetails";
import StaffRemarkRecognition from "../staffEntry/staffRemarkRecognition";
import StaffFreeze from "../staffEntry/staffFreeze";
import StaffSalary from "../staffEntry/staffSalaryDetails";
import { DesignationService } from "../../../services/DesignationService";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
}));

const FormItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  height: 180,
}));

const SubItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicGrid(props) {
  const [selectedStaff, setSelectedStaff] = React.useState(props.data);
  const [value, setValue] = React.useState(0);
  const [dob, setDob] = React.useState(null);

  React.useEffect(() => {
    onDesignationload();
    C.c(selectedStaff);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    console.log(data.get("CLASS"));
    let staffOBJ = {
      designation: data.get("class"),
      staffQualification: data.get("qualification"),
      staffDOB: data.get("dob"),
      bloodGroup: data.get("blood group"),
      staffMobileNo: [data.get("phone no")],
      staffMotherName: data.get("mother's name"),
      staffFatherName: data.get("father's name"),
      staffMaritals: data.get("maritals"),
      staffAdress: data.get("address"),
    };
    console.log(staffOBJ);
  };

  const [designation, setDesignation] = React.useState([]);

  const onDesignationload = async () => {
    try {
      let response = await DesignationService.designationListAll();
      setDesignation(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {/* <LaunchpadTopBar id="staff".. /> */}
      <Box component="form" onSubmit={handleSubmit} sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Box sx={{ m: 2 }}>
              <Typography
                variant="h6"
                heading
                color="primary"
                fontWeight="bold"
                sx={{ textAlign: "center" }}
              >
                Staff Name
              </Typography>
            </Box>
            <Box sx={{ m: 2 }}>
              <Stack direction="row" sx={{ mb: 5 }}>
                <TextField
                  size="small"
                  label="CLASS"
                  fullWidth
                  variant="filled"
                  name="class"
                />
                <FormControl fullWidth size="small">
                  <InputLabel>Designation</InputLabel>
                  <Select
                    labelId="designationID"
                    id="designation"
                    // value={designationVal}
                    label="Designation"
                    variant="filled"
                    name="designation"
                    // onChange={handleChange}
                  >
                    {designation.map((element) => (
                      <MenuItem value={element.designationID}>
                        {element.designation}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  size="small"
                  label="QALIFICATION "
                  fullWidth
                  variant="filled"
                  name="qualification"
                />

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="DATE OF BIRTH"
                    value={dob}
                    onChange={(newDate) => {
                      setDob(newDate);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        size="small"
                        name="dob"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>

                <FormControl variant="filled" size="small" fullWidth>
                  <InputLabel id="gender">Gender</InputLabel>
                  <Select>
                    <MenuItem value={"Male"}> Male</MenuItem>
                    <MenuItem value={"Female"}> Female</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  size="small"
                  id="sraffName"
                  label="BLOOD GROUP"
                  fullWidth
                  variant="filled"
                  name="blood group"
                />
                <TextField
                  size="small"
                  label="PHONE NO"
                  fullWidth
                  variant="filled"
                  name="phone no"
                  InputProps={{
                    inputProps: {
                      inputMode: "numeric",
                      maxLength: 10,
                    },
                  }}
                />
              </Stack>
              <Stack direction="row">
                <TextField
                  size="small"
                  label="MOTHER’S NAME "
                  fullWidth
                  variant="filled"
                  name="mother's name"
                />
                <TextField
                  size="small"
                  fullWidth
                  label="FATHER’S NAME"
                  variant="filled"
                  name="father's name"
                />
                <TextField
                  size="small"
                  label="MARITALS"
                  fullWidth
                  variant="filled"
                  name="maritals"
                />
                <TextField
                  size="small"
                  label="ADDRESS"
                  fullWidth
                  variant="filled"
                  name="address"
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <FormItem>
              <Avatar alt="A" sx={{ width: 100, height: 100, margin: 8 }} />
              <input
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
                accept="image/*"
                name="studentImg"
                //  onChange={fileBrowseHandler}
              />
              <label htmlFor="contained-button-file">
                {/* <Button variant="contained" color="primary" component="span">
                  Upload Image
                </Button> */}
              </label>
            </FormItem>
          </Grid>
        </Grid>
        <Button type="submit">save</Button>
      </Box>
      <Box sx={{ width: "100%", mt: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="ATTANDANCE" {...a11yProps(0)} />
            <Tab label="CLASS ROUTINE" {...a11yProps(1)} />
            <Tab label="SUBJECTS" {...a11yProps(2)} />
            <Tab label="DOCUMENTS" {...a11yProps(3)} />
            <Tab label="SALARIES DETAIL" {...a11yProps(4)} />
            <Tab label="PERSONAL INFORMATION " {...a11yProps(5)} />
            <Tab label=" REMARKS AND RECOGNISITION " {...a11yProps(6)} />
            <Tab label="FREEZE" {...a11yProps(7)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          ATTANDANCE
        </TabPanel>
        <TabPanel value={value} index={1}>
          CLASS ROUTINE
        </TabPanel>
        <TabPanel value={value} index={2}>
          <StaffSubject />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <StaffDocument />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <StaffSalary />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <StaffPersonalDetail />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <StaffRemarkRecognition />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <StaffFreeze />
        </TabPanel>
      </Box>
    </div>
  );
}
