import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class BuildSchoolExam {
  // static serverURL = "http://143.110.188.253:1919/testtitle/";
  static service = "testtitle/";
  static service1 = "standardtest/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  //  * Add test
  static addBuildSchoolStandardsTest(contact) {
    var dataURL = this.serverURL + "create";
    return axios.post(dataURL, contact);
  }
  // * list test
  static listBuildSchoolStandardTest(yearName) {
    var dataURL = this.serverURL + "list?yearName=" + yearName;

    return axios.get(dataURL);
  }
  // * delete test
  static deleteBuildSchoolStandardTest(contact, yearID, yearName) {
    var dataURL =
      this.serverURL +
      "delete?testTitleName=" +
      contact +
      "&yearID=" +
      yearID +
      "&yearName=" +
      yearName;
    return axios.delete(dataURL, contact);
  }

  // * not used
  static getBuildSchoolStandardTest(contact) {
    var dataURL = this.serverURL + "get";
    return axios.get(dataURL, contact);
  }
  // * not used

  static serverURL1 = this.baseURL + this.service1;

  static saveBuildSchoolStandardTest(contact) {
    console.log(contact);
    var dataURL = this.serverURL1 + "create";
    return axios.post(dataURL, contact);
  }

  // static showBuildSchoolStandardTest(contact) {
  //   var dataURL = this.serverURL1 + "list";
  //   return axios.get(dataURL, contact);
  // }

  // * load test on select standard
  static showBuildSchoolStandardTest(contact, yearName) {
    var dataURL =
      this.serverURL1 +
      "year/standard/list?standardID=" +
      contact +
      "&yearID=" +
      yearName;
    return axios.get(dataURL);
  }

  // * create test to standard
  static showBuildSchoolStandardTestScore(contact) {
    var dataURL = this.serverURL1 + "testvalue/create";
    return axios.post(dataURL, contact);
  }

  static deleteExamTitleFromExamList(contact) {
    console.log(contact);
    var dataURL = this.serverURL1 + "testtitle/delete";
    return axios.delete(dataURL, contact);
  }

  static deleteExamTitleFromForStandard(testTitleName, standardId, yearName) {
    // console.log(contact);
    var dataURL =
      this.serverURL1 +
      "delete/standard?standardID=" +
      standardId +
      "&testTitleName=" +
      testTitleName +
      "&yearID=" +
      yearName;
    return axios.delete(dataURL);
  }
  // standardtest/delete/standard?standardID=5566cd4f-3a11-4695-b4b4-16ee358f0af9&testTitleName=ENGLISH&yearID=75c2fd74-6b90-4784-bc28-646e4412a739

  // /standardtest/testvalue/create

  static getStandardSubjectTestValue(
    standardID,
    subjectID,
    testName,
    yearName
  ) {
    var dataURL =
      this.serverURL1 +
      "testvalueyear/standard/subject/test/list?standardID=" +
      standardID +
      "&subjectID=" +
      subjectID +
      "&testTitleName=" +
      testName +
      "&yearID=" +
      yearName;
    //localhost:1919/standardtest/testvalueyear/standard/subject/test/list?standardID=a26d7070-aeb5-42e8-a45a-09030b4b4b84&subjectID=a720fd78-9aec-4d53-bf35-74787174773c&testTitleName=test1&yearID=2023-2024
    return axios.get(dataURL);
  }
}
