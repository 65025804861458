import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class User {
  // static serverURL = "http://143.110.188.253:1919/year/";
  static service = "user/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static UserList() {
    var dataURL = this.serverURL + "list?pageNo=0";
    return axios.get(dataURL);
  }

  static UserLogin(contact) {
    var dataURL = this.serverURL + "login";
    return axios.post(dataURL, contact);
  }

  static UserRegister(data) {
    var dataURL = this.serverURL + "register";
    return axios.post(dataURL, data);
  }
}
