import axios from "axios";
import { AppRootUrl } from "./serviceUtil/rootUrl";

export class StaffService {
  // static serverURL = "http://143.110.188.253:1919/staff/";
  static service = "staff/";
  static baseURL = AppRootUrl.Rooturl();

  static serverURL = this.baseURL + this.service;

  static createStaff(contact) {
    var dataURL = this.serverURL + "create";

    return axios.post(dataURL, contact);
  }

  static salaryDetails(contact) {
    var dataURL = this.serverURL + "createStaffBankDetails";
    return axios.post(dataURL, contact);
  }

  static bankDetails(yearName, month) {
    // var dataURL = this.serverURL + "getAllStaffBankDetails?year="+yearName;

    var dataURL =
      this.serverURL +
      "getAllStaffBankDetails?Month=" +
      month +
      "&year=" +
      yearName;
    // http://localhost:1919/staff/getAllStaffBankDetails?year=2024
    return axios.get(dataURL);
  }

  static staffListAll() {
    var dataURL = this.serverURL + "listall";
    return axios.get(dataURL);
  }

  // static staffListAll(){
  //   var dataURL=this.serverURL+"listall";
  //   return axios.get(dataURL);
  // }

  static salariesDetailsList() {
    var dataURL = this.serverURL + "salariesDetailsList";
    return axios.get(dataURL);
  }

  static staffUpdates(contact) {
    var dataURL = this.serverURL + "staffUpdate";
    return axios.post(dataURL, contact);
  }

  static updatesalariesDetails(contact) {
    var dataURL = this.serverURL + "updatesalariesDetails";
    // http://localhost:1919/staff/updatesalariesDetails
    return axios.post(dataURL, contact);
  }
  static updatesalariesMonthlyDetails(contact) {
    var dataURL = this.serverURL + "updateStaffMonthlySalariesDetails";
    // http://localhost:1919/staff/updatesalariesDetails
    return axios.post(dataURL, contact);
  }
  static paySalariesDetailsmonth(contact) {
    var dataURL = this.serverURL + "paySalariesDetailsmonth";
    // http://localhost:1919/staff/updatesalariesDetails
    return axios.post(dataURL, contact);
  }

  static staffSalaryDetailsList(yearName) {
    var dataURL = this.serverURL + "staffSalaryDetailsList?year=" + yearName;
    // http://localhost:1919/staff/staffSalaryDetailsList?year=2024
    return axios.get(dataURL);
  }

  static staffSalaryDetailsMonths(ssid, yearName) {
    var dataURL =
      this.serverURL +
      "staffSalarypayDetailsList?SSID=" +
      ssid +
      "&year=" +
      yearName;
    return axios.get(dataURL);
  }

  static getStaffBankDetails(ssid, yearName) {
    var dataURL =
      this.serverURL + "getStaffBankDetails?SSID=" + ssid + "&year=" + yearName;
    return axios.get(dataURL);
  }

  static createStaffDocuments(contact) {
    var dataURL = this.serverURL + "createStaffDocuments";
    return axios.post(dataURL, contact);
  }

  static createStaffRemarks(staffRemarks) {
    var dataURL = this.serverURL + "createStaffRemarks";
    return axios.post(dataURL, staffRemarks);
  }

  static createStaffDocumentsRemarks(contact) {
    var dataURL = this.serverURL + "document/staff/create";
    return axios.post(dataURL, contact);
  }

  static getStaffDetails(ssid) {
    var dataURL = this.serverURL + "getStaffDetails?SSID=" + ssid;
    // http://localhost:1919/staff/getStaffDetails?SSID=He2
    return axios.get(dataURL);
  }

  static staffRemarkList(ssid) {
    var dataURL = this.serverURL + "remarkRecognisitionList?SSID=" + ssid;
    return axios.get(dataURL);
  }

  static getStaffDocuments(ssid) {
    var dataURL = this.serverURL + "document/staff/list?SSID=" + ssid;
    return axios.get(dataURL);
  }

  static getStaffDocumentsRemarks(ssid) {
    var dataURL = this.serverURL + "document/staff/list?SSID=" + ssid;
    return axios.get(dataURL);
  }

  static getStaffSalaryLaunchpad(present, previous, y) {
    var dataURL =
      this.serverURL +
      "staffSalaryLaunchPadDetails?presentMonth=" +
      present +
      "&priviousMonth=" +
      previous +
      "&year=" +
      y;
    return axios.get(dataURL);
  }

static getStaffSalarypaymonthList(month, year) {
  var dataURL=
  this.serverURL +
  "staffSalarypaymonthList?month="+
  month+
  "&year="+
  year;

  return axios.get(dataURL);
}

}
