import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";

const columns = [
  { field: "id", headerName: "No.", width: 90 },

  {
    field: "name",
    headerName: "Name",
    width: 250,
    editable: true
  },

  {
    field: "gender",
    headerName: "Gender",
    width: 90,
    editable: true
  },

  {
    field: "usid",
    headerName: "USID",
    width: 110,
    editable: true
  },

  {
    field: "std",
    headerName: "STD/DIV",
    width: 100
  },

  {
    field: "fathersName",
    headerName: "Fathers Name",
    width: 250
  },

  {
    field: "mothersName",
    headerName: "Mothers Name",
    width: 250
  }
];

const rows = [
  {
    id: 1,
    name: "Sali Amir",
    gender: "Boy",
    usid: "SA0013",
    std: "1/A",
    fathersName: "Suhail Mohammed",
    mothersName: "Shaheena"
  },
  {
    id: 2,
    name: "Aesalan Khan",
    gender: "Boy",
    usid: "MS0012",
    std: "2/B",
    fathersName: "Mohammed Usman",
    mothersName: "Ayesha"
  },
  {
    id: 3,
    name: "Salim Shaikh",
    gender: "Boy",
    usid: "SS0016",
    std: "9/A",
    fathersName: "Sarfaraz Shaikh",
    mothersName: "Aqsa"
  },
  {
    id: 4,
    name: "Mohammed Salman",
    gender: "Boy",
    usid: "MS0018",
    std: "PK/A",
    fathersName: "Mohammed Affan",
    mothersName: "Sanaa"
  },
  {
    id: 5,
    name: "Salil Choudhary",
    gender: "Boy",
    usid: "SC0008",
    std: "LK/A",
    fathersName: "Shamil Choudhary",
    mothersName: "Shamila"
  },
  {
    id: 6,
    name: "Saima Taj",
    gender: "Girl",
    usid: "ST0046",
    std: "5/A",
    fathersName: "Rahim Sherieff",
    mothersName: "Rahima"
  }
];

export default function Student() {
  return (
    <div>
        <LaunchpadTopBar id="reports" />
      <Box sx={{ border: "2px solid black" }}>
        <Grid container spacing={0.5}>
          <Grid item xs={4} sx={{ border: "2px solid black" }}>
            <Typography
              variant="subtitle2"
              fontWeight="fontWeightBold"
              color="darkblue"
            >
              FILL NEW ADMISSION FOR THE NEXT ACADEMIC YEAR
            </Typography>
          </Grid>

          <Grid item xs={4} sx={{ border: "2px solid black" }}>
            <Typography
              variant="subtitle2"
              fontWeight="fontWeightBold"
              color="darkblue"
            >
              GRANT/REJECT ADMISSION FOR NEXT ACADEMIC YEAR
            </Typography>
          </Grid>

          <Grid item xs={4} sx={{ border: "2px solid black" }}>
            <Typography
              variant="subtitle2"
              fontWeight="fontWeightBold"
              color="darkblue"
            >
              ACCEPT REQUEST FOR TC/LC
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Typography
        variant="h6"
        color="darkblue"
        align="center"
        fontWeight="fontWeightBold"
        sx={{ marginBottom: 1, marginTop: 1 }}
      >
        ACCEPTED REQUEST FOR TC/LC FOR FOLLOWING STUDENT
      </Typography>

      <Box sx={{ border: "2px solid black", marginBottom: 3 }}>
        <Grid container>
          <Grid item xs={12} sx={{ display: "flex" }}>
            <Typography
              variant="body1"
              color="steelblue"
              fontWeight="fontWeightBold"
            >
              Search Students(Name/USID) Min 3 letters
              <TextField
                size="small"
                sx={{
                  padding: "-20",
                  borderRadius: "10px",
                  marginLeft: 3,
                  height: "41px",
                  border: "2px solid black"
                }}
              >
                {" "}
              </TextField>
              <Button
                variant="outlined"
                sx={{
                  color: "black",
                  backgroundColor: "aqua",
                  marginLeft: 2,
                  borderRadius: "10px",
                  border: "2px solid black",
                  marginY: 0.3,
                  fontWeight: "fontWeightBold"
                }}
              >
                search
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "black",
                  backgroundColor: "yellow",
                  marginLeft: 30,
                  borderRadius: "10px",
                  border: "3px solid red",
                  fontWeight: "fontWeightBold"
                }}
              >
                click on the name to select
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "80%", display: "flex", justifyContent: "center" }}>
        <Box sx={{ height: 400, width: "85%" }}>
                  <DataGrid rows={rows} columns={columns} rowsPerPageOptions={[5]} pageSize={5} />
        </Box>
      </Box>
      <Box sx={{ marginTop: 2.5 }}>
        <Button
          variant="contained"
          sx={{
            marginLeft: 135,
            borderRadius: "25px",
            fontWeight: "fontWeightBold"
          }}
        >
          confirm
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{
            color: "black",
            marginLeft: 3,
            borderRadius: "25px",
            fontWeight: "fontWeightBold",
            width: 120
          }}
        >
          clear
        </Button>
      </Box>
    </div>
  );
}
